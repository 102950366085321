import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import get from 'lodash/get'
import { collections, db } from '../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import firebase from 'firebase/app'
import {
  RequestActionType,
  RequestStatus,
  SupportTicketStatus,
  SupportTicketType,
} from '../../../constants/enums'
import { MiniAccordion } from '../../../components/MiniAccordion'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { LoadingButton } from '../../../components/LoadingButton'
import { operativeOfferWithdrawal } from '../../../utils/notificationHandlers'

const {
  mobStatusButton,
  acceptButton,
  declineButton,
  fullOpacity,
} = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const OperativeWithAcceptedOffer = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const accordionState = useRecoilValue(requestActionAccordionState)
  const { activeRequests } = collections
  const withdrawAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.withdraw
  const [withdrawLoading, setWithdrawLoading] = useState(false)

  /**
   * Called by a model with an accepted offer. This function will:
   * 1. Set the offer to canceled
   * 2. Create a support ticket to refund the agency
   * 3. Send notifications to both parties to confirm the cancellation
   */
  const withdrawOfferAcceptanceHandler = () => {
    setWithdrawLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.canceled,
        operativeWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    batch.set(
      requestReference.collection('history').doc(),
      {
        note: `${currentUserAuth.uid} withdrew from this offer. ${booking.gig.createdBy} needs to be refunded`,
        timestamp: serverTimestamp,
      },
      { merge: true }
    )

    // After the withdrawal is successful create a ticket to refund the Agency
    batch.set(
      collections.supportTickets.doc(),
      {
        createdBy: booking.request.eventCreator,
        supportRequest: `Refund for gig ${requestId}`,
        requestType: SupportTicketType.refund,
        status: SupportTicketStatus.received,
        additionalInfo: '',
        createdAt: firebase.firestore.Timestamp.now(),
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        operativeOfferWithdrawal(
          booking.request.eventCreator,
          currentUserAuth.uid
        )
        setWithdrawLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_WITHDRAWING_FROM_OFFER',
          message: `Failed to withdraw user from request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OperativeWithAcceptedOffer.jsx',
        })
      })
  }

  // Model has accepted and has the option to withdraw from offer
  return (
    <>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, acceptButton, fullOpacity]}
        disabled={true}
      >
        ACCEPTED
      </Button>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 20px 5px 5px 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Available Actions
      </Text>
      {withdrawLoading && (
        <LoadingButton
          mobStyles={[mobStatusButton, declineButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!withdrawLoading && (
        <MiniAccordion
          closedButtonText="WITHDRAW"
          openButtonText="CANCEL"
          onClick={() => {
            updateAccordionState(RequestActionType.withdraw)
          }}
          open={withdrawAccordionOpen}
          buttonMobStyles={[mobStatusButton, declineButton]}
        >
          <Button
            mobStyles={[mobStatusButton, declineButton]}
            onClick={withdrawOfferAcceptanceHandler}
          >
            CONFIRM WITHDRAW
          </Button>
        </MiniAccordion>
      )}
    </>
  )
}
