import { css } from 'styled-components'
import { up } from 'styled-breakpoints'

export const mobLandingFooter = css`
  background-color: ${p => p.theme.colors.primary};
  bottom: 0;
  color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;
  height: 130px;

  ${up('tablet')} {
    height: 72px;
  }
`
export const mobLandingFooterIconWrapper = css`
  display: flex;
  width: 140px;
  height: 60px;
  justify-content: space-evenly;

  ${up('tablet')} {
    display: none;
  }
`
export const mobStylesHeading = css`
  font-family: ${p => p.theme.fonts.primary};
  color: ${p => p.theme.colors.secondary};
  font-weight: 800;
  font-size: 32px;
  line-height: 0.95;
`
