import React from 'react'
import Loader from 'react-loader-spinner'
import { Box, mobBoxVariants } from '../../shared/Box'
import { css } from 'styled-components'

const { center } = mobBoxVariants

const loaderWrapperStyles = css`
  height: 70vh;
  width: 100%;
`

/**
 * Should only be used at the top level. Not for lower level components
 * @returns {*}
 * @constructor
 */
// https://github.com/mhnpd/react-loader-spinner
export const LoadingScreen = () => {
  return (
    <Box mobStyles={[center, loaderWrapperStyles]}>
      <Loader type="ThreeDots" color="#7c848b" height={100} width={200} />
    </Box>
  )
}
