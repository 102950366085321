import styled from 'styled-components'
import { variantsAndStyles } from '../../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

export const StyledTableHead = styled.thead`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${variantsAndStyles()}
`

export const StyledMotionTableHead = styled(motion.thead)`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${variantsAndStyles()}
`
