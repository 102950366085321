import React, { useEffect, useRef, useState } from 'react'
import 'firebase/storage'
import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'
import get from 'lodash/get'

import { collections } from '../../firebaseApp'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/index'
import { FaqForm } from '../../classes/Forms/FaqForm'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { FaqContent } from './FaqContent.jsx'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { uuid } from '../../utils/uuid'

const { column } = mobBoxVariants
const { mobInstructionParagraph, mobFaqHeader } = mobTextVariants
const { deskFaqHeader, deskFaqText } = deskTextVariants

/**
 * User should be able to view questions we get all the time.
 * And also submit questions that are not listed.
 *
 * @constructor
 */
export const FaqPage = ({ currentUserAuth }) => {
  // Grab Authenticated User
  const [questions, setQuestions] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [submitFormDisabled, setSubmitFormDisabled] = useState(true)

  const toggleSubmitButton = disable => {
    setSubmitFormDisabled(disable)
  }

  // Only use ref for Classes and form that will never receive pre-filled data
  const faqForm = useRef(new FaqForm(toggleSubmitButton)).current

  // Use Firestore React Hook to get all the current FAQ questions.
  const [
    questionsSnapshot,
    questionsSnapshotLoading,
    questionsSnapshotError,
  ] = useCollection(collections.questions)

  const handleFaqRequest = () => {
    if (faqForm.validFaqData()) {
      setShowLoading(true)
      // Add question to questions data in collection called requests
      collections.faqRequests
        .add({
          requestor: currentUserAuth.uid,
          question: faqForm.question,
          additionalInfo: faqForm.additionalInfo || '',
          createdAt: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          // TODO: Clear form. V2
          setShowLoading(false)
        })
        .catch(error => {
          // TODO: Improve showing the error state visually. V2
          errorHandler({
            error,
            errorId: 'ERROR_FAQ_FORM',
            message: 'Error while user trying to create a FAQ request.',
            type: errorTypes.faq,
            file: 'FAQPage.jsx',
          })
        })
    } else {
      // TODO: Show show error state visually. V2
    }
  }

  useEffect(() => {
    setQuestions([])
    if (questionsSnapshot) {
      questionsSnapshot.docs.map(question => {
        setQuestions(currentQuestions => [...currentQuestions, question.data()])
      })
    }
  }, [questionsSnapshot])

  const createFaqQuestion = questionDocument => {
    const faq = new FrequentlyAskedQuestion(questionDocument)
    return (
      <Box mobStyles={[column]} key={uuid()}>
        <Text mobStyles={[mobFaqHeader]} deskStyles={deskFaqHeader}>
          {faq.question}
        </Text>
        <Text mobStyles={mobInstructionParagraph} deskStyles={deskFaqText}>
          {faq.answer}
        </Text>
        <br />
      </Box>
    )
  }

  // TODO: Separate the questions by category. i.e. Profile, Payments, Memberships, Information Privacy etc V2-V3
  // TODO: Cool EXTRA Super Duper Brownie Points. Make the FAQ section search results that narrow as the user forms a question. V2-V3
  return (
    <FaqContent
      questionsSnapshot={questionsSnapshot}
      questionsSnapshotLoading={questionsSnapshotLoading}
      questionsSnapshotError={questionsSnapshotError}
      questions={questions}
      createFaqQuestion={createFaqQuestion}
      faqForm={faqForm}
      showLoading={showLoading}
      handleFaqRequest={handleFaqRequest}
      submitFormDisabled={submitFormDisabled}
      currentUserAuth={currentUserAuth}
    />
  )
}

/**
 * JS wrapper for FAQ question data
 */
export class FrequentlyAskedQuestion {
  question
  answer

  constructor(faqDocument) {
    this.faqDocument = faqDocument
    this.faqDocumentData = this.faqDocument
    this.question = get(this.faqDocumentData, 'question', '')
    this.answer = get(this.faqDocumentData, 'answer', '')
  }
}
