import React from 'react'
import PropTypes from 'prop-types'

import { StyledLabel, StyledMotionLabel } from './styles'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Label = ({
  fontSize = {
    mob: '16px',
    tab: '18px',
    desk: '18px',
    lgDesk: '18px',
  },
  children,
  ...props
}) => {
  if (props.enableMotion) {
    return (
      <StyledMotionLabel fontSize={fontSize} {...props}>
        {children}
      </StyledMotionLabel>
    )
  }

  return (
    <StyledLabel fontSize={fontSize} {...props}>
      {children}
    </StyledLabel>
  )
}

Label.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
}
