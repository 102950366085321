import { ChatRoom } from './ChatRoom'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import firebase from 'firebase/app'

class MessageError extends Error {}

export class Message {
  /**
   * @constructor
   * @param {String} body String: the message body (message it self)
   * @param {String} fromUserId String: the id of the user sent this message
   * @param  {ChatRoom} chatRoom ChatRoom: reference to the chat room this message in
   * @param {(err:Error)=>void} [onComplete] Callback function call after changing chat room title or with err if not     *
   * @returns {Message} Message that has been created
   *
   */
  constructor(body, fromUserId, chatRoom, onComplete, fromRef) {
    // Check validation message body
    if (isEmpty(body) || !isString(body)) {
      throw new MessageError('Message should have body and be string')
    }
    this.body = body

    // // Check validation of the user id string
    // if (isEmpty(fromUserId)) {
    //   throw new MessageError('From should be not empty')
    // } else {
    //
    // }
    //
    // // Check if the user in this chat room
    // if (!isEmpty(intersection(chatRoom.members, [fromUserId]))) {
    //   throw new MessageError(" 'from' user must be in this chat room")
    // } else {
    // }

    // Set this instance of Message from to fromUserId
    this.from = fromUserId

    // Make sure ChatRoom is the correct Class
    if (!chatRoom instanceof ChatRoom) {
      throw new MessageError('chatRoom should be instance of ChatRoom class')
    }

    this.chatRoom = chatRoom
    this.createdAt = firebase.firestore.Timestamp.now()

    // If no reference to a Message is passed then this is a new message being created
    // Generate a new document for it and set it
    if (isEmpty(fromRef)) {
      this.messageRef = chatRoom.chatRoomRef.collection('messages').doc()

      this.messageRef
        .set({
          body: this.body,
          from: this.from,
          createdAt: this.createdAt,
          seen: false,
        })
        .then(onComplete)
    } else {
      // Else just set the passed in reference to the instance of this Class
      this.messageRef = fromRef
    }
  }

  /**
   * update message. DONT THINK WE"LL EVER NEED THIS BUT KEEPING IT AROUND
   * @param {String} newBody new message body for this message
   * @param  {(newBody:String)=>void} [callback] action after update
   *
   */
  updateBody = (newBody, callback) => {
    // check validation message body
    if (isEmpty(newBody) || !isString(newBody))
      throw new MessageError('Message should have body and be string')

    this.updatedAt = Date.now()
    // update chat room title
    this.messageRef
      .update({
        body: newBody,
        updatedAt: Date.now(),
      })
      .then(callback(newBody))
  }

  /**
   * remove message
   * @param {(err:Error)=>void)} [afterRemove] action after remove the message
   * @returns {Message} Message object
   */
  remove(afterRemove) {
    this.messageRef.remove(afterRemove)
    return this
  }
}
