import { css } from 'styled-components'

export const mobAutoCompleteTextArea = css`
  margin: 0;
  width: 100%;
  font-family: ${p => p.theme.fonts.primary};
  font-size: 16px;
  line-height: 16px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  resize: none;
  height: auto;
  border-radius: 5px;
  background-color: ${p => p.theme.colors.secondary};
  color: ${p => p.theme.colors.darkGray};
  box-shadow: ${p => p.theme.shadow.regular};
  ::placeholder {
    color: ${p => p.theme.colors.darkGray};
    opacity: 0.5;
  }
`

export const mobAutoCompleteWrapper = css`
  width: 100%;
  position: relative;
`

export const mobAutoCompleteDropdown = css`
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 100;
`

export const mobAutoCompleteSelection = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.primary};
  text-align: center;
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  line-height: 15px;
  margin: 10px 0;
`

export const mobAutoCompleteSelectionWrapper = css`
  margin: 0;
  width: 100%;
  display: inline-block;
  font-family: ${p => p.theme.fonts.primary};
  font-size: 16px;
  line-height: 22px;
  height: 48px;
  background-color: ${p => p.theme.colors.secondary};
  color: ${p => p.theme.colors.darkGray};
  box-shadow: ${p => p.theme.shadow.regular};
`
