// FIXME: V2 PAGE
import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { collections } from '../../firebaseApp'
import { ProfileBox, ProfileBoxType } from '../../components/ProfileBox'
import { SimpleSearchBar } from '../../components/SimpleSearchBar'
import { LoadingScreen } from '../../components/LoadingScreen'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { User } from '../../classes'

const { column, mobWrapper, mobResponsiveWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants

export const RequestModelsPage = () => {
  // Use Firestore React Hook to get document
  const [
    allActiveModels,
    allActiveModelsLoading,
    allActiveModelsError,
  ] = useCollectionData(collections.userInformation, {
    idField: 'id',
  })

  return (
    <DesktopPageWrapper>
      <hr />
      {allActiveModelsError && <strong>Error: {allActiveModelsError}</strong>}
      {allActiveModelsLoading && <LoadingScreen />}
      {allActiveModels && (
        <Box mobStyles={[column, mobWrapper]}>
          <Text mobStyles={mobPageHeader}>Request Models</Text>
          <Text mobStyles={mobInstructionParagraph}>
            Select up to 3 models for each one that you need and we'll send them
            an option request. Within 24 hours, we'll email you when they accept
            or decline your request. If they decline you can then send
            additional option requests to new models.
          </Text>

          <SimpleSearchBar />

          <Box mobStyles={mobResponsiveWrapper}>
            {allActiveModels.map((userSnapshot, index) => (
              <ProfileBox
                key={userSnapshot.id}
                user={new User(userSnapshot)}
                index={index}
                boxType={ProfileBoxType.Mini}
              />
            ))}
          </Box>
        </Box>
      )}
    </DesktopPageWrapper>
  )
}
