import { css } from 'styled-components'

/**
 * Module to
 *
 * @param props
 * @constructor
 */
export const mobMenuWrapper = css`
  opacity: 0;
  animation: 1s appear forwards;
  animation-delay: ${p => p.delay};
`
export const mobMenuItemStyle = css`
  font-family: Montserrat, Open Sans, sans-serif;
  padding: 0.5rem 0 0.5rem 1rem;
  margin: 0 5%;
  cursor: pointer;
  color: ${p => p.theme.colors.darkGray};
  transition: color 0.2s ease-in-out;
  animation: 0.5s slideIn forwards;
  animation-delay: ${p => p.delay};
  font-size: 15px;
  width: auto;
  letter-spacing: 1.07px;
  font-weight: 500;

  :hover {
    color: ${p => p.theme.fonts.gray};
  }
`
export const mobNavWrapperStyles = css`
  background-color: ${p =>
    p.absolutePositioning ? '' : p.theme.colors.secondary};
  box-shadow: ${p =>
    !p.absolutePositioning && '0 -6px 12px 4px rgba(120, 120, 120, 0.5)'};
  position: ${p => (p.absolutePositioning ? 'absolute' : '')};
  position: ${p => (p.fixedPositioning ? 'fixed' : '')};
  box-sizing: border-box;
  z-index: 99;
  opacity: 0.9;
  width: 100%;
  height: 75px;
`
export const mobMenuContainer = css`
  position: absolute;
  top: 75px;
  left: 0;
  height: ${p => (p.open ? 'auto' : '0')};
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.colors.secondary};
  position: ${p => (p.fixedPositioning ? 'fixed' : '')};
  // opacity: 0.85;
  color: ${p => p.theme.colors.secondary};
  transition: height 0.3s ease;
  z-index: 1000;
`
