import { Box, deskBoxVariants } from '../../shared/Box'
import styled, { css } from 'styled-components'
import { Input } from '../../shared/Input'
import React, { useState } from 'react'
import { Button } from '../../shared/Button'
import { deskInputVariants } from '../../shared/Input/deskInputVariants'
import Script from 'react-load-script'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import {
  mobAutoCompleteDropdown,
  mobAutoCompleteSelection,
  mobAutoCompleteWrapper,
} from '../Inputs/AutoCompleteLocationInput/styles'
import { uuid } from '../../utils/uuid'
import { Text } from '../../shared/Text'
import Loader from 'react-loader-spinner'
import { useRecoilState } from 'recoil'
import { searchState } from '../../store'

const { deskInput } = deskInputVariants
const { column, row, fullWidth } = deskBoxVariants

export const CityInput = () => {
  // TODO: Update to prod
  const googleAPIKey = 'AIzaSyAR4vHdSSnLiQzZYXSt8QGjwIYPGAg9U-s'
  const [search, setSearch] = useRecoilState(searchState)

  const clearCityFilter = () => {
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        nearMe: false,
        givenLocation: {
          lat: null,
          lng: null,
        },
      }
    })
  }

  // Used to display current address in input
  const [city, setCity] = useState('')
  // Used to keep current address in input up-to-date
  const handleCityChange = city => {
    setCity(city)
  }

  const handleCitySelect = address => {
    setCity(address)
    createLocationObject(address)
  }

  const createLocationObject = city => {
    geocodeByAddress(city)
      .then(results => getLatLng(results[0]))
      .then(latLong => {
        setSearch(previousSearch => {
          return {
            ...previousSearch,
            nearMe: false,
            givenLocation: {
              lat: latLong.lat,
              lng: latLong.lng,
            },
          }
        })
      })
  }

  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [scriptError, setScriptError] = useState(false)

  const handleScriptCreate = () => setScriptLoaded(false)

  const handleScriptError = () => setScriptError(true)

  const handleScriptLoad = () => setScriptLoaded(true)

  const renderAutoComplete = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
  }) => (
    <Box mobStyles={[column, mobAutoCompleteWrapper]}>
      <Box deskStyles={[column, autocompleteInputWrapper]}>
        <LabelText>City</LabelText>
        <Input
          placeholder="Any"
          deskStyles={[
            deskInput,
            css`
              width: 100%;
            `,
          ]}
          {...getInputProps()}
        />
      </Box>
      <Box mobStyles={[column, mobAutoCompleteDropdown]}>
        {loading && <AutoCompleteLoader />}
        {suggestions.map((suggestion, index) => {
          return (
            <Button
              mobStyles={autoCompleteSuggestionItem}
              {...getSuggestionItemProps(suggestion)}
              key={uuid()}
              data-testid={`address-autocomplete-${index}`}
            >
              <Text mobStyles={[mobAutoCompleteSelection]}>
                {`${suggestion.terms[0].value}, ${suggestion.terms[1].value}`}
              </Text>
            </Button>
          )
        })}
      </Box>
      <Box
        deskStyles={[
          fullWidth,
          css`
            align-items: flex-end;
          `,
        ]}
      >
        <Text deskStyles={[deskClearFiltersText]} onClick={clearCityFilter}>
          CLEAR CITY
        </Text>
      </Box>
    </Box>
  )

  // https://www.npmjs.com/package/react-places-autocomplete#searchOptions
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const searchOptions = {
    types: ['(cities)'],
    componentRestrictions: {
      country: 'us',
    },
  }

  return (
    <Box
      deskStyles={css`
        min-height: 200px;
        width: 100%;
      `}
    >
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`}
        onCreate={handleScriptCreate}
        onError={handleScriptError}
        onLoad={handleScriptLoad}
      />
      {scriptLoaded && (
        <PlacesAutocomplete
          value={city}
          searchOptions={searchOptions}
          onChange={handleCityChange}
          onSelect={handleCitySelect}
          debounce={500}
          shouldFetchSuggestions={city.length >= 2}
        >
          {renderAutoComplete}
        </PlacesAutocomplete>
      )}
    </Box>
  )
}

const autocompleteInputWrapper = css`
  position: relative;
  height: auto;
  width: 100%;
  margin-top: 20px;
`

export const LabelText = styled.label`
  height: 22px;
  width: 141px;
  color: #7c848b;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin: 10px 5px 10px 5px;
`

const autoCompleteSuggestionItem = css`
  margin: 0;
  width: 100%;
  display: inline-block;
  font-family: ${p => p.theme.fonts.primary};
  font-size: 16px;
  line-height: 22px;
  height: 48px;
  background-color: ${p => p.theme.colors.secondary};
  color: ${p => p.theme.colors.darkGray};
  box-shadow: ${p => p.theme.shadow.regular};
  border-radius: 4px;
  margin-top: 2px;
`

const deskClearFiltersText = css`
  opacity: 0.38;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  font-size: 15px;
  cursor: pointer;
  height: 29px;
  width: 100%;
`

const AutoCompleteLoader = () => {
  return (
    <Button
      mobStyles={[
        autoCompleteSuggestionItem,
        css`
          margin-top: 0px;
        `,
      ]}
      data-testid="loading-button"
    >
      <Loader type="ThreeDots" color="black" height={46} width={100} />
    </Button>
  )
}
