import React from 'react'
import { StyledCheckbox, StyledMotionCheckbox } from './styles'

import PropTypes from 'prop-types'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Checkbox = ({ enableMotion, labelText, ...props }) => {
  if (enableMotion) {
    return <StyledMotionCheckbox type={'checkbox'} {...props} />
  }

  return <StyledCheckbox type={'checkbox'} role={'checkbox'} {...props} />
}

Checkbox.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
  icon: PropTypes.any,
  enableMotion: PropTypes.bool,
}
