import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { desktopQuery } from '../../utils/responsiveVars'
import { MobileNavigationModule } from './MobileNavigationModule'
import { DesktopNavigationModule } from './DesktopNavigationModule'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebaseApp'
import { useUserAccountType } from '../../utils/useUserAccountType'

export const NavigationModule = props => {
  const isDesktop = useMediaQuery(desktopQuery)
  const [currentUserAuth] = useAuthState(auth)
  const userAccountType = useUserAccountType(currentUserAuth)

  if (isDesktop) {
    return (
      <DesktopNavigationModule
        {...props}
        userAccountType={userAccountType}
        currentUserAuth={currentUserAuth}
      />
    )
  }

  return (
    <MobileNavigationModule
      {...props}
      userAccountType={userAccountType}
      currentUserAuth={currentUserAuth}
    />
  )
}
