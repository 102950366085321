import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { useTheme } from 'styled-components'
import { mobTextVariants, Text } from '../../shared/Text'

const { mobInputHeader } = mobTextVariants

export const CardSection = () => {
  const theme = useTheme()
  return (
    <>
      <label>
        <Text mobStyles={mobInputHeader}>CARD DETAILS</Text>
        <CardElement
          style={{
            base: {
              fontSize: '16px',
              fontWeight: 100,
              '::placeholder': {
                color: theme.colors.gray,
              },
            },
            invalid: {
              color: '#9e2146',
            },
          }}
        />
      </label>
    </>
  )
}
