import React from 'react'
import { IconContext } from 'react-icons'
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { css, useTheme } from 'styled-components'

import { Box } from '../../shared'
import { mobModelRating, mobModelRatingContainer } from './styles'

export const RatingPosition = {
  left: 1,
  center: 2,
}

export const getRatingStars = (rating, theme, size) => {
  const starSize = size ? size : '1em'

  const iconStyle = {
    color: theme.colors.yellow,
    size: size,
  }

  if (0 <= rating && rating < 10) {
    // No stars 0 to 9
    return (
      <IconContext.Provider value={iconStyle}>
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (10 <= rating && rating <= 19) {
    // 0.5 star 10 to 19
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStarHalfAlt />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (20 <= rating && rating <= 29) {
    // 1 star 20 to 29
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (30 <= rating && rating <= 39) {
    // 1.5 stars 30 to 39
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStarHalfAlt />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (40 <= rating && rating <= 49) {
    // 2 stars 40 to 49
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (50 <= rating && rating <= 59) {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaStarHalfAlt />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (60 <= rating && rating <= 69) {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (70 <= rating && rating <= 79) {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStarHalfAlt />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (80 <= rating && rating <= 89) {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  } else if (90 <= rating && rating <= 94) {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStarHalfAlt />
      </IconContext.Provider>
    )
  } else if (rating => 95) {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </IconContext.Provider>
    )
  } else {
    return (
      <IconContext.Provider value={iconStyle}>
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
        <FaRegStar />
      </IconContext.Provider>
    )
  }
}

// This will get pass a number from 0 to 5 by 0.5s
// 0.5, 1, 1.5, 2.0, etc...
export const RatingModule = props => {
  const rating = props.rating
  const theme = useTheme()

  const RatingModuleLeft = (
    <>
      <Box mobStyles={mobModelRatingContainer}>
        <Box mobStyles={mobModelRating}>{getRatingStars(rating, theme)}</Box>
      </Box>
    </>
  )

  const RatingModuleCenter = (
    <>
      <Box mobStyles={mobModelRatingContainer}>
        <Box
          mobStyles={[
            mobModelRating,
            css`
              margin: 0 auto;
            `,
          ]}
        >
          {getRatingStars(rating, theme)}
        </Box>
      </Box>
    </>
  )

  switch (props.ratingPosition) {
    case RatingPosition.left:
      return RatingModuleLeft
    case RatingPosition.center:
      return RatingModuleCenter
    default:
      return null
  }
}
