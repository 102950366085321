import { useHistory } from 'react-router-dom'
import { Image } from '../../../shared/Image'
import { Box } from '../../../shared/Box'
import React from 'react'
import { css } from 'styled-components'
import BronnerLogo from '../../../assets/logos/bronner_logo.png'
import { ROUTES } from '../../../constants/routes'
import { Heading } from '../../../shared/Heading'

const imageStyles = css`
  margin-top: 20px;
  height: 75px;
  cursor: pointer;
`

const mobImageStyles = css`
  margin-top: 20px;
  height: 50px;
  cursor: pointer;
`

const mobSubHeader = css`
  margin-top: 25px;
  height: auto;
  width: 285px;
  opacity: 0.86;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  font-weight: bold;
`

const deskSubHeaderStyles = css`
  width: auto;
  font-size: 18px;
`

export const WhiteLabelCompany = () => {
  const history = useHistory()
  // const isDesktop = useMediaQuery(desktopQuery)

  return (
    <>
      <Heading mobStyles={mobSubHeader} deskStyles={deskSubHeaderStyles}>
        Upcoming BRONNER BROS. International Beauty Show
      </Heading>
      <Heading
        mobStyles={[
          mobSubHeader,
          css`
            margin-top: 5px;
          `,
        ]}
        deskStyles={deskSubHeaderStyles}
      >
        August 28th to August 30th, 2021
      </Heading>
      <Box
        deskStyles={css`
          flex: 1;
          width: auto;
        `}
      >
        <Image
          src={BronnerLogo}
          deskStyles={imageStyles}
          mobStyles={mobImageStyles}
          onClick={() => {
            history.push(ROUTES.HOME.link)
          }}
        />
      </Box>
    </>
  )
}
