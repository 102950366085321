import React from 'react'

import { CreativeCommunityModule } from './modules/CreativeCommunityModule'
import { HeaderModule } from './modules/HeaderModule'
import { GlobalCommunityModule } from './modules/GlobalCommunityModule'
import { HowItWorksModule } from './modules/HowItWorksModule'
import { ErrorBoundary, Footer } from '../../components'
import { TrendingModule } from './modules/TrendingModule'
import { BrandPartners } from './modules/BrandPartners'
import { CastingModule } from './modules/CastingModule'
import {
  deskGreyBamLogo,
  GigSpotlightModule,
  mobGreyBamLogo,
} from './modules/GigSpotlightModule'
import { WhiteLabelExperienceRouter } from './whiteLabel/WhiteLabelExperienceRouter'
import { Image } from '../../shared/Image'
import BlackBAMLogo from '../../assets/logos/black-b-logo.svg'

export const WHITE_LABEL_EXPERIENCE_ENABLED = false

const BAMIcon = (
  <Image
    src={BlackBAMLogo}
    mobStyles={[mobGreyBamLogo]}
    deskStyles={deskGreyBamLogo}
  />
)

/**
 * Home Page. Focal point of the app.
 *
 * @param currentUserAuth
 * @param user
 * @returns {*}
 * @constructor
 */
export const Home = ({ currentUserAuth, user }) => {
  return (
    <ErrorBoundary
      userId={currentUserAuth && currentUserAuth.id}
      file="Home.jsx"
      errorMessage="Error boundary caught error in home page"
    >
      <HeaderModule whiteLabelEnabled={WHITE_LABEL_EXPERIENCE_ENABLED} />
      {WHITE_LABEL_EXPERIENCE_ENABLED && (
        <WhiteLabelExperienceRouter
          currentUserAuth={currentUserAuth}
          user={user}
        />
      )}
      <GigSpotlightModule
        moduleHeader={'Gig Spotlight'}
        currentUserAuth={currentUserAuth}
        relevantField={'spotlight'}
        gigIcon={BAMIcon}
      />
      <CreativeCommunityModule />
      <CastingModule />
      <HowItWorksModule />
      <TrendingModule />
      <GlobalCommunityModule />
      <BrandPartners />
      <Footer />
    </ErrorBoundary>
  )
}
