import { Box, mobBoxVariants } from '../../shared/Box'
import { Button, mobButtonVariants } from '../../shared/Button'
import React from 'react'
import { AccountType } from '../../constants/enums'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { deskButtonVariants } from '../../shared/Button/deskButtonVariants'
import { hasAtLeastOneService } from '../../modules/CashServiceModule'
import { User } from '../../classes'

const { center, mobBoxSpacer } = mobBoxVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskButtonVariants

/**
 *
 * @param {User} user
 * @param {PrivateUser} privateUser
 * @param updateBookingCart
 * @param currentUserAuth
 * @returns {*}
 * @constructor
 */
export const RequestToBookButton = ({
  user,
  updateBookingCart,
  currentUserAuth,
}) => {
  if (!currentUserAuth) {
    return null
  }

  if (user.userId === currentUserAuth.uid) {
    return null
  }

  if (user.accountType === AccountType.agency) {
    return null
  }

  let userDocumentId
  if (user.managedBy) {
    userDocumentId = user.managedBy
  } else {
    userDocumentId = user.userId
  }

  const [selectedUserData] = useDocumentData(
    collections.userInformation.doc(userDocumentId),
    {
      idField: 'id',
    }
  )

  const [currentUserPrivateUserData] = useDocumentData(
    collections.privateUserInformation.doc(currentUserAuth.uid),
    {
      idField: 'id',
    }
  )

  const dependencies = selectedUserData && currentUserPrivateUserData
  if (!dependencies) {
    return null
  }

  const selectedUser = new User(selectedUserData)
  const employerPaymentReady =
    currentUserPrivateUserData.stripeCustomer.hasBillingInfo &&
    currentUserPrivateUserData.stripeCustomer.hasStripeCustomer
  const moneyServiceReady = hasAtLeastOneService(selectedUser.moneyServices)
  const acceptingBookingRequests = selectedUser.acceptingBookingRequests

  if (
    !moneyServiceReady ||
    !acceptingBookingRequests ||
    !employerPaymentReady
  ) {
    return null
  }

  return (
    <Box mobStyles={[center, mobBoxSpacer]}>
      <Button
        enableMotion
        whileHover={{ scale: 1.15 }}
        whileTap={{ scale: 0.9 }}
        mobStyles={[mobSubmitButton, mobLong305x46]}
        deskStyles={deskSubmitButton}
        onClick={updateBookingCart}
      >
        BOOK
      </Button>
    </Box>
  )
}
