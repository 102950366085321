import { css } from 'styled-components'

import { up } from 'styled-breakpoints'

export const deskBoxVariants = {
  column: css`
    flex-direction: column;
  `,
  row: css`
    flex-direction: row;
  `,
  fullWidth: css`
    width: 100%;
  `,
  center: css`
    align-items: center;
    justify-content: center;
    text-align: center;
  `,
  deskHomeWrapper: css`
    height: auto;
    width: 100%;
    padding-left: 100px;
    // padding-left: 165px;
    // padding-right: 165px;
    padding-right: 25px;
  `,
  deskWrapper: css`
    height: auto;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
  `,

  deskGigBox: css`
    height: auto;
    width: 100%;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin-top: 2%;
    margin-bottom: 3%;
    text-align: left;
    padding: 15px 15px 0px 15px;
    position: relative;
  `,

  // Spacer to give the contained elements room on the top and bottom
  mobBoxSpacer: css`
    padding-bottom: 25px;
    padding-top: 25px;
  `,

  mobMiniBoxSpacer: css`
    padding-bottom: 25px;
    padding-top: 25px;
  `,

  mobGigBox: css`
    height: auto;
    width: 100%;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin-top: 2%;
    margin-bottom: 3%;
    text-align: left;
    display: inline;
    padding: 15px 15px 0px 15px;
    position: relative;
  `,
  // miniLink Profile Box
  deskProfileBoxMini: css`
    height: 23vw;
    min-height: 30vw;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin: 10px;
    display: inline-flex;
    flex: 1;
  `,
  // miniLink Profile Box
  deskProfileBoxRequest: css`
    height: 25%;
    width: 100%;
    background-color: ${p => p.theme.colors.secondary};
    margin-top: 2%;
    margin-bottom: 3%;
    display: inline-flex;
  `,

  // miniLink Profile Box
  mobProfileBoxMiniRequests: css`
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left-style: 1px solid ${p => p.theme.colors.lightGray};
    background-color: ${p => p.theme.colors.secondary};
    display: inline-flex;
  `,

  // Responsive Container
  mobResponsiveWrapper: css`
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,

  // Invoice Row
  mobRow: css`
    width: auto;
  `,
  mobInvoiceRowMoneyGrand: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-weight: bold;
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    margin: 5px 0 0 auto;
  `,
  mobInvoiceRowMoney: css`
    margin-left: auto;
    margin-right: 0;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 14px;
    font-weight: bold;
    line-height: 28px;
  `,
  // Message Box
  mobMessageBox: css`
    padding: 20px;
    width: 100%;
    border: 1px solid ${p => p.theme.colors.lightGray};
    margin: 0 auto 3%;
    height: auto;
    border-radius: 5px;
    box-shadow: ${p => p.theme.shadow.regular};
  `,

  // Status Box
  mobStatusBox: css`
    padding: 10px;
    width: auto;
    margin: 0 auto;
    border: 1px solid ${p => p.theme.colors.gray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.lightGray};
    box-shadow: ${p => p.theme.shadow.regular};
    position: absolute;
    bottom: 1px;
    left: 25%;
  `,

  // Proposal Box
  mobInformationalBox: css`
    height: auto;
    width: auto;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin-top: 2%;
    margin-bottom: 3%;
    text-align: left;
    padding: 15px 20px 0px 20px;
  `,

  // Settings Section
  // Proposal Box
  mobSectionBox: css`
    height: auto;
    width: auto;
    border: 1px solid ${p => p.theme.colors.lightBlue};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: 0 0 4px 2px ${p => p.theme.colors.lightBlue};
    margin-top: 2%;
    margin-bottom: 3%;
    text-align: left;
    display: inline;
    padding: 15px 15px 20px 15px;

    position: relative;
  `,

  // Notification Box
  // FIXME:This may be temporary
  mobNotificationBox: css`
    width: 100%;
    border: 1px solid ${p => p.theme.colors.lightGray};
    height: 40vh;
    border-radius: 5px;
    box-shadow: ${p => p.theme.shadow.regular};
  `,
  mobNotification: css`
    width: 100%;
    padding: 5px;
    border: 1px solid ${p => p.theme.colors.lightGray};
    height: auto;
    box-shadow: ${p => p.theme.shadow.regular};
  `,

  // Mobile login container Box
  mobFormBox: css`
    height: auto;
    width: 90vw;
    border: 1px solid ${p => p.theme.colors.lightGray};
    background-color: ${p => p.theme.colors.secondary};
    margin-bottom: 35px;
    margin-top: 235px;
    padding: 20px 20px 0px 20px;
    border-radius: 8px;
    box-shadow: ${p => p.theme.shadow.regular}
    text-align: left;
  `,

  mobMultiImageWrapper: css`
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,

  mobDetailVerticalRule: css`
    height: 31px;
    width: 1px;
    border: 1px solid ${p => p.theme.colors.gray};
    opacity: 0.16;
  `,
  mobDetailsBox: css`
    justify-content: center;
    align-items: flex-end;
  `,

  deskDetailsBoxContainer: css`
    margin-top: 10px;
    margin-bottom: 10px;
    width: auto;
  `,

  mobSettingsValue: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-weight: bold;
    text-align: left;
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0 10px auto;
  `,

  mobImageDelete: css`
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: ${p => p.theme.colors.lightGray};
  `,

  mobSearchBar: css`
    box-shadow: ${p => p.theme.shadow.regular};
    border-radius: 10px;
  `,

  mobChatInputWrapper: css`
    height: auto;
    width: 335px;
    background-color: ${p => p.theme.colors.secondary};
    margin-bottom: 15px;
    margin-top: 15px;
    border-radius: 24px;
    box-shadow: ${p => p.theme.shadow.regular};
  `,

  deskMessagesContainer: css`
    height: auto;
    overflow-y: auto;
  `,
  mobHeaderIconWrapper: css`
    position: absolute;
    display: none;

    ${up('tablet')} {
      display: flex;
      width: 160px;
      right: 60px;
      top: 40px;
      justify-content: space-evenly;
    }

    ${up('desktop')} {
      display: flex;

      top: 65px;
      right: 112px;
    }
  `,

  mobImageWrapper: css`
    border: 1px solid ${p => p.theme.colors.lightGray};
    margin-top: 3.5%;
    position: relative;
    overflow: hidden;

    margin-bottom: 2.5%;
    display: inline-flex;
    width: 48%;
    border-radius: 5px;
    box-shadow: ${p => p.theme.shadow.regular};
    :after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  `,

  mobImageFormBox: css`
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    border-radius: 10px;
    min-height: 335px;
    max-height: 335px;
  `,
  deskProfileBoxDetails: css`
    display: flex;
    box-shadow: ${p => p.theme.shadow.regular};
    height: 15%;
    align-items: center;
    justify-content: space-evenly;
  `,

  deskImageLoader: css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    border-radius: 10px;
    width: 32vw;
    height: 32vw;
  `,
  deskDetailsContainer: css`
    align-items; space-evenly;
    margin: 10px 0 10px 0;
    padding: 2px;
      `,
  deskProfileDetailsContainer: css`
    margin: 25px 40px 0 40px;
    align-items; center;
    width: auto;
      `,
}
