import { Box, mobBoxVariants } from '../../shared/Box'
import React from 'react'
import { Booking, Gig, Request, User } from '../../classes'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections, db } from '../../firebaseApp'
import { LoadingScreen } from '../../components/LoadingScreen'
import { RequestActionModule } from '../../modules/RequestActionModule'
import { mobTextVariants, Text } from '../../shared/Text'
import { Image, mobImageVariants } from '../../shared/Image'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'
import { css } from 'styled-components'
import { ROUTES } from '../../constants/routes'
import { mobGigBoxHorizontalRule } from '../../modules/CashModule/styles'
import { InvoiceLineType, InvoiceRow } from '../../components/InvoiceRow'
import { MiniAccordion } from '../../components'
import {
  RequestActionType,
  RequestStatus,
  SupportTicketStatus,
  SupportTicketType,
} from '../../constants/enums'
import { Button, mobButtonVariants } from '../../shared'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { requestActionAccordionState } from '../../store'
import get from 'lodash/get'
import firebase from 'firebase'
import { ownerOfferWithdrawal } from '../../utils/notificationHandlers'
import { errorHandler, errorTypes } from '../../utils/errorHandler'

const { column, mobWrapper } = mobBoxVariants
const { deskPageHeader } = deskTextVariants
const { mobPageHeader, mobGigDate } = mobTextVariants
const { mobCenteredProfilePhoto } = mobImageVariants
const { deskProfilePhoto } = deskImageVariants
const { mobStatusButton, ongoingButton } = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const MoneyRequestOverviewPage = ({
  activeRequest,
  currentUserAuth,
}) => {
  const bamRequest = new Request(activeRequest)
  const accordionState = useRecoilValue(requestActionAccordionState)
  const setAccordionState = useSetRecoilState(requestActionAccordionState)
  const { activeRequests } = collections
  const markAsCompletedAccordionOpen =
    // accordionState.activeRequestId === activeRequest.id &&
    accordionState.type === RequestActionType.complete
  const [
    userSnapshot,
    userSnapshotLoading,
    userSnapshotError,
  ] = useDocumentData(collections.userInformation.doc(bamRequest.operative), {
    idField: 'id',
  })

  const [gigSnapshot, gigSnapshotLoading, gigSnapshotError] = useDocumentData(
    collections.gigs.doc(bamRequest.eventId),
    {
      idField: 'id',
    }
  )
  const updateAccordionState = requestActionType => {
    setAccordionState(previousAccordionState => {
      if (
        // previousAccordionState.requestId === booking.request.requestId &&
        previousAccordionState.type === requestActionType
      ) {
        return {
          ...previousAccordionState,
          // user: null,
          type: null,
        }
      } else {
        return {
          ...previousAccordionState,
          // activeRequestId: activeRequest.id,
          type: requestActionType,
        }
      }
    })
  }
  const [
    operativeSnapshot,
    operativeSnapshotLoading,
    operativeSnapshotError,
  ] = useDocumentData(collections.userInformation.doc(bamRequest.operative), {
    idField: 'id',
  })

  const dependencies = userSnapshot && gigSnapshot && operativeSnapshot
  const dependenciesLoading =
    userSnapshotLoading || gigSnapshotLoading || operativeSnapshotLoading
  const dependenciesError =
    userSnapshotError || gigSnapshotError || operativeSnapshotError

  const operative = new User(userSnapshot)
  const booking = new Booking(
    new Gig(gigSnapshot),
    new User(operativeSnapshot),
    bamRequest
  )
  const markAsCompletedHandler = () => {
    // setWithdrawLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.completed,
        agentPaidAt: serverTimestamp,
      },
      { merge: true }
    )
    //
    // batch.set(
    //   requestReference.collection('history').doc(),
    //   {
    //     note: `${currentUserAuth.uid} withdrew from this offer. ${booking.gig.createdBy} needs to be refunded`,
    //     timestamp: serverTimestamp,
    //   },
    //   { merge: true }
    // )

    batch
      .commit()
      .then(() => {
        // ownerOfferWithdrawl(curentUserAuth.uid, booking.request.operative)
        // setWithdrawLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_WITHDRAWING_FROM_OFFER',
          message: `Failed to withdraw user from request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && 'currentUserAuth.uid',
          file: 'OwnerWithAcceptedOffer.jsx',
        })
      })
  }

  return (
    <>
      {dependenciesError && <strong>Error: {dependenciesError}</strong>}
      {dependenciesLoading && <LoadingScreen />}
      {dependencies && (
        <Box mobStyles={[column, mobWrapper]}>
          <Image
            src={operative.getProfileImage()}
            mobStyles={[mobCenteredProfilePhoto]}
            deskStyles={[
              deskProfilePhoto,
              css`
                margin: 0 auto;
              `,
            ]}
            id="user-profile-image"
            onClick={() => history.push(ROUTES.PROFILE.base + operative.userId)}
          />
          <br />
          <br />
          <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
            {gigSnapshot.title}
          </Text>
          <Text mobStyles={mobGigDate}>
            {new Gig(gigSnapshot).getDateString()}
          </Text>
          <br />
          <br />

          <RequestActionModule
            booking={
              new Booking(
                new Gig(gigSnapshot),
                new User(operativeSnapshot),
                bamRequest
              )
            }
            currentUserAuth={currentUserAuth}
          />
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 20px 5px 5px 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Available Actions
          </Text>

          <MiniAccordion
            closedButtonText="MARK AS PAID"
            openButtonText="CANCEL"
            onClick={() => {
              updateAccordionState(RequestActionType.complete)
            }}
            open={markAsCompletedAccordionOpen}
            buttonMobStyles={[mobStatusButton, ongoingButton]}
          >
            <Button
              mobStyles={[mobStatusButton, ongoingButton]}
              onClick={markAsCompletedHandler}
            >
              CONFIRM PAID
            </Button>
          </MiniAccordion>

          <Text mobStyles={mobGigBoxHorizontalRule} />
          <InvoiceRow
            description="Total Booking Amount"
            money={booking.getBookingGrandTotal() / 100}
            lineType={InvoiceLineType.Regular}
          />
          <InvoiceRow
            description="Service Fees Collected From Employer"
            money={booking.getBookingServiceCharge() / 100}
            lineType={InvoiceLineType.Regular}
          />
          <InvoiceRow
            description="Service Fees Collected From Model"
            money={booking.getOperativeFees() / 100}
            lineType={InvoiceLineType.Regular}
          />
          <InvoiceRow
            description="Amount Owed To Operative"
            money={booking.getOperativePayPennies() / 100}
            lineType={InvoiceLineType.Grand}
          />
          <Text mobStyles={mobGigBoxHorizontalRule} />
        </Box>
      )}
    </>
  )
}
