import React, { useState } from 'react'
import firebase from 'firebase/app'
import { css } from 'styled-components'

import { Box, Button, mobButtonVariants } from '../../shared'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { collections, functions } from '../../firebaseApp'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { PrivateUser } from '../../classes'
import phone from 'phone'
import { Toggle } from '../../components/Toggle'
import { MessageBox } from '../../components/MessageBox'

const { mobSubmitButton, mobSmall154x42 } = mobButtonVariants

const SecondsToDisableButton = 30
const PhoneLinkedHeader = 'Linked Phone Number'
const PhoneNotLinkedHeader = 'Phone Number to Link'

const mobVerifyPhonebuttonBox = css`
  justify-content: space-around;
  display: flex;
  width: 100%;
  margin: 20px 0;
`

export const VerifyPhoneModule = ({
  currentUserAuth,
  accountSettingsForm,
  privateUserSnapshot,
}) => {
  const privateUser = new PrivateUser(privateUserSnapshot)
  const [sendCodeTimer, setSendCodeTimer] = useState(SecondsToDisableButton)
  const [sendCodeDisabled, setSendCodeDisabled] = useState(false)
  const [verifyCodeDisabled, setVerifyCodeDisabled] = useState(false)

  const sendCodeText = privateUser.unverifiedNumber
    ? 'RESEND CODE'
    : 'SEND CODE'
  let phoneNumberPlaceHolder
  if (privateUser.number) {
    phoneNumberPlaceHolder = privateUser.number
  } else if (privateUser.unverifiedNumber && !privateUser.number) {
    phoneNumberPlaceHolder = privateUser.unverifiedNumber
  } else {
    phoneNumberPlaceHolder = 'Not Set'
  }

  const sendVerificationCode = functions.httpsCallable('sendVerificationCode')
  const verifyVerificationCode = functions.httpsCallable(
    'verifyVerificationCode'
  )

  const hasUnverifiedPhoneNumber = privateUser.unverifiedNumber
  const hadCodeDeliveredInLast30Mins = privateUser.codeSentInLast30Mins()
  const codeVerifyEnabled =
    hasUnverifiedPhoneNumber && hadCodeDeliveredInLast30Mins

  const sendCode = () => {
    const normalizedNumber = phone(accountSettingsForm.newPhoneNumber)[0]

    setSendCodeDisabled(true)
    sendVerificationCode({
      number: normalizedNumber,
    })
      .then(() => {
        let timesRun = 0
        const interval = setInterval(() => {
          setSendCodeTimer(num => {
            return num - 1
          })
          timesRun += 1
          if (timesRun === SecondsToDisableButton) {
            setSendCodeDisabled(false)
            setSendCodeTimer(SecondsToDisableButton)
            clearInterval(interval)
          }
        }, 1000)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_SIGN_IN_SEND_CODE_PHONE',
          message: '',
          type: errorTypes.auth,
          userId: currentUserAuth && currentUserAuth.uid,
          file: 'VerifyPhoneModule.jsx',
        })
      })
  }

  const verifyCode = () => {
    setVerifyCodeDisabled(true)
    verifyVerificationCode({
      code: accountSettingsForm.verificationCode,
    }).catch(error => {
      setVerifyCodeDisabled(false)
      errorHandler({
        error,
        errorId: 'ERROR_SIGN_IN_VERIFY_PHONE',
        message: '',
        type: errorTypes.auth,
        userId: currentUserAuth && currentUserAuth.uid,
        file: 'VerifyPhoneModule.jsx',
      })
    })
  }

  const handleUnlinkPhone = async () => {
    try {
      await collections.privateUserInformation.doc(currentUserAuth.uid).update({
        'phoneVerification.codeStatus': null,
        'phoneVerification.unverifiedNumber': null,
        'phoneVerification.number': null,
        'phoneVerification.unlinkedAt': firebase.firestore.Timestamp.now(),
      })
    } catch (error) {
      errorHandler({
        error,
        errorId: 'ERROR_UNLINK_PHONE',
        message: '',
        type: errorTypes.auth,
        userId: currentUserAuth && currentUserAuth.uid,
        file: 'VerifyPhoneModule.jsx',
      })
    }
  }

  const handleTextNotificationChange = () => {
    const { privateUserInformation } = collections
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(privateUserInformation.doc(currentUserAuth.uid))
        .then(privateUserDocument => {
          const transactionUser = new PrivateUser(privateUserDocument)
          transaction.update(privateUserInformation.doc(currentUserAuth.uid), {
            textNotificationsEnabled: !transactionUser.textNotificationsEnabled,
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_VISIBLE_IN_SEARCH_OPTION',
            message: 'Error while updating visible in search option.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        })
    })
  }

  let phoneButtons
  if (privateUser.number) {
    phoneButtons = (
      <Box mobStyles={mobVerifyPhonebuttonBox}>
        <Button
          mobStyles={[
            mobSubmitButton,
            mobSmall154x42,
            css`
              background-color: ${p => p.theme.colors.green};
            `,
          ]}
        >
          LINKED
        </Button>
        <Button
          mobStyles={[mobSubmitButton, mobSmall154x42]}
          onClick={handleUnlinkPhone}
        >
          UNLINK
        </Button>
      </Box>
    )
  } else {
    phoneButtons = (
      <Box mobStyles={mobVerifyPhonebuttonBox}>
        <Button
          mobStyles={[mobSubmitButton, mobSmall154x42]}
          onClick={sendCode}
          disabled={sendCodeDisabled}
          id="send-code-button"
        >
          {!sendCodeDisabled && sendCodeText}
          {sendCodeDisabled && sendCodeTimer}
        </Button>
        <Button
          mobStyles={[mobSubmitButton, mobSmall154x42]}
          disabled={!codeVerifyEnabled || verifyCodeDisabled}
          onClick={verifyCodeDisabled ? () => {} : verifyCode}
        >
          VERIFY CODE
        </Button>
      </Box>
    )
  }

  return (
    <>
      {!privateUser.number && (
        <MessageBox
          header="Verify your number"
          message="To receive notifications please verify your number."
        />
      )}
      {privateUser.number && (
        <Toggle
          label="Text Notifications Enabled"
          onChangeHandler={handleTextNotificationChange}
          checked={privateUser.textNotificationsEnabled}
        />
      )}
      <InputWithHeader
        header={privateUser.number ? PhoneLinkedHeader : PhoneNotLinkedHeader}
        inputName="phone-number"
        onChange={accountSettingsForm.updateNewPhoneNumber}
        placeholderDefault="Please enter your number"
        placeholder={phoneNumberPlaceHolder}
        disabled={privateUser.number}
      />
      {!privateUser.number && (
        <InputWithHeader
          header="Verification Code"
          inputName="verification-code"
          onChange={accountSettingsForm.updateVerificationCode}
          placeholder="Please enter code"
          maxLength={6}
        />
      )}
      {phoneButtons}
    </>
  )
}
