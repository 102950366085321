import React, { useState } from 'react'
import { css } from 'styled-components'

import { auth } from '../../../firebaseApp'
import { InputWithHeader, LoadingButton } from '../../'
import {
  Box,
  Button,
  mobBoxVariants,
  mobButtonVariants,
  Text,
} from '../../../shared'
import { deskFieldLabelText } from '../styles'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import { ROUTES } from '../../../constants/routes'
import { useHistory } from 'react-router-dom'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'

const { mobLong305x46, mobSubmitButton } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants

const loginButtonStyles = css`
  margin-top: 30px;
`

export const EmailLoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showLoading, setShowLoading] = useState(false)
  const [error, setError] = useState(null)
  const history = useHistory()

  const handleLogin = event => {
    event.preventDefault()
    setShowLoading(true)

    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push(ROUTES.PROFILE.link)
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/wrong-password':
            setError('You have entered the wrong password for this user.')
            break
          case 'auth/user-not-found':
            setError('The email you entered is not associated with a user.')
            break
          default:
            setError('You have entered the wrong email and/or password.')
        }
        setShowLoading(false)
        errorHandler({
          error,
          errorId: 'ERROR_EMAIL_AND_PASSWORD_FORM',
          message:
            'Error while user trying to login using Email and Password login form.',
          type: errorTypes.auth,
          file: 'EmailLoginForm.jsx',
        })
      })
  }

  const handleEmailChange = event => {
    setError(null)
    setEmail(event.target.value)
  }

  const handlePasswordChange = event => {
    setError(null)
    setPassword(event.target.value)
  }

  return (
    <>
      <InputWithHeader
        headerStyles={{ deskStyles: deskFieldLabelText }}
        header="EMAIL ADDRESS"
        inputName="email"
        type="text"
        onChange={handleEmailChange}
        placeholderDefault="Enter email"
        boldHeader={true}
      />
      <InputWithHeader
        headerStyles={{ deskStyles: deskFieldLabelText }}
        header="PASSWORD"
        inputName="password"
        type="password"
        onChange={handlePasswordChange}
        placeholderDefault="Enter Password"
        boldHeader={true}
        inputTestId="password"
      />
      {error && (
        <Text
          mobStyles={css`
            color: red;
            margin: 0;
          `}
        >
          {error}
        </Text>
      )}
      <Box mobStyles={mobBoxVariants.center}>
        {showLoading && <LoadingButton mobStyles={loginButtonStyles} />}
        {!showLoading && (
          <Button
            mobStyles={[mobSubmitButton, mobLong305x46, loginButtonStyles]}
            deskStyles={deskSubmitButton}
            onClick={handleLogin}
          >
            LOG IN
          </Button>
        )}
      </Box>
    </>
  )
}
