import styled from 'styled-components'
import { variantsAndStyles } from '../../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

export const StyledTH = styled.th`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;
  ${variantsAndStyles()}
`

export const StyledMotionTH = styled(motion.th)`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${variantsAndStyles()}
`
