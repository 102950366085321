import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, Link, mobBoxVariants, mobTextVariants, Text } from '../../shared'
import { collections } from '../../firebaseApp'
import { DesktopPageWrapper, LoadingScreen, MessageBox } from '../../components'
import firebase from 'firebase/app'

import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { css } from 'styled-components'
import TimeAgo from 'react-timeago'
import { CloseButton } from '../../modules/NotificationsModule/Notifications'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { up } from 'styled-breakpoints'
import { ROUTES } from '../../constants/routes'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { deskPageHeader } = deskTextVariants

/**
 *
 *
 * @returns {*}
 * @constructor
 */
export const NotificationsPage = ({ currentUserAuth }) => {
  const [
    userNotifications,
    userNotificationsLoading,
    userNotificationsError,
  ] = useCollectionData(
    collections.notifications
      .doc(currentUserAuth.uid)
      .collection('notifications')
      .orderBy('createdAt', 'desc'),
    {
      idField: 'id',
    }
  )

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={[deskPageHeader]}>
          Notifications
        </Text>
        <Text mobStyles={mobInstructionParagraph}>
          All notifications you have received. Pressing the <strong>x</strong>{' '}
          here will delete the notification as opposed to only clearing it.
        </Text>
        {userNotificationsError && (
          <strong>Error: {userNotificationsError}</strong>
        )}
        {userNotificationsLoading && <LoadingScreen />}
        {userNotifications && (
          <>
            {userNotifications.length <= 0 && (
              <MessageBox
                header="No Notifications"
                message="Your notifications will appear here."
              />
            )}
            {userNotifications.map((notificationDocument, index) => {
              if (!notificationDocument.deleted) {
                return buildNotification(
                  notificationDocument,
                  currentUserAuth,
                  index
                )
              }
            })}
          </>
        )}
      </Box>
    </DesktopPageWrapper>
  )
}

const notificationBoxLink = css`
  width: 300px;
  background: white;
  margin: 10px;
  box-shadow: 0 0 20px 20px rgba(202, 201, 201, 0.5);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  ${up('desktop')} {
    width: 600px;
  }
`

const buildNotification = (notification, currentUserAuth, index) => {
  const deleteNotification = notificationId => {
    const { notifications } = collections
    const notificationReference = notifications
      .doc(currentUserAuth.uid)
      .collection('notifications')
      .doc(notificationId)

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(notificationReference)
        .then(notificationDocument => {
          transaction.update(notificationReference, {
            deleted: true,
            cleared: true,
          })
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_DELETING_NOTIFICATION',
            message: 'Error while deleting a notification.',
            type: errorTypes.notifications,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'Notifications.jsx',
          })
        })
    })
  }

  return (
    <Link
      key={index}
      positionTransition
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.2 },
      }}
      to={notification.link ?? ROUTES.NOTIFICATIONS.link}
      mobStyles={notificationBoxLink}
    >
      <Box
        mobStyles={[
          css`
            flex: 1;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobInstructionParagraph,
            css`
              margin: 10px 0px 20px 20px;
            `,
          ]}
        >
          {notification.message}
          <br />
          <br />
          <TimeAgo date={new Date(notification.createdAt.seconds * 1000)} />
        </Text>
      </Box>
      <Box
        mobStyles={[
          css`
            align-items: flex-start;
            margin: 10px;
          `,
        ]}
      >
        <CloseButton
          close={() => {
            deleteNotification(notification.id)
          }}
        />
      </Box>
    </Link>
  )
}
