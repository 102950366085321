import React, { useState } from 'react'
import { css } from 'styled-components'

import MobileLoginMainImage from '../../assets/photos/mobile_login_page_main.png'
import DesktopLoginMainImage from '../../assets/photos/desk_login_page_main.png'
import GreyBAMLogo from '../../assets/logos/grey-b-logo.svg'
import { EmailLoginForm, ForgotPasswordForm } from '../../components'
import {
  Box,
  Image,
  Link,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'

const { mobFormBox, center, column } = mobBoxVariants
const { mobGreyBamLogo } = mobImageVariants
const { deskGreyBamLogo } = deskImageVariants
const { mobPageHeader, mobSignUpText } = mobTextVariants
const { deskPageHeader } = deskTextVariants

const loginHeaderStyle = css`
  margin: 0 auto 20px;
`

const signUpLinkStyle = css`
  color: ${p => p.theme.colors.blueGray};
`

const noAccountTextStyle = css`
  margin-top: 15px;
  margin-right: 15px;
`

const mobForgotPasswordText = css`
  margin: 0 0 15px 0;
`

const mobDontHaveAccountText = css`
  margin: 0 0 15px 0;
`

const mobSignUpLinkText = css`
  margin-left: 10px;
`

// this also acts as a div
// FIXME: Replace below once the FB and Google buttons are added
const mobBackgroundImageStyles = css`
  min-height: 320px;
  align-items: center;
  justify-content: center;
  background-size: 100% 45%;
`

const deskBackgroundImageStyles = css`
  background-size: 100% auto;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 80vh 100vh !important;
  background-position: right;
  justify-content: unset;
`

const deskLoginBox = css`
  width: 35vw;
  margin-top: 30px;
  margin-left: 10vw;
`

const deskNoAccountText = css`
  font-size: 20px;
`

/**
 * Self-explanatory
 *
 * @returns {*}
 * @constructor
 */
export const LoginPage = ({ currentUserAuth }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery(desktopQuery)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  if (currentUserAuth) {
    history.push(ROUTES.PROFILE.link)
  }

  return (
    <Image
      enableUseAsBackground
      src={isDesktop ? DesktopLoginMainImage : MobileLoginMainImage}
      mobStyles={mobBackgroundImageStyles}
      deskStyles={deskBackgroundImageStyles}
    >
      <Box mobStyles={[mobFormBox, column]} deskStyles={deskLoginBox}>
        <Image
          src={GreyBAMLogo}
          mobStyles={[mobGreyBamLogo]}
          deskStyles={deskGreyBamLogo}
        />
        <Text
          mobStyles={[mobPageHeader, loginHeaderStyle]}
          deskStyles={[
            deskPageHeader,
            css`
              text-align: center;
            `,
          ]}
        >
          Log in
        </Text>
        <EmailLoginForm />
        {/*<FaceBookLoginButton*/}
        {/*  mobStyles={[mobSubmitButtonLight, mobLong305x46, loginButtonStyle]}*/}
        {/*  data-testid="facebook-login-button"*/}
        {/*/>*/}
        {/*<GoogleLoginButton*/}
        {/*  mobStyles={[mobSubmitButtonLight, mobLong305x46, loginButtonStyle]}*/}
        {/*  data-testid="google-login-button"*/}
        {/*/>*/}
        <Text
          mobStyles={[
            mobSignUpText,
            noAccountTextStyle,
            mobDontHaveAccountText,
          ]}
          deskStyles={deskNoAccountText}
        >
          Don't have an account yet?
          <Link
            mobStyles={[signUpLinkStyle, mobSignUpLinkText]}
            deskStyles={deskNoAccountText}
            to={ROUTES.SIGN_UP.link}
          >
            <strong>Sign Up</strong>
          </Link>
          <br />
        </Text>

        <Box mobStyles={[center, column]}>
          {showForgotPassword && (
            <ForgotPasswordForm
              setShowForgotPassword={setShowForgotPassword}
              data-testid="forgot-password-form"
            />
          )}
          <Text
            mobStyles={[signUpLinkStyle, mobForgotPasswordText]}
            deskStyles={deskNoAccountText}
            onClick={() => setShowForgotPassword(!showForgotPassword)}
            role="button"
          >
            Forgot Password
          </Text>
        </Box>
      </Box>
    </Image>
  )
}

// TODO: Replace once we have ramped up intitial users
// <FaceBookLoginButton
//   mobStyles={[mobSubmitButtonLight, mobLong305x46, loginButtonStyle]}
// />
// <GoogleLoginButton
// mobStyles={[mobSubmitButtonLight, mobLong305x46, loginButtonStyle]}
// />
