import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { collections } from '../../firebaseApp'
import { GigBox, GigBoxType } from '../../components/GigBox'
import { StickyFooterWithButton } from '../../components/StickyFooter'
import { LoadingScreen } from '../../components/LoadingScreen'
import { ROUTES } from '../../constants/routes'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { Gig } from '../../classes'
import { useRecoilState } from 'recoil'
import { bookingCartState } from '../../store'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

export const SelectGigPage = ({ history, currentUserAuth }) => {
  const [bookingCart, setBookingCart] = useRecoilState(bookingCartState)
  // TODO: Change this to only show active gugs
  const [allUserGigs, allUserGigsLoading, allUserGigsError] = useCollectionData(
    collections.gigs.where('createdBy', '==', currentUserAuth.uid),
    {
      idField: 'id',
    }
  )

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={[deskPageHeader]}>
          Select a Gig to Book For
        </Text>
        {allUserGigsError && <strong>Error: {allUserGigsError}</strong>}
        {allUserGigsLoading && <LoadingScreen />}
        {allUserGigs && (
          <>
            {allUserGigs.map((gigDocumentSnapshot, index) => (
              <GigBox
                key={index}
                gig={new Gig(gigDocumentSnapshot)}
                index={index}
                boxType={GigBoxType.fullButton}
                handleEventSelectChange={event => {
                  setBookingCart(previousCart => {
                    return {
                      ...previousCart,
                      gigId: event.currentTarget.value,
                    }
                  })
                  // setSelectedEventId(event.currentTarget.value)
                }}
              />
            ))}
          </>
        )}
      </Box>
      <StickyFooterWithButton
        mainButtonHandler={() => {
          history.push(ROUTES.CONFIRM_BOOKING_DETAILS.link)
        }}
        mainButtonText="SELECT"
        mainButtonDisabled={bookingCart.gigId === ''}
      />
    </DesktopPageWrapper>
  )
}
