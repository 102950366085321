import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import get from 'lodash/get'
import { collections, db } from '../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import firebase from 'firebase/app'
import { RequestActionType, RequestStatus } from '../../../constants/enums'
import { MiniAccordion } from '../../../components/MiniAccordion'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { LoadingButton } from '../../../components/LoadingButton'
import { proposalCancelled } from '../../../utils/notificationHandlers'

const {
  mobStatusButton,
  pendingButton,
  declineButton,
  fullOpacity,
} = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const OperativeWithPendingProposal = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const accordionState = useRecoilValue(requestActionAccordionState)
  const declineAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.decline
  const { activeRequests } = collections
  const [cancelLoading, setCancelLoading] = useState(false)

  const cancelProposalRequestHandler = () => {
    setCancelLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.canceled,
        operativeWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    batch.set(
      requestReference.collection('history').doc(),
      {
        note: `${currentUserAuth.uid} canceled this offer.`,
        timestamp: serverTimestamp,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        proposalCancelled(currentUserAuth.uid)
        setCancelLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_CANCELING_PROPOSAL',
          message: `Failed to cancel proposal for user for request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OperativeWithPendingProposal.jsx',
        })
      })
  }

  // Model accepting or declining an offer
  return (
    <>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, pendingButton, fullOpacity]}
        disabled={true}
      >
        PENDING
      </Button>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 20px 5px 5px 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Available Actions
      </Text>

      {cancelLoading && (
        <LoadingButton
          mobStyles={[declineButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!cancelLoading && (
        <MiniAccordion
          closedButtonText="CANCEL"
          openButtonText="CANCEL"
          onClick={() => {
            updateAccordionState(RequestActionType.decline)
          }}
          open={declineAccordionOpen}
          buttonMobStyles={[mobStatusButton, declineButton]}
        >
          <Button
            mobStyles={[mobStatusButton, declineButton]}
            onClick={cancelProposalRequestHandler}
          >
            CONFIRM CANCEL
          </Button>
        </MiniAccordion>
      )}
    </>
  )
}
