import { collections } from '../firebaseApp'
import firebase from 'firebase/app'
import { ROUTES } from '../constants/routes'
import { errorHandler, errorTypes } from './errorHandler'

export const sendNewProposalReceivedNotif = ownerId => {
  collections.notifications
    .doc(ownerId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message:
        'You have received a new proposal! You can now chat with the proposer by going to your inbox. Click here to view the proposal.',
      sms:
        'You have received a new proposal! You can now chat with the proposer by going to your inbox. Please log in to view.',
      link: ROUTES.VIEW_RECEIVED_PROPOSALS.link,
      cleared: false,
      deleted: false,
    })
    .then(result => {})
    .catch(error => {
      // notificationErrorHandler(error, ownerId)
    })
}

export const newOfferReceived = operativeId => {
  collections.notifications
    .doc(operativeId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message:
        'You have received a new offer! You can now chat with the gig owner by going to your inbox. Click here to view the offer.',
      sms:
        'You have received a new offer! You can now chat with the gig owner by going to your inbox. Please log in to view.',
      link: ROUTES.VIEW_RECEIVED_OFFERS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, operativeId)
    })
}

export const proposalAccepted = operativeId => {
  collections.notifications
    .doc(operativeId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message: 'Your proposal has been accepted! Click here to view it.',
      sms: 'Your proposal has been accepted! Please log in to view.',
      link: ROUTES.VIEW_CREATED_PROPOSALS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, operativeId)
    })
}

export const offerAccepted = ownerId => {
  collections.notifications
    .doc(ownerId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message: 'Your offer has been accepted! Click here to view it.',
      sms: 'Your offer has been accepted! Please log in to view.',
      link: ROUTES.VIEW_CREATED_OFFERS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, ownerId)
    })
}

export const offerDeclined = ownerId => {
  collections.notifications
    .doc(ownerId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message: 'Your offer has been declined. Click here to view it.',
      sms: 'Your offer has been declined. Please log in to view.',
      link: ROUTES.VIEW_CREATED_OFFERS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, ownerId)
    })
}

export const proposalDenied = operativeId => {
  collections.notifications
    .doc(operativeId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message: 'Your proposal has been denied. Click here to view it.',
      sms: 'Your proposal has been denied. Please log in to view.',
      link: ROUTES.VIEW_CREATED_PROPOSALS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, operativeId)
    })
}

export const proposalCancelled = operativeId => {
  collections.notifications
    .doc(operativeId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message: 'Your proposal has been cancelled. Click here to view it.',
      sms: 'Your proposal has been cancelled. Please log in to view.',
      link: ROUTES.VIEW_CREATED_PROPOSALS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, operativeId)
    })
}

export const fundsReleased = operativeId => {
  collections.notifications
    .doc(operativeId)
    .collection('notifications')
    .add({
      createdAt: firebase.firestore.Timestamp.now(),
      message:
        'The funds for your recently completed gig have been released!!!.',
      sms: 'The funds for your recently completed gig have been released!!!.',
      link: ROUTES.ACCOUNT_SETTINGS.link,
      cleared: false,
      deleted: false,
    })
    .catch(error => {
      notificationErrorHandler(error, operativeId)
    })
}

export const ownerOfferWithdrawal = async (ownerId, operativeId) => {
  try {
    await collections.notifications
      .doc(ownerId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message:
          'You have successfully withdrawn. Please click this link to view the progress of your refund.',
        sms:
          'You have successfully withdrawn. Please log in to view the progress of your refund.',
        link: ROUTES.SUPPORT.link,
        cleared: false,
        deleted: false,
      })

    await collections.notifications
      .doc(operativeId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message: 'Your accepted offer has been withdrawn.',
        sms: 'Your accepted offer has been withdrawn.',
        link: ROUTES.VIEW_RECEIVED_OFFERS.link,
        cleared: false,
        deleted: false,
      })
  } catch (error) {
    notificationErrorHandler(error, ownerId)
  }
}

export const ownerProposalWithdrawal = async (ownerId, operativeId) => {
  try {
    await collections.notifications
      .doc(ownerId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message:
          'You have successfully withdrawn. Please click this link to view the progress of your refund.',
        sms:
          'You have successfully withdrawn. Please log in to view the progress of your refund.',
        link: ROUTES.SUPPORT.link,
        cleared: false,
        deleted: false,
      })

    await collections.notifications
      .doc(operativeId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message: 'Your accepted proposal has been withdrawn.',
        sms: 'Your accepted proposal has been withdrawn.',
        link: ROUTES.VIEW_CREATED_PROPOSALS.link,
        cleared: false,
        deleted: false,
      })
  } catch (error) {
    notificationErrorHandler(error, ownerId)
  }
}

export const operativeOfferWithdrawal = async (ownerId, operativeId) => {
  try {
    await collections.notifications
      .doc(ownerId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message:
          'Your accepted offer has been withdrawn by the creative. Please click this link to view the progress of your refund.',
        sms:
          'Your accepted offer has been withdrawn by the creative. Please log in to view the progress of your refund.',
        link: ROUTES.SUPPORT.link,
        cleared: false,
        deleted: false,
      })

    await collections.notifications
      .doc(operativeId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message: 'You have successfully withdrawn.',
        sms: 'You have successfully withdrawn.',
        link: ROUTES.VIEW_RECEIVED_OFFERS.link,
        cleared: false,
        deleted: false,
      })
  } catch (error) {
    notificationErrorHandler(error, operativeId)
  }
}

export const operativeProposalWithdrawal = async (ownerId, operativeId) => {
  try {
    await collections.notifications
      .doc(ownerId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message:
          'A creative has withdrawn from a proposal you accepted. Please click this link to view the progress of your refund.',
        sms:
          'A creative has withdrawn from a proposal you accepted. Please log in to view the progress of your refund.',
        link: ROUTES.SUPPORT.link,
        cleared: false,
        deleted: false,
      })

    await collections.notifications
      .doc(operativeId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message: 'You have successfully withdrawn.',
        sms: 'You have successfully withdrawn.',
        link: ROUTES.VIEW_CREATED_PROPOSALS.link,
        cleared: false,
        deleted: false,
      })
  } catch (error) {
    notificationErrorHandler(error, operativeId)
  }
}

export const disputeCreation = async (ownerId, operativeId) => {
  try {
    await collections.notifications
      .doc(ownerId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message:
          'A support ticket has been created for the dispute. Please click to track the status in the support center.',
        sms:
          'A support ticket has been created for the dispute. Please log in to track the status in the support center.',
        link: ROUTES.SUPPORT.link,
        cleared: false,
        deleted: false,
      })

    await collections.notifications
      .doc(operativeId)
      .collection('notifications')
      .add({
        createdAt: firebase.firestore.Timestamp.now(),
        message:
          'A dispute that involves you has been created, please click here to track the status in the support center.',
        sms:
          'A dispute that involves you has been created, please log in to track the status in the support center.',
        link: ROUTES.SUPPORT.link,
        cleared: false,
        deleted: false,
      })
  } catch (error) {
    notificationErrorHandler(error, ownerId)
  }
}

const notificationErrorHandler = (error, userId) => {
  errorHandler({
    error,
    errorId: 'ERROR_CREATING_NOTIFICATION',
    message: `Error while creating a notification for ${userId}. Message: ${message}`,
    type: errorTypes.notifications,
    user: userId,
    file: 'notificationHandlers.js',
  })
}
