import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Box, mobBoxVariants } from '../../../../shared/Box'
import { css } from 'styled-components'
import {
  StripeAccountModule,
  StripeCustomerModule,
} from '../../../../components'
import { PrivateUser } from '../../../../classes'
import { Image } from '../../../../shared/Image'
import BronnerSmallLogo from '../../../../assets/logos/bronner_small_logo.jpg'
import { Button, mobButtonVariants } from '../../../../shared/Button'
import React from 'react'
import {
  imageSignUpInStyles,
  LoadingSlide,
  mobImageSignUpInStyles,
  whiteLabelMotionVariants,
} from '../WhiteLabelExperienceRouter'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections } from '../../../../firebaseApp'
import { ROUTES } from '../../../../constants/routes'
import { useLottie } from 'lottie-react'
import SuccessCheck from '../../../../assets/lottie/54092-success'
import { deskTextVariants } from '../../../../shared/Text/deskTextVariants'

const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants
const { column, row } = mobBoxVariants

export const CollectEmployerPaymentSlide = ({ currentUserAuth }) => {
  const history = useHistory()
  const [
    privateUserSnapshot,
    privateUserSnapshotLoading,
    privateUserSnapshotError,
  ] = useDocumentData(
    collections.privateUserInformation.doc(currentUserAuth.uid),
    {
      idField: 'id',
    }
  )

  if (privateUserSnapshotLoading) {
    return <LoadingSlide />
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box deskStyles={[row]} mobStyles={[column]}>
        <Box
          deskStyles={[
            column,
            css`
              flex: 3;
              padding: 50px 10px 0 50px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 3;
              padding: 50px 20px 0 20px;
            `,
          ]}
        >
          <StripeCustomerModule
            privateUserSnapshot={new PrivateUser(privateUserSnapshot)}
            flattenedInputs={true}
          />
          {privateUserSnapshot.stripeCustomer.hasStripeCustomer && (
            <Box
              deskStyles={[
                column,
                css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-top: 25px;
                `,
              ]}
            >
              <Button
                deskStyles={[
                  deskSubmitButton,
                  css`
                    width: 100%;
                  `,
                ]}
                mobStyles={[
                  mobSubmitButton,
                  mobLong305x46,
                  css`
                    width: 100%;
                  `,
                ]}
                onClick={() => {
                  history.push(ROUTES.SEARCH.link)
                }}
              >
                VIEW MODELS
              </Button>
            </Box>
          )}
        </Box>
        <Box
          mobStyles={[
            column,
            css`
              flex: 1;
              margin-top: 25px;
              justify-content: center;
              align-items: center;
            `,
          ]}
        >
          <Image
            src={BronnerSmallLogo}
            deskStyles={imageSignUpInStyles}
            mobStyles={mobImageSignUpInStyles}
          />
        </Box>
      </Box>
    </motion.div>
  )
}

export const CollectModelPaymentSlide = ({ currentUserAuth }) => {
  const history = useHistory()

  return (
    <Box deskStyles={[row]}>
      <Box
        deskStyles={[
          column,
          css`
            flex: 1;
            padding: 50px 10px 0 50px;
          `,
        ]}
      >
        <StripeAccountModule
          currentUserAuth={currentUserAuth}
          // privateUserSnapshot={privateUserSnapshot}
          privateUserSnapshot={new PrivateUser()}
        />
      </Box>
      <Box
        deskStyles={[
          column,
          css`
            flex: 1;
            padding: 50px 10px 0 50px;
            justify-content: center;
            align-items: center;
          `,
        ]}
      >
        <Image src={BronnerSmallLogo} deskStyles={imageSignUpInStyles} />
      </Box>
    </Box>
  )
}
