import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { AccountType } from '../../constants/enums'
import React, { useState } from 'react'
import 'firebase/storage'
import { TextAreaWithHeader } from '../../components/Inputs/TextAreaWithHeader'

/**
 *
 * @param {User} user
 * @param profileForm
 * @param userAccountType
 * @returns {null|*}
 * @constructor
 */
export const PhotographerUpdateProfileModule = ({ user, profileForm }) => {
  if (user.accountType !== AccountType.photographer) {
    return null
  }

  const [description, setDescription] = useState(
    profileForm.current.description
  )

  return (
    <>
      <InputWithHeader
        header="NAME"
        inputName="fullName"
        onChange={profileForm.current.updateName}
        placeholder={user.fullName}
        placeholderDefault="Enter Full Name"
      />
      <InputWithHeader
        header="SPECIALTY"
        inputName="specialty"
        onChange={profileForm.current.updatePhotoSpecialty}
        placeholder={user.agencyType}
        placeholderDefault={`Example: Maternity, Nature, Generalist`}
      />
      <InputWithHeader
        header="EQUIPMENT"
        inputName="equipment"
        onChange={profileForm.current.updateEquipment}
        placeholder={user.equipment}
        placeholderDefault="Example: Nikon D850"
      />
      <InputWithHeader
        header="WEBSITE"
        inputName="website"
        onChange={profileForm.current.updateWebsiteUrl}
        placeholder={user.websiteUrl}
        placeholderDefault="Example: bookamodel.com"
      />
      <InputWithHeader
        header="ZIP CODE"
        inputName="zipCode"
        type="text"
        pattern="\d*"
        maxLength="5"
        onChange={profileForm.current.updateZip}
        placeholder={user.zipCode}
        placeholderDefault="Example: 10001"
      />
      <InputWithHeader
        header="HOURLY RATE ($)"
        inputName="hourlyRate"
        type="number"
        onChange={profileForm.current.updateRate}
        placeholder={`$${user.getUserHourlyRate()}`}
        placeholderDefault="100"
      />
      <TextAreaWithHeader
        header="BIO"
        inputName="description"
        onChange={event => {
          setDescription(event.target.value)
          profileForm.current.updateDescription(event)
        }}
        value={description}
        placeholderDefault="Tell everyone a bit about yourself."
        inputTestId="photographer-description"
      />
    </>
  )
}
