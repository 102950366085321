import React, { useState } from 'react'
import { Input, inputVariants, mobBoxVariants } from '../../../shared/index'
import styled, { css } from 'styled-components'
import { Box, Button } from '../../../shared'
import { FaTimes } from 'react-icons/fa'

const { mobInput } = inputVariants
const { column } = mobBoxVariants

// Inspired by https://codepen.io/raubaca/pen/BayXmBZ
export const TagInput = ({ imageTags, setImageTags }) => {
  const [userInput, setUserInput] = useState('')

  const addTagHandler = tag => {
    setImageTags([...imageTags, tag])
  }
  const deleteTagHandler = i => {
    setImageTags(imageTags.filter((_, index) => index !== i))
  }
  const keyPressHandler = e => {
    if (imageTags[0] === 'Example' && imageTags.length <= 1) {
      // delete imageTags[0]
      deleteTagHandler(0)
    }

    if (e.which === 8 && imageTags.length >= 1 && userInput === '') {
      deleteTagHandler(imageTags.length - 1)
    }

    // Only allow up to 10 tags
    if (imageTags.length >= 10) {
      return
    }

    // 13: Enter
    // 32: Space
    // 8:  Backspace
    if ((e.which === 13 || e.which === 32) && userInput.trim() !== '') {
      let santizedInput = userInput.trim()
      santizedInput =
        santizedInput.charAt(0).toUpperCase() + santizedInput.slice(1)
      addTagHandler(santizedInput)
      setUserInput('')
    }
  }

  return (
    <Box mobStyles={[column]}>
      <Box
        mobStyles={[
          css`
            flex-wrap: wrap;
            margin-bottom: 10px;
          `,
        ]}
      >
        {imageTags.map((tag, i) => (
          <Tag
            key={tag + i}
            label={tag}
            onClickDelete={() => deleteTagHandler(i)}
          />
        ))}
      </Box>
      <Input
        type="text"
        value={userInput}
        onChange={e => setUserInput(e.target.value)}
        onKeyDown={keyPressHandler}
        mobStyles={[mobInput]}
        placeholder="Add up to 10 relevant tags"
      />
    </Box>
  )
}

const Tag = ({ label, onClickDelete }) => (
  <TagSpan>
    <TagText>{label}</TagText>
    <Button mobStyles={[mobTagDeleteButton]} onClick={onClickDelete}>
      <FaTimes />
    </Button>
  </TagSpan>
)

const TagSpan = styled.span`
  display: flex;
  align-items: center;
  margin: 0.25em;
  background: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.secondary};
  line-height: 2;
  border-radius: 4px;
`

const TagText = styled.text`
  padding: 0 0.5em;
`

const mobTagDeleteButton = css`
  background: transparent;
  color: inherit;
  font: inherit;
`
