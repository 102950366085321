import { css, useTheme } from 'styled-components'
import React, { useState } from 'react'
import { Box, deskBoxVariants } from '../../shared/Box'
import { Text } from '../../shared/Text'
import { IconContext } from 'react-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { FaMinus, FaPlus } from 'react-icons/fa'

const { row, fullWidth } = deskBoxVariants

export const FilterAccordion = ({ filterName, children }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box
        deskStyles={[row]}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <Box deskStyles={[row, fullWidth]}>
          <Box>
            <Text deskStyles={deskFilterTypeHeader}>{filterName}</Text>
          </Box>
          <Box
            deskStyles={[
              fullWidth,
              css`
                align-items: center;
                justify-content: flex-end;
              `,
            ]}
          >
            {!open && (
              <IconContext.Provider
                value={{
                  color: theme.colors.blueGray,
                  size: '1.5em',
                }}
              >
                <FaPlus />
              </IconContext.Provider>
            )}
            {open && (
              <IconContext.Provider
                value={{
                  color: theme.colors.blueGray,
                  size: '1.5em',
                }}
              >
                <FaMinus />
              </IconContext.Provider>
            )}
          </Box>
        </Box>
      </Box>

      <AnimatePresence initial={false}>
        {open && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
      <Box deskStyles={[deskHorizontalDivider]} />
    </>
  )
}

const deskFilterTypeHeader = css`
  height: 22px;
  width: 141px;
  color: #7c848b;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
`

const deskHorizontalDivider = css`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #979797;
`
