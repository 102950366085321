import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { css } from 'styled-components'

import { collections } from '../../firebaseApp'
import { Box, mobBoxVariants } from '../../shared/index'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'

const { column, mobWrapper } = mobBoxVariants

export const AdminWaitListPage = ({ currentUserAuth }) => {
  const [
    waitlistSlots,
    waitlistSlotsLoading,
    waitlistSlotsError,
  ] = useCollection(collections.waitlist, {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  if (currentUserAuth) {
    return (
      <DesktopPageWrapper>
        <Box mobStyles={[column, mobWrapper]}>
          <Box>
            <p>Current User: {currentUserAuth.email}</p>
          </Box>
          <Box>
            {waitlistSlotsError && <strong>Error: {waitlistSlotsError}</strong>}
            {waitlistSlots && (
              <Box mobStyles={column}>
                <Box mobStyles={row}>
                  Wait list signups
                  <Box mobStyles={rowValue}>~{waitlistSlots.docs.length}</Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DesktopPageWrapper>
    )
  }
  return null
}

const row = css`
  width: auto;
`

const rowValue = css`
  margin-left: auto;
  margin-right: 0;
  color: ${p => p.theme.colors.gray};
  font-family: 'Open Sans', serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
`
