import React from 'react'
import styled, { css } from 'styled-components'

import { Image } from '../../shared/index'

const StyledA = styled.a`
  :focus {
    outline: thin dotted ${p => p.theme.colors.primary};
  }
`

const mobImageStyles = css`
  height: 45px;
  width: 45px;
`

const deskImageStyles = css`
  height: 60px;
  width: 60px;
`

const IconContainer = ({ icons = [] }) => {
  return (
    <>
      {icons.map((icon, index) => (
        <StyledA href={icon.link} key={index}>
          <Image
            src={icon.icon}
            mobStyles={mobImageStyles}
            deskStyles={deskImageStyles}
          />
        </StyledA>
      ))}
    </>
  )
}

export default IconContainer
