import React from 'react'

import { StyledMotionTR, StyledTR } from './styles'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

export const TR = ({ children, ...props }) => {
  if (props.enableMotion) {
    return <StyledMotionTR {...props}>{children}</StyledMotionTR>
  }

  return <StyledTR {...props}>{children}</StyledTR>
}

TR.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
