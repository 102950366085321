import React from 'react'
import {
  Box,
  deskBoxVariants,
  Image,
  Link,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared/index'
import { css } from 'styled-components'
import { RatingModule, RatingPosition } from '../../modules/RatingModule'
import { ROUTES } from '../../constants/routes'
import { Detail } from '../Detail'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { inchesToHumanReadable } from '../../modules/ProfileDetailsModule'

const {
  column,
  row,
  center,
  mobProfileBoxMini,
  mobProfileBoxRequest,
  mobProfileBoxDetails,
} = mobBoxVariants
const { deskProfileBoxMini, deskProfileBoxDetails } = deskBoxVariants
const { mobUserProfileImageMini, mobUserProfileImageRequest } = mobImageVariants
const { mobUserProfileLocationMini, mobUserProfileNameMini } = mobTextVariants
const {
  deskUserProfileLocationMini,
  deskUserProfileNameMini,
} = deskTextVariants

export const ProfileBoxType = {
  Full: 1,
  Mini: 2,
  Button: 3,
  MiniHorizontal: 4,
}

/**
 * This component builds various different types of boxes for profiles
 *
 *  fullLink: full-length box used in most of the pages
 *  miniLink: Slightly condensed box for search pages
 *  MiniButton: box that is a button used in the select user/models page
 *
 * @param props
 * @constructor
 */
export const ProfileBox = ({ user, index, boxType }) => {
  // Used in the search page
  //     height: 396px;
  const MiniBox = (
    <Link
      mobStyles={[column, mobProfileBoxMini]}
      deskStyles={[deskProfileBoxMini]}
      to={'/profile/' + user.userId}
      key={index}
    >
      <Image
        enableUseAsBackground={true}
        src={user.getProfileImage()}
        mobStyles={mobUserProfileImageMini}
      />
      <Text
        mobStyles={mobUserProfileNameMini}
        deskStyles={deskUserProfileNameMini}
      >
        {user.fullName}
      </Text>
      <Text
        mobStyles={mobUserProfileLocationMini}
        deskStyles={[deskUserProfileLocationMini]}
      >
        {user.getUserCity()}
      </Text>
      <Box
        mobStyles={[mobProfileBoxDetails]}
        deskStyles={[deskProfileBoxDetails, row]}
      >
        <Detail detail={inchesToHumanReadable(user.height)} text="Height" />
        <Detail detail={user.weight} text="Weight" />
        <Detail detail={user.getGender()} text="Gender" />
        <Detail detail={user.getUserHourlyRate()} text="Rate" />
      </Box>
    </Link>
  )

  const MiniHorizontalBorderLess = (
    <Link
      to={ROUTES.PROFILE.link + '/' + user.userId}
      mobStyles={[row, mobProfileBoxRequest]}
      key={index}
    >
      <Box
        mobStyles={[
          css`
            width: 100px;
            height: 100px;
            min-width: 100px;
            min-height: 100px;
          `,
          center,
        ]}
      >
        <Image
          src={user.getProfileImage()}
          mobStyles={mobUserProfileImageRequest}
        />
      </Box>
      <Box
        mobStyles={[
          center,
          column,
          css`
            width: auto;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobUserProfileNameMini,
            css`
              font-size: auto;
              line-height: 20px;
              margin-top: 0;
              text-align: left;
            `,
          ]}
        >
          {user.fullName}
        </Text>
        <Text
          mobStyles={[
            mobUserProfileLocationMini,
            css`
              text-align: left;
            `,
          ]}
        >
          {user.getUserCity()}
        </Text>
        <RatingModule
          rating={user.rating}
          ratingPosition={RatingPosition.left}
        />
      </Box>
    </Link>
  )
  switch (boxType) {
    case ProfileBoxType.MiniHorizontal:
      return MiniHorizontalBorderLess
    case ProfileBoxType.Mini:
      return MiniBox
    default:
      return MiniBox
  }
}
