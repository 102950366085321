import React from 'react'
import { IconContext } from 'react-icons'
import { FaSearch } from 'react-icons/fa'

import {
  Box,
  Input,
  inputVariants,
  mobBoxVariants,
  Option,
  Select,
  selectVariants,
} from '../../shared/index'
import { SearchType } from '../../constants/enums'
import { css, useTheme } from 'styled-components'
import { useRecoilState, useRecoilValue } from 'recoil'
import { searchState, searchTypeState } from '../../store'
import { ROUTES } from '../../constants/routes'

const { mobSearchBar, row } = mobBoxVariants
const { mobSearchBarInput } = inputVariants
const { mobSearchSelectInput } = selectVariants

const dropdownOptions = [
  { value: SearchType.creative, label: 'Users' },
  { value: SearchType.gig, label: 'Gigs' },
  { value: SearchType.images, label: 'Images', disabled: true },
  { value: SearchType.education, label: 'Learning', disabled: true },
]

const mobSearchBarWrapper = css`
  background-color: ${p => p.theme.colors.secondary};
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
`

const mobDropdownWrapper = css`
  width: 40%;
`

const deskDropdownWrapper = css`
  width: 30%;
`

const boxSpacerStyles = css`
  font-size: 15px;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0px 10px 0px 5px;
`

/**
 * Lighter version of the search bar component that will forward the searched text
 * to a page that implements the actual SearchBar
 *
 * @param props
 * @constructor
 */
export const LightSearchBar = ({ history }) => {
  const theme = useTheme()
  const [search, setSearch] = useRecoilState(searchState)

  const { placeholder } = useRecoilValue(searchTypeState)

  const handleSearch = event => {
    if (event.key === 'Enter') {
      history.push(ROUTES.SEARCH.link)
    }
  }

  const renderOptions = () => {
    return dropdownOptions.map(currOption => {
      const { value, label, disabled } = currOption
      return (
        <Option key={value} value={value} disabled={disabled === true}>
          {label}
        </Option>
      )
    })
  }

  const handleSearchTypeChange = event => {
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        type: Number(event.target.value),
      }
    })
  }

  return (
    <Box mobStyles={[mobSearchBar, mobSearchBarWrapper]}>
      <Box
        mobStyles={[row, mobDropdownWrapper]}
        deskStyles={deskDropdownWrapper}
      >
        <Select
          mobStyles={mobSearchSelectInput}
          id="search-type-select"
          onChange={handleSearchTypeChange}
          defaultValue={search.type}
        >
          {renderOptions()}
        </Select>
        <Box mobStyles={boxSpacerStyles} />
      </Box>
      <Box
        mobStyles={[
          row,
          css`
            width: 60%;
          `,
        ]}
      >
        <Box
          mobStyles={[
            css`
              font-size: 15px;
              align-items: center;
              padding: 0px 10px 0px 10px;
            `,
          ]}
        >
          <IconContext.Provider
            value={{
              color: theme.colors.gray,
            }}
          >
            <FaSearch />
          </IconContext.Provider>
        </Box>
        <Input
          type="select"
          mobStyles={[mobSearchBarInput]}
          placeholder={placeholder}
          onChange={event => {
            setSearch(previousSearch => {
              return {
                ...previousSearch,
                query: event.target.value,
              }
            })
          }}
          onKeyPress={handleSearch}
        />
      </Box>
    </Box>
  )
}
