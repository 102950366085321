import styled from 'styled-components'
import { variantsAndStyles } from '../../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

export const StyledTD = styled.td`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;
  ${variantsAndStyles()}
`

export const StyledMotionTD = styled(motion.td)`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${variantsAndStyles()}
`
