import get from 'lodash/get'
import { collections } from '../firebaseApp'
import firebase from 'firebase/app'

/**
 * Error Object
 * @typedef {Object} ErrorObject
 * @property {string} message - custom message
 * @property {Object|string} error - the JavaScript error object or message
 * @property {string} [errorId] - an optional ID, used to help uniquely identify errors
 * @property {string} [type] - the type of error that occurred
 * @property {string} [userId] - the id of the current user
 * @property {string} [file] - the file the error occurred in, used for tracing the error
 * @property {any} [additionalData] related to the error
 */

/**
 *
 * @param {ErrorObject} errorObject
 */
export const errorHandler = errorObject => {
  const loggingObject = { ...errorObject }
  const page = get(window, 'location.href')
  const errors = collections.errors
  loggingObject.createdAt = firebase.firestore.Timestamp.now()
  page ? (loggingObject.page = page) : (loggingObject.page = null)

  if (process.env.NODE_ENV === 'development') {
    console.error('Error occurred', loggingObject)
  }

  if (!loggingObject.type) {
    loggingObject.type = 'default'
  }

  if (loggingObject.error && loggingObject.error instanceof Error) {
    loggingObject.error = JSON.stringify(
      loggingObject.error,
      Object.getOwnPropertyNames(loggingObject.error)
    )
  }

  const errorTypeCollection = errors
    .doc(loggingObject.type)
    .collection('errors')

  errorTypeCollection
    .add(loggingObject)
    .then(() => {
      console.error('Error has been logged successfully.')
    })
    .catch(error => {
      console.error('Error while trying to store error.', error)
    })
}

export const errorTypes = {
  auth: 'auth',
  gig: 'gig',
  chat: 'chat',
  profile: 'profile',
  billing: 'billing',
  notifications: 'notifications',
  cash: 'cash',
  creation: 'creation',
  edit: 'edit',
  errorBoundary: 'errorBoundary',
  faq: 'faq',
  support: 'support',
  refunds: 'refunds',
  customHook: 'customHook',
  cancellation: 'cancellation',
  passbase: 'passbase',
  dispute: 'dispute',
  onboarding: 'onboarding',
  deletion: 'deletion',
}
