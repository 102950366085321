import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Form,
  Input,
  mobBoxVariants,
  mobButtonVariants,
  mobFormVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import { IconContext } from 'react-icons'
import { FaPlus } from 'react-icons/fa'
import { css, useTheme } from 'styled-components'
import ReactCrop from 'react-image-crop'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { deskFormVariants } from '../../../shared/Form/deskFormVariants'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../../utils/responsiveVars'
import { ImageInputLoader } from '../ImageInputLoader/ImageInputLoader'
import { collections, profileImageStorage } from '../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'

const { mobPageSubHeader, mobInstructionParagraph } = mobTextVariants
const { mobImageForm } = mobFormVariants
const { deskImageForm } = deskFormVariants
const { center, column, mobBoxSpacer } = mobBoxVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants
const { deskPageSubHeader, deskInstructionParagraph } = deskTextVariants

const displayFileExplorer = (event, elementId) => {
  let inputElement = document.getElementById(elementId)
  inputElement.click()
}

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4

export const ProfileImageInput = ({ user, setShowProfileInput }) => {
  const inputElementId = 'user-profile-image-upload'
  const isDesktop = useMediaQuery(desktopQuery)
  const theme = useTheme()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageCropped, setImageCropped] = useState(false)
  const [crop, setCrop] = useState({ aspect: 1 })
  const [upImg, setUpImg] = useState()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [imageUploading, setImageUploading] = useState(false)

  const onSelectFile = e => {
    setImageLoaded(true)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      const url = reader.readAsDataURL(e.target.files[0])
      return url
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const context = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    context.imageSmoothingEnabled = false

    context.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
  }, [completedCrop])

  const handleImageSubmit = async event => {
    setImageUploading(true)
    event.preventDefault()

    // Create a reference to the User's directory in Firebase Storage
    const profileStorageRef = profileImageStorage.ref()
    const userProfileImageReference = profileStorageRef.child(user.userId)
    const croppedImageCanvas = document.getElementById(
      'user-profile-image-upload-canvas'
    )
    const imageInput = document.getElementById('user-profile-image-upload')
    const originalFile = imageInput.files && imageInput.files[0]

    try {
      // Upload cropped Image
      croppedImageCanvas.toBlob(blob => {
        const uploadTask = userProfileImageReference
          .child(user.userId + '-cropped')
          .put(blob, {
            customMetadata: {
              userUid: user.userId,
            },
          })

        // Tracker for the progress on the upload of the image
        uploadTask.on(
          'state_changed',
          (/*snapshot*/) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            /*            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            switch (snapshot.state) {
              case storage.TaskState.PAUSED: // or 'paused'
                break
              case storage.TaskState.RUNNING: // or 'running'
                break
              default:
              // code block
            }*/
          },
          error => {
            errorHandler({
              error,
              errorId: 'ERROR_UPLOADING_PROFILE_IMAGE',
              message: 'Error while user trying to upload profile image.',
              type: errorTypes.profile,
              user: user.userId,
              file: 'ProfileImageInput.jsx',
            })
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              collections.userInformation
                .doc(user.userId)
                .update({
                  'profileImage.croppedUrl': downloadURL,
                  'profileImage.defaultImage': false,
                })
                .then(ref => {
                  return ref
                })
                .catch(error => {
                  const errorObject = {
                    page: 'ProfileImageInput.jsx',
                    type: errorTypes.cloudFunction,
                    error: error,
                    errorId: 'ERROR_ADDING_URLS_TO_PROFILE_IMAGE',
                    message: `Error adding profile image URL. User Id: ${user.userId}`,
                  }
                  errorHandler(errorObject)
                })
            })
          }
        )
      })

      // Upload Original Image
      if (originalFile) {
        const uploadTask = userProfileImageReference
          .child(user.userId + '-original')
          .put(originalFile, {
            customMetadata: {
              userUid: user.userId,
            },
          })

        uploadTask.on(
          'state_changed',
          () => {},
          error => {
            errorHandler({
              error,
              errorId: 'ERROR_UPLOADING_PROFILE_IMAGE',
              message: 'Error while user trying to upload profile image.',
              type: errorTypes.profile,
              user: user.userId,
              file: 'UpdateProfilePage.jsx',
            })
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              collections.userInformation
                .doc(user.userId)
                .update({
                  'profileImage.url': downloadURL,
                  'profileImage.defaultImage': false,
                })
                .then(ref => {
                  return ref
                })
                .catch(error => {
                  const errorObject = {
                    page: 'ProfileImageInput',
                    type: errorTypes.cloudFunction,
                    error: error,
                    errorId: 'ERROR_ADDING_URLS_TO_PROFILE_IMAGE',
                    message: `Error adding profile image URL. User Id: ${user.userId}`,
                  }
                  errorHandler(errorObject)
                })
            })
          }
        )
      }
    } catch (error) {
      const errorObject = {
        page: 'ProfileImageInput',
        type: errorTypes.cloudFunction,
        error: error,
        errorId: 'ERROR_ADDING_URLS_TO_PROFILE_IMAGE',
        message: `Error adding profile image URL. User Id: ${user.userId}`,
      }
      errorHandler(errorObject)
    } finally {
      setImageUploading(false)
      setShowProfileInput(false)
    }
  }

  let imageInputComponent

  // If the profile is being optimized show the loader
  if (imageUploading) {
    // If they have a currently uploading Profile Image
    imageInputComponent = <ImageInputLoader />
  } else if (!imageUploading && !imageLoaded) {
    // If the image is not being uploaded and an image is not loaded
    imageInputComponent = (
      <Form
        onClick={event => displayFileExplorer(event, inputElementId)}
        deskStyles={[deskImageForm]}
        mobStyles={[mobImageForm]}
      >
        <IconContext.Provider
          value={{
            color: theme.colors.gray,
          }}
        >
          <Box
            mobStyles={[
              css`
                font-size: 75px;
                width: 100%;
              `,
              center,
            ]}
          >
            <FaPlus />
          </Box>
        </IconContext.Provider>
      </Form>
    )
  } else if (imageLoaded && !imageUploading) {
    // If an image is loaded and profile image is not being processed
    imageInputComponent = (
      <>
        {!imageCropped && (
          <Text
            mobStyles={mobInstructionParagraph}
            deskStyles={[deskInstructionParagraph]}
          >
            Please crop the portion of the image you want displayed in
            thumbnails. The full un-cropped image will still be available when
            you click the image of the site
          </Text>
        )}
        <ReactCrop
          src={upImg}
          crop={crop}
          minHeight={150}
          minWidth={150}
          onImageLoaded={onLoad}
          onDragStart={() => {
            setImageCropped(true)
          }}
          onChange={crop => {
            if (crop.height === 0 && crop.width === 0) {
              setImageCropped(false)
              setCrop(crop)
            } else {
              setCrop(crop)
            }
          }}
          onComplete={crop => {
            if (crop.height === 0 && crop.width === 0) {
              setImageCropped(false)
              setCrop(crop)
            } else {
              setCrop(crop)
            }
            setCompletedCrop(crop)
          }}
          style={{
            borderRadius: '10px',
            width: isDesktop ? '32vw' : '90vw',
            height: isDesktop ? '32vw' : '90vw',
            boxShadow: theme.shadow.regular,
            marginTop: '1%',
            marginBottom: '1%',
          }}
        />
        {imageCropped && (
          <>
            <Box mobStyles={[mobBoxSpacer, center]}>
              <Button
                mobStyles={[mobSubmitButton, mobLong305x46]}
                deskStyles={deskSubmitButton}
                onClick={e => {
                  handleImageSubmit(e)
                  setImageUploading(true)
                  setImageLoaded(false)
                }}
              >
                UPLOAD
              </Button>
            </Box>
            <Box
              enableMotion
              mobStyles={[]}
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0.5,
                transition: { duration: 1 },
              }}
            >
              <canvas
                id={'user-profile-image-upload-canvas'}
                ref={previewCanvasRef}
                style={{
                  borderRadius: '10px',
                  width: isDesktop ? '32vw' : '90vw',
                  height: isDesktop ? '32vw' : '90vw',
                  boxShadow: theme.shadow.regular,
                }}
              />
            </Box>
          </>
        )}
      </>
    )
  }

  return (
    <Box mobStyles={[center, column]}>
      <Text mobStyles={[mobPageSubHeader]} deskStyles={[deskPageSubHeader]}>
        Profile Image
      </Text>
      <Text
        mobStyles={mobInstructionParagraph}
        deskStyles={[deskInstructionParagraph]}
      >
        Once you select a photo crop it into a square by tapping or dragging the
        cropping box to your desired location on the photo. As you crop you may
        also view the preview of your crop below.
      </Text>
      <Input
        onChange={onSelectFile}
        type="file"
        id={inputElementId}
        name="file"
        hidden
      />
      {imageInputComponent}
    </Box>
  )
}
