import React from 'react'
import { StyledLink } from './styles'
import PropTypes from 'prop-types'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Link = ({
  fontSize = {
    mob: '16px',
    tab: '20px',
    desk: '24px',
    lgDesk: '28px',
  },
  children,
  ...props
}) => {
  return (
    <StyledLink fontSize={fontSize} {...props}>
      {children}
    </StyledLink>
  )
}

Link.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
}
