import React from 'react'
import { css, useTheme } from 'styled-components'
import {
  Box,
  Image,
  Link,
  mobBoxVariants,
  mobLinkVariants,
  Text,
} from '../../shared'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import BAMLogo from '../../assets/logos/logo.svg'

const { mobFootLink, mobIconLinkWrapper } = mobLinkVariants
const { column, row } = mobBoxVariants

const socialIcons = [
  { icon: <FaInstagram />, link: 'https://www.instagram.com/book.a.model/' },
  { icon: <FaTwitter />, link: 'https://twitter.com/BookAmodel_' },
  { icon: <FaFacebook />, link: 'https://facebook.com/BookAmodel_' },
]

const mobImageStyles = css`
  width: 50%;
  margin: 20px 0px 20px 0px;
`

const deskImageStyles = css`
  width: 20%;
`

const mobFooterLinkBox = css`
  flex-direction: column;
  background-color: ${p => p.theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;
`

const mobFooter = css`
  background-color: ${p => p.theme.colors.darkGray};
  bottom: 0;
  color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;
  height: 130px;
`

const tabFooter = css`
  height: 72px;
`

const deskFooter = css`
  height: 160px;
`

const mobFootIconWrapper = css`
  width: 140px;
  height: 60px;
  justify-content: space-evenly;
`

const tabFootIconWrapper = css`
  display: none;
`

const deskFootIconWrapper = css`
  height: 120px;
  margin: 5px;
`

const linksConfig = [
  { link: '#header', label: 'HOME' },
  { link: '/contributors', label: 'CONTRIBUTORS' },
  { link: '/licensing', label: 'LICENSING' },
  { link: '/faq', label: 'FAQ' },
]

const renderLinks = () => {
  return linksConfig.map(({ link, label }) => (
    <Link key={link} mobStyles={[mobFootLink]} to={link}>
      {label}
    </Link>
  ))
}

export const Footer = () => {
  const theme = useTheme()
  return (
    <>
      <Box mobStyles={[mobFooterLinkBox]}>
        <Image
          src={BAMLogo}
          mobStyles={mobImageStyles}
          deskStyles={deskImageStyles}
        />
        {renderLinks()}
      </Box>

      <Box
        mobStyles={[mobFooter, column]}
        tabStyles={tabFooter}
        deskStyles={deskFooter}
      >
        <Box
          mobStyles={[mobFootIconWrapper, row]}
          deskStyles={deskFootIconWrapper}
        >
          {socialIcons.map((icon, index) => (
            <Link
              mobStyles={[mobIconLinkWrapper]}
              to={{ pathname: icon.link }}
              target="_blank"
              key={index}
            >
              <IconContext.Provider
                value={{ color: theme.colors.secondary, size: '2em' }}
              >
                {icon.icon}
              </IconContext.Provider>
            </Link>
          ))}
        </Box>
        <Text>© 2021. All Rights Reserved.</Text>
      </Box>
    </>
  )
}
