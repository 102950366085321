import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { AccountType } from '../../constants/enums'
import React, { useState } from 'react'
import 'firebase/storage'
import { TextAreaWithHeader } from '../../components/Inputs/TextAreaWithHeader'

const acceptableAccountTypes = [
  AccountType.agency,
  AccountType.instructor,
  AccountType.exhibitor,
]
/**
 *
 * @param {User} user
 * @param profileForm
 * @param userAccountType
 * @returns {null|*}
 * @constructor
 */
export const AgencyUpdateProfileModule = ({ user, profileForm }) => {
  if (!acceptableAccountTypes.includes(user.accountType)) {
    return null
  }

  const [description, setDescription] = useState(
    profileForm.current.description
  )

  return (
    <>
      <InputWithHeader
        header="AGENCY NAME"
        inputName="fullName"
        onChange={profileForm.current.updateName}
        placeholder={user.fullName}
        placeholderDefault="Enter Agency's Name"
      />
      <InputWithHeader
        header="AGENCY TYPE"
        inputName="agency-type"
        onChange={profileForm.current.updateAgencyType}
        placeholder={user.agencyType}
        placeholderDefault={`Example: Private modeling agency`}
      />
      <InputWithHeader
        header="INDUSTRY"
        inputName="industry"
        onChange={profileForm.current.updateIndustry}
        placeholder={user.industry}
        placeholderDefault="Example: Fashion"
      />
      <InputWithHeader
        header="LOCATION ZIP CODE"
        inputName="location"
        onChange={profileForm.current.updateZip}
        placeholder={user.zipCode}
        placeholderDefault="Example: 90001"
      />
      <InputWithHeader
        header="WEBSITE"
        inputName="website"
        onChange={profileForm.current.updateWebsiteUrl}
        placeholder={user.websiteUrl}
        placeholderDefault="Example: bookamodel.com"
      />
      <InputWithHeader
        header="FOUNDED"
        inputName="founded"
        onChange={profileForm.current.updateYearFounded}
        placeholder={user.yearFounded}
        placeholderDefault="Example: 2020"
      />
      <InputWithHeader
        header="REPRESENTATIVE"
        inputName="REP"
        onChange={profileForm.current.updateRepresentative}
        placeholder={user.representative}
        placeholderDefault="Example: Book A. Model"
      />
      <TextAreaWithHeader
        header="AGENCY DESCRIPTION"
        inputName="description"
        onChange={event => {
          setDescription(event.target.value)
          profileForm.current.updateDescription(event)
        }}
        value={description}
        placeholderDefault="Short description of the agency."
        inputTestId="agency-description"
      />
    </>
  )
}
