import React from 'react'
import PropTypes from 'prop-types'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/index'
import { css } from 'styled-components'
import { InformativeMessageType } from '../../constants/enums'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

const { column, mobMessageBox } = mobBoxVariants
const { mobMessageBoxMessage, mobMessageBoxHeader } = mobTextVariants

// Plain black and white
const bamBasicMessageText = css`
  color: ${p => p.theme.colors.white};
`

const bamInfoMessageText = css`
  color: ${p => p.theme.colors.yellow};
`
const bamInfoWrapper = css`
  background-color: ${p => p.theme.colors.yellow};
`

const bamWarningMessageText = css`
  color: ${p => p.theme.colors.lightRed};
`

const disputeButton = css`
  background-color: ${p => p.theme.colors.purple};
  color: ${p => p.theme.colors.secondary};
`

const successMessageBox = css`
  background-color: ${p => p.theme.colors.secondary};
`
const successMessageHeaderText = css`
  color: ${p => p.theme.colors.green};
`

const defaultStyleProps = {
  mobStyles: [],
  tabStyles: [],
  deskStyles: [],
  lgDeskStyles: [],
}

/**
 * This component builds various different types of boxes for profiles
 *
 *  fullLink: full-length box used in most of the pages
 *  miniLink: Slightly condensed box for search pages
 *  MiniButton: box that is a button used in the select user/models page
 *
 * @param props
 * @constructor
 */
export const MessageBox = ({
  header,
  message,
  messageType,
  wrapperStyles = defaultStyleProps,
  messageHeaderStyles = defaultStyleProps,
  messageStyles = defaultStyleProps,
  onClick,
  subMessage,
}) => {
  let messageStyle

  switch (messageType) {
    case InformativeMessageType.general:
      messageStyle = bamBasicMessageText
      break
    case InformativeMessageType.success:
      messageStyle = bamInfoMessageText
      break
    case InformativeMessageType.info:
      messageStyle = bamInfoMessageText
      break
    case InformativeMessageType.danger:
      messageStyle = bamWarningMessageText
      break
    default:
      messageStyle = bamBasicMessageText
  }

  return (
    <Box
      {...wrapperStyles}
      mobStyles={[column, mobMessageBox, ...wrapperStyles.mobStyles]}
      onClick={onClick}
    >
      <Text
        {...messageHeaderStyles}
        mobStyles={[
          messageStyle,
          mobMessageBoxHeader,
          ...messageHeaderStyles.mobStyles,
        ]}
      >
        {header}
      </Text>
      <Text
        {...messageStyles}
        mobStyles={[mobMessageBoxMessage, ...messageStyles.mobStyles]}
      >
        {message}
      </Text>
      {subMessage && (
        <Text
          {...messageStyles}
          mobStyles={[mobMessageBoxMessage, ...messageStyles.mobStyles]}
        >
          {subMessage}
        </Text>
      )}
    </Box>
  )
}

MessageBox.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  messageHeaderStyles: PropTypes.object,
  messageStyles: PropTypes.shape(responsiveStyleAndVariantsProps),
  messageType: PropTypes.shape(responsiveStyleAndVariantsProps),
  wrapperStyles: PropTypes.shape(responsiveStyleAndVariantsProps),
}
