import React from 'react'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'

export const ContributorPage = () => {
  return (
    <DesktopPageWrapper>
      <p>Share your content!</p>
    </DesktopPageWrapper>
  )
}
