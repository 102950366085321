import get from 'lodash/get'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import without from 'lodash/without'
import firebase from 'firebase/app'

import { Message } from './Message'
import { collections } from '../../firebaseApp'
import { ChatRoomError } from '../../utils/createChatroom'

class MessageError extends Error {}

export class ChatRoom {
  /**
   * ChatRoom instance that exposes all methods to interact with Chat including
   * sending messages, removing users, etc.
   *
   * This constructor should not be called directly and
   * instead from {Chat.createChatRoom}
   *
   *
   * @constructor
   * @returns {ChatRoom} object contains all chat room properties
   * @param chatRoomData
   */
  constructor(chatRoomData) {
    if (chatRoomData) {
      this.chatRoomDocument = chatRoomData
      this.chatRoomData =
        chatRoomData && chatRoomData.data ? chatRoomData.data() : chatRoomData
      this.id = chatRoomData.id
    }
    this.title = get(this.chatRoomData, 'title', 'Chat Thread')
    this.members = get(this.chatRoomData, 'members', [])
    this.lastMessageSentTime = get(
      this.chatRoomData,
      'lastMessageSentTime',
      firebase.firestore.Timestamp.fromMillis(0)
    )
    this.lastMessageSentBody = get(this.chatRoomData, 'lastMessageSentBody', '')
    this.createdAt = get(
      this.chatRoomData,
      'createdAt',
      firebase.firestore.Timestamp.now()
    )
    this.isRemoved = get(this.chatRoomData, 'isRemoved', false)
    this.isOpen = get(this.chatRoomData, 'isOpen', true)
  }

  getRecipient = currentUserId => {
    return head(without(this.members, currentUserId))
  }

  /**
   * get all messages and listen to new messages
   * make an action when received a new mesage
   * @param {(newMessage:Message)=>void} action that should happen when receiving this message
   */
  getMessagesAndListen(action) {
    this.chatRoomRef.collection('messages').onSnapshot(messagesSnapshot => {
      messagesSnapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          const doc = change.doc
          const messageRef = this.chatRoomRef.collection('messages').doc(doc.id)
          const message = doc.data()

          let newMessage = new Message(
            message.body,
            message.from,
            this,
            undefined,
            messageRef
          )
          newMessage.createdAt = message.createdAt

          action(newMessage)
        }
      })
    })
  }

  /**
   * Send message in this chat room
   * @param body Message body text
   * @param senderUserId
   * @param {(err:Error)=>void}  [onComplete] Callback function call after changing chat room title or with err if not     *
   * @returns {Message} Message that has been sent
   *
   */
  sendMessage(body, senderUserId, onComplete) {
    // 3rd Param is passing the current instance of ChatRoom
    // Check validation message body
    if (isEmpty(body) || !isString(body)) {
      throw new MessageError('Message should have body and be string')
    }

    // // Check validation of the user id string
    // if (isEmpty(senderUserId)) {
    //   throw new MessageError('From should be not empty')
    // } else {
    // }
    //
    // // Check if the user in this chat room
    // if (!isEmpty(intersection(this.members, [senderUserId]))) {
    //   throw new MessageError(" 'from' user must be in this chat room")
    // } else {

    // }

    // Make sure ChatRoom is the correct Class
    if (!this instanceof ChatRoom) {
      throw new MessageError('chatRoom should be instance of ChatRoom class')
    }

    const messageCreatedAt = firebase.firestore.Timestamp.now()
    // Make doc if for the new message
    const newMessageReference = collections.chatRooms
      .doc(this.chatRoomDocument.id)
      .collection('messages')
      .doc()

    collections.chatRooms.doc(this.chatRoomDocument.id).set(
      {
        lastMessageSentTime: messageCreatedAt,
        lastMessageSentBody: body,
      },
      { merge: true }
    )

    newMessageReference
      .set({
        body: body,
        from: senderUserId,
        createdAt: messageCreatedAt,
        seen: false,
      })
      .then(onComplete)
  }

  /**
   * change the title of the chat room
   * @param {String} title  the new title to be changed
   */
  setNewTitle(title) {
    // validate title
    if (!isString(title) || isEmpty(title)) {
      throw new ChatRoomError('title should be not empty and string')
    }
    // update chat room title
    this.chatRoomRef
      .update(
        {
          title,
        },
        { merge: true }
      )
      .then(() => {})
  }

  /**
   * Remove chat room.
   *
   * Soft: sets ChatRoom status to removed so users cant see it. DEFAULT
   * Hard: Totally deletes the ChatRoom and all Data
   *
   * @param {Boolean} softRemove set flag to chat room to marked it as removed
   */
  remove(softRemove = true) {
    const hardRemove = !softRemove
    if (softRemove) {
      // Update chat room isRemoved flag
      this.chatRoomRef
        .update({ isRemoved: true }, { merge: true })
        .then(() => {})
      return this
    } else if (hardRemove) {
      // TODO: Add a timestamp to the attempted Hard removes and delete at that date
      // We will not be doing hard removes just in case we need to access this data
      // this.chatRoomRef.remove(onComplete)
      return this
    }
  }
}
