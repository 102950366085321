// PROD-READY-MOBILE
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { auth, googleProvider } from '../../../firebaseApp'
import { ROUTES } from '../../../constants/routes'
import { Button, mobButtonVariants } from '../../../shared/index'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'

const { mobLong305x46, authButtonLight } = mobButtonVariants

export const GoogleLoginButton = ({ mobStyles, ...props }) => {
  const [error, setError] = useState(null)
  const history = useHistory()

  const signInGoogle = () => {
    auth
      .signInWithRedirect(googleProvider)
      .then(() => {
        history.push(ROUTES.HOME.link)
      })
      .catch(error => {
        setError(error)
        errorHandler({
          error,
          errorId: 'ERROR_AUTHENTICATING_WITH_GOOGLE',
          message:
            'There was an error authenticating using Google Authentication',
          type: errorTypes.auth,
          file: 'GoogleLoginButton.jsx',
        })
      })
  }

  const googleLoginBtnMobStyles = Array.isArray(props.mobStyles)
    ? [mobLong305x46, authButtonLight, ...mobStyles]
    : [mobLong305x46, authButtonLight, mobStyles]

  return (
    <>
      <div>{error && <div>Error: {error.message}</div>}</div>
      <Button
        mobStyles={[googleLoginBtnMobStyles]}
        onClick={signInGoogle}
        {...props}
      >
        LOG IN WITH GOOGLE
      </Button>
    </>
  )
}

GoogleLoginButton.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
