import { AccountType } from './enums'

/*
 * NAV LINKS WILL BE HIDDEN FROM ANY ACCOUNT TYPE NOT IN THE ARRAY GROUP
 * */

/* All possible account types. For pages anyone that visits the site can see. */
export const all = Object.values(AccountType)

/* Authenticated account types. Anyone who is logged in can see these in their menu. */
/* All account types MINUS the guest account type */
export const authenticatedWithAccountType = all.filter(accountType => {
  return accountType !== AccountType.guest && accountType !== AccountType.none
})

export const authenticatedNoAccountType = all.filter(accountType => {
  return accountType !== AccountType.guest
})

/* Developer account types. Most Powerful Account type. */
/* All other user types are built on top of this account type */
export const developer = [AccountType.developer]

/* Creative account types. Only Models, Photographers, and Makeup Artists will be able to see these. */
export const creative = developer.concat(
  AccountType.model,
  AccountType.photographer,
  AccountType.makeupArtist,
  AccountType.influencer
)

/* Agency account type. Only Agencies will be able to see these. */
export const agency = developer.concat(
  AccountType.agency,
  AccountType.admin,
  AccountType.instructor,
  AccountType.exhibitor
)

/* Admin account type. Only Admin will be able to see these. */
export const admin = developer.concat(AccountType.admin)

/* Guest account types. These will be hidden while authenticated. */
export const guest = developer.concat(AccountType.guest)
