import styled, { css } from 'styled-components'
import { variantsAndStyles } from '../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

const sectionStyles = css`
  border: none;
  box-sizing: border-box;

  ${variantsAndStyles()}
`

export const StyledSection = styled.section`
  ${sectionStyles}
`

export const StyledMotionSection = styled(motion.section)`
  ${sectionStyles}
`
