import React from 'react'
import { mobTextVariants, Text } from '../../../shared/index'
import TimePicker from 'rc-time-picker'
import styled from 'styled-components'

const { mobInputHeader } = mobTextVariants

export const StyledTimePicker = styled(TimePicker)`
  .rc-time-picker-input {
    border: none;
    width: 100%;
    color: ${p => p.theme.colors.lightGray};
    font-size: 16px;
    line-height: 22px;
    padding-left: 15px;
    height: 48px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    color: ${p => p.theme.colors.darkGray};
    margin: 0 0 15px;
    box-shadow: ${p => p.theme.shadow.regular};
    -webkit-appearance: none;

    ::placeholder {
      color: ${p => p.theme.colors.darkGray};
      opacity: 0.44;
    }
  }
`
export const TimeInputWithHeader = ({
  header,
  startTimeHandler,
  endTimeHandler,
  startPlaceholder,
  endPlaceholder,
}) => {
  return (
    <>
      <Text mobStyles={mobInputHeader}>{header}</Text>
      <StyledTimePicker
        placeholder={startPlaceholder || 'Gig start time'}
        use12Hours
        showSecond={false}
        minuteStep={15}
        clearIcon={<></>}
        onChange={startTimeHandler}
        allowEmpty={false}
        data-testid="date-start-time"
      />
      <StyledTimePicker
        placeholder={endPlaceholder || 'Gig end time'}
        use12Hours
        showSecond={false}
        minuteStep={15}
        clearIcon={<></>}
        onChange={endTimeHandler}
        allowEmpty={false}
        data-testid="date-end-time"
      />
    </>
  )
}
