import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import {
  Button,
  Image,
  mobBoxVariants,
  mobButtonVariants,
} from '../../shared/index'
import { Box } from '../../shared/Box'

const {
  landingPageButton,
  mobLong305x46,
  tabLong296x48,
  deskLong325x52,
} = mobButtonVariants
const { column } = mobBoxVariants

const PositionWrapper = styled.div`
  position: relative;
  bottom: 21px;
  width: 100%;
  justify-content: center;
  display: flex;

  ${up('tablet')} {
    bottom: 44px;
    justify-content: center;
  }

  ${up('desktop')} {
    bottom: 25px;
    justify-content: center;
  }
`

const mobImageStyles = css`
  padding-bottom: 16px;
  width: 280px;
  height: 308px;
`

const deskImageStyles = css`
  width: 289px;
  height: 320px;
`

const lgDeskImageStyles = css`
  height: 360px;
  width: 320px;
`

const ImageWithButton = ({ buttonText, imgSrc, ...props }) => {
  return (
    <Box mobStyles={[column]}>
      <Image
        src={imgSrc}
        mobStyles={mobImageStyles}
        deskStyles={deskImageStyles}
        lgDeskStyles={lgDeskImageStyles}
        {...props}
      />
      <PositionWrapper>
        <Button
          mobStyles={[landingPageButton, mobLong305x46]}
          tabStyles={tabLong296x48}
          deskStyles={
            (deskLong325x52,
            css`
              width: 267px;
            `)
          }
        >
          {buttonText}
        </Button>
      </PositionWrapper>
    </Box>
  )
}

ImageWithButton.propTypes = {
  buttonText: PropTypes.string,
  imgSrc: PropTypes.string,
}

export default ImageWithButton
