import styled, { css } from 'styled-components'
import { getFontSize, variantsAndStyles } from '../../utils/variantsAndStyles'

const HeadingStyles = css`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  text-decoration: none;
  margin-block-start: 0;
  margin-block-end: 0;
  box-sizing: border-box;

  ${getFontSize}
  ${variantsAndStyles()};
`

export const StyledH1 = styled.h1`
  ${HeadingStyles}
`
export const StyledH2 = styled.h2`
  ${HeadingStyles}
`
export const StyledH3 = styled.h3`
  ${HeadingStyles}
`
export const StyledH4 = styled.h4`
  ${HeadingStyles}
`
export const StyledH5 = styled.h5`
  ${HeadingStyles}
`
export const StyledH6 = styled.h6`
  ${HeadingStyles}
`
