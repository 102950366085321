import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Image,
  mobBoxVariants,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared/'
import { collections } from '../../firebaseApp'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { AccountType } from '../../constants/enums'
import { mobTextAreaVariants, TextArea } from '../../shared/TextArea'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Frame, Scroll } from 'framer'
import {
  AnnouncementContainer,
  announcementDescription,
  deskGreyBamLogo,
  mobGreyBamLogo,
  SignatureDate,
} from '../Home/modules/CastingModule'
import BlackBAMLogo from '../../assets/logos/black-b-logo.svg'
import TimeAgo from 'react-timeago'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { deskFieldLabelText } from '../../components/Auth/styles'
import firebase from 'firebase/app'

const { mobGigBoxHeader, mobHorizontalRule, mobPageHeader } = mobTextVariants
const { mobTextArea } = mobTextAreaVariants
const { deskPageHeader } = deskTextVariants
const { mobSubmitButton, redButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { column, mobWrapper, row, center } = mobBoxVariants

/**
 *
 * @param currentUserAuth
 * @param match
 * @param user {User}
 * @returns {*}
 * @constructor
 */
export const AnnouncementPage = ({ currentUserAuth, match, user, history }) => {
  const [
    announcements,
    announcementsLoading,
    announcementsError,
  ] = useCollectionData(
    collections.announcements.orderBy('createdAt', 'desc'),
    {
      idField: 'id',
    }
  )

  const [body, setBody] = useState(null)
  const [title, setTitle] = useState(null)

  const handleTextAreaChange = event => {
    setBody(event.target.value)
  }

  useEffect(() => {
    if (
      user.accountType !== AccountType.admin &&
      user.accountType !== AccountType.developer
    ) {
      history.push('/')
    }
  })

  const handleAnnouncementCreation = () => {
    if (title && body) {
      collections.announcements.add({
        body: body,
        title: title,
        hidden: false,
        deleted: false,
        createdAt: firebase.firestore.Timestamp.now(),
      })
    }
  }

  if (announcementsLoading || announcementsError) {
    return null
  }

  if (announcements.length <= 0) {
    return null
  }

  return (
    <Box mobStyles={[column, mobWrapper, center]}>
      <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
        Manage Announcements
      </Text>

      <Box mobStyles={[row]}>
        <Box
          mobStyles={[
            column,
            css`
              width: 50vw;
              padding: 50px;
            `,
          ]}
        >
          <InputWithHeader
            headerStyles={{ deskStyles: deskFieldLabelText }}
            header="TITLE"
            inputName="title"
            type="title"
            onChange={event => {
              setTitle(event.target.value)
            }}
            placeholderDefault="Title"
            boldHeader={true}
            value={title}
          />
          <TextArea
            name="announcement-body"
            mobStyles={mobTextArea}
            onChange={handleTextAreaChange}
            placeholder="Announcement Body"
          />
          <Button
            mobStyles={[
              mobSubmitButton,
              mobLong305x46,
              css`
                width: 100%;
                margin: 20px 0;
              `,
            ]}
            onClick={handleAnnouncementCreation}
          >
            CREATE
          </Button>
        </Box>
        <Box
          mobStyles={[
            column,
            css`
              width: 50vw;
              height: 1000px;
            `,
          ]}
        >
          <Scroll direction="both" height={'100%'} width={'50%'}>
            <Frame background="FFF" height={'100%'} width={'50%'}>
              {announcements.map((casting, index) => {
                if (casting.deleted !== true) {
                  return createAnnouncement(casting, index)
                }
              })}
            </Frame>
          </Scroll>
        </Box>
      </Box>
    </Box>
  )
}

export const hiddenText = css`
  height: auto;
  width: auto;
  color: ${p => p.theme.colors.black};
  font-size: 50px !important;
  margin: 0 auto;
  border: 2px black solid;
  border-radius: 10px;
`

const createAnnouncement = ({ title, body, createdAt, id, hidden }, index) => {
  const buttonMargin = css`
    margin: 20px 0;
  `
  const handleDeletion = docId => {
    collections.announcements.doc(docId).set(
      {
        deleted: true,
        deletedAt: firebase.firestore.Timestamp.now(),
      },
      { merge: true }
    )
  }

  const handleHide = docId => {
    collections.announcements.doc(docId).set(
      {
        hidden: true,
        hiddenAt: firebase.firestore.Timestamp.now(),
      },
      { merge: true }
    )
  }

  const handleRestore = docId => {
    collections.announcements.doc(docId).set(
      {
        hidden: false,
        restoredAt: firebase.firestore.Timestamp.now(),
      },
      { merge: true }
    )
  }

  return (
    <Box
      mobStyles={[
        row,
        css`
          width: 50vw;
          padding: 10px;
        `,
      ]}
    >
      <Box mobStyles={[column]}>
        <AnnouncementContainer key={index}>
          <Box mobStyles={[row]}>
            <Text
              mobStyles={[
                mobGigBoxHeader,
                css`
                  height: auto;
                  font-size: 20px !important;
                `,
              ]}
              deskStyles={[
                mobGigBoxHeader,
                css`
                  font-size: 25px !important;
                `,
              ]}
            >
              {title}
            </Text>
            <Image
              src={BlackBAMLogo}
              mobStyles={[mobGreyBamLogo]}
              deskStyles={deskGreyBamLogo}
            />
          </Box>
          <Text mobStyles={mobHorizontalRule} />
          <Text mobStyles={announcementDescription}>{body}</Text>
          {hidden && <Text mobStyles={hiddenText}>HIDDEN</Text>}
          <Box
            mobStyles={[
              row,
              css`
                justify-content: flex-end;
              `,
            ]}
          >
            <SignatureDate>
              Posted <TimeAgo date={new Date(createdAt.seconds * 1000)} />
            </SignatureDate>
          </Box>
        </AnnouncementContainer>
      </Box>

      <Box
        mobStyles={[
          column,
          css`
            width: 50%;
            height: auto;
            padding-top: 50px;
          `,
        ]}
      >
        {!hidden && (
          <Button
            mobStyles={[
              buttonMargin,
              mobSubmitButton,
              mobLong305x46,
              css`
                width: 100%;
              `,
            ]}
            onClick={() => {
              handleHide(id)
            }}
          >
            HIDE
          </Button>
        )}
        {hidden && (
          <Button
            mobStyles={[
              buttonMargin,
              mobSubmitButton,
              mobLong305x46,
              css`
                width: 100%;
              `,
            ]}
            onClick={() => {
              handleRestore(id)
            }}
          >
            RESTORE
          </Button>
        )}

        <Button
          mobStyles={[
            buttonMargin,
            mobSubmitButton,
            mobLong305x46,
            redButton,
            css`
              width: 100%;
            `,
          ]}
          onClick={() => {
            handleDeletion(id)
          }}
        >
          DELETE
        </Button>
      </Box>
    </Box>
  )
}
