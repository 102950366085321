import { css } from 'styled-components'

export const deskTextAreaVariants = {
  default: css`
    font-size: 12px;
  `,
  bold: css`
    font-weight: bold;
  `,
  deskTextArea: css`
    resize: none;
    width: 100%;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 16px;
    line-height: 22px;
    padding-left: 15px;
    padding-top: 15px;
    height: 180px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    color: ${p => p.theme.colors.darkGray};
    margin: 0 0 15px;
    box-shadow: ${p => p.theme.shadow.regular};
    ::placeholder {
      color: ${p => p.theme.colors.darkGray};
      opacity: 0.5;
    }
  `,
}
