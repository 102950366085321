import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Form,
  Input,
  mobBoxVariants,
  mobButtonVariants,
  mobFormVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import { IconContext } from 'react-icons'
import { FaPlus } from 'react-icons/fa'
import { css, useTheme } from 'styled-components'
import ReactCrop from 'react-image-crop'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { deskFormVariants } from '../../../shared/Form/deskFormVariants'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../../utils/responsiveVars'
import { ImageInputLoader } from '../ImageInputLoader/ImageInputLoader'
import { collections, portfolioImagesStorage } from '../../../firebaseApp'
import firebase from 'firebase/app'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import { uuid } from '../../../utils/uuid'

const { mobPageSubHeader, mobInstructionParagraph } = mobTextVariants
const { mobImageForm } = mobFormVariants
const { deskImageForm } = deskFormVariants
const { center, column, mobBoxSpacer } = mobBoxVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants
const { deskPageSubHeader, deskInstructionParagraph } = deskTextVariants
const acceptColor = css`
  background-color: #70bd74;
  color: #ffffff;
`
const displayFileExplorer = (event, elementId) => {
  let inputElement = document.getElementById(elementId)
  inputElement.click()
}

// Setting a high pixel ratio avoids blurriness in the canvas crop preview.
const pixelRatio = 4

export const PortfolioImageInput = ({ user }) => {
  const inputElementId = 'user-portfolio-image-upload'
  const isDesktop = useMediaQuery(desktopQuery)
  const theme = useTheme()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageCropped, setImageCropped] = useState(false)
  const [crop, setCrop] = useState({ aspect: 1 })
  const [upImg, setUpImg] = useState()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)

  const onSelectFile = e => {
    setImageLoaded(true)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      const url = reader.readAsDataURL(e.target.files[0])
      return url
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const context = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    context.imageSmoothingEnabled = false

    context.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
  }, [completedCrop])

  const handleImageSubmit = async event => {
    setImageUploading(true)
    event.preventDefault()

    // Create a reference to the User's directory in Firebase Storage
    const portfolioStorageRef = portfolioImagesStorage.ref()
    const userPortfolioImageReference = portfolioStorageRef.child(user.userId)
    const croppedImageCanvas = document.getElementById(
      'user-portfolio-image-upload-canvas'
    )
    const imageInput = document.getElementById('user-portfolio-image-upload')
    const file = imageInput.files && imageInput.files[0]
    const uniqueId = uuid()
    const portfolioDocId = uniqueId

    try {
      // Cropped Image Upload
      croppedImageCanvas.toBlob(blob => {
        const uploadTask = userPortfolioImageReference
          .child('/user-portfolio-image-org-' + portfolioDocId)
          .put(blob, {
            customMetadata: {
              userUid: user.userId,
              uniqueId: uniqueId,
            },
          })

        // Tracker for the progress on the upload of the image
        uploadTask.on(
          'state_changed',
          () => {},
          error => {
            errorHandler({
              error,
              errorId: 'ERROR_UPLOADING_PROFILE_IMAGE',
              message: 'Error while user trying to upload profile image.',
              type: errorTypes.profile,
              user: user.userId,
              file: 'ProfileImageInput.jsx',
            })
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(downloadURL => {
                collections.userInformation
                  .doc(user.userId)
                  .collection('portfolioImages')
                  .doc(portfolioDocId)
                  .set(
                    {
                      uniqueId: uniqueId,
                      uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
                      croppedUrl: downloadURL,
                    },
                    { merge: true }
                  )
              })
              .catch(error => {
                errorHandler({
                  error,
                  errorId:
                    'ERROR_SAVING_GETTING_URL_DOWNLOAD_PORTFOLIO_CROPPED',
                  message:
                    'Error while user trying to get image download cropped url.',
                  type: errorTypes.profile,
                  user: user.userId,
                  file: 'PortfolioImageInput.jsx',
                })
              })
          }
        )
      })

      // Original Image Upload
      if (file) {
        const uploadTask = userPortfolioImageReference
          .child('/user-portfolio-image-cropped-' + portfolioDocId)
          .put(file, {
            customMetadata: {
              userUid: user.userId,
            },
          })

        uploadTask.on(
          'state_changed',
          () => {},
          error => {
            errorHandler({
              error,
              errorId: 'ERROR_UPLOADING_PORTFOLIO_IMAGE',
              message: 'Error while user trying to upload portfolio image.',
              type: errorTypes.profile,
              user: user.userId,
              file: 'PortfolioImageInput.jsx',
            })
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(downloadURL => {
                collections.userInformation
                  .doc(user.userId)
                  .collection('portfolioImages')
                  .doc(portfolioDocId)
                  .set(
                    {
                      url: downloadURL,
                    },
                    { merge: true }
                  )
              })
              .catch(error => {
                errorHandler({
                  error,
                  errorId:
                    'ERROR_SAVING_GETTING_URL_DOWNLOAD_PORTFOLIO_ORIGINAL',
                  message:
                    'Error while user trying to get image download org url.',
                  type: errorTypes.profile,
                  user: user.userId,
                  file: 'PortfolioImageInput.jsx',
                })
              })
          }
        )
      }
    } catch (error) {
      errorHandler({
        error,
        errorId: 'ERROR_SAVING_PORTFOLIO_UPLOAD_GENERAL',
        message: 'Error while user trying to upload portfolio image.',
        type: errorTypes.profile,
        user: user.userId,
        file: 'PortfolioImageInput.jsx',
      })
    } finally {
      setUploadSuccess(true)
      setTimeout(() => {
        setUploadSuccess(false)
        setImageUploading(false)
        setImageLoaded(false)
      }, 2000)
    }
  }

  let imageInputComponent

  if (uploadSuccess) {
    imageInputComponent = (
      <Box mobStyles={[mobBoxSpacer, center]}>
        <Button
          mobStyles={[mobSubmitButton, mobLong305x46, acceptColor]}
          deskStyles={deskSubmitButton}
        >
          SEE UPLOADED IMAGE BELOW
        </Button>
      </Box>
    )
  } else if (imageUploading) {
    // If they have a currently uploading Profile Image
    imageInputComponent = <ImageInputLoader />
  } else if (!imageUploading && !imageLoaded) {
    // If the image is not being optimized and an image is not loaded
    imageInputComponent = (
      <Form
        onClick={event => displayFileExplorer(event, inputElementId)}
        deskStyles={[deskImageForm]}
        mobStyles={[mobImageForm]}
      >
        <IconContext.Provider
          value={{
            color: theme.colors.gray,
          }}
        >
          <Box
            mobStyles={[
              css`
                font-size: 75px;
                width: 100%;
              `,
              center,
            ]}
          >
            <FaPlus />
          </Box>
        </IconContext.Provider>
      </Form>
    )
  } else if (imageLoaded && !imageUploading) {
    // If an image is loaded, crop mode is enabled, and profile image is not being processed
    imageInputComponent = (
      <>
        {!imageCropped && (
          <Text
            mobStyles={mobInstructionParagraph}
            deskStyles={[deskInstructionParagraph]}
          >
            Please crop the portion of the image you want displayed in
            thumbnails. The full un-cropped image will still be available when
            you click the image of the site
          </Text>
        )}
        <ReactCrop
          src={upImg}
          crop={crop}
          minHeight={150}
          minWidth={150}
          onImageLoaded={onLoad}
          onDragStart={() => {
            setImageCropped(true)
          }}
          onChange={crop => {
            if (crop.height === 0 && crop.width === 0) {
              setImageCropped(false)
              setCrop(crop)
            } else {
              setCrop(crop)
            }
          }}
          onComplete={crop => {
            if (crop.height === 0 && crop.width === 0) {
              setImageCropped(false)
              setCrop(crop)
            } else {
              setCrop(crop)
            }
            setCompletedCrop(crop)
          }}
          style={{
            borderRadius: '10px',
            width: isDesktop ? '32vw' : '90vw',
            height: isDesktop ? '32vw' : '90vw',
            boxShadow: theme.shadow.regular,
            marginTop: '1%',
            marginBottom: '1%',
          }}
        />
        {imageCropped && (
          <>
            <Box mobStyles={[mobBoxSpacer, center]}>
              <Button
                mobStyles={[mobSubmitButton, mobLong305x46]}
                deskStyles={deskSubmitButton}
                onClick={e => {
                  setImageUploading(true)
                  handleImageSubmit(e)
                }}
              >
                UPLOAD
              </Button>
            </Box>
            <Box
              enableMotion
              mobStyles={[]}
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0.5,
                transition: { duration: 1 },
              }}
            >
              <canvas
                id={'user-portfolio-image-upload-canvas'}
                ref={previewCanvasRef}
                style={{
                  borderRadius: '10px',
                  width: isDesktop ? '32vw' : '90vw',
                  height: isDesktop ? '32vw' : '90vw',
                  boxShadow: theme.shadow.regular,
                }}
              />
            </Box>
          </>
        )}
      </>
    )
  }

  return (
    <Box mobStyles={[center, column]} id={'update-portfolio-images'}>
      <Text mobStyles={[mobPageSubHeader]} deskStyles={[deskPageSubHeader]}>
        Portfolio Images
      </Text>
      <Text
        mobStyles={mobInstructionParagraph}
        deskStyles={[deskInstructionParagraph]}
      >
        Once you select a photo crop it into a square by tapping or dragging the
        cropping box to your desired location on the photo. As you crop you may
        also view the preview of your crop below.
      </Text>
      <Input
        onChange={onSelectFile}
        type="file"
        id={inputElementId}
        name="file"
        hidden
      />
      {imageInputComponent}
    </Box>
  )
}

//      <MessageBox
//         header="Maintenance Notice"
//         message={"Having some slight issues with portfolio photo upload and deletion. Should be fixed soon!"}
//       />
