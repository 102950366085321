import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { isProduction } from '../../firebaseApp/firebase'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// pk_test_8EmlhyM0P2yNd5rivqMRgE4t00WbalSgTu
const stripePromise = loadStripe(
  isProduction
    ? 'pk_live_WXl2rjIHah1ZTEuWobTWkuVl00El9dlqou'
    : 'pk_test_8EmlhyM0P2yNd5rivqMRgE4t00WbalSgTu'
)

// https://stripe.com/docs/stripe-js/react
export const Stripe = props => {
  // For PCI compliance purposes, you must load Stripe.js directly from Stripe’s servers at runtime
  return <Elements stripe={stripePromise}>{props.children}</Elements>
}
