import React from 'react'
import PropTypes from 'prop-types'
import { Box, mobBoxVariants } from '../../shared/index'
import { css } from 'styled-components'

const { column } = mobBoxVariants

const mobStickyFooterContentWrapper = css`
  width: 100vw;
  height: 100px;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: ${p => p.theme.colors.secondary};
  box-shadow: ${p => p.theme.shadow.regular};
  padding: 35px 20px 45px;
  box-sizing: border-box;
  z-index: 100;
`

export const StickyHeader = ({ children }) => {
  return (
    <>
      <Box
        mobStyles={[
          css`
            display: block;
            height: 100px;
            width: 100vw;
            z-index: 100;
          `,
        ]}
      />
      <Box mobStyles={[column, mobStickyFooterContentWrapper]}>{children}</Box>
    </>
  )
}

StickyHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}
