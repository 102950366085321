import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { AccountType, Gender } from '../../constants/enums'
import React, { useState } from 'react'
import 'firebase/storage'
import {
  inputVariants,
  mobTextVariants,
  Option,
  Select,
  Text,
} from '../../shared'
import { TextAreaWithHeader } from '../../components/Inputs/TextAreaWithHeader'

const { mobSelectInput } = inputVariants
const { mobInputHeader } = mobTextVariants

/**
 *
 * @param {User} user
 * @param profileForm
 * @param userAccountType
 * @returns {null|*}
 * @constructor
 */
export const ModelUpdateProfileModule = ({ user, profileForm }) => {
  if (user.accountType !== AccountType.model) {
    return null
  }

  const [description, setDescription] = useState(
    profileForm.current.description
  )

  return (
    <>
      <InputWithHeader
        header="NAME"
        inputName="fullName"
        onChange={profileForm.current.updateName}
        placeholder={user.fullName}
        placeholderDefault="Enter Full Name"
      />
      <InputWithHeader
        header="HEIGHT (INCHES)"
        inputName="height"
        type="number"
        onChange={profileForm.current.updateHeight}
        placeholder={user.height}
        placeholderDefault={`Example: 68`}
      />
      <InputWithHeader
        header="WEIGHT (LBS)"
        inputName="weight"
        type="number"
        onChange={profileForm.current.updateWeight}
        placeholder={user.weight}
        placeholderDefault="Example: 100"
      />
      <Text mobStyles={mobInputHeader}>GENDER</Text>
      <Select
        mobStyles={mobSelectInput}
        name="gender"
        defaultValue={user.gender}
        onChange={profileForm.current.updateGender}
        value={profileForm.current.gender}
      >
        <Option value={Gender.none} disabled>
          Not Set
        </Option>
        <Option value={Gender.female}>Female</Option>
        <Option value={Gender.male}>Male</Option>
        <Option value={Gender.other}>Rather not say</Option>
      </Select>
      <InputWithHeader
        header="RACE"
        inputName="race"
        onChange={profileForm.current.updateRace}
        placeholder={user.race}
        placeholderDefault="Example: Black"
      />
      <InputWithHeader
        header="ZIP CODE"
        inputName="zipCode"
        type="text"
        pattern="\d*"
        maxLength="5"
        onChange={profileForm.current.updateZip}
        placeholder={user.zipCode}
        placeholderDefault="Example: 10001"
      />
      <InputWithHeader
        header="HOURLY RATE ($)"
        inputName="hourlyRate"
        type="number"
        onChange={profileForm.current.updateRate}
        placeholder={`$${user.getUserHourlyRate()}`}
        placeholderDefault="100"
      />
      <InputWithHeader
        header="WEBSITE"
        inputName="website"
        onChange={profileForm.current.updateWebsiteUrl}
        placeholder={user.websiteUrl}
        placeholderDefault="Example: bookamodel.com"
      />
      <TextAreaWithHeader
        header="BIO"
        inputName="description"
        onChange={event => {
          setDescription(event.target.value)
          profileForm.current.updateDescription(event)
        }}
        value={description}
        placeholderDefault="Tell everyone a bit about yourself."
        inputTestId="model-description"
      />
    </>
  )
}

// <Select
//   mobStyles={mobSelectInput}
//   name="race"
//   defaultValue={user.race}
//   onChange={profileForm.current.updateRace}
//   value={profileForm.current.race}
// >
//   <Option value={Race.none} disabled>
//     Not Set
//   </Option>
//   <Option value={Race.black}>Black</Option>
//   <Option value={Race.white}>White</Option>
//   <Option value={Race.pnts}>Prefer not to say</Option>
// </Select>
