import React from 'react'

import { StyledMotionTD, StyledTD } from './styles'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

export const TD = ({ children, ...props }) => {
  if (props.enableMotion) {
    return <StyledMotionTD {...props}>{children}</StyledMotionTD>
  }

  return <StyledTD {...props}>{children}</StyledTD>
}

TD.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
