import firebase from 'firebase/app'
import { AccountType, Gender } from '../../constants/enums'
import get from 'lodash/get'
import keys from 'lodash/keys'
import PropTypes from 'prop-types'

import { Instagram } from './Instagram'
import ProfileImagePlaceholder from '../../assets/photos/profile_image_placeholder.png'

/**
 * User object constructed from the user information Snapshot
 *
 */
export class User {
  user
  userData

  accountType
  accountTypeLastUpdated

  portfolioImageUrls

  availableDates

  // Universal details
  profileDetails
  fullName
  zipCode
  rating
  instagram
  userId
  visibleInSearch
  description
  city

  // Model details
  gender
  height
  weight
  hourlyRate
  race

  // photographers fields
  photoSpecialty
  equipment

  // makeup artist field
  makeupSpecialty

  // Agency details

  // Manged Account
  managedBy
  managedByAgencyName

  croppedProfileImage
  originalProfileImage

  profileImage
  defaultImage

  identityVerified

  ratings

  constructor(
    user,
    userJSON,
    // If a user is created without either user OR userJSON
    // the values below will be used. Think of them as a blank
    // user. "Not Set" and 0s for everything
    userId = '',
    portfolioImageUrls = {
      ImagePlaceholder: firebase.firestore.Timestamp.now(),
    },
    originalProfileImage = ProfileImagePlaceholder,
    fullName = 'Book A. Model',
    gender = Gender.notAvailable,
    height = 0,
    weight = 0,
    zipCode = 10001,
    hourlyRate = 0,
    race = 'Not Set',
    rating = 0,
    availableDates = [],
    visibleInSearch = false,
    accountType = 0,
    // Agency Details
    agencyType = '',
    industry = '',
    representative = '',
    yearFounded = 2020,
    websiteUrl = 'bookamodel.com',
    description = null,
    managedBy = null,
    managedByAgencyName = null,
    photoSpecialty = '',
    equipment = '',
    city = 'Unknown'
  ) {
    if (user) {
      this.user = user
      this.userId = user.id
      this.userData = this.user && this.user.data ? this.user.data() : this.user
      this.profileDetails = get(this.userData, 'profileDetails', {})
    }

    if (userJSON) {
      this.userData = userJSON
      this.userId = get(this.userData, 'objectID', '')
      this.profileDetails = get(this.userData, 'profileDetails', {})
    }

    this.portfolioImageUrls =
      get(this.userData, 'portfolioImages', portfolioImageUrls) ||
      portfolioImageUrls

    // This is the profile image object that has info about the users profile image
    // such as the original url or if it is the default image
    this.profileImage = get(this.userData, 'profileImage', {})
    this.defaultImage = get(this.profileImage, 'defaultImage', true)

    //   croppedProfileImage
    //   originalProfileImage
    // If the cropped image isn't there use the regular photo
    this.originalProfileImage = get(
      this.profileImage,
      'url',
      ProfileImagePlaceholder
    )
    this.croppedProfileImage = get(
      this.profileImage,
      'croppedUrl',
      this.originalProfileImage
    )

    this.agencyType =
      get(this.profileDetails, 'agencyType', agencyType) || agencyType
    this.industry = get(this.profileDetails, 'industry', industry) || industry
    this.representative =
      get(this.profileDetails, 'representative', representative) ||
      representative
    this.yearFounded =
      get(this.profileDetails, 'yearFounded', yearFounded) || yearFounded
    this.websiteUrl =
      get(this.profileDetails, 'websiteUrl', websiteUrl) || websiteUrl
    this.description =
      get(this.profileDetails, 'description', description) || description

    // Double redundant setting of profile details
    // Takes into account values being absent or falsy
    this.fullName = get(this.profileDetails, 'fullName', fullName) || fullName
    this.gender = get(this.profileDetails, 'gender', gender) || gender
    this.height = get(this.profileDetails, 'height', height) || height
    this.weight = get(this.profileDetails, 'weight', weight) || weight
    this.zipCode = get(this.profileDetails, 'zipCode', zipCode)
    this.city = get(this.profileDetails, 'city', city)
    this.hourlyRate =
      get(this.profileDetails, 'hourlyRate', hourlyRate) || hourlyRate
    this.race = get(this.profileDetails, 'race', race)
    this.rating = rating //get(this.userData, 'rating', rating) || rating
    this.availableDates =
      get(this.userData, 'availableDates', []) || availableDates

    this.instagram = new Instagram(get(this.userData, 'instagram', {}) || {})
    this.visibleInSearch = get(this.userData, 'visibleInSearch', false)
    this.acceptingBookingRequests = get(
      this.userData,
      'acceptingBookingRequests',
      false
    )
    this.portfolioImageProcessing = get(
      this.userData,
      'portfolioImageProcessing',
      false
    )
    this.accountType = get(this.userData, 'accountType', accountType)

    this.managedBy = get(this.userData, 'managedBy', managedBy)
    this.managedByAgencyName = get(
      this.userData,
      'managedByAgencyName',
      managedByAgencyName
    )
    this.accountTypeLastUpdated = get(
      this.userData,
      'accountTypeLastUpdated',
      null
    )
    this.photoSpecialty = get(this.userData, 'photoSpecialty', photoSpecialty)
    this.equipment = get(this.userData, 'equipment', equipment)

    this.onboarding = get(this.userData, 'onboarding', {})
    this.onboardingProfile = get(this.onboarding, 'profile', true)
    this.onboardingSettings = get(this.onboarding, 'settings', false)
    this.onboardingUpdate = get(this.onboarding, 'update', true)

    this.identityVerified = get(this.userData, 'identityVerified', false)
    this.moneyServices = get(this.userData, 'moneyServices', {})
    this.boothNumber_bb = get(this.userData, 'boothNumber_bb', "Not Set")
    this.admin = get(this.userData, 'admin', false)
    this.modelTypes = get(this.userData, 'modelTypes', {
        hair: false,
        makeup: false,
        nails: false,
        skin: false}
      )
  }

  getUserCity = () => {
    if (this.city !== 'Unknown' && this.city !== null) {
      return this.city
    } else {
      return `Unknown`
    }
  }

  getProfileImage = () => {
    if (
      this.instagram.useInstagramProfilePhoto &&
      this.instagram.hasInstagramProfilePhoto
    ) {
      return this.instagram.instagramProfilePicUrl
    } else if (this.defaultImage) {
      return ProfileImagePlaceholder
    } else {
      return this.croppedProfileImage
    }
  }

  getProfileImageThumb = () => {
    if (
      this.instagram.useInstagramProfilePhoto &&
      this.instagram.hasInstagramProfilePhoto
    ) {
      return this.instagram.instagramProfilePicUrl
    } else {
      return this.croppedProfileImage
    }
  }

  getBAMProfileImage = () => {
    return this.croppedProfileImage
  }

  getPortfolioImages = () => {
    return keys(this.portfolioImageUrls)
  }

  getGender = () => {
    switch (Number(this.gender)) {
      case Gender.notAvailable:
        return 'Not Set'
      case Gender.female:
        return 'F'
      case Gender.male:
        return 'M'
      default:
        return 'N/A'
    }
  }

  getUserHourlyRate = () => {
    return this.hourlyRate / 100
  }
  
  getStripeUserHourlyRate = () => {
    // Hourly Rate stored as pennies
    return this.hourlyRate
  }
  
  getUserData = () => {
    return this.userData
  }

  getUserDoc = () => {
    return this.user
  }

  isVisbleInSearch = () => this.visibleInSearch
  isAcceptingBookingRequests = () => this.acceptingBookingRequests

  hasNoAccountType = () => this.accountType === AccountType.none
  isAgency = () => this.accountType === AccountType.agency
  isCreative = () =>
    this.accountType === AccountType.model ||
    this.accountType === AccountType.photographer ||
    this.accountType === AccountType.makeupArtist ||
    this.accountType === AccountType.influencer ||
    this.accountType === AccountType.admin ||
    this.accountType === AccountType.developer

  isModel = () => this.accountType === AccountType.model
  isMakeupArtist = () => this.accountType === AccountType.makeupArtist
  isPhotographer = () => this.accountType === AccountType.photographer
  isInfluencer = () => this.accountType === AccountType.influencer
  isAdmin = () => this.accountType === AccountType.admin

  // Prevent users from switching their accounts back and forth
  updatedAccountInLastWeek = () => {
    if (!this.accountTypeLastUpdated) {
      return false
    }
    const oneWeekInSeconds = 604800
    const nowSeconds = firebase.firestore.Timestamp.now().seconds
    const lastUpdatedSeconds = this.accountTypeLastUpdated.seconds
    return !(nowSeconds - lastUpdatedSeconds > oneWeekInSeconds)
  }

  viewingOwnProfile = userId => {
    return userId === this.userId
  }
}

export const userPropTypes = {
  getUserCity: PropTypes.func,
  getProfileImage: PropTypes.func,
  getBAMProfileImage: PropTypes.func,
  getPortfolioImages: PropTypes.func,
  getGender: PropTypes.func,
  getUserHourlyRate: PropTypes.func,
  getUserData: PropTypes.func,
  getUserDoc: PropTypes.func,
  userId: PropTypes.string,
  portfolioImageUrls: PropTypes.object,
  profileImageUrl: PropTypes.string,
  fullName: PropTypes.string,
  gender: PropTypes.number,
  height: PropTypes.number,
  weight: PropTypes.number,
  zipCode: PropTypes.number,
  hourlyRate: PropTypes.number,
  race: PropTypes.string,
  rating: PropTypes.number,
  availableDates: PropTypes.array,
}
