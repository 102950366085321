import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, collections } from '../../firebaseApp'
import { ChatRoom } from '../../classes/Models/ChatRoom'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { Box, mobBoxVariants } from '../../shared/Box'
import { LoadingScreen } from '../../components/LoadingScreen'
import { Message } from './Message'
import React, { useEffect } from 'react'
import { css } from 'styled-components'
import firebase from 'firebase/app'

const { mobWrapper, column, mobMessagesContainer } = mobBoxVariants

/**
 *
 * @param chatRoomSnapshot
 * @param lastMessageHeight
 * @returns {*}
 * @constructor
 */
export const
  
  MessageContainer = ({ chatRoomSnapshot, lastMessageHeight }) => {
  const [currentUserAuth] = useAuthState(auth)
  const chatRoom = new ChatRoom(chatRoomSnapshot)
  const [
    chatRoomMessagesSnapshot,
    chatRoomMessagesSnapshotLoading,
    chatRoomMessagesSnapshotError,
  ] = useCollection(
    collections.chatRooms
      .doc(chatRoomSnapshot.id)
      .collection('messages')
      .orderBy('createdAt', 'asc')
  )
  const [
    currentUserSnapshot,
    currentUserSnapshotLoading,
    currentUserSnapshotError,
  ] = useDocument(collections.userInformation.doc(currentUserAuth.uid))
  const userBId = chatRoom.getRecipient(currentUserAuth.uid)
  const [userBSnapshot, userBSnapshotLoading, userBSnapshotError] = useDocument(
    collections.userInformation.doc(userBId)
  )

  useEffect(() => {
    let messageContainerBottom = document.getElementById(
      'message-container-bottom'
    )

    if (messageContainerBottom) {
      messageContainerBottom.scrollTop =
        messageContainerBottom.scrollHeight -
        messageContainerBottom.clientHeight

      messageContainerBottom.scrollIntoView()
    }
  })

  const dependenciesLoading =
    chatRoomMessagesSnapshotLoading ||
    currentUserSnapshotLoading ||
    userBSnapshotLoading
  const dependenciesError =
    chatRoomMessagesSnapshotError ||
    currentUserSnapshotError ||
    userBSnapshotError
  const dependenciesReady =
    chatRoomMessagesSnapshot && currentUserSnapshot && userBSnapshot

  const updateSeen = messageDoc => {
    const messageData = messageDoc.data()
    if (currentUserAuth.uid === messageData.from || messageData.seen === true) {
      return
    }

    const { chatRooms } = collections

    const currentMessage = chatRooms
      .doc(chatRoomSnapshot.id)
      .collection('messages')
      .doc(messageDoc.id)

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(currentMessage)
        .then(messageDocument => {
          if (messageDocument.data().seen === false) {
            transaction.update(currentMessage, {
              seen: true,
            })
          }
        })
        .then(result => {
          return
        })
        .catch(error => {
          // TODO finish this
          // errorHandler({
          //   error,
          //   errorId: 'ERROR_UPDATE_VISIBLE_IN_SEARCH_OPTION',
          //   message: 'Error while updating visible in search option.',
          //   type: errorTypes.profile,
          //   user: user && user.uid,
          //   file: 'GeneralSettingsModule.jsx',
          // })
        })
    })
  }

  return (
    <>
      <Box
        mobStyles={[column, mobWrapper, mobMessagesContainer]}
        class={'chat-messages-box'}
        id={'message-container'}
      >
        {dependenciesError && (
          <strong>Error: {chatRoomMessagesSnapshotError}</strong>
        )}
        {dependenciesLoading && <LoadingScreen />}
        {dependenciesReady && (
          <>
            {chatRoomMessagesSnapshot.docs.map((messageDocument, index) => {
              let lastMessage =
                index === chatRoomMessagesSnapshot.docs.length - 1

              updateSeen(messageDocument)

              return (
                <Message
                  messageSnapshot={messageDocument}
                  currentUserId={currentUserAuth.uid}
                  currentUserSnapshot={currentUserSnapshot}
                  userBSnapshot={userBSnapshot}
                  lastMessage={lastMessage}
                  lastMessageHeight={lastMessageHeight}
                />
              )
            })}
            <Box
              id={'message-container-bottom'}
              mobStyles={[
                css`
                  width: 100%;
                  min-height: ${lastMessageHeight}px;
                  overflow-y: auto;
                `,
              ]}
            />
          </>
        )}
      </Box>
    </>
  )
}
