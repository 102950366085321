import { collections, functions } from '../firebaseApp'
import hashSum from 'hash-sum'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import firebase from 'firebase/app'

export class ChatRoomError extends Error {}

export const createChatRoom = async memberIds => {
  const existingChatForMembers = functions.httpsCallable(
    'existingChatForMembers'
  )
  const existingChat = await existingChatForMembers({
    memberIds: memberIds,
  })

  // Chatroom keys are a hash of the 2 members in the room
  // Sort them to make sure they are always in the same order
  const ids = memberIds.sort()
  const chatRoomHashKey = hashSum(ids[0] + ids[1])

  if (!existingChat.data.existingChatroomEmpty) {
    // If there is an existing chat for these 2 then return.
    return Promise.resolve()
  }

  // Make sure all user IDs are not empty and are strings
  memberIds.forEach(memberId => {
    if (isEmpty(memberId) || !isString(memberId)) {
      throw new ChatRoomError(
        'Users must be a string and not empty ,your Object keys must be  {userId, username if any, photo if any} '
      )
    }
  })
  // Generate a doc for the new ChatRoom
  const newChatRoomReference = collections.chatRooms.doc()

  // Get firestore TimeStamp to use as ChatRoom creation date
  const newChatRoomCreatedAt = firebase.firestore.Timestamp.now()

  // Set new ChatRoom settings
  await newChatRoomReference
    .set({
      chatRoomHashKey: chatRoomHashKey,
      members: firebase.firestore.FieldValue.arrayUnion(...memberIds),
      createdAt: newChatRoomCreatedAt,
      isRemoved: false,
      isOpen: true,
    })
    .then(() => {})
}
