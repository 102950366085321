import { css } from 'styled-components'

export const selectVariants = {
  darkGreyBackground: css`
    // this is just an example
    background-color: ${p => p.theme.colors.darkGray};
  `,
  authInputMobile: css`
    height: 48px;
    width: 309px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
  `,
  mobSearchSelectInput: css`
    width: 100%;
    margin: 0;
    font-family: ${p => p.theme.fonts.primary};
    font-size: 16px;
    line-height: 22px;
    height: 50px;
    z-index: 2;
    text-indent: 15px;
    color: ${p => p.theme.colors.darkGray};
    background-color: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ::placeholder {
      color: ${p => p.theme.fonts.darkGray};
    }
  `,
}
