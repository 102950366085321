// PROD-READY-MOBILE
import React, { useEffect, useState } from 'react'
import { useDocument, useDocumentData } from 'react-firebase-hooks/firestore'
import styled, { css } from 'styled-components'

import { ChatRoom } from '../../classes/Models/ChatRoom'
import { collections } from '../../firebaseApp'
import { Box } from '../../shared/Box'
import { MoneyRequestItemDetails } from './MoneyRequestItemDetails'
import { LoadingScreen } from '../../components/LoadingScreen'
import { useSetRecoilState } from 'recoil'
import { chatThreadState } from '../../store'
import { activeRequestState } from '../../store/states/activeRequestState'

const deskChatItemBox = css`
  height: 83px;
  width: 100%;
  padding: 10px 0 10px 10px;
  align-items: center;
  box-sizing: border-box;
  margin: 10px 0 10px 0;
  padding-right: 15px;
`

/**
 * This component represents a conversation in the user's inbox
 * contains the recipients photo, last message sent and last time
 * updated
 *
 * @param chatRoom{String}
 * @returns {*}
 * @constructor
 */
export const DesktopMoneyRequestItem = ({ activeRequest }) => {
  // const [
  //   requestSnapshot,
  //   requestSnapshotLoading,
  //   requestSnapshotError,
  // ] = useDocumentData(collections.activeRequests.doc(moneyRequest.requestId),
  //   {
  //     idField: 'id',
  //   })
  const setActiveRequest = useSetRecoilState(activeRequestState)

  return (
    <>
      <Box
        deskStyles={[deskChatItemBox]}
        onClick={() => {
          setActiveRequest(activeRequest)
        }}
      >
        <MoneyRequestItemDetails requestSnapshot={activeRequest} />
      </Box>
      <HorizontalDivider />
    </>
  )
}

const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 2px solid #979797;
  opacity: 0.32;
`
