import { css } from 'styled-components'

export const mobTextVariants = {
  default: css`
    font-size: 12px;
  `,
  bold: css`
    font-weight: bold;
  `,
  fullWidth: css`
    width: 100%;
  `,
  mobPageHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 35px;
    font-weight: bold;
    line-height: 35px;
    margin: 0 0 10px;
  `,
  mobPageSubHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 25px;
    font-weight: bold;
    margin: 25px auto 10px 0;
  `,
  mobPageSectionHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    margin: 5px 0 20px;
    letter-spacing: 0.4px;
  `,
  mobInstructionParagraph: css`
    height: auto;
    width: 100%;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0;
    text-align: left;
  `,

  mobInstructionHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 18px;
    line-height: 20px;
    margin: 0;
  `,
  //   overflow: scroll;
  //     margin-bottom: 35px;
  mobInputHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 15px 0 7.5px;
    opacity: 0.71;
    font-size: 16px;
    line-height: 30px;
  `,
  mobInputHeaderBold: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 15px 0 7.5px;
    opacity: 0.71;
    font-size: 16px;
    line-height: 19px;
    font-weight: 900 !important;
  `,

  // GigBox Box Text
  mobGigBoxHeader: css`
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: normal;
    height: auto;
    width: 90%;
    text-align: left;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 25px;
    font-weight: bold;
    line-height: 27px;
    margin: 0;
  `,

  mobGigBoxLocation: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    font-style: italic;
    line-height: 20px;
    margin: 2% 0 5%;
  `,
  mobGigDate: css`
    height: 20px;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    margin: 2.5% 0;
  `,
  mobHorizontalRule: css`
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 1px solid ${p => p.theme.colors.gray};
    opacity: 0.3;
    margin: 10px auto;
  `,

  // fullLink GigBox Box Text
  mobGigBoxDescription: css`
    height: 160px;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 3% 0 1%;
    overflow: scroll;
    word-break: break-word;
  `,

  // miniLink GigBox Box Text
  mobGigBoxDescriptionMini: css`
    height: 100px;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 14px;
    line-height: 20px;
    margin: 3% 0 1%;
    overflow: scroll;
  `,

  // miniLink User Profile Text
  mobUserProfileNameMini: css`
    height: auto;
    width: 100%;
    line-height: 16px;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin: 13px 0 0;
  `,

  mobUserProfileLocationMini: css`
    margin: 0;
    height: auto;
    width: 100%;
    line-height: 20px;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 13px;
    text-align: center;
  `,

  mobTestText: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 25px;
    font-weight: bold;
    line-height: 25px;
    margin: 0 0 10px;
    word-break: break-all;
  `,
  mobGigPaymentDuration: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 3% 0 1%;
    overflow: scroll;
  `,

  // Grand InvoiceRow Text
  mobInvoiceRowGrand: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-weight: bold;
    text-align: left;
    margin: 5px 0 0;
    font-size: 18px;
    line-height: 20px;
  `,
  // Message Box Header
  mobMessageBoxHeader: css`
    margin: 0;
    width: auto;
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    height: auto;
    font-size: 20px;
    line-height: 29px;
  `,
  mobMessageBoxMessage: css`
    width: auto;
    margin: 0 0 10px;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 15px;
    line-height: 20px;
  `,

  // Status Box Text
  mobMessageBoxText: css`
    margin: 0;
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.green};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  `,

  // Notification text
  mobNotificationText: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    width: 90%;
  `,

  mobFaqHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  `,
  mobSignUpText: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 30px !important;
    text-align: center;
  `,

  // Centered Profile Text
  mobCenteredProfileName: css`
    height: auto;
    width: 60%;
    line-height: 35px;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    margin: 13px auto 0;
    overflow: ellipsis;
  `,

  mobCenteredProfileLocation: css`
    height: 13px;
    width: 100%;
    line-height: 15px;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 7px 0 0;
    font-size: 15px;
    text-align: center;
  `,

  // Left Profile Text
  mobLeftProfileName: css`
    height: auto;
    width: auto;
    line-height: 22px;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    margin: 5px 0 0;
  `,

  mobLeftProfileLocation: css`
    height: 13px;
    width: auto;
    line-height: 15px;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 7px 0 0;
    font-size: 15px;
    text-align: left;
  `,

  mobSettingsText: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
  `,

  mobRequestBoxHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 10px;
  `,

  mobButtonGroupHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 10px;
    text-align: center;
  `,

  mobSectionHeader: css`
    height: 29px;
    width: 100%;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 24px;
    color: ${p => p.theme.fonts.primary};
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 29px;
  `,
}
