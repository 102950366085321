import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, mobTextVariants, Text } from '../../shared'
import { collections } from '../../firebaseApp'
import { mobBoxVariants } from '../../shared/index'
import { Request } from '../../classes'
import { LoadingScreen } from '../../components/LoadingScreen'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { MessageBox } from '../../components/MessageBox'
import { RequestBox } from '../../components/RequestBox'
import { uuid } from '../../utils/uuid'
import { RequestType } from '../../constants/enums'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

export const ViewCreatedProposalsPage = ({ currentUserAuth }) => {
  const [
    userCreatedProposals,
    userCreatedProposalsLoading,
    userCreatedProposalsError,
  ] = useCollectionData(
    collections.activeRequests
      .where('requestCreator', '==', currentUserAuth.uid)
      .where('requestType', '==', RequestType.proposal),
    { idField: 'id' }
  )

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          Created Proposals
        </Text>
        <Text
          mobStyles={mobInstructionParagraph}
          deskStyles={deskInstructionParagraph}
        >
          These are proposals you have made for active gigs.
        </Text>
        {userCreatedProposalsError && (
          <strong>Error: {userCreatedProposalsError}</strong>
        )}
        {userCreatedProposalsLoading && <LoadingScreen />}
        {userCreatedProposals && (
          <>
            {userCreatedProposals.length <= 0 && (
              <MessageBox
                header="No Created Proposals"
                message="Proposals you create will appear here."
              />
            )}
            {userCreatedProposals.map(receivedOfferData => {
              return (
                <RequestBox
                  key={uuid()}
                  request={new Request(receivedOfferData)}
                  currentUserAuth={currentUserAuth}
                />
              )
            })}
          </>
        )}
      </Box>
    </DesktopPageWrapper>
  )
}
