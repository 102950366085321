import React from 'react'
import { css } from 'styled-components'

import { mobBoxVariants } from '../../shared/index'
import { Box } from '../../shared/Box'
import { Text } from '../../shared/Text'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { RequestStatus, RequestType } from '../../constants/enums'
import { LoadingScreen } from '../../components/LoadingScreen'

const { column, row } = mobBoxVariants

/**
 *
 * @param {User} user
 * @returns {*}
 * @constructor
 */
export const ManagedUserKeyInfoModule = ({ user }) => {
  const [
    currentUserRequests,
    currentUserRequestsLoading,
    currentUserRequestsError,
  ] = useCollectionData(
    collections.activeRequests
      .where('operative', '==', user.userId)
      .where('status', 'in', [RequestStatus.accepted, RequestStatus.pending]),
    {
      idField: 'id',
    }
  )

  const getProposals = () => {
    const proposals = currentUserRequests.filter(request => {
      return request.requestType === RequestType.proposal
    })

    return proposals.length
  }

  const getOffers = () => {
    const offers = currentUserRequests.filter(request => {
      return request.requestType === RequestType.offer
    })

    return offers.length
  }

  return (
    <>
      {currentUserRequestsError && (
        <strong>Error: {currentUserRequestsError}</strong>
      )}
      {currentUserRequestsLoading && <LoadingScreen />}
      {currentUserRequests && (
        <Box mobStyles={[row, mobKeyInfoModule]}>
          <Box mobStyles={[column, mobInfoCol]}>
            <Text mobStyles={[mobFieldValue]}>{getProposals()}</Text>
            <Text mobStyles={[mobFieldName]}>Proposals</Text>
          </Box>
          <Box mobStyles={[column, mobInfoCol]}>
            <Text mobStyles={[mobFieldValue]}>{getOffers()}</Text>
            <Text mobStyles={[mobFieldName]}>Offers</Text>
          </Box>
          <Box mobStyles={[column, mobInfoCol]}>
            <Text mobStyles={[mobFieldValue]}>0</Text>
            <Text mobStyles={[mobFieldName]}>Notifications</Text>
          </Box>
        </Box>
      )}
    </>
  )
}

const mobKeyInfoModule = css`
  flex: 1 1 auto;
  justify-content: flex-end;
  box-shadow: ${p => p.theme.shadow.regular};
  width: 100%;
  height: 50%;
  margin-bottom: 20px;
  border: 1px solid ${p => p.theme.colors.lightGray};
  border-radius: 5px;
`

const mobInfoCol = css`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 0;
  text-align: center;
`

const mobFieldName = css`
  font-family: Montserrat, serif;
  font-weight: bold;
  text-align: center;
  letter-spacing: 2.4px;
  font-size: 11px;
  color: ${p => p.theme.colors.black};
  width: 100%;
  margin-top: 0;
`

const mobFieldValue = css`
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 35px;
  font-weight: bold;
  margin: 0;
`
