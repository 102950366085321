import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import { variantsAndStyles } from '../../utils/variantsAndStyles'

export const ModalBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  top: 0;
  z-index: 10001;
  box-sizing: border-box;
  left: 0;
  ${variantsAndStyles()};
`

export const ModalPositioner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-sizing: border-box;
`

export const ModalContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.secondary};
  box-sizing: border-box;
  box-shadow: ${p => p.theme.shadow.regular};
  border-radius: 10px;
  position: relative;
  justify-content: center;
  align-items: center;

  ${up('tablet')} {
    height: 400px;
    width: 600px;
  }

  ${up('desktop')} {
    height: 90vh;
    width: 90vw;
  }
  ${variantsAndStyles()}
`
export const buttonPosition = css`
  position: absolute;
  right: 35px;
  top: 35px;
  box-sizing: border-box;
`
