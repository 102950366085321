import styled from 'styled-components'
import { getFontSize, variantsAndStyles } from '../../utils/variantsAndStyles'

export const StyledTextArea = styled.textarea`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  border: none;
  -webkit-appearance: none;
  box-sizing: border-box;

  ${getFontSize}
  ${variantsAndStyles()}
`
