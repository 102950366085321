import React from 'react'
import { Box, Button, Link, mobBoxVariants, mobButtonVariants, mobTextVariants, Text } from '../../shared/index'
import { Gig, Request, User } from '../../classes'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { ProfileBox, ProfileBoxType } from '../ProfileBox'
import { RequestStatus } from '../../constants/enums'
import { css } from 'styled-components'
import Loader from 'react-loader-spinner'
import { useHistory } from 'react-router-dom'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { mobInformationalBox } = mobBoxVariants
const {
  mobPageHeader,
  mobGigBoxLocation,
  mobGigDate,
  mobGigBoxDescription,
  mobRequestBoxHeader,
} = mobTextVariants
const { mobButton } = mobButtonVariants

const {
  mobStatusButton,
  canceledButton,
  completedButton,
  acceptButton,
  ongoingButton,
  pendingButton,
  declineButton,
  disputeButton,
  fullOpacity,
} = mobButtonVariants
const { deskButtonGroupHeader } = deskTextVariants

/**
 *
 * @param {Request} request
 * @param currentUserAuth
 * @returns {*}
 * @constructor
 */
export const RequestBox = ({ request, currentUserAuth }) => {
  let userToDisplayId
  const history = useHistory()
  
  if (request.isOffer()) {
    // If this is an offer:
    // 1. If the creator of the request (Model) is looking at it display the Agency's Profile
    // 2. If the received of the request (Agency) is looking at it display the Model's Profile
    userToDisplayId = request.isRequestCreator(currentUserAuth.uid)
      ? request.operative
      : request.requestCreator
  } else if (request.isProposal()) {
    // If this is an proposal:
    // 1. If the creator of the request (Agency) is looking at it display the Model's Profile
    // 2. If the received of the request (Model) is looking at it display the Agency's Profile
    userToDisplayId = request.isRequestCreator(currentUserAuth.uid)
      ? request.eventCreator
      : request.requestCreator
  } else {
    throw 'Oh no'
  }
  
  const [
    userSnapshot,
    userSnapshotLoading,
    userSnapshotError,
  ] = useDocumentData(collections.userInformation.doc(userToDisplayId), {
    idField: 'id',
  })
  
  const [gigSnapshot, gigSnapshotLoading, gigSnapshotError] = useDocumentData(
    collections.gigs.doc(request.eventId),
    {
      idField: 'id',
    },
  )
  
  const requestBaseLink = request.isProposal() ? '/proposal/' : '/offer/'
  
  const dependencies = userSnapshot && gigSnapshot
  const dependenciesLoading = userSnapshotLoading || gigSnapshotLoading
  const dependenciesError = userSnapshotError || gigSnapshotError
  
  return (
    <>
      {dependenciesError && <strong>Error: {dependenciesError}</strong>}
      {dependenciesLoading && <OfferBoxLoader/>}
      {dependencies && (
        <Link
          to={requestBaseLink + request.requestId}
          mobStyles={mobInformationalBox}
        >
          <ProfileBox
            user={new User(userSnapshot)}
            boxType={ProfileBoxType.MiniHorizontal}
          />
          <Text mobStyles={mobRequestBoxHeader}>{gigSnapshot.title}</Text>
          <Text mobStyles={mobGigDate}>
            {new Gig(gigSnapshot).getDateString()}
          </Text>
          <Text mobStyles={mobGigBoxLocation}>{gigSnapshot.address}</Text>
          <Text
            mobStyles={[
              mobGigBoxDescription,
              css`
                height: 60px;
                line-height: 15px;
              `,
            ]}
          >
            {gigSnapshot.description}
          </Text>
          {getMoneyToDisplay(request, currentUserAuth)}
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Status
          </Text>
          {getStatusButton(request.status)}
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Available Actions
          </Text>
          <Box
            mobStyles={[
              css`
                margin: 20px;
              `,
            ]}
          >
            <Button
              mobStyles={[
                fullOpacity,
                mobStatusButton,
                completedButton,
                css`
                  width: 100%;
                `,
              ]}
              onClick={() => {
                history.push(requestBaseLink + request.requestId)
              }}
            >
              OPEN REQUEST
            </Button>
          </Box>
        </Link>
      )}
    </>
  )
}

const getMoneyToDisplay = (request, currentUserAuth) => {
  const isOwner = request.isOwner(currentUserAuth.uid)
  let money
  if (request.isOffer()) {
    if (isOwner) {
      // Agency
      money = request.getAgencyGrandTotal()
    } else {
      // Proposal Creator
      money = request.getOperativePayWithoutFees()
    }
  } else if (request.isProposal()) {
    if (!isOwner) {
      // Proposal Creator
      money = request.getOperativePayWithoutFees()
    } else {
      // Agency
      money = request.getAgencyGrandTotal()
    }
  }
  
  return (
    <Box
      mobStyles={[
        css`
          margin: 10px;
          justify-content: center;
        `,
      ]}
    >
      <Text
        mobStyles={[
          mobPageHeader,
          css`
            font-size: 30px;
          `,
        ]}
      >
        Total:{' '}{money}
      </Text>
    </Box>
  )
}

const getStatusButton = requestStatus => {
  let color
  let statusText
  switch (requestStatus) {
    case RequestStatus.pending:
      color = pendingButton
      statusText = 'PENDING'
      break
    case RequestStatus.awaitingBAM:
      color = disputeButton
      statusText = 'AWAITING'
      break
    case RequestStatus.accepted:
      color = acceptButton
      statusText = 'ACCEPTED'
      break
    case RequestStatus.declined:
      color = declineButton
      statusText = 'DECLINED'
      break
    case RequestStatus.canceled:
      color = canceledButton
      statusText = 'CANCELLED'
      break
    case RequestStatus.completed:
      color = completedButton
      statusText = 'COMPLETED'
      break
    case RequestStatus.disputed:
      color = disputeButton
      statusText = 'DISPUTED'
      break
    default:
      color = disputeButton
      statusText = 'ERROR'
  }
  
  return (
    <Box
      mobStyles={[
        css`
          margin: 20px;
        `,
      ]}
    >
      <Button
        mobStyles={[
          fullOpacity,
          mobStatusButton,
          color,
          css`
            width: 100%;
          `,
        ]}
        disabled={true}
      >
        {statusText}
      </Button>
    </Box>
  )
}

const OfferBoxLoader = () => {
  return (
    <Button disabled mobStyles={mobInformationalBox}>
      <Loader type="ThreeDots" color="#7c848b" height={350} width={'auto'}/>
    </Button>
  )
}
