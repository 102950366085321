import {
  admin,
  agency,
  all,
  authenticatedNoAccountType,
  authenticatedWithAccountType,
  creative,
  developer,
  guest,
} from './access'
import React from 'react'

import {
  AdminPage,
  AdminWaitListPage,
  AnnouncementPage,
  BlogPage,
  ChatThreadPage,
  ConfirmBookingDetailsPage,
  ContributorPage,
  CreateCreativePage,
  CreateGigPage,
  EditGigPage,
  FaqPage,
  GuestSupportPage,
  Home,
  InboxPage,
  LicenseImagePage,
  LicensePage,
  LoginPage,
  ManageLicensingPage,
  ManageUsersPage,
  MoneyRequestPage,
  NotificationsPage,
  PaymentInfoPage,
  PrivacyPage,
  PublicProfile,
  RequestModelsPage,
  SearchPage,
  SelectGigPage,
  SignOutButton,
  SignUpPage,
  TermsOfServicePage,
  TermsPage,
  UpdateProfilePage,
  ViewCreatedOffersPage,
  ViewCreatedProposalsPage,
  ViewGigPage,
  ViewMyGigsPage,
  ViewOfferPage,
  ViewProposalPage,
  ViewReceivedOffersPage,
  ViewReceivedProposalsPage,
} from '../pages'
import { LegalPage } from '../pages/Legal'

const SupportPage = React.lazy(() => import('../pages/SupportPage'))
const AccountSettingsPage = React.lazy(() =>
  import('../pages/AccountSettingsPage')
)
// const AdminPage = React.lazy(() => import('../pages/AdminPage'));

// TODO: Audit all links to make sure protected links require Auth

/**
 * ALL routes in the Application.
 *
 * Link: The route recognized by React Router
 * Text: String to display if this route is attached to a button
 * AuthRequired: Do not display to users if they are not logged in
 * DisplayInNavBar: True if this will be a displayed link for users to click in the UI
 * Access: Minimum access level needed to access this route. Ranges from ALL (least access) to Developer (can access all)
 * NavCategory: Categories for grouping the links in a navbar
 *
 * Access Levels Explained. All account types listed in an access level CAN see that link in the Nav. Does not protect against URL hacking
 *
 *
 */
export const ROUTES = {
  // Unauthenticated Routes
  HOME: {
    link: '/',
    exact: true,
    text: 'Home',
    authRequired: false,
    displayInNavBar: true,
    access: all,
    navCategory: 'Navigate',
    component: Home,
  },
  SIGN_IN: {
    link: '/sign-in',
    exact: true,
    text: 'Sign In',
    authRequired: false,
    displayInNavBar: true,
    access: guest,
    component: LoginPage,
  },
  SIGN_UP: {
    link: '/sign-up',
    exact: true,
    text: 'Sign Up',
    authRequired: false,
    displayInNavBar: true,
    access: guest,
    component: SignUpPage,
  },
  SEARCH: {
    link: '/search',
    exact: true,
    text: 'Search',
    authRequired: true,
    displayInNavBar: true,
    access: all,
    navCategory: 'Navigate',
    component: SearchPage,
  },
  FAQ: {
    link: '/faq',
    exact: true,
    text: 'FAQ',
    authRequired: false,
    displayInNavBar: true,
    access: all,
    navCategory: 'Navigate',
    component: FaqPage,
  },
  // ALL
  PROFILE: {
    link: '/profile',
    base: '/profile/',
    exact: true,
    text: 'My Profile',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedNoAccountType,
    navCategory: 'Account',
    component: PublicProfile,
  },
  UPDATE_PROFILE: {
    link: '/update-profile',
    exact: true,
    text: 'Update Profile',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedNoAccountType,
    navCategory: 'Account',
    component: UpdateProfilePage,
  },
  INBOX: {
    link: '/inbox',
    exact: true,
    text: 'Inbox',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedWithAccountType,
    navCategory: 'Account',
    component: InboxPage,
  },

  // Agency
  MANAGE_USERS: {
    link: '/manage-users',
    exact: true,
    text: 'Manage Users',
    authRequired: true,
    displayInNavBar: true,
    access: agency,
    navCategory: 'Manage',
    component: ManageUsersPage,
  },
  MANAGE_LICENSING: {
    link: '/manage-licensing',
    exact: true,
    text: 'Manage Licensing',
    authRequired: true,
    displayInNavBar: false,
    navCategory: 'Manage',
    access: admin,
    component: ManageLicensingPage,
  },
  VIEW_RECEIVED_PROPOSALS: {
    link: '/view-received-proposals',
    exact: true,
    text: 'View Received Proposals',
    authRequired: true,
    displayInNavBar: true,
    access: agency,
    navCategory: 'Manage',
    component: ViewReceivedProposalsPage,
  },
  VIEW_CREATED_OFFERS: {
    link: '/view-created-offers',
    exact: true,
    text: 'View Created Offers',
    authRequired: true,
    displayInNavBar: true,
    access: agency,
    navCategory: 'Manage',
    component: ViewCreatedOffersPage,
  },

  // licensing
  LICENSING: {
    link: '/licensing',
    exact: true,
    text: 'Licensing',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    navCategory: 'Navigate',
    component: LicensePage,
  },
  GIG: {
    link: '/gig/:gig_id',
    exact: false,
    text: 'Gig',
    authRequired: false,
    displayInNavBar: false,
    access: all,
    component: ViewGigPage,
  },
  EDIT_GIG: {
    link: '/edit-gig/:gig_id',
    exact: false,
    base: '/edit-gig/',
    text: 'Gig',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    component: EditGigPage,
  },

  NOTIFICATIONS: {
    link: '/notifications',
    exact: true,
    text: 'Notifications',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedWithAccountType,
    navCategory: 'Account',
    component: NotificationsPage,
  },
  BLOG: {
    link: '/blog',
    text: 'Blog',
    exact: true,
    authRequired: false,
    displayInNavBar: true,
    access: all,
    navCategory: 'Navigate',
    component: BlogPage,
  },
  // FORGOT_PASSWORD: {
  //   link: '/forgot-password',
  //   text: 'Forgot Password',
  //   authRequired: false,
  //   displayInNavBar: false, // TODO: I dont think this should be true but explore
  //   access: guest,
  //   navCategory: 'More',
  //   component: ForgotPasswordPage,
  // },
  // LANDING: {
  //   link: '/landing',
  //   text: 'Landing',
  //   authRequired: false,
  //   displayInNavBar: false,
  //   access: all,
  //   component: Landing,
  // },

  USER_PROFILE: {
    link: '/profile/:profile_id',
    exact: false,
    base: '/profile/',
    text: 'Dynamic Profile',
    authRequired: false,
    access: all,
    component: PublicProfile,
  },

  // Authenticated Routes
  ACCOUNT_SETTINGS: {
    link: '/account-settings',
    exact: true,
    text: 'Settings',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedNoAccountType,
    navCategory: 'Account',
    component: AccountSettingsPage,
  },
  ACCOUNT_SETTINGS_SERVICE: {
    link: '/account-settings/:service?',
    exact: false,
    text: 'Account Settings',
    authRequired: true,
    displayInNavBar: false,
    access: authenticatedNoAccountType,
    navCategory: 'Account',
    component: AccountSettingsPage,
  },
  CONFIRM_BOOKING_DETAILS: {
    link: '/confirm-booking-details',
    exact: true,
    text: 'Confirm Booking Details',
    authRequired: true,
    access: agency,
    component: ConfirmBookingDetailsPage,
  },
  CREATE_GIG: {
    link: '/create-gig',
    exact: true,
    text: 'Create Gig',
    authRequired: true,
    displayInNavBar: true,
    access: agency,
    navCategory: 'Manage',
    component: CreateGigPage,
  },

  SELECT_GIG: {
    link: '/select-gig',
    exact: true,
    text: 'Select Gig',
    authRequired: true,
    access: agency,
    component: SelectGigPage,
  },
  SETUP_PROFILE: {
    link: '/update-profile?type=setup',
    text: 'Setup Profile',
    authRequired: true,
    access: authenticatedWithAccountType,
    component: UpdateProfilePage,
  },

  VIEW_MY_GIGS: {
    link: '/view-my-gigs',
    exact: true,
    text: 'View My Gigs',
    authRequired: true,
    displayInNavBar: true,
    access: agency,
    navCategory: 'Manage',
    component: ViewMyGigsPage,
  },

  LICENSE_IMAGE: {
    link: '/license-image/:image_id',
    exact: false,
    text: 'License Image',
    authRequired: true,
    displayInNavBar: false,
    access: developer,
    component: LicenseImagePage,
  },

  // Temporary Routes
  ADMIN_WAIT_LIST_PAGE: {
    link: '/admin-wait-list-page',
    text: 'Waitlist Panel',
    authRequired: true,
    access: admin,
    component: AdminWaitListPage,
  },
  ADMIN: {
    link: '/juggernaut-eyes',
    exact: true,
    text: 'Stats',
    authRequired: true,
    access: admin,
    component: AdminPage,
    displayInNavBar: true,
    navCategory: 'Account', // only applies to desktop
  },
  MR: {
    link: '/with-regards',
    exact: true,
    text: 'Money Requests',
    authRequired: true,
    access: admin,
    component: MoneyRequestPage,
    displayInNavBar: true,
    navCategory: 'Account', // only applies to desktop
  },
  MANAGE_ANNOUNCEMENTS: {
    link: '/manage/a',
    exact: true,
    text: 'Manage Announcements',
    authRequired: true,
    access: admin,
    component: AnnouncementPage,
    displayInNavBar: true,
    navCategory: 'Manage', // only applies to desktop
  },

  W_ACCOUNT_TYPE: {
    link: '/w/account-type',
    exact: true,
    text: 'WL',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    component: Home,
  },
  W_PAYMENT: {
    link: '/w/payment',
    exact: true,
    text: 'WL',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    component: Home,
  },
  W_MODEL_PAYMENT: {
    link: '/w/model/payment',
    exact: true,
    text: 'WL',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    component: Home,
  },
  W_DETAILS: {
    link: '/w/details',
    exact: true,
    text: 'WL',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    component: Home,
  },
  W_GIGS: {
    link: '/w/gigs',
    exact: true,
    text: 'WL',
    authRequired: true,
    displayInNavBar: false,
    access: all,
    component: Home,
  },

  // TODO Not Final. Will be a dynamic route like above
  VIEW_RECEIVED_OFFERS: {
    link: '/view-received-offers',
    exact: true,
    text: 'View Received Offers',
    authRequired: true,
    displayInNavBar: true,
    access: creative,
    navCategory: 'Manage',
    component: ViewReceivedOffersPage,
  },
  OFFER: {
    link: '/offer/:offer_id',
    exact: false,
    base: '/offer/',
    text: 'Offer',
    authRequired: true,
    access: authenticatedWithAccountType,
    component: ViewOfferPage,
  },
  VIEW_CREATED_PROPOSALS: {
    link: '/view-created-proposals',
    exact: true,
    text: 'View Created Proposals',
    authRequired: true,
    displayInNavBar: true,
    access: creative,
    navCategory: 'Manage',
    component: ViewCreatedProposalsPage,
  },

  PROPOSAL: {
    link: '/proposal/:proposal_id',
    exact: false,
    base: '/proposal/',
    text: 'Proposal',
    authRequired: true,
    access: authenticatedWithAccountType,
    component: ViewProposalPage,
  },

  UPDATE_MANAGED_USERS: {
    link: '/update-managed-profile/:user_id',
    exact: false,
    text: 'Update Managed User Profile',
    authRequired: true,
    access: agency,
    navCategory: 'Manage',
    component: UpdateProfilePage,
  },
  CREATE_CREATIVE: {
    link: '/create-creative',
    exact: true,
    text: 'Create Creative',
    authRequired: true,
    displayInNavBar: true,
    access: agency,
    navCategory: 'Manage',
    component: CreateCreativePage,
  },

  TERMS_AND_CONDITIONS: {
    link: '/terms-and-conditions',
    exact: true,
    text: 'Terms and Conditions',
    authRequired: false,
    displayInNavBar: false,
    access: all,
    navCategory: 'Account',
    component: TermsPage,
  },
  LEGAL: {
    link: '/legal',
    exact: true,
    text: 'Legal Information',
    authRequired: false,
    displayInNavBar: true,
    access: all,
    navCategory: 'Account',
    component: LegalPage,
  },
  TERMS_OF_SERVICE: {
    link: '/terms-of-service',
    exact: true,
    text: 'Terms of Service',
    authRequired: false,
    displayInNavBar: false,
    access: all,
    navCategory: 'Account',
    component: TermsOfServicePage,
  },
  PRIVACY: {
    link: '/privacy-policy',
    exact: true,
    text: 'Privacy',
    authRequired: false,
    displayInNavBar: false,
    access: all,
    navCategory: 'Account',
    component: PrivacyPage,
  },

  // TODO: update to be dynamic
  CHAT: {
    link: '/chat',
    text: 'Chat Thread',
    exact: true,
    authRequired: true,
    displayInNavBar: false,
    access: authenticatedWithAccountType,
    component: ChatThreadPage,
  },
  CHAT_THREAD: {
    link: '/chat/:chat_id',
    exact: false,
    text: 'Chat Thread Dynamic',
    authRequired: true,
    displayInNavBar: false,
    access: authenticatedWithAccountType,
    component: ChatThreadPage,
  },

  // Items that should be at the bottom of the nav
  CONTRIBUTORS: {
    link: '/contributors',
    exact: true,
    text: 'Contributors',
    authRequired: true,
    displayInNavBar: false,
    access: admin,
    navCategory: 'Navigate', // only applies to desktop
    component: ContributorPage,
  },
  SUPPORT: {
    link: '/support',
    exact: true,
    text: 'Support',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedWithAccountType,
    navCategory: 'Account',
    component: SupportPage,
  },

  //   SEARCH: {
  //     link: '/search',
  //     exact: true,
  //     text: 'Search',
  //     authRequired: true,
  //     displayInNavBar: true,
  //     access: all,
  //     navCategory: 'Navigate',
  //     component: SearchPage,
  //   },

  GUEST_SUPPORT: {
    link: '/support',
    exact: true,
    text: 'Support',
    authRequired: false,
    displayInNavBar: true,
    access: guest,
    navCategory: 'Navigate',
    component: GuestSupportPage,
  },
  LOG_OUT: {
    link: '/logout',
    exact: true,
    text: 'Logout',
    authRequired: true,
    displayInNavBar: true,
    access: authenticatedNoAccountType,
    navCategory: 'Account',
    component: SignOutButton,
  },

  // TODO: May not need these right now. Maybe in a future version.
  CONFIRM_PAYMENT_DETAILS: {
    link: '/confirm-payment-details',
    text: 'Confirm Payment Details',
    authRequired: true,
    displayInNavBar: false,
    access: developer,
    component: PaymentInfoPage,
  },
  REQUEST_MODELS: {
    link: '/request-models',
    text: 'Request Models for Booking',
    authRequired: true,
    displayInNavBar: false,
    access: developer,
    component: RequestModelsPage,
  },
}
