import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Masonry from 'react-masonry-css'
import { useCollection } from 'react-firebase-hooks/firestore'

import {
  Box,
  Image,
  mobBoxVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import { collections } from '../../../firebaseApp'
import { User } from '../../../classes'
import { SearchType } from '../../../constants/enums'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { useSetRecoilState } from 'recoil'
import { searchState } from '../../../store'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { up } from 'styled-breakpoints'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../../utils/responsiveVars'
import { uuid } from '../../../utils/uuid'

const { center, column, mobWrapper } = mobBoxVariants
const { deskSectionHeader } = deskTextVariants
const { mobSectionHeader } = mobTextVariants

const TrendingBoxText = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  color: ${p => p.theme.colors.secondary};
  font-family: 'Open Sans', serif;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  ${up('desktop')} {
    font-size: 20px;
    line-height: 60px;
    justify-content: flex-end;
  }
`

const deskInnerWrapper = css`
  max-width: 1000px;
  display: flex;
  justify-content: center;
  width: 100%;
`

// used to postion the inner wrapper in the center
const deskOuterWrapper = css`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const TrendingModule = () => {
  const history = useHistory()
  const isDesktop = useMediaQuery(desktopQuery)
  const setSearch = useSetRecoilState(searchState)
  const redirectToUserSearch = () => {
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        type: SearchType.creative,
      }
    })
    history.push(ROUTES.SEARCH.link)
  }

  // TODO: Rethink this. Maybe we wont do the below and just let Firebase pick random accounts like it is currently doing
  // Plan is to add users to display here to some special place in the database
  const [usersSnapshot, userSnapshotLoading, userSnapshotError] = useCollection(
    collections.userInformation.where('trending', '==', true).limit(7)
  )
  const [trendingUsers, setTrendingUsers] = useState([])

  useEffect(() => {
    if (usersSnapshot) {
      setTrendingUsers([])
      usersSnapshot.docs.map((userDocument, index) => {
        let user = new User(userDocument)
        let mobHeights =
          {
            1: 238,
            2: 137,
            3: 159,
            4: 178,
            5: 194,
            6: 116,
            7: 145,
          }[index + 1] || 0

        let deskHeights =
          {
            1: 310,
            2: 110,
            3: 195,
            4: 125,
            5: 219,
            6: 241,
            7: 100,
          }[index + 1] || 0

        const heightToUse = isDesktop ? deskHeights : mobHeights

        const PhantomBox = () => {
          return (
            <Box
              mobStyles={[
                css`
                  display: none;
                `,
              ]}
            ></Box>
          )
        }

        // This is a hack for the masonry component. Without it the photos won't line up correctly
        // Comment this out to see it.
        if (index === 6) {
          setTrendingUsers(currentObjs => [
            ...currentObjs,
            <PhantomBox key={uuid()} />,
          ])
        }
        setTrendingUsers(currentObjs => [
          ...currentObjs,
          <Image
            enableMotion
            enableUseAsBackground={true}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            key={uuid()}
            onClick={() => {
              history.push('/profile/' + user.userId)
            }}
            mobStyles={css`
              height: ${heightToUse}px;
              min-height: ${heightToUse}px;
              max-height: ${heightToUse}px;
            `}
            deskStyles={css`
              height: ${heightToUse * 2}px;
              min-height: ${heightToUse * 2}px;
              max-height: ${heightToUse * 2}px;
              width: 350px;
            `}
            src={user.getProfileImage()}
          >
            <TrendingBoxText>{user.fullName}</TrendingBoxText>
          </Image>,
        ])
      })
    }
  }, [usersSnapshot])

  if (usersSnapshot && usersSnapshot.size < 7) {
    return null
  }

  // If there is an error just do not display
  return (
    <>
      {userSnapshotError && null}
      {usersSnapshot && (
        <Box deskStyles={deskOuterWrapper}>
          <Box mobStyles={[center, column, mobWrapper, deskInnerWrapper]}>
            <Text
              mobStyles={[mobSectionHeader]}
              deskStyles={[deskSectionHeader]}
            >
              TRENDING
            </Text>
            <ViewLink
              enableMotion
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                redirectToUserSearch()
              }}
            >
              VIEW ALL USERS
            </ViewLink>
            <Masonry
              breakpointCols={isDesktop ? 3 : 2}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {trendingUsers}
            </Masonry>
          </Box>
        </Box>
      )}
    </>
  )
}

export const HomeSectionHeader = styled.div`
  margin: 15px 15px 10px 15px;
  color: ${p => p.theme.colors.primary};
  height: 39px;
  font-family: Montserrat, serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 39px;
`

export const ViewLink = styled(motion.a)`
  color: ${p => p.theme.colors.gray};
  height: 20px;
  font-family: Montserrat, serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 20px;
  margin: 0 0 20px;
`

//   <Box
// enableMotion
// whileHover={{ scale: 1.1 }}
// whileTap={{ scale: 0.9 }}
// key={index}
// onClick={() => {
//   history.push('/profile/' + user.userId)
// }}
// mobStyles={css`
//               height: ${heightToUse}px;
//               min-height: ${heightToUse}px;
//               max-height: ${heightToUse}px;
//               background-image: url(${user.getProfileImage()});
//             `}
// deskStyles={css`
//               height: ${heightToUse * 2}px;
//               min-height: ${heightToUse * 2}px;
//               max-height: ${heightToUse * 2}px;
//               width: 350px;
//               background-image: url(${user.getProfileImage()});
//             `}
//   >
//   <TrendingBoxText>{user.fullName} {index + 1}</TrendingBoxText>
// </Box>
