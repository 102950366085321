import React, { useEffect, useState } from 'react'
import { collections, functions } from '../../firebaseApp'
import { useDocument } from 'react-firebase-hooks/firestore'

import {
  Box,
  Image,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared/index'
import { PrivateUser } from '../../classes'
import {
  LoadingButton,
  LoadingScreen,
  StickyFooterWithButton,
} from '../../components'
import { InvoiceLineType, InvoiceRow } from '../../components/InvoiceRow'
import { useLocation } from 'react-router-dom'
import { uuid } from '../../utils/uuid'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { LicenseImage } from '../../classes/Models/LicenseImage'
import { StickyFooter } from '../../components/StickyFooter/StickyFooter'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper, center } = mobBoxVariants
const { deskPageHeader } = deskTextVariants
const { mobModalImage } = mobImageVariants
const {
  mobPageHeader,
  mobInstructionParagraph,
  mobHorizontalRule,
  mobPageSectionHeader,
} = mobTextVariants

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

/**
 * License Image page is where users can view the details of a potential image
 * license purchase. The license type can either be MONTHLY or EXCLUSIVE.
 * Once the they press purchase their card on file will be charged.
 *
 * Be careful when using `privateOperative` since this info does not belong to the user viewing this page
 * @param props
 * @constructor
 */
export const LicenseImagePage = ({ match, user, currentUserAuth }) => {
  const chargeImageLicensePurchase = functions.httpsCallable(
    'chargeImageLicensePurchase'
  )
  const chargeMonthlyImageLicensePurchase = functions.httpsCallable(
    'chargeMonthlyImageLicensePurchase'
  )
  const chargeExclusiveImageLicensePurchase = functions.httpsCallable(
    'chargeExclusiveImageLicensePurchase'
  )
  const [privateUser, setPrivateUser] = useState(new PrivateUser())
  const [licenseImage, setLicenseImage] = useState(new LicenseImage())
  const [showPurchaseInProgress, setShowPurchaseInProgress] = useState(false)

  const [
    privateUserSnapshot,
    privateUserSnapshotLoading,
    privateUserSnapshotError,
  ] = useDocument(collections.privateUserInformation.doc(currentUserAuth.uid))

  const licenseImageId = match.params.image_id
  const [purchaseLoading, setPurchaseLoading] = useState(false)
  // const createPaymentIntent = functions.httpsCallable('createPaymentIntent')
  const query = useQuery()
  const licenseType = query.get('type')

  // Use Firestore React Hook to get document
  const [
    licenseImageSnapshot,
    licenseImageSnapshotLoading,
    licenseImageSnapshotError,
  ] = useDocument(collections.licenseImages.doc(licenseImageId))

  const handleExclusiveImagePurchase = () => {
    setShowPurchaseInProgress(true)
    chargeExclusiveImageLicensePurchase({
      licenseImageId: licenseImageId,
      // Customers PAY us
      purchaserStripeCustomerId: privateUser.stripeCustomerId,
      idempotencyKey: uuid(),
      imageOwnerId: licenseImage.createdBy,
    })
      .then(stripeResult => {
        setShowPurchaseInProgress(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_PURCHASE_IMAGE',
          message: 'Error occurred while user tried to purchase an image',
          type: errorTypes.billing,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'LicenseImagePage.jsx',
        })
      })
  }

  const handleMonthlyImagePurchase = () => {
    setShowPurchaseInProgress(true)
    chargeMonthlyImageLicensePurchase({
      licenseImageId: licenseImageId,
      // Customers PAY us
      purchaserStripeCustomerId: privateUser.stripeCustomerId,
      idempotencyKey: uuid(),
      imageOwnerId: licenseImage.createdBy,
    })
      .then(stripeResult => {
        setShowPurchaseInProgress(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_PURCHASE_IMAGE',
          message: 'Error occurred while user tried to purchase an image',
          type: errorTypes.billing,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'LicenseImagePage.jsx',
        })
      })
  }

  useEffect(() => {
    setPrivateUser(new PrivateUser(privateUserSnapshot))
    setLicenseImage(new LicenseImage(licenseImageSnapshot))
  }, [privateUserSnapshot, licenseImageSnapshot])

  return (
    <>
      {licenseImageSnapshotError && (
        <strong>Error: {licenseImageSnapshotError}</strong>
      )}
      {licenseImageSnapshotLoading || (purchaseLoading && <LoadingScreen />)}
      {licenseImageSnapshot && privateUserSnapshot && (
        <>
          <Box mobStyles={[column, mobWrapper]}>
            <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
              License This Image
            </Text>
            <Box mobStyles={[center]}>
              <Image
                src={licenseImage.largeSizeUrl}
                mobStyles={mobModalImage}
              />
            </Box>
            <Text mobStyles={mobInstructionParagraph}>
              License this image monthly or buy the rights for the image
              exclusively.
            </Text>

            <Text mobStyles={mobHorizontalRule} />
            <Text mobStyles={[mobPageSectionHeader]}>
              {licenseType === 'monthly'
                ? 'MONTHLY LICENSING DETAILS'
                : 'EXCLUSIVE LICENSING DETAILS'}
            </Text>
            <InvoiceRow
              description="Subtotal"
              money={licenseImage.getLicenseSubTotal(licenseType)}
              lineType={InvoiceLineType.Regular}
            />

            <InvoiceRow
              description="Tax"
              money={licenseImage.getLicenseTaxFee(licenseType)}
              lineType={InvoiceLineType.Regular}
            />
            <InvoiceRow
              description="Service Fees"
              money={licenseImage.getLicenseServiceFee(licenseType)}
              lineType={InvoiceLineType.Regular}
            />
            <InvoiceRow
              description={
                licenseType === 'monthly'
                  ? 'Monthly Licensing Amount'
                  : 'Total Licensing Amount'
              }
              money={licenseImage.getLicenseGrandTotal(licenseType)}
              lineType={InvoiceLineType.Grand}
            />
          </Box>
          {showPurchaseInProgress && (
            <StickyFooter>
              <LoadingButton />
            </StickyFooter>
          )}
          {!showPurchaseInProgress && (
            <StickyFooterWithButton
              mainButtonHandler={
                licenseType === 'monthly'
                  ? handleMonthlyImagePurchase
                  : handleExclusiveImagePurchase
              }
              mainButtonText="PURCHASE LICENSE"
            />
          )}
        </>
      )}
    </>
  )
}
