import { ROUTES } from '../../constants/routes'
import includes from 'lodash/includes'

export const getMobMenuItems = userAccountType => {
  let routesToDisplay = []

  Object.keys(ROUTES).forEach(key => {
    let userCanAccess = includes(ROUTES[key]['access'], userAccountType)
    let displayInNav = ROUTES[key]['displayInNavBar']

    if (userCanAccess && displayInNav) {
      routesToDisplay.push(ROUTES[key])
    }
  })

  return routesToDisplay
}

export const getDeskMenuItems = userAccountType => {
  const routeCategories = {}

  Object.keys(ROUTES).map(routeKey => {
    const currRoute = ROUTES[routeKey]
    const { navCategory } = currRoute

    let userCanAccess = includes(currRoute['access'], userAccountType)
    let displayInNav = currRoute['displayInNavBar']

    // If the user can access the route and that route should be displayed in the nav
    if (userCanAccess && displayInNav) {
      // If the route has a nav category and the nav category hasn't already been added
      // add the category and the route
      if (navCategory && !routeCategories[navCategory]) {
        routeCategories[navCategory] = [currRoute]
      } else if (navCategory && routeCategories[navCategory]) {
        routeCategories[navCategory].push(currRoute)
      }
    }
  })

  // Return an object with the following structure
  /*
   *  { "categoryName" : [<Route>, <Route>, <Route>],
   *    "categoryName" : [<Route>, <Route>],
   *    "categoryName" : [<Route>, <Route>, <Route>]
   *   }
   * */
  return routeCategories
}
