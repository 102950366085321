import firebase from 'firebase/app'
import get from 'lodash/get'
import { LicenseImageType } from '../../constants/enums'

/**
 * LicenseImage is a JS wrapper for License Image firestore documents
 *
 */
export class LicenseImage {
  licenseImageDocument
  licenseImageData
  id

  createdBy
  uploadedAt
  monthlyAvailable
  monthlyPrice
  exclusiveAvailable
  exclusivePrice
  orderMap
  status
  tags
  uniqueId
  originalImageUrl
  originalUrl
  largeSizeUrl

  licenseMonthlyLink
  licenseExclusiveLink

  static imageLicensingTaxRate = 0.095
  static imageLicensingServiceRate = 0.125

  constructor(licenseImage, licenseImageJSON) {
    if (licenseImage) {
      this.licenseImageDocument = licenseImage
      this.id = get(this.licenseImageDocument, 'id', '')
      this.licenseImageData =
        this.licenseImageDocument && this.licenseImageDocument.data
          ? this.licenseImageDocument.data()
          : this.licenseImageDocument
      this.uploadedAt = get(
        this.licenseImageData,
        'uploadedAt',
        firebase.firestore.Timestamp.now()
      )
    }

    if (licenseImageJSON) {
      this.licenseImageData = licenseImageJSON
      this.id = get(this.licenseImageData, 'objectID', '')
      this.uploadedAt = this.convertToFSTimestamp(
        get(this.licenseImageData, 'uploadedAt', { seconds: 0, nanoseconds: 0 })
      )
    }

    // If Constructed without either param set defaults.
    // Most likely will only ever be doing for testing or while data is loading
    if (!licenseImageJSON && !licenseImage) {
      this.uploadedAt = firebase.firestore.Timestamp.now()
    }

    // FIXME: Replace default value with a default image url
    this.originalUrl = get(this.licenseImageData, 'originalUrl', '')
    this.largeSizeUrl = get(this.licenseImageData, 'largeSizeUrl', '')
    this.licenseMonthlyLink = `/license-image/${this.id}?type=monthly`
    this.licenseExclusiveLink = `/license-image/${this.id}?type=exclusive`

    this.monthlyAvailable = get(
      this.licenseImageData,
      'monthlyAvailable',
      false
    )
    this.monthlyPrice = get(this.licenseImageData, 'monthlyPrice', 0) / 100
    this.exclusiveAvailable = get(
      this.licenseImageData,
      'exclusiveAvailable',
      false
    )
    this.exclusivePrice = get(this.licenseImageData, 'exclusivePrice', 0) / 100
    this.orderMap = get(this.licenseImageData, 'orderMap', {})
    this.tags = get(this.licenseImageData, 'tags', [])
    this.uniqueId = get(this.licenseImageData, 'uniqueId', '')
    this.createdBy = get(this.licenseImageData, 'createdBy', '')
    this.originalImageUrl = get(this.licenseImageData, 'originalImageUrl', '')
    this.visibleInSearch = get(this.licenseImageData, 'visibleInSearch', false)
  }

  getLicenseSubTotal = licenseType => {
    return licenseType === 'monthly' ? this.monthlyPrice : this.exclusivePrice
  }

  getUSDFormattedSubtotal = licenseType => {
    const monthlyPricing = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format(this.monthlyPrice)

    const exclusivePricing = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format(this.exclusivePrice)

    return licenseType === LicenseImageType.monthly
      ? monthlyPricing
      : exclusivePricing
  }

  getLicenseTaxFee = licenseType => {
    return (
      this.getLicenseSubTotal(licenseType) * LicenseImage.imageLicensingTaxRate
    )
  }

  getLicenseServiceFee = licenseType => {
    return (
      this.getLicenseSubTotal(licenseType) *
      LicenseImage.imageLicensingServiceRate
    )
  }

  getLicenseGrandTotal = licenseType => {
    return Number(
      this.getLicenseSubTotal(licenseType) +
        this.getLicenseServiceFee(licenseType) +
        this.getLicenseTaxFee(licenseType)
    )
  }

  isUserOwner = userId => {
    return userId === this.createdBy
  }

  /**
   *  Used to transform Alogolia date objects back into Firestore Timestamps
   * @param object
   */
  convertToFSTimestamp(object) {
    return new firebase.firestore.Timestamp(
      object._seconds,
      object._nanoseconds
    )
  }
}
