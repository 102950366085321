import { Box, mobBoxVariants } from '../../../shared/Box'
import { Input, inputVariants } from '../../../shared/Input'
import { mobTextVariants, Text } from '../../../shared/Text'
import { css } from 'styled-components'
import React, { useState } from 'react'

const { row } = mobBoxVariants
const { mobInput } = inputVariants
const { fullWidth } = mobTextVariants

export const FileInput = ({ elementId }) => {
  const [fileName, setFileName] = useState('')

  const displayFileExplorer = () => {
    let inputElement = document.getElementById(elementId)
    inputElement.click()
  }

  return (
    <>
      <Box
        onClick={displayFileExplorer}
        mobStyles={[row, mobInput, mobFileInputWrapper]}
      >
        <Text mobStyles={[fullWidth]}>Choose File</Text>
        <Text mobStyles={[mobFileInputFileName]}>{fileName}</Text>
      </Box>

      <Input
        onChange={() => {
          const imageInput = document.getElementById(elementId)
          const file = imageInput.files && imageInput.files[0]
          setFileName(file.name.substr(0, 20))
        }}
        type="file"
        id={elementId}
        name="file"
        hidden
      />
    </>
  )
}

const mobFileInputWrapper = css`
  line-height: 16px;
  overflow: hidden;
`
const mobFileInputFileName = css`
  width: 100%;
  text-align: right;
  padding: 0 15px 0 15px;
`
