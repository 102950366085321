import { Box, mobBoxVariants } from '../../shared/Box'
import { ProfileAlignment, ProfileModule } from '../../modules/ProfileModule'
import { AvailabilityModule } from '../../modules/AvailabilityModule'
import { ActiveGigs } from './ActiveGigs'
import { Text } from '../../shared/Text'
import { RequestToBookButton } from './RequestToBookButton'
import React from 'react'
import { mobLinkVariants } from '../../shared/Link'
import { MessageBox } from '../../components/MessageBox'
import { ProfileOnboarding } from '../../components/BookAModelOnboarding'
import { Button, mobButtonVariants } from '../../shared/Button'
import { ROUTES } from '../../constants/routes'
import { useRecoilValue } from 'recoil'
import { generalState } from '../../store'
import { deskButtonVariants } from '../../shared/Button/deskButtonVariants'
import { useHistory } from 'react-router-dom'
import { PortfolioImageWithModal } from './DeskProfileModule'
import { AdminSection } from './AdminSection'

const {
  column,
  mobWrapper,
  mobMultiImageWrapper,
  center,
  mobBoxSpacer,
} = mobBoxVariants
const { mobProfileLink } = mobLinkVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskButtonVariants

export const MobileProfileModule = ({
  user,
  currentUserAuth,
  redirectToUserLicenseImages,
  portfolioImagesData,
  updateBookingCart,
  viewingOwnProfile,
}) => {
  const appState = useRecoilValue(generalState)
  const history = useHistory()

  return (
    <>
      <ProfileOnboarding user={user} />
      <Box mobStyles={[column, mobWrapper]}>
        <ProfileModule user={user} profileAlignment={ProfileAlignment.center} />
        <br />
        {viewingOwnProfile && (
          <Box mobStyles={[column]}>
            <Box mobStyles={[center, mobBoxSpacer]}>
              <Button
                enableMotion
                animate={{
                  scale: [0.95, 1.11, 0.95],
                }}
                transition={{
                  duration: 2.5,
                  ease: 'easeInOut',
                  loop: Infinity,
                }}
                mobStyles={[mobSubmitButton, mobLong305x46]}
                deskStyles={deskSubmitButton}
                onClick={() => {
                  history.push(ROUTES.UPDATE_PROFILE.link)
                }}
              >
                UPDATE PROFILE
              </Button>
            </Box>
          </Box>
        )}
        <AvailabilityModule currentUserAuth={currentUserAuth} user={user} />
        <br />
        <ActiveGigs user={user} currentUserAuth={currentUserAuth} />
        <Text
          enableMotion
          whileHover={{
            scale: 1.2,
            transition: { duration: 1 },
          }}
          whileTap={{ scale: 0.9 }}
          mobStyles={[mobProfileLink]}
          id="user-portfolio-images-text"
        >
          PORTFOLIO IMAGES
        </Text>
        {portfolioImagesData.length <= 0 && (
          <MessageBox
            header="NO PORTFOLIO IMAGES ADDED"
            message={
              user.viewingOwnProfile
                ? 'You have not added any portfolio images. Visit the update profile page to add a few!.'
                : 'This user has not added any portfolio images yet. Check back soon!.'
            }
          />
        )}
        {portfolioImagesData.length > 0 && (
          <Box mobStyles={mobMultiImageWrapper}>
            {portfolioImagesData.map((imageDocument, index) => {
              return (
                <PortfolioImageWithModal
                  user={user}
                  imageDocument={imageDocument}
                  index={index}
                  currentUserAuth={currentUserAuth}
                />
              )
            })}
          </Box>
        )}
        <AdminSection user={user} currentUserAuth={currentUserAuth} />
        <RequestToBookButton
          user={user}
          updateBookingCart={updateBookingCart}
          currentUserAuth={currentUserAuth}
        />
      </Box>
    </>
  )
}

/*
* {false &&      <Text
        enableMotion
        whileHover={{
          scale: 1.2,
          transition: { duration: 1 },
        }}
        whileTap={{ scale: 0.9 }}
        mobStyles={[mobProfileLink]}
        onClick={redirectToUserLicenseImages}
      >
        CLICK TO VIEW IMAGES AVAILABLE FOR LICENSING
      </Text>}
*
* */
