import React from 'react'
import { css } from 'styled-components'
import { Text } from '../../../shared/Text'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import {
  CollectDetailsSlide,
  CollectEmployerPaymentSlide,
  LoginSlide,
  MainSlide,
  SelectAccountTypeSlide,
  SignUpSlide,
} from './slides'
import Loader from 'react-loader-spinner'
import { Box, mobBoxVariants } from '../../../shared/Box'
import { ROUTES } from '../../../constants/routes'
import { GigSpotlightModule } from '../modules/GigSpotlightModule'
import { Image } from '../../../shared/Image'
import BronnerSmallLogo from '../../../assets/logos/bronner_small_logo.jpg'
import { CashServiceModule, hasAtLeastOneService } from '../../../modules/CashServiceModule'

const { column, row } = mobBoxVariants

export const imageSignUpInStyles = css`
  height: 15vw;
  width: 15vw;
`

export const mobImageSignUpInStyles = css`
  height: 35vw;
  width: 35vw;
`

export const whiteLabelMotionVariants = {
  initial: {
    opacity: 0,
    x: '100%',
  },
  in: {
    opacity: 1,
    transition: { duration: 1 },
    x: 'calc(100vw - 100%)',
  },
  out: {
    opacity: 0,
  },
}

const mobHeader = css`
  height: auto;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: bold;
`
const deskMainHeaderStyles = css`
  font-size: 35px;
`

export const WhiteLabelExperienceRouter = ({ currentUserAuth, user }) => {
  let location = useLocation()
  
  return (
    <motion.div
      initial={{ minHeight: '75vh' }}
      animate={{ height: 'auto' }}
      transition={{ duration: 0 }}
      exit={{ height: 0 }}
    >
      <AnimatePresence>
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={{
            initial: {
              opacity: 0,
              x: '0%',
            },
            in: {
              opacity: 1,
              transition: { duration: 3 },
              x: 'calc(100% - 100vw)',
            },
            out: {
              opacity: 0,
            },
          }}
        >
          <NewOrleansText/>
        
        </motion.div>
        <Switch location={location} key={location.pathname}>
          <Route
            exact={true}
            path={'/'}
            render={props => (
              <MainSlide
                {...props}
                currentUserAuth={currentUserAuth}
                user={user}
              />
            )}
          />
            <Route
              exact={true}
              path={ROUTES.W_ACCOUNT_TYPE.link}
              render={props => (
                <SelectAccountTypeSlide
                  {...props}
                  currentUserAuth={currentUserAuth}
                />
              )}
            />
            <Route
              exact={true}
              path={ROUTES.W_PAYMENT.link}
              render={props => (
                <CollectEmployerPaymentSlide
                  {...props}
                  currentUserAuth={currentUserAuth}
                />
              )}
            />
          {user && (
            <Route
              exact={true}
              path={ROUTES.W_MODEL_PAYMENT.link}
              render={props => (
                <CashServiceSlide
                  {...props}
                  user={user}
                  currentUserAuth={currentUserAuth}
                />
              )}
            />)}
          {user && (
            <Route
              exact={true}
              path={ROUTES.W_DETAILS.link}
              render={props => (
                <CollectDetailsSlide
                  {...props}
                  user={user}
                  currentUserAuth={currentUserAuth}
                />
              )}
            />
          )}
          {user && (
            <Route
              exact={true}
              path={ROUTES.W_GIGS.link}
              render={props => (
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={whiteLabelMotionVariants}
                >
                  <GigSpotlightModule
                    moduleHeader={'Bronner Bros. Beauty Show Gigs'}
                    currentUserAuth={currentUserAuth}
                    relevantField={'bronnerBrosIBS'}
                    gigIcon={
                      <Image
                        src={BronnerSmallLogo}
                        deskStyles={css`
                        height: 5vw;
                        width: 5vw;
                      `}
                        mobStyles={css`
                        height: 15vw;
                        width: 15vw;
                      `}
                      />
                    }
                  />
                </motion.div>
              )}
            />)}
          <Route path="/w/signup" component={SignUpSlide}/>
          <Route path="/w/login" component={LoginSlide}/>
          <Route
            path="*"
            render={props => (
              <MainSlide
                {...props}
                currentUserAuth={currentUserAuth}
                user={user}
              />
            )}
          
          />
        </Switch>
      </AnimatePresence>
    </motion.div>
  )
}

export const CashServiceSlide = ({ currentUserAuth, user }) => {
  const history = useHistory()
  
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box
        deskStyles={[
          row,
          css`
            flex: 1;
            padding: 50px 0 0 50px;
          `,
        ]}
        mobStyles={[
          column,
          css`
            flex: 1;
            padding: 50px 0 0 50px;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobHeader,
            css`
              margin: 0;
            `,
          ]}
          deskStyles={deskMainHeaderStyles}
        >
          Add a Cash Service.
        </Text>
      </Box>
      <Box deskStyles={[row]} mobStyles={[column]}>
        <Box
          deskStyles={[
            column,
            css`
              flex: 1;
              padding: 10px 50px 0 50px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 1;
              padding: 10px 20px 0 20px;
            `,
          ]}
        >
          <CashServiceModule
            user={user}
            currentUserAuth={currentUserAuth}
            rightButton={
              hasAtLeastOneService(user.moneyServices) ? (
                <Text
                  enableMotion
                  whileTap={{ scale: 0.9 }}
                  whileHover={{ scale: 1.11 }}
                  mobStyles={[
                    css`
                      height: auto;
                      color: ${p => p.theme.colors.primary};
                      font-family: ${p => p.theme.fonts.secondary};
                      font-size: 20px;
                      font-weight: bold;
                      justify-content: flex-end;
                      align-items: center;
                      text-align: center;
                      display: flex;
                      flex: 1;
                      cursor: pointer;
                    `,
                  ]}
                  deskStyles={[
                    deskMainHeaderStyles,
                    css`
                      justify-content: flex-end;
                      align-items: center;
                      display: flex;
                      flex: 1;
                      cursor: pointer;
                    `,
                  ]}
                  onClick={() => {
                    history.push('/w/gigs')
                  }}
                >
                  View Bronner Gigs
                </Text>
              ) : null
            }
          />
        </Box>
      </Box>
    </motion.div>
  )
}

const LocationLetter = ({ color, letter, addSpace }) => {
  return (
    <Text
      mobStyles={css`
        font-size: 25px !important;
        color: ${color};
        font-weight: bold;
        margin: 0;
      `}
      deskStyles={css`
        font-size: 50px !important;
      `}
    >
      {letter}
    </Text>
  )
}

export const NewOrleansText = () => {
  return (
    <Box
      deskStyles={css`
        margin: 25px 50px 0 50px;
      `}
      mobStyles={[
        css`
          margin: 25px 5px 0 5px;
          width: auto;
          border: 1px solid ${p => p.theme.colors.lightGray};
          border-radius: 5px;
          box-shadow: ${p => p.theme.shadow.regular};
          padding: 10px;
          justify-content: center;
        `,
      ]}
    >
      <LocationLetter letter={'N'} color={'#600f97'}/>
      <LocationLetter letter={'E'} color={'#06bc40'}/>
      <LocationLetter letter={'W'} color={'#f8b619'}/>
      <LocationLetter letter={'_'} color={'#ffffff'}/>
      <LocationLetter letter={'O'} color={'#600f97'}/>
      <LocationLetter letter={'R'} color={'#06bc40'}/>
      <LocationLetter letter={'L'} color={'#600f97'}/>
      <LocationLetter letter={'E'} color={'#f8b619'}/>
      <LocationLetter letter={'A'} color={'#06bc40'}/>
      <LocationLetter letter={'N'} color={'#600f97'}/>
      <LocationLetter letter={'S'} color={'#f8b619'}/>
      <LocationLetter letter={','} color={'#06bc40'}/>
      <LocationLetter letter={'_'} color={'#ffffff'}/>
      <LocationLetter letter={'L'} color={'#600f97'}/>
      <LocationLetter letter={'O'} color={'#f8b619'}/>
      <LocationLetter letter={'U'} color={'#06bc40'}/>
      <LocationLetter letter={'I'} color={'#600f97'}/>
      <LocationLetter letter={'S'} color={'#f8b619'}/>
      <LocationLetter letter={'I'} color={'#06bc40'}/>
      <LocationLetter letter={'A'} color={'#600f97'}/>
      <LocationLetter letter={'N'} color={'#f8b619'}/>
      <LocationLetter letter={'A'} color={'#06bc40'}/>
    </Box>
  )
}

export const LoadingSlide = () => {
  return (
    <Box
      deskStyles={[
        css`
          width: 100%;
          justify-content: center;
          align-items: center;
          flex: 1;
          min-height: 75vh;
        `,
      ]}
    >
      <Loader type="ThreeDots" color="#7c848b" height={100} width={300}/>
    </Box>
  )
}

const DontGotItSlide = () => {
  return <Text>Dont Got it!!!!</Text>
}
