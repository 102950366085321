import React from 'react'
import { Router } from '../../router/Router'

export const App = () => {
  return (
    <>
      <Router />
      <div id="modal-portal" />
    </>
  )
}
