import React, { useState } from 'react'
import { Box, Button, deskBoxVariants } from '../../shared/index'
import styled, { css } from 'styled-components'
import { Text } from '../../shared/Text'
import { Input } from '../../shared/Input'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { searchState } from '../../store'
import { Gender, SearchType } from '../../constants/enums'
import { deskInputVariants } from '../../shared/Input/deskInputVariants'
import { FilterCheckbox } from './FilterCheckbox'
import { FilterAccordion } from './FilterAccordion'
import { CityInput } from './CityInput'
import Calendar from 'react-calendar'
import { WHITE_LABEL_EXPERIENCE_ENABLED } from '../../pages/Home/Home'

const { column, row, fullWidth } = deskBoxVariants
const { deskInput } = deskInputVariants

const deskSearchFilterHeader = css`
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 30px;
  font-weight: bold;
  margin: 25px auto 10px 0;

  height: 29px;
  width: 141px;
  color: #7c848b;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
`

const deskClearFiltersText = css`
  opacity: 0.38;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  font-size: 15px;
  cursor: pointer;
  height: 29px;
  width: 100%;
`

const deskApplyFilterButton = css`
  opacity: 0.38;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
  font-size: 18px;
  cursor: pointer;
  height: 29px;
  width: 100%;
  background-color: transparent;
`

const deskHorizontalDivider = css`
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #979797;
  // opacity: 0.42;
`

/**
 * This is a user only search bar that will eventually be used to search for users to send offers to directly
 * after creating the Gig itself. Not sure when this feature will actually be added to the App. More of a
 * nicety than V1 feature
 *
 * @param props
 * @constructor
 */
export const SearchFilters = () => {
  const [search, setSearch] = useRecoilState(searchState)
  const resetSearchFilters = useResetRecoilState(searchState)

  const [rateMin, setRateMin] = useState(null)
  const [rateMax, setRateMax] = useState(null)

  const [heightMin, setHeightMin] = useState(null)
  const [heightMax, setHeightMax] = useState(null)

  const [weightMin, setWeightMin] = useState(null)
  const [weightMax, setWeightMax] = useState(null)

  /**
   * Multiply by 100 because we store money as pennies
   */
  const updateRateFilter = () => {
    if (rateMin && rateMax) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          rate: {
            min: Number(rateMin) * 100,
            max: Number(rateMax) * 100,
          },
        }
      })
    } else if (rateMax) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          rate: {
            max: Number(rateMax) * 100,
          },
        }
      })
    } else if (rateMin) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          rate: {
            min: Number(rateMin) * 100,
          },
        }
      })
    } else {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          rate: {
            min: null,
            max: null,
          },
        }
      })
    }
  }

  const updateHeightFilter = () => {
    if (heightMin && heightMax) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          height: {
            min: Number(heightMin),
            max: Number(heightMax),
          },
        }
      })
    } else if (heightMax) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          height: {
            max: Number(heightMax),
          },
        }
      })
    } else if (heightMin) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          height: {
            min: Number(heightMin),
          },
        }
      })
    } else {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          height: {
            min: null,
            max: null,
          },
        }
      })
    }
  }

  const updateWeightFilter = () => {
    if (weightMin && weightMax) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          weight: {
            min: Number(weightMin),
            max: Number(weightMax),
          },
        }
      })
    } else if (weightMax) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          weight: {
            max: Number(weightMax),
          },
        }
      })
    } else if (weightMin) {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          weight: {
            min: Number(weightMin),
          },
        }
      })
    } else {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          weight: {
            min: null,
            max: null,
          },
        }
      })
    }
  }

  const clearAllSearchFilters = () => {
    // Store type to preserve it
    const searchType = search.type
    resetSearchFilters()
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        type: searchType,
      }
    })
  }

  const modelFilters = (
    <>
      <FilterAccordion filterName={'Gender'}>
        <FilterCheckbox
          labelText={'Female'} // 10
          onChangeHandler={() => {
            setSearch(previousSearch => {
              return {
                ...previousSearch,
                gender: {
                  ...previousSearch.gender,
                  female: !search.gender.female,
                },
              }
            })
          }}
        />
        <FilterCheckbox
          labelText={'Male'} // 20
          onChangeHandler={() => {
            setSearch(previousSearch => {
              return {
                ...previousSearch,
                gender: {
                  ...previousSearch.gender,
                  male: !search.gender.male,
                },
              }
            })
          }}
        />
        <FilterCheckbox
          labelText={'Non-Binary'} // 30
          onChangeHandler={() => {
            setSearch(previousSearch => {
              return {
                ...previousSearch,
                gender: {
                  ...previousSearch.gender,
                  other: !search.gender.other,
                },
              }
            })
          }}
        />
      </FilterAccordion>
      <FilterAccordion filterName={'Rate'}>
        <Box
          deskStyles={[
            row,
            css`
              justify-content: space-around;
            `,
          ]}
        >
          <Box
            deskStyles={[
              column,
              css`
                position: relative;
                height: 100px;
                width: 100px;
                margin-top: 20px;
              `,
            ]}
          >
            <RateLabelText>Min.</RateLabelText>
            <Input
              placeholder="Any"
              deskStyles={[
                deskInput,
                css`
                  width: 100px;
                `,
              ]}
              onKeyPress={numberInputValidator}
              onChange={event => {
                setRateMin(Number(event.target.value))
              }}
            />
            <StyledInputIcon>$</StyledInputIcon>
          </Box>

          <Box
            deskStyles={[
              column,
              css`
                position: relative;
                height: 100px;
                width: 100px;
                margin-top: 20px;
              `,
            ]}
          >
            <RateLabelText>Max.</RateLabelText>
            <Input
              type="text"
              placeholder="Any"
              pattern="\d{10}"
              deskStyles={[
                deskInput,
                css`
                  width: 100px;
                `,
              ]}
              onChange={event => {
                setRateMax(Number(event.target.value))
              }}
            />
            <StyledInputIcon>$</StyledInputIcon>
          </Box>
        </Box>
        <Button
          deskStyles={[deskApplyFilterButton]}
          onClick={() => {
            updateRateFilter(setSearch, rateMin, rateMax)
          }}
        >
          APPLY
        </Button>
      </FilterAccordion>
      <FilterAccordion filterName={'Height'}>
        <Box
          deskStyles={[
            row,
            css`
              justify-content: space-around;
            `,
          ]}
        >
          <Box
            deskStyles={[
              column,
              css`
                position: relative;
                height: 100px;
                width: 130px;
                margin-top: 20px;
              `,
            ]}
          >
            <RateLabelText>Min.</RateLabelText>
            <Input
              placeholder="Any"
              deskStyles={[
                deskInput,
                css`
                  width: 130px;
                `,
              ]}
              onKeyPress={numberInputValidator}
              onChange={event => {
                setHeightMin(Number(event.target.value))
              }}
            />
            <StyledInputText>Inches</StyledInputText>
          </Box>

          <Box
            deskStyles={[
              column,
              css`
                position: relative;
                height: 100px;
                width: 130px;
                margin-top: 20px;
              `,
            ]}
          >
            <RateLabelText>Max.</RateLabelText>
            <Input
              type="text"
              placeholder="Any"
              pattern="\d{10}"
              deskStyles={[
                deskInput,
                css`
                  width: 130px;
                `,
              ]}
              onKeyPress={numberInputValidator}
              onChange={event => {
                setHeightMax(Number(event.target.value))
              }}
            />
            <StyledInputText>Inches</StyledInputText>
          </Box>
        </Box>
        <Button
          deskStyles={[deskApplyFilterButton]}
          onClick={() => {
            updateHeightFilter(setSearch, heightMin, heightMax)
          }}
        >
          APPLY
        </Button>
      </FilterAccordion>
      <FilterAccordion filterName={'Weight'}>
        <Box
          deskStyles={[
            row,
            css`
              justify-content: space-around;
            `,
          ]}
        >
          <Box
            deskStyles={[
              column,
              css`
                position: relative;
                height: 100px;
                width: 130px;
                margin-top: 20px;
              `,
            ]}
          >
            <RateLabelText>Min.</RateLabelText>
            <Input
              placeholder="Any"
              deskStyles={[
                deskInput,
                css`
                  width: 130px;
                `,
              ]}
              onKeyPress={numberInputValidator}
              onChange={event => {
                setWeightMin(Number(event.target.value))
              }}
            />
            <StyledInputText>Pounds</StyledInputText>
          </Box>

          <Box
            deskStyles={[
              column,
              css`
                position: relative;
                height: 100px;
                width: 130px;
                margin-top: 20px;
              `,
            ]}
          >
            <RateLabelText>Max.</RateLabelText>
            <Input
              type="text"
              placeholder="Any"
              pattern="\d{10}"
              deskStyles={[
                deskInput,
                css`
                  width: 130px;
                `,
              ]}
              onKeyPress={numberInputValidator}
              onChange={event => {
                setWeightMax(Number(event.target.value))
              }}
            />
            <StyledInputText>Pounds</StyledInputText>
          </Box>
        </Box>
        <Button
          deskStyles={[deskApplyFilterButton]}
          onClick={() => {
            updateWeightFilter(setSearch, weightMin, weightMax)
          }}
        >
          APPLY
        </Button>
      </FilterAccordion>
    </>
  )

  const bronnerBrosFilter = (
    <FilterAccordion filterName={'Bronner Bros.'}>
      <FilterCheckbox
        labelText={'Bronner Bros gigs Only'}
        onChangeHandler={() => {
          setSearch(previousSearch => {
            return {
              ...previousSearch,
              bronnerBrosIBS: !search.bronnerBrosIBS,
            }
          })
        }}
        checked={search.bronnerBrosIBS}
      />
    </FilterAccordion>
  )
  const fixedPriceFilter = (
    <FilterAccordion filterName={'Fixed Price'}>
      <FilterCheckbox
        labelText={'Fixed Price Gigs Only'}
        onChangeHandler={() => {
          setSearch(previousSearch => {
            return {
              ...previousSearch,
              fixedPrice: !search.fixedPrice,
            }
          })
        }}
        checked={search.fixedPrice}
      />
    </FilterAccordion>
  )

  const dateFilter = (
    <FilterAccordion filterName={'Date'}>
      <Calendar
        onChange={(dates, event) => {
          setSearch(previousSearch => {
            return {
              ...previousSearch,
              eventDate: {
                start: dates[0],
                end: dates[1],
              },
            }
          })
        }}
        selectRange={true}
        allowPartialRange={true}
        next2Label={null}
        prev2Label={null}
        className="availability-calendar search-filter-calendar"
      />
      <Box
        deskStyles={[
          fullWidth,
          css`
            align-items: flex-end;
          `,
        ]}
      >
        <Text
          deskStyles={[deskClearFiltersText]}
          onClick={() => {
            setSearch(previousSearch => {
              return {
                ...previousSearch,
                eventDate: {
                  start: null,
                  end: null,
                },
              }
            })
          }}
        >
          CLEAR DATE
        </Text>
      </Box>
    </FilterAccordion>
  )

  return (
    <Box
      deskStyles={[
        column,
        //css`flex: 1 1 auto; background-color: red; overflow-y:auto; width: 25vw; padding: 20px; 10px 0 10px;`,
        css`padding: 20px; 10px 0 10px;`,
      ]}
    >
      <Box
        deskStyles={[
          row,
          fullWidth,
          css`
            height: 75px;
          `,
        ]}
      >
        <Box>
          <Text deskStyles={[deskSearchFilterHeader]}>Filters</Text>
        </Box>
        <Box
          deskStyles={[
            fullWidth,
            css`
              align-items: flex-end;
            `,
          ]}
        >
          <Text
            deskStyles={[deskClearFiltersText]}
            onClick={clearAllSearchFilters}
          >
            CLEAR ALL
          </Text>
        </Box>
      </Box>
      <Box deskStyles={[deskHorizontalDivider]} />
      {WHITE_LABEL_EXPERIENCE_ENABLED && search.type === SearchType.gig && (
        <>{bronnerBrosFilter}</>
      )}
      <FilterAccordion filterName={'Location'}>
        <FilterCheckbox
          labelText={'Near Me'}
          onChangeHandler={() => {
            setSearch(previousSearch => {
              return {
                ...previousSearch,
                nearMe: !search.nearMe,
                givenLocation: {
                  lat: null,
                  lng: null,
                },
              }
            })
          }}
          checked={search.nearMe}
        />
        <Box
          deskStyles={css`
            margin: 10px;
          `}
        >
          <CityInput />
        </Box>
      </FilterAccordion>
      {search.type === SearchType.gig && <>{dateFilter}</>}
      {search.type === SearchType.gig && <>{fixedPriceFilter}</>}
      {search.type === SearchType.creative && (
        <>
          <FilterAccordion filterName={'Creative Type'}>
            <FilterCheckbox
              labelText={'Model'} // 20
              onChangeHandler={() => {
                setSearch(previousSearch => {
                  return {
                    ...previousSearch,
                    accountType: {
                      ...previousSearch.accountType,
                      model: !search.accountType.model,
                    },
                  }
                })
              }}
              checked={search.accountType.model}
            />
            <FilterCheckbox
              labelText={'Photographer'} // 21
              onChangeHandler={() => {
                setSearch(previousSearch => {
                  return {
                    ...previousSearch,
                    accountType: {
                      ...previousSearch.accountType,
                      photographer: !search.accountType.photographer,
                    },
                  }
                })
              }}
              checked={search.accountType.photographer}
            />
            <FilterCheckbox
              labelText={'Makeup Artist'} // 22
              onChangeHandler={() => {
                setSearch(previousSearch => {
                  return {
                    ...previousSearch,
                    accountType: {
                      ...previousSearch.accountType,
                      makeupArtist: !search.accountType.makeupArtist,
                    },
                  }
                })
              }}
              checked={search.accountType.makeupArtist}
            />
            <FilterCheckbox
              labelText={'Influencer / Content Creator'} // 23
              onChangeHandler={() => {
                setSearch(previousSearch => {
                  return {
                    ...previousSearch,
                    accountType: {
                      ...previousSearch.accountType,
                      influencer: !search.accountType.influencer,
                    },
                  }
                })
              }}
              checked={search.accountType.influencer}
            />
          </FilterAccordion>
          {search.accountType.model && <>{modelFilters}</>}
        </>
      )}
      <Box
        deskStyles={css`
          height: 25vh;
        `}
      />
    </Box>
  )
}

const numberInputValidator = event => {
  const keyCode = event.keyCode || event.which
  const keyValue = String.fromCharCode(keyCode)
  if (!/[0-9]/.test(keyValue) || event.target.value.length >= 5) {
    event.preventDefault()
  }
}

const RateLabelText = styled.label`
  height: 22px;
  width: 141px;
  color: #7c848b;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin: 10px 5px 5px 5px;
`

const StyledInputIcon = styled.i`
  font-style: normal;
  position: absolute;
  z-index: 1;
  top: 45px;
  right: 8px;
  color: #7c848b;
  font-family: 'Open Sans';
  font-size: 25px;
  font-weight: bold;
`

const StyledInputText = styled.i`
  font-style: normal;
  position: absolute;
  z-index: 1;
  top: 52px;
  right: 8px;
  color: #7c848b;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: bold;
`
