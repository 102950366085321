import React from 'react'

export const PaymentInfoPage = ({ currentUserAuth }) => {
  // const [{ cartObject } /*, dispatch*/] = () => {}
  // const cart = () => {}
  //
  // const base =
  //   100
  //
  // const taxRate = 0.125
  // const serviceRate = 0.0962
  // const subTotal = base
  // const taxes = base * taxRate
  // const serviceCharge = base * serviceRate
  // const grandTotal = subTotal + taxes + serviceCharge
  //
  // const handleSubmit = token => {
  //   const { activeRequests } = collections
  //   let serverTimestamp = firebase.firestore.Timestamp.now()
  //   const requestObject = {
  //     eventId: cart.eventId,
  //     operative: cart.operative,
  //     createdAt: serverTimestamp,
  //     status: RequestStatus.pending,
  //     duration: cart.duration,
  //     creator: currentUserAuth.uid,
  //     eventRequestType: RequestType.offer,
  //   }
  //
  //   return
  // }
  //
  // const denormalizeActiveOfferId = (requestId, operativeId) => {
  //   const { activeRequests, gigs, userInformation } = collections
  //   let serverTimestamp = firebase.firestore.Timestamp.now()
  //
  //   firebase
  //     .firestore()
  //     .runTransaction(transaction => {
  //       return transaction
  //         .get(gigs.doc(cartObject.eventId))
  //         .then(eventDocumentSnapshot => {
  //           let gig = new Gig(eventDocumentSnapshot)
  //
  //           offersMap[operativeId] = requestId
  //
  //           transaction.set(
  //             gigs.doc(cartObject.eventId),
  //             { offersMap: offersMap },
  //             { merge: true }
  //           )
  //
  //           // Add to operative -> offers
  //           transaction.update(userInformation.doc(cartObject.eventOperative), {
  //             offers: firebase.firestore.FieldValue.arrayUnion(requestId),
  //           })
  //
  //           // Add to offer creator -> myOffers
  //           transaction.update(userInformation.doc(currentUserAuth.uid), {
  //             myOffers: firebase.firestore.FieldValue.arrayUnion(requestId),
  //           })
  //
  //           // Create history collection for request
  //           transaction.set(
  //             activeRequests.doc(requestId).collection('history').doc(),
  //             {
  //               log: {
  //                 note: `${currentUserAuth.uid} created this offer.`,
  //                 timestamp: serverTimestamp,
  //               },
  //             }
  //           )
  //         })
  //     })
  //     .then(result => {
  //       return
  //     })
  //     .catch(error => {
  //       errorHandler({
  //         error,
  //         errorId: 'ERROR_DENORMALIZE_ACTIVE_OFFER_ID',
  //         message: 'Error while trying to denormalize active offerID',
  //         type: errorTypes.billing,
  //         user: currentUserAuth && currentUserAuth.uid,
  //         file: 'PaymentInfoPage.jsx',
  //       })
  //     })
  // }

  // TODO: Not being used UFN
  return null

  /*  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader}>Confirm Booking</Text>
        <Text mobStyles={mobTestText}>{JSON.stringify(cartObject)}</Text>

        <Text mobStyles={mobHorizontalRule} />

        <Text mobStyles={mobInputHeader}>INVOICE</Text>
        <InvoiceRow
          description="Base Model"
          money={base}
          lineType={InvoiceLineType.Regular}
        />
        <Text mobStyles={mobGigPaymentDuration}>2 hours @ $50/hr</Text>

        <Text mobStyles={mobHorizontalRule} />
        <InvoiceRow
          description="Subtotal"
          money={subTotal}
          lineType={InvoiceLineType.Regular}
        />
        <InvoiceRow
          description="Taxes"
          money={taxes}
          lineType={InvoiceLineType.Regular}
        />
        <InvoiceRow
          description="Service Fees"
          money={serviceCharge}
          lineType={InvoiceLineType.Regular}
        />
        <InvoiceRow
          description="Total Booking Amount"
          money={grandTotal}
          lineType={InvoiceLineType.Grand}
        />
      </Box>

      <StickyFooterWithButton
        mainButtonHandler={handleSubmit}
        mainButtonText="BOOK NOW"
        mainButtonFormId="stripe-checkout-form"
        mainButtonType="submit"
      />
    </DesktopPageWrapper>
  )*/
}
