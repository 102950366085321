import React, { useState } from 'react'
import 'firebase/storage'

import {
  Button,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { MiniAccordion } from '../../components/MiniAccordion'
import { css } from 'styled-components'
import { collections } from '../../firebaseApp'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import firebase from 'firebase/app'
import { errorHandler, errorTypes } from '../../utils/errorHandler'

const { mobSubmitButton, redButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { mobInstructionParagraph, mobInstructionHeader } = mobTextVariants

/**
 * This component will delete user's account Firebase account
 *
 * @param props
 * @constructor
 */
export const AccountDeletionModule = ({
  currentUserAuth,
  accountSettingsForm,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  const deleteAccountHandler = () => {
    auth
      .signInWithEmailAndPassword(
        currentUserAuth.email,
        accountSettingsForm.currentPassword
      )
      .then(() => {
        // Once the users presses delete with their password we can assume they are serious and delete their data.
        // We are not going to delete their gigs since they may still need to be displayed
        collections.userInformation
          .doc(currentUserAuth.uid)
          .delete()
          .then(result => {})
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_DELETING_USER_INFORMATION',
              message: `Error while deleting user: ${currentUserAuth.userId}. Message: ${error.message}`,
              type: errorTypes.deletion,
              user: currentUserAuth.userId,
              file: 'AccountDeletionModule.jsx',
            })
          })
        collections.privateUserInformation
          .doc(currentUserAuth.uid)
          .delete()
          .then(result => {})
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_DELETING_USER_INFO_PRIVATE',
              message: `Error while deleting user: ${currentUserAuth.userId}. Message: ${error.message}`,
              type: errorTypes.deletion,
              user: currentUserAuth.userId,
              file: 'AccountDeletionModule.jsx',
            })
          })
        currentUserAuth
          .delete()
          .then(result => {
            collections.deletedAccounts
              .add({
                userId: currentUserAuth.userId,
                email: currentUserAuth.email,
                date: firebase.firestore.Timestamp.now(),
              })
              .then(() => {})
              .catch(error => {
                errorHandler({
                  error,
                  errorId: 'ERROR_ADDING_DELETED_USER_TO_FS',
                  message: `Error while deleting user: ${currentUserAuth.userId}. Message: ${error.message}`,
                  type: errorTypes.deletion,
                  user: currentUserAuth.userId,
                  file: 'AccountDeletionModule.jsx',
                })
              })
          })
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_DELETING_USER',
              message: `Error while deleting user: ${currentUserAuth.userId}. Message: ${error.message}`,
              type: errorTypes.deletion,
              user: currentUserAuth.userId,
              file: 'AccountDeletionModule.jsx',
            })
          })
        // Delete Photos
        // Eventually we can make a job that checks all the photo directories against the existing ids
        // and deletes the id directories it does not find. Or look at the delete users collection and
        // find what to delete from there
        // https://googleapis.dev/nodejs/storage/latest/index.html#deleteFiles
        //return false
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_DELETING_USER_AUTH',
          message: `Error while deleting user: ${currentUserAuth.userId}. Message: ${error.message}`,
          type: errorTypes.deletion,
          user: currentUserAuth.userId,
          file: 'AccountDeletionModule.jsx',
        })
      })
  }

  return (
    <>
      <Text mobStyles={mobInstructionHeader}>
        Delete your Book A Model™ account.
      </Text>
      <Text mobStyles={mobInstructionParagraph}>
        This cannot be reversed once done. Your account will be deleted
        immediately and all other data will be deleted within 7 days.
      </Text>
      <InputWithHeader
        header="Password"
        inputName="password"
        type="password"
        onChange={accountSettingsForm.updateCurrentPassword}
        placeholderDefault="Enter Current Password"
      />
      <MiniAccordion
        closedButtonText="DELETE ACCOUNT"
        openButtonText="CANCEL"
        onClick={() => {
          setAccordionOpen(!accordionOpen)
        }}
        open={accordionOpen}
        buttonMobStyles={[
          mobSubmitButton,
          mobLong305x46,
          redButton,
          css`
            margin-top: 20px;
          `,
        ]}
        innerButtonBoxStyle={[
          css`
            width: 100%;
          `,
        ]}
      >
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            redButton,
            css`
              width: 100%;
            `,
          ]}
          onClick={deleteAccountHandler}
        >
          I'M SURE
        </Button>
      </MiniAccordion>
    </>
  )
}
