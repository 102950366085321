import React from 'react'

import { StyledMotionTableHead, StyledTableHead } from './styles'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

export const TableHead = ({ children, ...props }) => {
  if (props.enableMotion) {
    return <StyledMotionTableHead {...props}>{children}</StyledMotionTableHead>
  }

  return <StyledTableHead {...props}>{children}</StyledTableHead>
}

TableHead.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
