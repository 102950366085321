import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import get from 'lodash/get'
import { collections, db } from '../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import firebase from 'firebase/app'
import { RequestActionType, RequestStatus } from '../../../constants/enums'
import { MiniAccordion } from '../../../components/MiniAccordion'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { LoadingButton } from '../../../components/LoadingButton'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import {
  deskSevenDayReminderText,
  mobSevenDayReminderText,
} from './OperativeWithPendingOffer'

const {
  mobStatusButton,
  pendingButton,
  declineButton,
  canceledButton,
  fullOpacity,
} = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const OwnerWithPendingOffer = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const accordionState = useRecoilValue(requestActionAccordionState)
  const { activeRequests } = collections
  const declineAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.decline
  const [cancelLoading, setCancelLoading] = useState(false)

  const cancelOfferRequestHandler = () => {
    setCancelLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.canceled,
        operativeWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    batch.set(
      requestReference.collection('history').doc(),
      {
        note: `${currentUserAuth.uid} canceled this offer.`,
        timestamp: serverTimestamp,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        setCancelLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_DECLINING_OFFER',
          message: `Failed to cancel offer for user for request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OwnerWithPendingOffer.jsx',
        })
      })
  }

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  const today = new Date()
  const expirationDate = new Date(
    booking.request.paymentResponse.created * 1000
  ).addDays(7)
  const offerValid = today < expirationDate
  const dateString = new Date(booking.request.paymentResponse.created * 1000)
    .addDays(7)
    .toLocaleDateString()
  const timeString = new Date(booking.request.paymentResponse.created * 1000)
    .addDays(7)
    .toLocaleTimeString()

  // Model accepting or declining an offer
  return (
    <>
      {!offerValid && (
        <>
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Status
          </Text>
          <Button
            mobStyles={[mobStatusButton, canceledButton, fullOpacity]}
            disabled={true}
          >
            EXPIRED
          </Button>
          <Text
            mobStyles={[mobSevenDayReminderText]}
            deskStyles={[deskSevenDayReminderText]}
          >
            Your offer has expired due to not being accepted within the 7 day
            time frame. We will refund all charges associated with this offer as
            soon as possible:
            <br />
            {dateString} at {timeString}
          </Text>
        </>
      )}
      {offerValid && (
        <>
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Status
          </Text>
          <Button
            mobStyles={[mobStatusButton, pendingButton, fullOpacity]}
            disabled={true}
          >
            PENDING
          </Button>
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 20px 5px 5px 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Available Actions
          </Text>
          <Text
            mobStyles={[mobSevenDayReminderText]}
            deskStyles={[deskSevenDayReminderText]}
          >
            NOTE: This offer will automatically expire in one week.
            <br />
            {dateString} at {timeString}
          </Text>
          {cancelLoading && (
            <LoadingButton
              mobStyles={[declineButton]}
              deskStyles={[
                css`
                  width: unset;
                `,
              ]}
            />
          )}
          {!cancelLoading && (
            <MiniAccordion
              closedButtonText="CANCEL"
              openButtonText="CANCEL"
              onClick={() => {
                updateAccordionState(RequestActionType.decline)
              }}
              open={declineAccordionOpen}
              buttonMobStyles={[mobStatusButton, declineButton]}
            >
              <Button
                mobStyles={[mobStatusButton, declineButton]}
                onClick={cancelOfferRequestHandler}
              >
                CONFIRM CANCEL
              </Button>
            </MiniAccordion>
          )}
        </>
      )}
    </>
  )
}
