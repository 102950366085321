// PROD-READY-MOBILE
import React, { useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import styled, { css } from 'styled-components'

import { ChatRoom } from '../../classes/Models/ChatRoom'
import { collections } from '../../firebaseApp'
import { Link } from '../../shared/Link'
import { Box } from '../../shared/Box'
import { ChatItemDetails } from './ChatItemDetails'
import { LoadingScreen } from '../../components/LoadingScreen'

const mobChatItemBox = css`
  height: 83px;
  width: 100%;
  padding: 10px 0 10px 10px;
  align-items: center;
  box-sizing: border-box;
  margin: 10px 0 10px 0;
  padding-right: 15px;
`

/**
 * This component represents a conversation in the user's inbox
 * contains the recipients photo, last message sent and last time
 * updated
 *
 * @param chatRoom{String}
 * @returns {*}
 * @constructor
 */
export const MobileChatItem = ({
  chatRoomId,
  currentUserId,
  chatRoomsObject,
  setChatRoomsObject,
  unreadObject,
}) => {
  const [chatRoom, setChatRoom] = useState(new ChatRoom())
  const [
    chatRoomSnapshot,
    chatRoomSnapshotLoading,
    chatRoomSnapshotError,
  ] = useDocument(collections.chatRooms.doc(chatRoomId))

  useEffect(() => {
    const chatRoom = new ChatRoom(chatRoomSnapshot)
    setChatRoom(chatRoom)
    // Forgot why I did this. But it helps put the chats in order so the one
    // with the most recent is at the top.
    setChatRoomsObject({
      ...chatRoomsObject,
      [chatRoomId]: {
        lastMessageSentTime: chatRoom.lastMessageSentTime,
      },
    })
  }, [chatRoomSnapshot])

  return (
    <>
      {chatRoomSnapshotError && <strong>Error: {chatRoomSnapshotError}</strong>}
      {chatRoomSnapshotLoading && <LoadingScreen />}
      {chatRoomSnapshot && (
        <Link to={'/chat/' + chatRoom.id}>
          <Box mobStyles={[mobChatItemBox]}>
            {chatRoom.getRecipient(currentUserId) && (
              <ChatItemDetails
                chatRoom={chatRoom}
                currentUserId={currentUserId}
                unreadObject={unreadObject}
              />
            )}
          </Box>
          <HorizontalDivider />
        </Link>
      )}
    </>
  )
}

const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 2px solid #979797;
  opacity: 0.32;
`
