import React from 'react'
import { Box, Button, mobBoxVariants } from '../../shared/index'
import { css, useTheme } from 'styled-components'
import { AnimatePresence } from 'framer-motion'

const { column } = mobBoxVariants
/**
 * This mini accordion is intended to be opened and closed by Global state
 * @param open
 * @param closedButtonText
 * @param openButtonText
 * @param children
 * @param onClick
 * @param buttonMobStyles
 * @param innerButtonBoxStyle
 * @returns {*}
 * @constructor
 */
export const MiniAccordion = ({
  open,
  closedButtonText,
  openButtonText,
  children,
  onClick,
  buttonMobStyles,
  innerButtonBoxStyle,
}) => {
  const theme = useTheme()

  return (
    <>
      <Button
        mobStyles={[
          buttonMobStyles,
          css`
            margin-bottom: 25px;
          `,
        ]}
        enableMotion
        initial={false}
        animate={{
          backgroundColor: open ? theme.colors.blueGray : '',
        }}
        onClick={onClick}
      >
        {open ? openButtonText : closedButtonText}
      </Button>
      <AnimatePresence initial={false}>
        {open && (
          <Box
            enableMotion
            mobStyles={[
              column,
              css`
                margin-bottom: 25px;
              `,
            ].concat(innerButtonBoxStyle)}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </Box>
        )}
      </AnimatePresence>
    </>
  )
}
