import { css } from 'styled-components'

export const mobDetailName = css`
  height: 13px;
  line-height: 12px;
  color: ${p => p.theme.colors.gray};
  font-size: 12px;
  text-align: left;
  margin: 2px 0 0;
`

export const mobDetail = css`
  margin: 0;
  color: ${p => p.theme.colors.primary};
  font-family: 'Open Sans', serif;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  line-height: 13px;
  overflow-wrap: break-word;
`

export const mobModelDetailsContainer = css``
