import React, { useState } from 'react'
import {
  Box,
  Button,
  Image,
  mobBoxVariants,
  mobButtonVariants,
  mobImageVariants,
  Modal,
} from '../../shared/index'
import { css } from 'styled-components'
import { collections, portfolioImagesStorage } from '../../firebaseApp'
import { uuid } from '../../utils/uuid'
import 'firebase/storage'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'
import firebase from 'firebase/app'

const { column, center } = mobBoxVariants
const { mobImageSquare, mobModalImage } = mobImageVariants
const { deskImageSquare } = deskImageVariants
const { mobSubmitButton } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants

const deleteButtonStyles = css`
  background-color: ${p => p.theme.colors.red};
  width: 75%;
  margin-top: 20px;
`

export const ImageWithModal = ({
  imageDocument,
  imageSrc,
  userId,
  profile,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const deletePortfolioImageHandler = () => {
    setDeleteLoading(true)
    // Modal must be closed before deletion or it will lock the scroll on the document body
    setModalOpen(false)
    const { userInformation } = collections

    userInformation
      .doc(userId)
      .collection('portfolioImages')
      .doc(imageDocument.id)
      .set(
        {
          deleted: true,
          deletedAt: firebase.firestore.Timestamp.now(),
        },
        { merge: true }
      )
      .then(() => {
        // Only delete the actual image if we successfully delete the reference to the image from the
        // user's information
        portfolioImagesStorage
          .ref()
          .child(
            `${userId}/user-portfolio-image-cropped-${
              imageDocument.data().uniqueId
            }`
          )
          .delete()
          .then(() => {
            // File deleted successfully
          })
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_DELETING_CROPPED_PORTFOLIO_IMAGE',
              message:
                'Error occurred while user trying to delete cropped image.',
              type: errorTypes.profile,
              userId,
              file: 'ImageWithModal.jsx',
            })
          })

        portfolioImagesStorage
          .ref()
          .child(
            `${userId}/user-portfolio-image-org-${
              imageDocument.data().uniqueId
            }`
          )
          .delete()
          .then(() => {
            // File deleted successfully
          })
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_DELETING_ORG_PORTFOLIO_IMAGE',
              message: 'Error occurred while user trying to delete org image.',
              type: errorTypes.profile,
              userId,
              file: 'ImageWithModal.jsx',
            })
          })
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_RETRIEVING_USER_PORTFOLIO_IMAGES',
          message:
            'There was an error while trying to retrieve user portfolio images.',
          type: errorTypes.profile,
          userId,
          file: 'ImageWithModal.jsx',
        })
      })
  }

  const deleteProfileImageHandler = () => {
    setDeleteLoading(true)
    const { userInformation } = collections
    // Modal must be closed before deletion or it will lock the scroll on the document body
    setModalOpen(false)

    // We do not have to actually delete the file because the next time they add a new image
    // it will overwrite the previous
    userInformation.doc(userId).update({
      'profileImage.url': null,
      'profileImage.croppedUrl': null,
      'profileImage.defaultImage': true,
    })
  }

  return (
    <>
      <Image
        key={uuid()}
        enableUseAsBackground={true}
        src={profile ? imageSrc : imageDocument.data().croppedUrl}
        mobStyles={[mobImageSquare]}
        deskStyles={[deskImageSquare]}
        onClick={() => setModalOpen(!modalOpen)}
      />
      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => {
          setModalOpen(false)
        }}
      >
        <Box
          mobStyles={[
            column,
            center,
            css`
              width: 100%;
            `,
          ]}
        >
          <Image
            src={profile ? imageSrc : imageDocument.data().url}
            mobStyles={mobModalImage}
            onClick={() => setModalOpen(!modalOpen)}
          />
          {!deleteLoading && (
            <Button
              mobStyles={[mobSubmitButton, deleteButtonStyles]}
              deskStyles={deskSubmitButton}
              onClick={
                profile
                  ? deleteProfileImageHandler
                  : deletePortfolioImageHandler
              }
            >
              DELETE PHOTO
            </Button>
          )}
        </Box>
      </Modal>
    </>
  )
}
