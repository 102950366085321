import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import get from 'lodash/get'
import { collections, db } from '../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import firebase from 'firebase/app'
import {
  RequestActionType,
  RequestStatus,
  SupportTicketStatus,
  SupportTicketType,
} from '../../../constants/enums'
import { MiniAccordion } from '../../../components/MiniAccordion'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { LoadingButton } from '../../../components/LoadingButton'
import { TextAreaWithHeader } from '../../../components/Inputs/TextAreaWithHeader'
import {
  disputeCreation,
  ownerOfferWithdrawal,
} from '../../../utils/notificationHandlers'

const {
  mobStatusButton,
  acceptButton,
  ongoingButton,
  declineButton,
  disputeButton,
  fullOpacity,
} = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const OwnerWithAcceptedOffer = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const accordionState = useRecoilValue(requestActionAccordionState)
  const disputeAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.dispute
  const releaseAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.releaseFunds
  const withdrawAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.withdraw

  const { activeRequests } = collections
  const [releaseLoading, setReleaseLoading] = useState(false)
  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [disputeLoading, setDisputeLoading] = useState(false)
  const [disputeText, setDisputeText] = useState('')

  // TraceProposalRelease:
  // TraceOfferRelease:
  // This should be the last step for a best case scenario offer and proposal.
  // This function will trigger a payment to the event operative equal to stripeOperativePay
  // which is defined on the event request.
  const releaseRequestFundsHandler = async () => {
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)
    setReleaseLoading(true)

    batch.set(
      requestReference,
      {
        status: RequestStatus.awaitingBAM,
        agencyWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    // After the withdrawal is successful create a ticket to refund the Agency
    batch.set(
      collections.moneyRequests.doc(),
      {
        requestId: booking.request.requestId,
        releasedAt: serverTimestamp,
        createdBy: booking.request.eventCreator,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(result => {
        // ownerOfferWithdrawal(
        //   currentUserAuth.uid,
        //   booking.request.operative
        // )
        setReleaseLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_WITHDRAWING_FROM_OFFER',
          message: `Failed to withdraw user from request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OwnerWithAcceptedOffer.jsx',
        })
      })
  }

  const withdrawOfferAcceptanceHandler = () => {
    setWithdrawLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.canceled,
        agencyWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    batch.set(
      requestReference.collection('history').doc(),
      {
        note: `${currentUserAuth.uid} withdrew from this offer. ${booking.gig.createdBy} needs to be refunded`,
        timestamp: serverTimestamp,
      },
      { merge: true }
    )

    // After the withdrawal is successful create a ticket to refund the Agency
    batch.set(
      collections.supportTickets.doc(),
      {
        createdBy: booking.request.eventCreator,
        supportRequest: `Refund for gig ${requestId}`,
        requestType: SupportTicketType.refund,
        status: SupportTicketStatus.received,
        additionalInfo: '',
        createdAt: firebase.firestore.Timestamp.now(),
      },
      { merge: true }
    )

    batch
      .commit()
      .then(result => {
        ownerOfferWithdrawal(currentUserAuth.uid, booking.request.operative)
        setWithdrawLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_WITHDRAWING_FROM_OFFER',
          message: `Failed to withdraw user from request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OwnerWithAcceptedOffer.jsx',
        })
      })
  }

  const handleDisputeCreation = () => {
    setDisputeLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()

    collections.supportTickets
      .add({
        createdBy: currentUserAuth.uid,
        supportRequest: disputeText,
        requestType: SupportTicketType.dispute,
        status: SupportTicketStatus.received,
        createdAt: serverTimestamp,
        relatedUser: booking.request.getUserB(currentUserAuth.uid),
      })
      .then(supportTicketRef => {
        const batch = db.batch()
        const requestReference = activeRequests.doc(requestId)
        batch.set(
          requestReference,
          {
            status: RequestStatus.disputed,
            disputedAt: serverTimestamp,
            disputedBy: currentUserAuth.uid,
            supportTicketId: supportTicketRef.id,
          },
          { merge: true }
        )

        batch
          .commit()
          .then(() => {
            disputeCreation(currentUserAuth.uid, booking.request.operative)
            setDisputeLoading(false)
          })
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_USER_DISPUTING',
              message: `Failed to dispute request id: ${requestId}`,
              type: errorTypes.dispute,
              user: currentUserAuth && currentUserAuth.uid,
              file: 'CompletedRequest.jsx',
            })
          })
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_DISPUTE_CREATION',
          message:
            'Error while user trying to create a SUPPORT Dispute request.',
          type: errorTypes.support,
          file: 'CompletedRequest.jsx',
        })
      })
  }

  // Model has accepted and has the option to withdraw from offer
  return (
    <>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, acceptButton, fullOpacity]}
        disabled={true}
      >
        ACCEPTED
      </Button>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 20px 5px 5px 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Available Actions
      </Text>
      {releaseLoading && !withdrawLoading && !disputeLoading && (
        <LoadingButton
          mobStyles={[ongoingButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!disputeLoading && !withdrawLoading && !releaseLoading && (
        <MiniAccordion
          closedButtonText="MARK GIG COMPLETED"
          openButtonText="CANCEL"
          onClick={() => {
            updateAccordionState(RequestActionType.releaseFunds)
          }}
          open={releaseAccordionOpen}
          buttonMobStyles={[mobStatusButton, ongoingButton]}
        >
          <Button
            mobStyles={[mobStatusButton, ongoingButton]}
            onClick={releaseRequestFundsHandler}
          >
            CONFIRM COMPLETED
          </Button>
        </MiniAccordion>
      )}
      {withdrawLoading && !releaseLoading && !disputeLoading && (
        <LoadingButton
          mobStyles={[declineButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!disputeLoading && !withdrawLoading && !releaseLoading && (
        <MiniAccordion
          closedButtonText="WITHDRAW"
          openButtonText="CANCEL"
          onClick={() => {
            updateAccordionState(RequestActionType.withdraw)
          }}
          open={withdrawAccordionOpen}
          buttonMobStyles={[mobStatusButton, declineButton]}
        >
          <Button
            mobStyles={[mobStatusButton, declineButton]}
            onClick={withdrawOfferAcceptanceHandler}
          >
            CONFIRM WITHDRAW
          </Button>
        </MiniAccordion>
      )}
      {disputeLoading && !withdrawLoading && !releaseLoading && (
        <LoadingButton
          mobStyles={[disputeButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!disputeLoading && !withdrawLoading && !releaseLoading && (
        <MiniAccordion
          closedButtonText="DISPUTE"
          openButtonText="CLOSE"
          onClick={() => {
            updateAccordionState(RequestActionType.dispute)
          }}
          open={disputeAccordionOpen}
          buttonMobStyles={[mobStatusButton, disputeButton]}
        >
          <TextAreaWithHeader
            header="DISPUTE"
            inputName="dispute"
            onChange={event => {
              setDisputeText(event.target.value)
            }}
            value={disputeText}
            placeholderDefault="Tell us what happened."
          />
          <Button
            mobStyles={[mobStatusButton, disputeButton]}
            onClick={handleDisputeCreation}
            disabled={disputeText.length < 50}
          >
            SUBMIT DISPUTE
          </Button>
        </MiniAccordion>
      )}
    </>
  )
}
