import React, { useState } from 'react'
import 'firebase/storage'
import firebase from 'firebase/app'

import { collections } from '../../firebaseApp'
import { PrivateUser, User } from '../../classes'
import { Toggle } from '../../components/Toggle'
import { Select } from '../../shared/Select'
import { inputVariants } from '../../shared/Input'
import { AccountType, IdentityVerificationStatus } from '../../constants/enums'
import {
  Box,
  Button,
  Link,
  mobBoxVariants,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { ROUTES } from '../../constants/routes'
import { css } from 'styled-components'

import { convertSeconds } from '../../utils/convertFirestoreTimestamp'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import ProfileImagePlaceholder from '../../assets/photos/profile_image_placeholder.png'
import { IconContext } from 'react-icons'
import { FaCheck, FaClock, FaTimes } from 'react-icons/fa'
import { theme } from '../../theme'
import { hasAtLeastOneService } from '../CashServiceModule'

const { mobSelectInput } = inputVariants
const {
  mobInstructionParagraph,
  mobInstructionHeader,
  mobHorizontalRule,
} = mobTextVariants
const { mobInTextLink } = mobLinkVariants
const { mobSubmitButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { deskSubmitButton } = deskTextVariants
const { row, fullWidth } = mobBoxVariants

export const mobTaskText = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0 0 0;
  text-align: left;
`

export const accountSelectOptions = [
  { value: AccountType.guest, label: 'Not Set' },
  { value: AccountType.model, label: 'Model' },
  { value: AccountType.influencer, label: 'Influencer / Content Creator' },
  { value: AccountType.photographer, label: 'Photographer' },
  { value: AccountType.makeupArtist, label: 'Makeup Artist' },
  { value: AccountType.agency, label: 'Agency' },
]
/**
 * Module to hold all general app settings that don't fall under another group
 *
 * List of setting it currently holds
 *  1. Search visibility
 *  2. FIXME: Add new functionality to the list, and move this comment down!!!
 *
 *
 * @param {User} user
 * @param privateUserSnapshot
 * @param userAccountType
 * @returns {*}
 * @constructor
 */
export const GeneralSettingsModule = ({
  user,
  privateUserSnapshot,
  userAccountType,
}) => {
  const [accountType, setAccountType] = useState(user.accountType)
  const privateUser = new PrivateUser(privateUserSnapshot)

  const handleVisibleInSearchChange = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)

          if (transactionUser.accountType === AccountType.model) {
            if (isModelProfileCompleted(transactionUser)) {
              transaction.update(userInformation.doc(user.userId), {
                visibleInSearch: !transactionUser.isVisbleInSearch(),
              })
            } else {
              return
            }
          } else if (
            transactionUser.accountType === AccountType.makeupArtist ||
            transactionUser.accountType === AccountType.photographer ||
            transactionUser.accountType === AccountType.influencer
          ) {
            if (isCreativeProfileCompleted(transactionUser)) {
              transaction.update(userInformation.doc(user.userId), {
                visibleInSearch: !transactionUser.isVisbleInSearch(),
              })
            }
          } else if (transactionUser.accountType === AccountType.agency) {
            if (isAgencyProfileCompleted(transactionUser)) {
              transaction.update(userInformation.doc(user.userId), {
                visibleInSearch: !transactionUser.isVisbleInSearch(),
              })
            }
          }
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_VISIBLE_IN_SEARCH_OPTION',
            message: 'Error while updating visible in search option.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        })
    })
  }

  /**
   * If a user updates their account type see if their account is complete enough to show in search
   *
   */
  const reassessVisibleInSearch = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)

          if (transactionUser.accountType === AccountType.model) {
            if (isModelProfileCompleted(transactionUser)) {
              // No change
            } else {
              transaction.update(userInformation.doc(user.userId), {
                visibleInSearch: false,
              })
            }
          } else if (
            transactionUser.accountType === AccountType.makeupArtist ||
            transactionUser.accountType === AccountType.photographer ||
            transactionUser.accountType === AccountType.influencer
          ) {
            if (isCreativeProfileCompleted(transactionUser)) {
              return
            } else {
              transaction.update(userInformation.doc(user.userId), {
                visibleInSearch: false,
              })
            }
          } else if (transactionUser.accountType === AccountType.agency) {
            if (isAgencyProfileCompleted(transactionUser)) {
              return
            } else {
              transaction.update(userInformation.doc(user.userId), {
                visibleInSearch: false,
              })
            }
          }
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_VISIBLE_IN_SEARCH_OPTION',
            message: 'Error while updating visible in search option.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        })
    })
  }

  /**
   * To begin accepting booking requests the user has to have:
   * 1. Full profile
   * 2. Stripe Account setup
   * 3. Identity verified
   */
  const handleAcceptingBookingRequestsChange = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)
          // transaction.update(userInformation.doc(user.userId), {
          //   acceptingBookingRequests: !transactionUser.isAcceptingBookingRequests(),
          // })

          if (transactionUser.accountType === AccountType.model) {
            if (isModelBookingReady(transactionUser)) {
              transaction.update(userInformation.doc(user.userId), {
                acceptingBookingRequests: !transactionUser.isAcceptingBookingRequests(),
              })
            } else {
            }
          } else if (
            transactionUser.accountType === AccountType.makeupArtist ||
            transactionUser.accountType === AccountType.photographer ||
            transactionUser.accountType === AccountType.influencer
          ) {
            if (isCreativeBookingReady(transactionUser, privateUser)) {
              transaction.update(userInformation.doc(user.userId), {
                acceptingBookingRequests: !transactionUser.isAcceptingBookingRequests(),
              })
            }
          }
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ACCEPTING_BOOKING_REQUESTS_OPTION',
            message: 'Error while updating accepting booking requests option.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        })
    })
  }

  const handleAccountTypeChange = () => {
    const { userInformation } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()

    // Dont let users set their account type to guest
    if (accountType === AccountType.guest) {
      return
    }

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          transaction.update(userInformation.doc(user.userId), {
            accountType: Number(accountType),
            accountTypeLastUpdated: serverTimestamp,
          })
        })
        .then(result => {
          return reassessVisibleInSearch()
        })
        .catch(error =>
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ACCOUNT_TYPE_CHANGE',
            message: 'Error while updating account type.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        )
    })
  }

  const UpdateAccountType = () => {
    return (
      <>
        <StepStatusRow
          stepText={'Account Type Selected'}
          icon={user.hasNoAccountType() ? NotCompletedIcon : CompletedIcon}
        />
        <Text mobStyles={mobInstructionParagraph}>
          Change your account type. Your account type may be changed once a
          week. Please file a{' '}
          <Link
            mobStyles={[mobInstructionParagraph, mobInTextLink]}
            deskStyles={[
              mobInstructionParagraph,
              mobInTextLink,
              css`
                font-size: 18px;
              `,
            ]}
            to={ROUTES.SUPPORT.link}
          >
            support
          </Link>{' '}
          ticket for exceptions.
          <br />
          {user.updatedAccountInLastWeek() &&
            `Disabled until: ${convertSeconds(
              user.accountTypeLastUpdated.seconds + 604800
            )}`}
        </Text>
        <Select
          mobStyles={[mobSelectInput]}
          name="accountType"
          onChange={event => {
            setAccountType(Number(event.target.value))
          }}
          value={accountType}
          disabled={user.updatedAccountInLastWeek()}
          options={accountSelectOptions}
          data-testid="account-type-select"
        />
        <Button
          disabled={user.updatedAccountInLastWeek()}
          mobStyles={[mobSubmitButton, mobLong305x46]}
          deskStyles={deskSubmitButton}
          onClick={handleAccountTypeChange}
        >
          UPDATE ACCOUNT TYPE
        </Button>
      </>
    )
  }
  const SearchToggle = () => {
    return (
      <Toggle
        label="Search Visibility. For when you want to disappear. To enable this you must have a complete profile. (i.e. profile photo and details)"
        onChangeHandler={handleVisibleInSearchChange}
        checked={user.isVisbleInSearch()}
      />
    )
  }

  if (user.isAgency()) {
    return (
      <>
        <Text mobStyles={mobInstructionHeader}>
          Controls to change various settings within the application.
        </Text>
        <Text mobStyles={[mobHorizontalRule]} />
        <Text mobStyles={mobInstructionHeader}>
          To book a model you must have a Stripe customer created, your identity
          verified, and a completed profile. Stripe and Identity verification
          can be completed below. Please use the menu to navigate to the update
          profile page.
        </Text>
        <StepStatusRow
          stepText={'Profile Completed'}
          icon={getProfileCompletenessIcon(user, privateUser)}
        />
        <StepStatusRow
          stepText={'Identity Verified'}
          icon={getIdentityIcon(privateUser)}
        />
        <StepStatusRow
          stepText={'Stripe Customer Created'}
          icon={
            privateUser.hasStripeCustomer ? CompletedIcon : NotCompletedIcon
          }
        />
        <Text mobStyles={[mobHorizontalRule]} />
        <SearchToggle />
        <Text mobStyles={[mobHorizontalRule]} />
        <UpdateAccountType />
      </>
    )
  } else {
    return (
      <>
        <Text mobStyles={mobInstructionHeader}>
          Controls to change various settings within the application.
        </Text>
        <Text mobStyles={[mobHorizontalRule]} />
        <StepStatusRow
          stepText={'Profile Completed'}
          icon={getProfileCompletenessIcon(user, privateUser)}
        />
        <StepStatusRow
          stepText={'Cash Service Added'}
          icon={getCashServiceCompletenessIcon(user)}
        />
        <Text mobStyles={[mobHorizontalRule]} />
        <SearchToggle />
        <Toggle
          label="Accepting booking requests. To enable this you must have a completed profile, add a Cash Service."
          onChangeHandler={handleAcceptingBookingRequestsChange}
          checked={user.isAcceptingBookingRequests()}
        />
        <Text mobStyles={[mobHorizontalRule]} />
        <UpdateAccountType />
      </>
    )
  }
}

export const StepStatusRow = ({ stepText, icon }) => {
  return (
    <Box mobStyles={[row, fullWidth]}>
      <Text mobStyles={mobTaskText}>{stepText}</Text>
      <label style={{ marginTop: '5px' }}>{icon}</label>
    </Box>
  )
}

/**
 *
 * @param {User} user
 */
const isModelProfileCompleted = user => {
  return (
    ProfileImagePlaceholder !== user.originalProfileImage &&
    user.fullName !== '' &&
    user.fullName !== 'Book A. Model' &&
    user.height !== 0 &&
    user.weight !== 0 &&
    user.hourlyRate !== 0 &&
    user.gender !== 'N/A'
  )
}

/**
 *
 * @param {User} user
 */
export const isCreativeProfileCompleted = user => {
  return (
    user.fullName !== '' &&
    user.fullName !== 'Book A. Model' &&
    ProfileImagePlaceholder !== user.originalProfileImage &&
    user.hourlyRate !== 0
  )
}

export const isAgencyProfileCompleted = user => {
  return (
    user.fullName !== '' &&
    user.fullName !== 'Book A. Model' &&
    ProfileImagePlaceholder !== user.originalProfileImage &&
    user.representative !== ''
  )
}

export const isModelBookingReady = user => {
  return (
    isModelProfileCompleted(user) && hasAtLeastOneService(user.moneyServices)
  )
}

const isCreativeBookingReady = user => {
  return (
    isCreativeProfileCompleted(user) && hasAtLeastOneService(user.moneyServices)
  )
}

const isAgencyBookingReady = (user, privateUser) => {
  return (
    isAgencyProfileCompleted(user) &&
    privateUser.hasStripeCustomer &&
    (privateUser.identityVerificationStatus ===
      IdentityVerificationStatus.complete ||
      privateUser.identityVerified)
  )
}

const getCashServiceCompletenessIcon = (user) => {
  if(hasAtLeastOneService(user.moneyServices)) {
    return CompletedIcon
  } else {
    return NotCompletedIcon
  }
}

const getProfileCompletenessIcon = (user, privateUser) => {
  if (user.isModel()) {
    if (isModelProfileCompleted(user)) {
      return CompletedIcon
    } else {
      return NotCompletedIcon
    }
  } else if (
    user.isMakeupArtist() ||
    user.isPhotographer() ||
    user.isInfluencer()
  ) {
    if (isCreativeProfileCompleted(user)) {
      return CompletedIcon
    } else {
      return NotCompletedIcon
    }
  } else if (user.isAgency()) {
    if (isAgencyProfileCompleted(user)) {
      return CompletedIcon
    } else {
      return NotCompletedIcon
    }
  } else {
    return NotCompletedIcon
  }
}

const getIdentityIcon = privateUser => {
  if (privateUser.identityVerified) {
    return CompletedIcon
  }

  switch (privateUser.identityVerificationStatus) {
    case IdentityVerificationStatus.processing:
      return WaitingIcon
    case IdentityVerificationStatus.complete:
      return CompletedIcon
    case IdentityVerificationStatus.denied:
    case IdentityVerificationStatus.notStarted:
    default:
      return NotCompletedIcon
  }
}

export const NotCompletedIcon = (
  <IconContext.Provider
    value={{
      color: theme.colors.red,
      size: '1.5em',
    }}
  >
    <FaTimes />
  </IconContext.Provider>
)

export const CompletedIcon = (
  <IconContext.Provider
    value={{
      color: theme.colors.green,
      size: '1.5em',
    }}
  >
    <FaCheck />
  </IconContext.Provider>
)

export const WaitingIcon = (
  <IconContext.Provider
    value={{
      color: theme.colors.yellow,
      size: '1.5em',
    }}
  >
    <FaClock />
  </IconContext.Provider>
)
