import { Box } from '../../shared/Box'
import { css } from 'styled-components'
import { Checkbox } from '../../shared/Checkbox'
import { Text } from '../../shared/Text'
import React from 'react'

export const FilterCheckbox = ({ onChangeHandler, labelText, checked }) => {
  return (
    <Box
      deskStyles={css`
        margin: 10px;
      `}
    >
      <Checkbox
        deskStyles={[
          css`
            width: 25px;
            height: 25px;
            min-width: 25px;
            min-height: 25px;
          `,
        ]}
        onChange={onChangeHandler}
        checked={checked}
      />
      <Text
        deskStyles={[
          css`
            align-self: center;
            margin: 0;
            padding-left: 10px;
            color: #9b9b9b;
            font-family: 'Open Sans';
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
            width: auto;
          `,
        ]}
      >
        {labelText}
      </Text>
    </Box>
  )
}
