import { css } from 'styled-components'

export const optionVariants = {
  darkGreyBackground: css`
    // this is just an example
    background-color: ${p => p.theme.colors.darkGray};
  `,
  authInputMobile: css`
    height: 48px;
    width: 309px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
  `,
}
