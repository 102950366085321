import { Box, mobBoxVariants } from '../../shared/Box'
import { Button, mobButtonVariants } from '../../shared/Button'
import React from 'react'
import { AccountType } from '../../constants/enums'
import { deskButtonVariants } from '../../shared/Button/deskButtonVariants'
import { PrivateUser, User } from '../../classes'
import { useUserAccountType } from '../../utils/useUserAccountType'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { Toggle } from '../../components/Toggle'
import { collections } from '../../firebaseApp'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import 'firebase/storage'
import firebase from 'firebase/app'
import { css } from 'styled-components'
import { createChatRoom } from '../../utils/createChatroom'

const { center, mobBoxSpacer, column } = mobBoxVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskButtonVariants

/**
 *
 * @param {User} user
 * @param {PrivateUser} privateUser
 * @param updateBookingCart
 * @param currentUserAuth
 * @returns {*}
 * @constructor
 */
export const AdminSection = ({ user, currentUserAuth }) => {
  const userAccountType = useUserAccountType(currentUserAuth)
  const history = useHistory()

  if (
    !currentUserAuth ||
    (userAccountType !== AccountType.admin &&
      userAccountType !== AccountType.developer)
  ) {
    return null
  }

  const createChat = async () => {
    await createChatRoom([currentUserAuth.uid, user.userId])
    history.push(ROUTES.INBOX.link)
  }

  const handleVisibleInSearchChange = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)

          transaction.update(userInformation.doc(user.userId), {
            visibleInSearch: !transactionUser.isVisbleInSearch(),
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ADMIN_VISIBLE_IN_SEARCH_OPTION',
            message: 'Error while updating visible in search option ADMIN.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'AdminSection.jsx',
          })
        })
    })
  }

  return (
    <Box mobStyles={[column, center, mobBoxSpacer]}>
      <Button
        enableMotion
        whileHover={{ scale: 1.15 }}
        whileTap={{ scale: 0.9 }}
        mobStyles={[mobSubmitButton, mobLong305x46]}
        deskStyles={deskSubmitButton}
        onClick={createChat}
      >
        MESSAGE
      </Button>
      <Box
        mobStyles={[
          css`
            width: 50vw;
          `,
        ]}
      >
        <Toggle
          label="Hide this user from search"
          onChangeHandler={handleVisibleInSearchChange}
          checked={user.isVisbleInSearch()}
        />
      </Box>
    </Box>
  )
}
