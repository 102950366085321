import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useRecoilValue } from 'recoil'

import { collections, functions } from '../../firebaseApp'
import { uuid } from '../../utils/uuid'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/index'
import { Booking, Gig, PrivateUser, User } from '../../classes'
import { RequestStatus, RequestType } from '../../constants/enums'
import { CashModule } from '../../modules/CashModule'
import {
  StickyFooter,
  StickyFooterWithButton,
} from '../../components/StickyFooter'
import { ProfileAlignment, ProfileModule } from '../../modules/ProfileModule'
import { GigModule } from '../../modules/GigModule'
import { LoadingScreen } from '../../components/LoadingScreen'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { bookingCartState } from '../../store'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { LoadingButton } from '../../components/LoadingButton'
import { createChatRoom } from '../../utils/createChatroom'
import { newOfferReceived } from '../../utils/notificationHandlers'

const { column, mobWrapper } = mobBoxVariants
const {
  mobPageHeader,
  mobInstructionParagraph,
  mobTestText,
  mobHorizontalRule,
  mobPageSectionHeader,
} = mobTextVariants
const {
  deskPageHeader,
  deskInstructionParagraph,
  deskPageSectionHeader,
} = deskTextVariants

const docOptions = {
  idField: 'id',
}
/**
 * Confirm Booking Page is where active request get created from the gig creators perspective.
 * It also is where we send the request to authorize the credit card charge.
 *
 * Be careful when using `privateOperative` since this info does not belong to the user viewing this page
 * @param props
 * @constructor
 */
export const ConfirmBookingDetailsPage = ({ history, currentUserAuth }) => {
  const bookingCart = useRecoilValue(bookingCartState)
  const createPaymentIntent = functions.httpsCallable('createPaymentIntent')
  const [showLoading, setShowLoading] = useState(false)

  if (bookingCart.operative === '' || bookingCart.gigId === '') {
    history.push('/search')
  }

  const [privateCurrentUser, setPrivateCurrentUser] = useState(
    new PrivateUser()
  )
  const [booking, setBooking] = useState(new Booking())

  const [userData, userDataLoading, userDataError] = useDocumentData(
    collections.userInformation.doc(bookingCart.operative),
    docOptions
  )
  const [gigData, gigDataLoading, gigDataError] = useDocumentData(
    collections.gigs.doc(bookingCart.gigId),
    docOptions
  )
  const [
    privateUserData,
    privateUserDataLoading,
    privateUserDataError,
  ] = useDocumentData(
    collections.privateUserInformation.doc(currentUserAuth.uid),
    docOptions
  )
  
  useEffect(() => {
    // Whenever you create a Booking, the User object should be the user being booked aka operative
    setBooking(new Booking(new Gig(gigData), new User(userData)))
    setPrivateCurrentUser(new PrivateUser(privateUserData))
  }, [userData, gigData, privateUserData])

  const handleSubmit = token => {
    setShowLoading(true)
    const { activeRequests } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()

    // TraceOfferStep4: After details about the offer have been collected on previous pages we create
    // the offer in the database with all the information below
    activeRequests
      .add({
        // These get added at the same time for offers and proposals
        eventId: bookingCart.gigId,
        gigId: bookingCart.gigId,
        operative: bookingCart.operative,
        createdAt: serverTimestamp,
        status: RequestStatus.pending,
        duration: booking.gig.duration,
        operativeRate: bookingCart.operativeRate,
        fixedPriceGig: booking.gig.fixedPriceGig,
        fixedPrice: booking.gig.fixedPricePennies,
        requestCreator: currentUserAuth.uid,
        eventCreator: currentUserAuth.uid,
        requestType: RequestType.offer,
        eventDocument: gigData,
        // END FIELDS THAT SHOULD BE THE SAME AS PROPOSAL
        // FIELDS BELOW ARE UNIQUE TO OFFER CREATION
        // TODO: Create a cloud function that'll update the request if the gig is updated
        // Set this in the same function that will create the payment intent
        stripeOperativePay: booking.getOperativePayPennies(),
        stripeGrandTotal: booking.getStripeGrandTotal(),
        // Customers PAY us
        ownerStripeCustomerId: privateCurrentUser.stripeCustomerId,
      })
      .then(async requestDocument => {
        // TraceOfferStep5: The Stripe payment intent is made. See cloud function to see the rest.
        // In short, it authorizes their card for payment
        try {
          await createPaymentIntent({
            idempotencyKey: uuid(),
            amount: booking.getStripeGrandTotal(),
            requestId: requestDocument.id,
            operativeId: bookingCart.operative,
            eventId: bookingCart.eventId,
            eventCreator: currentUserAuth.uid,
            ownerStripeCustomerId: privateCurrentUser.stripeCustomerId,
          })
          await createChatRoom([currentUserAuth.uid, bookingCart.operative])
          await newOfferReceived(bookingCart.operative)
          // TraceOfferStep7: The request creation and stripe intent succeeded redirect to new offer!
          history.push(`/offer/${requestDocument.id}`)
        } catch (error) {
          errorHandler({
            error,
            message: 'Error while creating stripe payment intent',
            type: errorTypes.billing,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'ConfirmBookingDetailsPage.jsx',
          })
        }
      })
      .catch(error => {
        errorHandler({
          error,
          message: 'Error while retrieving active requests from stripe',
          type: errorTypes.billing,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'ConfirmBookingDetailsPage.jsx',
        })
      })
  }

  const dependenciesLoading =
    userDataLoading ||
    gigDataLoading ||
    privateUserDataLoading

  const dependenciesError =
    userDataError ||
    gigDataError ||
    privateUserDataError

  const dependencies =
    userData && gigData && privateCurrentUser

  return (
    <DesktopPageWrapper>
      {dependenciesError && <strong>Error: {dependenciesError}</strong>}
      {dependenciesLoading && <LoadingScreen />}
      {dependencies && (
        <>
          <Box mobStyles={[column, mobWrapper]}>
            <Text mobStyles={mobPageHeader} deskStyles={[deskPageHeader]}>
              Confirm Booking
            </Text>
            <Text
              mobStyles={mobInstructionParagraph}
              deskStyles={[deskInstructionParagraph]}
            >
              Ensure the follow details are correct before confirming this
              booking.
            </Text>

            <Text
              mobStyles={[mobPageSectionHeader]}
              deskStyles={deskPageSectionHeader}
            >
              MODEL DETAILS
            </Text>
            <ProfileModule
              user={booking.operative}
              profileAlignment={ProfileAlignment.left}
            />
            <Text mobStyles={mobHorizontalRule} />

            <Text
              mobStyles={[mobPageSectionHeader]}
              deskStyles={deskPageSectionHeader}
            >
              GIG DETAILS
            </Text>
            <GigModule gig={booking.gig} />
            <Text mobStyles={mobHorizontalRule} />

            <Text
              mobStyles={[mobPageSectionHeader]}
              deskStyles={deskPageSectionHeader}
            >
              TRANSACTION DETAILS
            </Text>
            <CashModule
              booking={booking}
              creatingBooking={true}
              currentUserAuth={currentUserAuth}
            />
          </Box>
          {showLoading && (
            <StickyFooter>
              <LoadingButton />
            </StickyFooter>
          )}
          {!showLoading && (
            <StickyFooterWithButton
              mainButtonHandler={handleSubmit}
              mainButtonText="CONFIRM BOOKING PAYMENT"
            />
          )}
        </>
      )}
    </DesktopPageWrapper>
  )
}
