import React, { useEffect } from 'react'
import VerifyButton from '@passbase/button/react'
import { css, useTheme } from 'styled-components'

import {
  Box,
  Link,
  mobBoxVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { PrivateUser } from '../../classes'
import { AccountType, IdentityVerificationStatus } from '../../constants/enums'
import { ROUTES } from '../../constants/routes'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import {
  CompletedIcon,
  isAgencyProfileCompleted,
  isCreativeProfileCompleted,
  mobTaskText,
  NotCompletedIcon,
  WaitingIcon,
} from '../GeneralSettingsModule'
import firebase from 'firebase/app'
import { collections } from '../../firebaseApp'
import { MessageBox } from '../../components/MessageBox'

const { mobInstructionParagraph, mobInstructionHeader } = mobTextVariants
const { mobInTextLink } = mobLinkVariants
const { row } = mobBoxVariants

export const VerifyIdentityModule = ({
  currentUserAuth,
  privateUserSnapshot,
  user,
}) => {
  const privateUser = new PrivateUser(privateUserSnapshot)
  const theme = useTheme()
  let prefillInformation = {
    country: 'us', // Make language English automatically
  }

  useEffect(() => {
    if (currentUserAuth.email) {
      prefillInformation['email'] = currentUserAuth.email
    }
  }, [currentUserAuth])

  const IdentityVerificationInstructionHeader = (
    <Text mobStyles={mobInstructionHeader}>
      Verify your identity to increase trust.
    </Text>
  )

  const persistAccessKey = accessKey => {
    const { privateUserInformation } = collections
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(privateUserInformation.doc(currentUserAuth.userId))
        .then(privateDocument => {
          const transactionUser = new PrivateUser(privateDocument)
          transaction.update(
            privateUserInformation.doc(currentUserAuth.userId),
            {
              'identityVerification.identityAccessKey': accessKey,
              'identityVerification.status':
                IdentityVerificationStatus.processing,
              'identityVerification.flowCompleted': firebase.firestore.Timestamp.now(),
            }
          )
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_ADDING_ACCESS_KEY_PASSBASE',
            message: 'Error while updating access key passbase.',
            type: errorTypes.passbase,
            user: currentUserAuth.uid,
            file: 'VerifyIdentityModule.jsx',
          })
        })
    })
  }

  const showVerifyButton =
    isCreativeProfileCompleted(user) || isAgencyProfileCompleted(user)
  const verifyMessageBoxText =
    user.accountType === AccountType.agency
      ? 'To verify your identity your profile must be completed. (Full name, profile image, and agency representative)'
      : 'To verify your identity your profile must be completed. (Full name, profile image, and hourly rate)'

  switch (privateUser.identityVerificationStatus) {
    case IdentityVerificationStatus.notStarted:
      return (
        <>
          {IdentityVerificationInstructionHeader}
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;
                margin-top: 20px;
              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Verification Status</Text>
            <label style={{ marginTop: '5px' }}>{NotCompletedIcon}</label>
          </Box>
          <Text mobStyles={mobInstructionParagraph}>
            Verifying your identity sends a strong signal to other users that
            you are the person that you claim to be. To verify your identity you
            must use your real name in your profile and once verified your full
            name will only be modifiable on a case-by-case basis.
          </Text>
          {!showVerifyButton && (
            <MessageBox
              header="Profile Not Complete"
              message={verifyMessageBoxText}
            />
          )}
          {showVerifyButton && (
            <VerifyButton
              apiKey={
                'yUiedVWin5nJtuDAuDxJGwLTjVXJ5Qq4lhc28AdkoJrsmDyb7V0Nh7dsyPB2wXuj'
              }
              onFinish={identityAccessKey => {
                persistAccessKey(identityAccessKey)
              }}
              onError={errorCode => {
                errorHandler({
                  error,
                  errorId: 'ERROR_IDENTITY_FLOW',
                  message: `Error while user trying to verify their id: ${errorCode}`,
                  type: errorTypes.passbase,
                  user: currentUserAuth.uid,
                  file: 'VerifyIdentityModule.jsx',
                })
              }}
              prefillAttributes={prefillInformation}
              theme={{
                accentColor: theme.colors.primary,
                darkMode: true,
              }}
              additionalAttributes={{
                customer_user_id: currentUserAuth.uid,
              }}
            />
          )}
        </>
      )
    case IdentityVerificationStatus.processing:
      return (
        <>
          {IdentityVerificationInstructionHeader}
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;
                margin-top: 20px;
              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Verification Status</Text>
            <label style={{ marginTop: '5px' }}>{WaitingIcon}</label>
          </Box>
          <Text mobStyles={mobInstructionParagraph}>
            We have received your identity verification request and hope to have
            a response soon!
          </Text>
        </>
      )
    case IdentityVerificationStatus.denied:
      return (
        <>
          {IdentityVerificationInstructionHeader}
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;
                margin-top: 20px;
              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Verification Status</Text>
            <label style={{ marginTop: '5px' }}>{WaitingIcon}</label>
          </Box>
          <Text mobStyles={mobInstructionParagraph}>
            Uh-oh, your identity verification request has been denied. Please
            visit the{' '}
            <Link
              mobStyles={[mobInstructionParagraph, mobInTextLink]}
              to={ROUTES.SUPPORT.link}
            >
              support
            </Link>{' '}
            center for more information.
          </Text>
        </>
      )
    case IdentityVerificationStatus.complete:
      return (
        <>
          {IdentityVerificationInstructionHeader}
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;
                margin-top: 20px;
              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Verification Status</Text>
            <label style={{ marginTop: '5px' }}>{CompletedIcon}</label>
          </Box>
        </>
      )
    default:
      return (
        <>
          {IdentityVerificationInstructionHeader}
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;
                margin-top: 20px;
              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Verification Status</Text>
            <label style={{ marginTop: '5px' }}>{WaitingIcon}</label>
          </Box>
          <Text mobStyles={mobInstructionParagraph}>
            Uh-oh, something went wrong. Please visit the{' '}
            <Link
              mobStyles={[mobInstructionParagraph, mobInTextLink]}
              to={ROUTES.SUPPORT.link}
            >
              support
            </Link>{' '}
            center and create a ticket to get this resolved.
          </Text>
        </>
      )
  }
}
