import React, { useState } from 'react'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { Document, Page, pdfjs } from 'react-pdf'
import terms from '../../assets/files/bam_terms_and_conditions.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const {
  mobGigBoxDescriptionMini,
  mobGigBoxHeader,
  mobHorizontalRule,
  mobPageHeader,
  mobInstructionParagraph,
} = mobTextVariants
const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

const { column, mobWrapper, row, center } = mobBoxVariants

export const TermsPage = ({ currentUserAuth, match, user }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Box mobStyles={[column, mobWrapper, center]}>
      <Text
        mobStyles={[
          mobPageHeader,
          css`
            margin: 20px;
          `,
        ]}
        deskStyles={deskPageHeader}
      >
        Terms & Conditions
      </Text>
      <Document file={terms} onLoadSuccess={onDocumentLoadSuccess}>
        <Page width={1000} pageNumber={pageNumber} />
      </Document>
    </Box>
  )
}
