// PROD-READY-MOBILE
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDocument } from 'react-firebase-hooks/firestore'
import TimeAgo from 'react-timeago'

import { User } from '../../classes'
import { collections } from '../../firebaseApp'
import { Image } from '../../shared/Image'
import { Box, mobBoxVariants } from '../../shared/Box'
import { LoadingScreen } from '../../components/LoadingScreen'

/**
 * Component that holds the actual ChatItem info
 * This is nested so we can take advantage of
 * react-firebase-hooks which we cannot do above
 * this component because hook need queries first
 *
 * @param {ChatRoom} chatRoom
 * @param {string} userBId
 * @returns {*}
 * @constructor
 */

const { column, row, fullWidth } = mobBoxVariants

const mobChatRecipientImage = css`
  height: 70px;
  width: 70px;
  min-width: 70px;
  border-radius: 100%;
  box-shadow: 0 4px 8px 2px rgba(173, 165, 165, 0.5);
`

export const ChatItemDetails = ({ chatRoom, currentUserId, unreadObject }) => {
  const [userB, setUserB] = useState(new User())
  const [userBSnapshot, userBSnapshotLoading, userBSnapshotError] = useDocument(
    collections.userInformation.doc(chatRoom.getRecipient(currentUserId))
  )
  const unread = unreadObject[chatRoom.id] > 0

  useEffect(() => {
    if (userBSnapshot) {
      let userB = new User(userBSnapshot)
      setUserB(userB)
    }
  }, [userBSnapshot])

  return (
    <>
      {userBSnapshotError && <strong>Error: {userBSnapshotError}</strong>}
      {userBSnapshotLoading && <LoadingScreen />}
      {userBSnapshot && (
        <>
          <Image
            mobStyles={[mobChatRecipientImage]}
            src={userB.getProfileImage()}
          />
          <Box mobStyles={[column, fullWidth]}>
            <Box mobStyles={[row, fullWidth]}>
              <RecipientName>{userB.fullName}</RecipientName>
              <MessageLastUpdated>
                <TimeAgo
                  date={new Date(chatRoom.lastMessageSentTime.seconds * 1000)}
                />
              </MessageLastUpdated>
            </Box>

            {unread ? (
              <UnreadMessageSnippet>
                {chatRoom.lastMessageSentBody}
              </UnreadMessageSnippet>
            ) : (
              <MessageSnippet>{chatRoom.lastMessageSentBody}</MessageSnippet>
            )}
          </Box>
        </>
      )}
    </>
  )
}

const MessageSnippet = styled.text`
  height: 40px;
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 10px 0 10px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UnreadMessageSnippet = styled.text`
  font-weight: 900;
  color: black;
  font-size: 16.5px;
  height: 40px;
  width: auto;
  font-family: ${p => p.theme.fonts.primary};
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 10px 0 10px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RecipientName = styled.text`
  height: 23px;
  width: 100%;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding: 0 10px 2.5px 10px;
  overflow: hidden;
`

export const MessageLastUpdated = styled.text`
  height: 15px;
  width: auto;
  opacity: 0.31;
  color: ${p => p.theme.colors.darkGray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  white-space: nowrap;
`
