import styled, { css } from 'styled-components'
import React, { useState } from 'react'
import Joyride, { EVENTS, STATUS } from 'react-joyride'
import { useRecoilState } from 'recoil'
import { generalState } from '../../store'
import { Box, Checkbox, Text } from '../../shared'
import { collections } from '../../firebaseApp'
import firebase from 'firebase/app'
import { User } from '../../classes'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { up } from 'styled-breakpoints'

/**
 *
 * @param {User} user
 * @returns {*}
 * @constructor
 */
export const ProfileOnboarding = ({ user }) => {
  const [appState, setAppState] = useRecoilState(generalState)
  const [disableTour, setDisableTour] = useState(false)

  const showUpdateRedirectButton = () => {
    setAppState(previousAppState => {
      return {
        ...previousAppState,
        showGoToUpdateButton: true,
      }
    })
  }

  if (!user.onboardingProfile) {
    return null
  }

  const handleJoyrideCallback = data => {
    const { status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
    } else if ([STATUS.FINISHED /*, STATUS.SKIPPED*/].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      if (disableTour) {
        disableProfileTour()
      }

      showUpdateRedirectButton()
    }
  }

  const disableProfileTour = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)
          transaction.update(userInformation.doc(user.userId), {
            'onboarding.profile': !transactionUser.onboardingProfile,
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_DISABLING_PROFILE_TOUR',
            message: 'Error while disabling the profile tour.',
            type: errorTypes.onboarding,
            user: user && user.uid,
            file: 'BAMProfileOnboarding.jsx',
          })
        })
    })
  }

  const Tooltip = ({
    continuous,
    index,
    step,
    isLastStep,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
  }) => {
    return (
      <TooltipBody {...tooltipProps}>
        {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
        <TooltipContent>{step.content}</TooltipContent>

        <Box
          mobStyles={css`
            margin: 15px 10px 0px 10px;
            width: 100%;
            justify-content: center;
          `}
        >
          <Checkbox
            mobStyles={[
              css`
                width: 30px;
                height: 30px;
              `,
            ]}
            onChange={() => {
              setDisableTour(!disableTour)
            }}
            checked={disableTour}
          />
          <Text
            deskStyles={[
              css`
                font-size: 15px;
                line-height: 15px;
              `,
            ]}
            mobStyles={[
              css`
                align-self: center;
                margin: 5px 0 0 0;
                padding-left: 10px;
                color: #9b9b9b;
                font-family: 'Open Sans';
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 13px;
                width: 100%;
              `,
            ]}
          >
            Disable profile tour after closing?
          </Text>
        </Box>

        <TooltipFooter>
          {index > 0 && (
            <TooltipButton {...backProps}>
              <FormattedMessage id="back">Back</FormattedMessage>
            </TooltipButton>
          )}
          {continuous && (
            <TooltipButton {...primaryProps}>
              <FormattedMessage id="next">
                {isLastStep ? 'Close' : 'Next'}
              </FormattedMessage>
            </TooltipButton>
          )}
          {!continuous && (
            <TooltipButton {...closeProps}>
              <FormattedMessage id="close">Close</FormattedMessage>
            </TooltipButton>
          )}
        </TooltipFooter>
      </TooltipBody>
    )
  }

  return (
    <Joyride
      steps={steps}
      continuous={true}
      styles={toolTipStyles}
      showProgress={true}
      spotlightClicks={true}
      callback={handleJoyrideCallback}
      tooltipComponent={Tooltip}
    />
  )
}

//
// https://www.npmjs.com/package/react-joyride
const steps = [
  {
    target: '#user-profile-image',
    content: 'Your main profile will be displayed here after you add an image.',
    title: 'Profile image.',
  },
  {
    target: '#user-details-box',
    content: 'Here is where your public profile details will be displayed.',
    title: 'Profile details.',
  },
  {
    target: '#user-availability-calender',
    content:
      'Click a day to show you are available for booking on a particular day.',
    title: 'Set your availability.',
  },
  {
    target: '#user-portfolio-images-text',
    content: 'Portfolio images to showcase your talent.',
    title: 'Portfolio images.',
    placement: 'top',
  },
  {
    target: '#user-portfolio-images-text',
    content:
      'To enable booking you will need a completed profile, verified identity, and a Stripe account to receive your payments.',
    title: 'Enabling booking.',
    placement: 'center',
  },
]

const toolTipStyles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    primaryColor: '#000',
    textColor: '#000',
    spotlightShadow: 'red',
    overlayColor: '#fff',
    zIndex: 10000,
    beaconSize: 50,
  },
}

const TooltipBody = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  font-size: 16px;
  max-width: 100%;
  padding: 15px;
  position: relative;
  width: 300px;
  text-align: center;

  ${up('desktop')} {
    width: 400px;
  }
`

const TooltipTitle = styled.text`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  margin: 5px 0 20px;
  letter-spacing: 0.4px;
  display: block;
  text-align: center;
`

const TooltipContent = styled.text`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  line-height: 15px;
  margin: 20px 0;
  text-align: center;

  ${up('desktop')} {
    font-size: 20px;
    line-height: 20px;
  }
`

const TooltipFooter = styled.div`
  justify-content: space-evenly;
  margin-top: 25px;
  height: 50px;
  display: flex;
  align-items: center;
`
const FormattedMessage = styled.p`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  line-height: 20px;
  margin: 20px 0;
  text-align: center;
`
const TooltipButton = styled.button`
  border: 0;
  width: 100px;
  font-family: Montserrat, serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  line-height: 15px;
  border-radius: 30px;
  background-color: ${p => p.theme.colors.primary};
  font-size: 14px;
  color: ${p => p.theme.colors.secondary};
  height: 40px;
  width: 40%;
  box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`
