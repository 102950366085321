import React from 'react'
import { Heading, mobBoxVariants, mobImageVariants } from '../../shared/index'
import styled, { css } from 'styled-components'

import LandingFooter from './LandingFooter'
import { MainImage } from '../../components/MainImage'
import IconContainer from './IconContainer'
import BAMLogo from '../../assets/logos/logo.svg'
import LandingMainImage from '../../assets/photos/landing_page_main.png'
import ImageWithButton from './ImageWithButton'
import { Box, Image } from '../../shared'
import { up } from 'styled-breakpoints'
import PropTypes from 'prop-types'
import InstagramIcon from '../../assets/icons/instagram.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import ModelLeft from '../../assets/photos/model_left.png'
import ModelCenter from '../../assets/photos/models_center.png'
import ModelRight from '../../assets/photos/model_right.png'

const mobStylesHeading = css`
  font-family: ${p => p.theme.fonts.primary};
  color: ${p => p.theme.colors.secondary};
  font-weight: 800;
  font-size: 32px;
  line-height: 0.95;
`

const tabStylesHeading = css`
  font-size: 48px;
`

const deskStylesHeading = css`
  font-size: 60px;
  line-height: 1.1;
`

const mobStylesSubHeading = css`
  color: ${p => p.theme.colors.secondary};
  font-size: 16px;
  opacity: 0.85;
`

const tabStylesSubheading = css`
  font-size: 24px;
`

const deskStylesSubHeading = css`
  font-size: 24px;
`

const { row, mobHeaderIconWrapper } = mobBoxVariants
const { mobHeaderLogo } = mobImageVariants

export const Landing = () => {
  return (
    <div>
      <MainImage
        mobHeight="500px"
        imgSrc={LandingMainImage}
        borderBottomLeftRadius="50% 50px"
        borderBottomRightRadius="50% 50px"
      >
        <Image src={BAMLogo} mobStyles={[mobHeaderLogo]} />
        <Box mobStyles={[row, mobHeaderIconWrapper]}>
          <IconContainer icons={socialIcons} />
        </Box>

        <HeaderWrapper>
          <Heading
            mobStyles={mobStylesHeading}
            tabStyles={tabStylesHeading}
            deskStyles={deskStylesHeading}
          >
            Get paid for your content.
          </Heading>
        </HeaderWrapper>
        <HeaderWrapper
          desktopTop="465px"
          desktopLeft="222px"
          tabletLeft="150px"
          tabletTop="403px"
          tabletWidth="460px"
          mobileLeft="20px"
          mobileTop="246px"
        >
          <Heading
            mobStyles={mobStylesSubHeading}
            tabStyles={tabStylesSubheading}
            deskStyles={deskStylesSubHeading}
          >
            We connect models to successful projects
          </Heading>
        </HeaderWrapper>
        <PositionalDiv mobBottom="-75px"></PositionalDiv>
      </MainImage>

      <ImageContainer>
        {modelImages.map((modelCard, index) => (
          <ImageWithButton
            imgSrc={modelCard.photo}
            buttonText={modelCard.text}
            key={index}
          />
        ))}
      </ImageContainer>
      <LandingFooter />
    </div>
  )
}

const PositionalDiv = styled.div`
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  top: ${props => props.mobTop || 'auto'};
  bottom: ${props => props.mobBottom || 'auto'};

  ${up('tablet')} {
    bottom: -92px;
  }

  ${up('desktop')} {
    bottom: -106px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 75px 0 0 0;
  margin-bottom: 40px;
  height: auto;

  ${up('desktop')} {
    flex-direction: row;
    justify-content: space-around;
    padding: 105px 50px 35px 50px;
  }
`

const HeaderWrapper = styled.div`
  position: absolute;
  width: ${p => (p.mobileWidth ? p.mobileWidth : '246px')};
  height: ${p => (p.mobileHeight ? p.mobileHeight : '70px')};
  top: ${p => (p.mobileTop ? p.mobileTop : '163px')};
  left: ${p => (p.mobileLeft ? p.mobileLeft : '20px')};
  ${up('tablet')} {
    width: ${p => (p.tabletWidth ? p.tabletWidth : '600px')};
    height: ${p => (p.tabletHeight ? p.tabletHeight : '176px')};
    top: ${p => (p.tabletTop ? p.tabletTop : '263px')};
    left: ${p => (p.tabletLeft ? p.tabletLeft : '150px')};
  }

  ${up('desktop')} {
    width: ${p => (p.desktopWidth ? p.desktopWidth : '600px')};
    height: ${p => (p.desktopHeight ? p.desktopHeight : '176px')};
    top: ${p => (p.desktopTop ? p.desktopTop : '263px')};
    left: ${p => (p.desktopLeft ? p.desktopLeft : '222px')};
  }
`
HeaderWrapper.propTypes = {
  desktopTop: PropTypes.string,
  desktopLeft: PropTypes.string,
  tabletLeft: PropTypes.string,
  tabletTop: PropTypes.string,
  tabletWidth: PropTypes.string,
  mobileLeft: PropTypes.string,
  mobileTop: PropTypes.string,
  mobileWidth: PropTypes.string,
  mobileHeight: PropTypes.string,
  tabletHeight: PropTypes.string,
  desktopWidth: PropTypes.string,
  desktopHeight: PropTypes.string,
}

const socialIcons = [
  // { icon: FacebookIcon, link: '' },
  { icon: InstagramIcon, link: 'https://www.instagram.com/book.a.model/' },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/BookAmodel_',
  },
]
const modelImages = [
  {
    photo: ModelLeft,
    text: 'Get Booked For Modeling Gigs',
  },
  { photo: ModelCenter, text: 'Find Makeup Artist' },
  { photo: ModelRight, text: 'Sell or License Your Photos' },
]
