import { Box, mobBoxVariants } from '../../../shared/Box'
import { Heading, headingVariants } from '../../../shared/Heading'
import { Image } from '../../../shared/Image'
import React from 'react'
import { css } from 'styled-components'
import LuluLogo from '../../../assets/logos/lulu_clean_logo.png'
import HotdogLogo from '../../../assets/logos/hotdog_factory_logo.png'
import BlowLogo from '../../../assets/logos/blow_logo.png'
import AllStarKidLogo from '../../../assets/logos/allstar_kid_logo.png'
import CruLogo from '../../../assets/logos/cru_logo.png'
import FoodChingLogo from '../../../assets/logos/food_ching_logo.png'
import GogoLogo from '../../../assets/logos/gogo_logo.jpg'
import GoodMorningLogo from '../../../assets/logos/good_morning_logo.jpg'
import HempLogo from '../../../assets/logos/hemp_logo.png'
import SperryParkLogo from '../../../assets/logos/sperry_park_logo.png'
import NyakLogo from '../../../assets/logos/nyak_logo.png'

const { column, center } = mobBoxVariants
const { blackBold } = headingVariants

const mobModuleWrapper = css`
  background-color: white;
`

const deskHeading = css`
  color: ${p => p.theme.colors.primary};
  font-size: 55px;
  width: auto;
  font-family: ${p => p.theme.fonts.secondary};
  margin-bottom: 10px;
`

const mobHeadingWrapper = css`
  margin-top: 45px;
  margin-bottom: 10px;
`

const itemSpacing = css`
  padding: 12px;
  max-width: 1200px;
`
const mobBrandPartnerImage = css`
  max-width: 49vw;
  max-height: 49vw;
  min-width: 49vw;
  min-height: 49vw;
  flex: 1 1 50%;
  background-position: center;
  background-size: contain;
`

const deskBrandPartnerImage = css`
  max-width: 15vw;
  max-height: 15vw;
  min-width: 15vw;
  min-height: 15vw;
  margin: 2vw;
  flex: 1 1 33.333333%;
  background-position: center;
  background-size: contain;
`

const brandPartnerContainer = css`
  height: auto;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const BrandPartners = () => {
  return (
    <Box mobStyles={[column, center, mobModuleWrapper]}>
      <Box mobStyles={mobHeadingWrapper} deskStyles={itemSpacing}>
        <Heading
          mobStyles={blackBold}
          fontSize={{ mob: '32px' }}
          deskStyles={deskHeading}
        >
          Who We Have Worked With
        </Heading>
      </Box>
      <Box mobStyles={[brandPartnerContainer]}>
        <Image
          enableUseAsBackground={true}
          src={LuluLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={HotdogLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={BlowLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={AllStarKidLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={CruLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={FoodChingLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={GogoLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={GoodMorningLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={HempLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={SperryParkLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
        <Image
          enableUseAsBackground={true}
          src={NyakLogo}
          mobStyles={mobBrandPartnerImage}
          deskStyles={deskBrandPartnerImage}
        />
      </Box>
    </Box>
  )
}
