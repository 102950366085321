import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import get from 'lodash/get'
import { collections, db, functions } from '../../../firebaseApp'
import { uuid } from '../../../utils/uuid'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import firebase from 'firebase/app'
import { PrivateUser } from '../../../classes'
import { RequestActionType, RequestStatus } from '../../../constants/enums'
import { MiniAccordion } from '../../../components/MiniAccordion'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { LoadingButton } from '../../../components/LoadingButton'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import {
  proposalAccepted,
  proposalDenied,
} from '../../../utils/notificationHandlers'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { MessageBox } from '../../../components/MessageBox'
import { ROUTES } from '../../../constants/routes'

const {
  mobStatusButton,
  acceptButton,
  pendingButton,
  declineButton,
  fullOpacity,
} = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const OwnerWithPendingProposal = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const accordionState = useRecoilValue(requestActionAccordionState)
  const acceptAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.accept
  const declineAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.decline
  const createAndCapturePaymentIntent = functions.httpsCallable(
    'createAndCapturePaymentIntent'
  )
  const { activeRequests, privateUserInformation } = collections
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [deniedLoading, setDeniedLoading] = useState(false)

  const [
    userPrivateSnapshot,
    userPrivateSnapshotLoading,
    userPrivateSnapshotError,
  ] = useDocumentData(
    collections.privateUserInformation.doc(currentUserAuth.uid),
    {
      idField: 'id',
    }
  )

  /* TraceProposalStep2: If the event owner is fine with the price of the operative
   they would accept on the proposal. This step creates and captures (authorizes and charges)
   the owners credit card (located in the createAndCapturePaymentIntent cloud function. */
  const acceptProposalRequestHandler = async () => {
    const requestId = get(booking, 'request.requestId')
    setAcceptLoading(true)

    try {
      // 1. Grab the user's private user to get their payment method.
      const userPrivateDocument = await privateUserInformation
        .doc(currentUserAuth.uid)
        .get()

      const ownerPrivateDoc = new PrivateUser(userPrivateDocument)

      // 2. Create and capture the payment intent for the request
      await createAndCapturePaymentIntent({
        idempotencyKey: uuid(),
        amount: booking.getStripeGrandTotal(),
        operativePay: booking.getOperativePayPennies(),
        requestId: booking.request.requestId,
        operativeId: booking.operative.userId,
        eventId: booking.gig.id,
        ownerStripeCustomerId: ownerPrivateDoc.stripeCustomerId,
      })
      setAcceptLoading(false)

      await proposalAccepted(booking.operative.userId)
    } catch (error) {
      errorHandler({
        error,
        errorId: 'ERROR_USER_ACCEPT_PROPOSAL_BOOKING_GIG',
        message: `Failed to accept proposal for booking id: ${requestId}, request id: ${requestId}`,
        type: errorTypes.gig,
        user: currentUserAuth && currentUserAuth.uid,
        file: 'RequestActionModule.jsx',
      })
    }
  }

  // TraceProposalDecline: If the event owner is NOT fine with the proposal
  // they would click this button they would set the status to declined
  const declineProposalRequestHandler = () => {
    setDeniedLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.declined,
        operativeWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    batch.set(
      requestReference.collection('history').doc(),
      {
        note: `${currentUserAuth.uid} declined this offer. ${booking.gig.createdBy}.`,
        timestamp: serverTimestamp,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        setDeniedLoading(false)
        proposalDenied(booking.operative.userId)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_DECLINING_PROPOSAL',
          message: `Failed to decline proposal for user for request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OwnerWithPendingProposal.jsx',
        })
      })
  }

  // hasBillingInfo

  if (!userPrivateSnapshot) {
    return null
  }
  const hasBilling = userPrivateSnapshot.stripeCustomer.hasBillingInfo

  // Model accepting or declining an offer
  return (
    <>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, pendingButton, fullOpacity]}
        disabled={true}
      >
        PENDING
      </Button>
      <Text
        mobStyles={[
          mobRequestBoxHeader,
          css`
            margin: 20px 5px 5px 5px;
            text-align: center;
          `,
        ]}
        deskStyles={[deskButtonGroupHeader]}
      >
        Available Actions
      </Text>
      

      {acceptLoading && !deniedLoading && (
        <LoadingButton
          mobStyles={[acceptButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!acceptLoading && !deniedLoading && (
        <>
          {!hasBilling && (
            <MessageBox
              header="No billing information."
              message={
                'To accept this gig you must enter your billing details in the account settings. '
              }
              subMessage={'Click here to go to account settings.'}
              onClick={() => {
                history.push(ROUTES.ACCOUNT_SETTINGS.link)
              }}
            />
          )}
          <MiniAccordion
            closedButtonText={hasBilling ? 'HIRE' : 'HIRING DISABLED'}
            openButtonText="CANCEL"
            onClick={
              hasBilling
                ? () => {
                    updateAccordionState(RequestActionType.accept)
                  }
                : null
            }
            open={acceptAccordionOpen}
            buttonMobStyles={[mobStatusButton, acceptButton]}
          >
            <Button
              mobStyles={[mobStatusButton, acceptButton]}
              onClick={acceptProposalRequestHandler}
            >
              CONFIRM HIRE
            </Button>
          </MiniAccordion>
        </>
      )}
      {deniedLoading && !acceptLoading && (
        <LoadingButton
          mobStyles={[declineButton]}
          deskStyles={[
            css`
              width: unset;
            `,
          ]}
        />
      )}
      {!acceptLoading && !deniedLoading && (
        <MiniAccordion
          closedButtonText="DECLINE"
          openButtonText="CANCEL"
          onClick={() => {
            updateAccordionState(RequestActionType.decline)
          }}
          open={declineAccordionOpen}
          buttonMobStyles={[mobStatusButton, declineButton]}
        >
          <Button
            mobStyles={[mobStatusButton, declineButton]}
            onClick={declineProposalRequestHandler}
          >
            CONFIRM DECLINE
          </Button>
        </MiniAccordion>
      )}
    </>
  )
}
