export const theme = {
  colors: {
    primary: '#070c17',
    secondary: '#ffffff',
    gray: '#525252',
    lightGray: '#d4d4d4',
    darkGray: '#333333',
    blueGray: '#7c848b',
    yellow: '#ffca00',
    purple: '#9e70bd',
    green: '#7fbc4b',
    red: '#fc4c4c',
    lightRed: '#e96f6f',
    blue: '#5469d4',
    lightBlue: '#70a9bd',
  },
  fonts: {
    primary: 'Open Sans, helvetica,  sans-serif',
    secondary: 'Montserrat, Open Sans, helvetica,  sans-serif',
    mobTextSize: '16px',
    tabTextSize: '18px',
    deskTextSize: '18px',
    mobHeaderTextSize: '20px',
    tabHeaderTextSize: '24px',
    deskHeaderTextSize: '28px',
  },
  shadow: {
    regular: '0 0 4px 2px rgba(202, 201, 201, 0.5)',
  },
}
