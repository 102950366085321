import React, { useState } from 'react'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { Document, Page, pdfjs } from 'react-pdf'
import terms from '../../assets/files/bam_terms_and_conditions.pdf'
import termsOfService from '../../assets/files/terms_of_service.pdf'
import privacy from '../../assets/files/privacy.pdf'
import { Footer } from '../../components/Footer'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { Link } from '../../shared'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const { mobPageHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

const { column, mobWrapper, center } = mobBoxVariants

export const LegalPage = ({ currentUserAuth, match, user }) => {
  const isDesktop = useMediaQuery(desktopQuery)

  const [numPagesTermsConditions, setNumPagesTermsConditions] = useState(null)
  const [termsConditionsPageNumber, setTermsConditionsPageNumber] = useState(1)

  function onTermsConditionsLoadSuccess({ numPages }) {
    setNumPagesTermsConditions(numPages)
  }

  const [numPagesTermsService, setNumPagesTermsService] = useState(null)
  const [pageNumberTermsService, setPageNumberTermsService] = useState(1)

  function onTermsServiceLoadSuccess({ numPages }) {
    setNumPagesTermsService(numPages)
    setPageNumberTermsService(1)
  }

  function changeTermsServicePage(offset) {
    setPageNumberTermsService(prevPageNumber => prevPageNumber + offset)
  }

  function previousTermsServicePage() {
    changeTermsServicePage(-1)
  }

  function nextTermsServicePage() {
    changeTermsServicePage(1)
  }

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  if (!isDesktop) {
    return (
      <>
        <Box
          mobStyles={[
            column,
            mobWrapper,
            center,
            css`
              height: 50vh;
            `,
          ]}
        >
          <Link
            mobStyles={[
              mobPageHeader,
              css`
                font-size: 20px;
                margin: 30px 0;
              `,
            ]}
            deskStyles={[
              deskPageHeader,
              css`
                font-size: 20px;
                width: unset;
                text-align: left;
              `,
            ]}
            to={{ pathname: terms }}
            target="_blank"
          >
            View the Terms & Conditions
          </Link>
          <Text
            mobStyles={[
              mobPageHeader,
              css`
                font-size: 20px;
                margin: 30px 0;
              `,
            ]}
            deskStyles={[
              deskPageHeader,
              css`
                font-size: 20px;
                width: unset;
                text-align: left;
              `,
            ]}
            to={{ pathname: termsOfService }}
            target="_blank"
          >
            View the Terms of Service
          </Text>
          <Text
            mobStyles={[
              mobPageHeader,
              css`
                font-size: 20px;
                margin: 30px 0;
              `,
            ]}
            deskStyles={[
              deskPageHeader,
              css`
                font-size: 20px;
                width: unset;
                text-align: left;
              `,
            ]}
            to={{ pathname: privacy }}
            target="_blank"
          >
            View the Privacy Policy
          </Text>
        </Box>
        <Footer />
      </>
    )
  }

  return (
    <>
      <Box mobStyles={[column, mobWrapper, center]}>
        <Text
          mobStyles={[
            mobPageHeader,
            css`
              font-size: 20px;
            `,
          ]}
          deskStyles={[
            deskPageHeader,
            css`
              font-size: 20px;
              width: unset;
              text-align: left;
            `,
          ]}
        >
          This page includes:
          <ul>
            <li>Terms & Conditions</li>
            <li>Terms of Service</li>
            <li>Privacy Policy</li>
          </ul>
        </Text>
        <Text
          mobStyles={[
            mobPageHeader,
            css`
              margin: 20px 0 0 0;
            `,
          ]}
          deskStyles={[deskPageHeader]}
        >
          1. Terms & Conditions
        </Text>
        <Document file={terms} onLoadSuccess={onTermsConditionsLoadSuccess}>
          <Page scale={1.5} pageNumber={termsConditionsPageNumber} />
        </Document>
        <Text
          mobStyles={[
            mobPageHeader,
            css`
              margin: 20px 0 0 0;
            `,
          ]}
          deskStyles={deskPageHeader}
        >
          2. Terms of Service
        </Text>
        <div>
          <p>
            Page {pageNumberTermsService || (numPagesTermsService ? 1 : '--')}{' '}
            of {numPagesTermsService || '--'}
          </p>
          <button
            type="button"
            disabled={pageNumberTermsService <= 1}
            onClick={previousTermsServicePage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumberTermsService >= numPagesTermsService}
            onClick={nextTermsServicePage}
          >
            Next
          </button>
        </div>
        <Document
          file={termsOfService}
          onLoadSuccess={onTermsServiceLoadSuccess}
        >
          <Page scale={1.5} pageNumber={pageNumberTermsService} />
        </Document>
        <Box
          mobStyles={[
            css`
              margin-bottom: 75px;
            `,
          ]}
        >
          <button
            type="button"
            disabled={pageNumberTermsService <= 1}
            onClick={previousTermsServicePage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumberTermsService >= numPagesTermsService}
            onClick={nextTermsServicePage}
          >
            Next
          </button>
        </Box>

        <Text
          mobStyles={[
            mobPageHeader,
            css`
              margin: 20px;
            `,
          ]}
          deskStyles={deskPageHeader}
        >
          Privacy Policy
        </Text>
        <div>
          <p>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
        <Document file={privacy} onLoadSuccess={onDocumentLoadSuccess}>
          <Page scale={1.5} pageNumber={pageNumber} />
        </Document>
        <Box
          mobStyles={[
            css`
              margin-bottom: 75px;
            `,
          ]}
        >
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </Box>
      </Box>
      <Footer />
    </>
  )
}
