import React, { useState } from 'react'
import 'firebase/storage'

import {
  Button,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { css } from 'styled-components'
import { LoadingButton } from '../../components/LoadingButton'
import { auth } from '../../firebaseApp'

const { mobSubmitButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { mobInstructionParagraph } = mobTextVariants
const { deskSubmitButton } = deskTextVariants
const acceptColor = css`
  background-color: #70bd74;
  color: #ffffff;
`

/**
 * This component will be shown to users that signed up with a email/password
 * combo
 *
 * @param currentUserAuth
 * @param {AccountSettingsForm} accountSettingsForm
 * @returns {*}
 * @constructor
 */
export const AccountPasswordModule = ({
  currentUserAuth,
  accountSettingsForm,
}) => {
  const [passwordUpdating, setPasswordUpdating] = useState(false)
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  // TODO: Update to support users that do not use email when we let people use Google/Facebook
  const submitUpdatePassword = () => {
    if (
      accountSettingsForm.newPassword === accountSettingsForm.confirmNewPassword
    ) {
      setPasswordUpdating(true)

      auth
        .signInWithEmailAndPassword(
          currentUserAuth.email,
          accountSettingsForm.currentPassword
        )
        .then(() => {
          currentUserAuth
            .updatePassword(accountSettingsForm.newPassword)
            .then(() => {
              setPasswordUpdated(true)
              setPasswordUpdating(false)

              setTimeout(() => {
                setPasswordUpdated(false)
              }, 2000)
              // Update successful.
            })
            .catch(error => {
              // An error happened.
              // errorHandler({
              //   error,
              //   errorId: 'ERROR_PASSWORD_UPDATE',
              //   message: 'Error while user updating password',
              //   type: errorTypes.auth,
              //   user: currentUserAuth && currentUserAuth.uid,
              //   file: 'AccountPasswordModule.jsx',
              // })
            })
        })
        .catch(error => {
          // errorHandler({
          //   error,
          //   errorId: 'ERROR_EMAIL_UPDATE_LOGIN',
          //   message:
          //     'Error occurred while user trying to authenticate to change email',
          //   type: errorTypes.auth,
          //   user: currentUserAuth && currentUserAuth.uid,
          //   file: 'AccountEmailModule.jsx',
          // })
        })
    }
  }

  return (
    <>
      <Text mobStyles={mobInstructionParagraph}>
        Change the password to log in to your account.
      </Text>
      <InputWithHeader
        header="New Password"
        inputName="new-password"
        type="password"
        onChange={accountSettingsForm.updateNewPassword}
        placeholderDefault="Enter New Password"
      />
      <InputWithHeader
        header="Confirm New Password"
        inputName="confirm-new-password"
        type="password"
        onChange={accountSettingsForm.updateConfirmNewPassword}
        placeholderDefault="Confirm New Password"
      />
      <InputWithHeader
        header="Password"
        inputName="password"
        type="password"
        onChange={accountSettingsForm.updateCurrentPassword}
        placeholderDefault="Enter Current Password"
      />

      {passwordUpdating && (
        <LoadingButton
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              margin: 30px 0;
            `,
          ]}
        />
      )}
      {!passwordUpdating && !passwordUpdated && (
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              margin: 30px 0;
            `,
          ]}
          deskStyles={deskSubmitButton}
          onClick={submitUpdatePassword}
        >
          CHANGE PASSWORD
        </Button>
      )}
      {passwordUpdated && (
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            acceptColor,
            css`
              margin: 30px 0;
            `,
          ]}
          deskStyles={deskSubmitButton}
          disabled={true}
        >
          SENT
        </Button>
      )}
    </>
  )
}
