import React from 'react'
import PropTypes from 'prop-types'
import 'firebase/storage'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../../src/shared'

const { mobSettingsText } = mobTextVariants
const { mobRow, mobSettingsValue } = mobBoxVariants

/**
 * This component conveys extra information about various settings in the
 * user's account settings. For instance, for the Stripe module we may
 * show the the last time they updated the information.
 *
 * @param props
 * @constructor
 */
export const AccountSettingsDetailRow = ({ name, value }) => {
  return (
    <Box mobStyles={mobRow}>
      <Text mobStyles={mobSettingsText}>{name}</Text>
      <Box mobStyles={mobSettingsValue}>{value}</Box>
    </Box>
  )
}

AccountSettingsDetailRow.propTypes = {
  name: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
