import React, { useState } from 'react'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { Document, Page, pdfjs } from 'react-pdf'
import privacy from '../../assets/files/privacy.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const { mobPageHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

const { column, mobWrapper, center } = mobBoxVariants

export const PrivacyPage = () => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  // function onDocumentLoadSuccess({numPages}) {
  //   setNumPages(numPages);
  // }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <Box mobStyles={[column, mobWrapper, center]}>
      <Text
        mobStyles={[
          mobPageHeader,
          css`
            margin: 20px;
          `,
        ]}
        deskStyles={deskPageHeader}
      >
        Privacy Policy
      </Text>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
      <Document file={privacy} onLoadSuccess={onDocumentLoadSuccess}>
        <Page scale={1.5} pageNumber={pageNumber} />
      </Document>
      <Box
        mobStyles={[
          css`
            margin-bottom: 75px;
          `,
        ]}
      >
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </Box>
    </Box>
  )
}
