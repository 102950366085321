import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { IconContext } from 'react-icons'
import { MdCancel, MdNewReleases } from 'react-icons/md'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Moment from 'moment'

import { collections } from '../../firebaseApp'
import { Gig } from '../../classes'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { AccountType } from '../../constants/enums'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

const MomentRange = require('moment-range')

const moment = MomentRange.extendMoment(Moment)

const ScheduleGigMatchIcon = (
  <IconContext.Provider value={{ color: '#000000', size: '1.1em' }}>
    <MdNewReleases />
  </IconContext.Provider>
)

const ScheduleGigNoMatchIcon = (
  <IconContext.Provider value={{ color: '#000000', size: '1.1em' }}>
    <MdCancel />
  </IconContext.Provider>
)

/**
 * For this module the user and currentUserAuth will nto always be the same person.
 * This allows us to render the correct calender during the following cases:
 * 1. User visits their own pages
 * 2. Another user visits their page
 *
 * @param {User} user
 * @param currentUserAuth
 * @param activeStartDate
 * @returns {*}
 * @constructor
 */
export const AvailabilityModule = ({ user, currentUserAuth, startDate }) => {
  if (user.accountType === AccountType.agency || !currentUserAuth) {
    return null
  }

  const [date, setDate] = useState(new Date())
  const [userGigRanges, setUserGigRanges] = useState([])
  // Note: currentUserAuth should be an agency and user will be the potential operative
  const userViewingOwnProfile = user.userId === currentUserAuth.uid

  const [allUserGigs, allUserGigsLoading, allUserGigsError] = useCollectionData(
    collections.gigs.where('createdBy', '==', currentUserAuth.uid),
    {
      idField: 'id',
    }
  )

  useEffect(() => {
    if (allUserGigs && !userViewingOwnProfile) {
      allUserGigs.map((gigDocumentSnapshot, index) => {
        let gig = new Gig(gigDocumentSnapshot)
        // Convert Firebase timestamps to regular dates
        let gigStart = new Date(gig.startTime.seconds * 1000)
        let gigEnd = new Date(gig.endTime.seconds * 1000)
        let range = moment.range(gigStart, gigEnd)
        setUserGigRanges(currentRanges => [...currentRanges, range])
      })
    }
  }, [allUserGigs])

  const toggleDateAvailabilityHandler = date => {
    let dateEpochNumber = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).getTime()

    const { userInformation } = collections

    firebase
      .firestore()
      .runTransaction(transaction => {
        return transaction
          .get(userInformation.doc(user.userId))
          .then(userDocument => {
            // let transactionUser = new User(userDocument)
            let availableDates = user.availableDates

            if (!availableDates.includes(dateEpochNumber)) {
              transaction.update(userInformation.doc(user.userId), {
                availableDates: firebase.firestore.FieldValue.arrayUnion(
                  dateEpochNumber
                ),
              })
            } else {
              transaction.update(userInformation.doc(user.userId), {
                availableDates: firebase.firestore.FieldValue.arrayRemove(
                  dateEpochNumber
                ),
              })
            }
          })
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_AVAILABILITY',
          message: 'Error occurred while getting user availability.',
          type: errorTypes.profile,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'AvailabilityModule.jsx',
        })
      })
  }

  const dateIsAvailable = date => {
    let calDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    return user.availableDates.includes(calDate.getTime())
  }

  const gigDateAvailableLogo = date => {
    // Take the date from the calander and create 2 dates that span the whole day
    // Date we are passed automatically starts at 00:00:000. calDate below.
    let calDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    let startOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).setHours(0, 0, 0, 0)
    let endOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).setHours(23, 59, 59, 999)
    let calendarDayRange = moment.range(startOfDay, endOfDay)

    for (const gigRange of userGigRanges) {
      if (
        calendarDayRange.contains(gigRange) &&
        user.availableDates.includes(calDate.getTime())
      ) {
        return ScheduleGigMatchIcon
      } else if (calendarDayRange.contains(gigRange)) {
        return ScheduleGigNoMatchIcon
      }
    }
    return null
  }

  const defautCalendarProps = {
    onChange: event => setDate(event),
    value: date,
    next2Label: null,
    prev2Label: null,
    className: 'availability-calendar',
  }

  const allowAvailabilityEditing =
    userViewingOwnProfile || user.managedBy === currentUserAuth.uid

  return (
    <div id={'user-availability-calender'}>
      {allUserGigsError && <strong>Error: {allUserGigsError}</strong>}
      {allUserGigsLoading && <span>Loading...</span>}
      {allUserGigs && (
        <>
          {allowAvailabilityEditing && (
            <Calendar
              {...defautCalendarProps}
              onClickDay={date => {
                toggleDateAvailabilityHandler(date)
              }}
              tileClassName={({ date }) => {
                if (dateIsAvailable(date)) {
                  return 'available-day'
                } else {
                  return 'unavailable-day'
                }
              }}
              tileDisabled={({ date }) => {
                return date < new Date()
              }}
              activeStartDate={startDate ? startDate : null }
            />
          )}
          {!allowAvailabilityEditing && (
            <Calendar
              {...defautCalendarProps}
              tileContent={({ date }) => {
                return gigDateAvailableLogo(date)
              }}
              tileDisabled={({ date }) => {
                return !dateIsAvailable(date)
              }}
              tileClassName={({ date }) => {
                if (dateIsAvailable(date)) {
                  return 'available-day'
                }
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
