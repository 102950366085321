import React from 'react'
import PropTypes from 'prop-types'
import { StyledInfiniteScroll } from './styles'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const InfiniteScroll = ({ children, ...props }) => {
  return <StyledInfiniteScroll {...props}>{children}</StyledInfiniteScroll>
}

InfiniteScroll.propTypes = {
  next: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  loader: PropTypes.element.isRequired,
  scrollThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  endMessage: PropTypes.element,
  style: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scrollableTarget: PropTypes.element,
  hasChildren: PropTypes.bool,
  pullDownToRefresh: PropTypes.bool,
  pullDownToRefreshContent: PropTypes.element,
  releaseToRefreshContent: PropTypes.element,
  pullDownToRefreshThreshold: PropTypes.number,
  refreshFunction: PropTypes.func,
  onScroll: PropTypes.func,
  dataLength: PropTypes.number,
  initialScrollY: PropTypes.number,
  key: PropTypes.string,
  className: PropTypes.string,
  ...responsiveStyleAndVariantsProps,
}
