import moment from 'moment'

/**
 * CreateGigForm is ....
 *
 */
export class CreateGigForm {
  title = ''
  description = ''
  time = {
    date: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    ),
    startTime: undefined,
    endTime: undefined,
    duration: 0,
  }
  location = {
    address: '',
    latLong: {
      lat: 0,
      lng: 0,
    },
    locationMobileImageUrl: '',
  }
  acceptingProposals = false
  fixedPricePennies = 0
  fixedPriceInputEnabled = false

  constructor(updateDateValue, fixedPriceInputEnabled, clearErrors) {
    this.updateDateValue = updateDateValue
    this.fixedPriceInputEnabled = fixedPriceInputEnabled
    this.clearErrors = clearErrors
  }

  updateTitle = event => {
    this.title = event.target.value
    this.clearErrors()
  }

  updateFixedPrice = event => {
    // Convert User Input to Pennies!!!
    this.fixedPricePennies = Number(event.target.value) * 100
    this.clearErrors()
  }

  updateAddress = locationObject => {
    this.location.address = locationObject.eventAddress
    this.location.latLong = locationObject.eventLatLong
    this.location.locationMobileImageUrl =
      locationObject.eventLocationMobileImageUrl
    this.clearErrors()
  }

  updateDescription = event => {
    this.description = event.target.value
    this.clearErrors()
  }

  updateDate = date => {
    this.time.date = date
    this.updateDateValue(date)

    if (this.time.startTime !== undefined) {
      this.updateStartTime(moment(this.time.startTime))
    }
    if (this.time.endTime !== undefined) {
      this.updateEndTime(moment(this.time.endTime))
    }
    this.clearErrors()
  }

  updateEndTime = time => {
    this.time.endTime = this.momentChangedToDate(time)
    this.clearErrors()
  }

  updateStartTime = time => {
    this.time.startTime = this.momentChangedToDate(time)
    this.clearErrors()
  }

  momentChangedToDate = time => {
    return new Date(
      time
        .toDate()
        .setFullYear(
          this.time.date.getFullYear(),
          this.time.date.getMonth(),
          this.time.date.getDate()
        )
    )
  }

  addGigDuration = (gigStart, gigEnd) => {
    let start = moment(gigStart)
    let end = moment(gigEnd)

    this.time.duration = end.diff(start, 'hours', true)
  }

  timeRangeIsLogical = (startTime, endTime) => {
    return startTime.getTime() < endTime.getTime()
  }
}
