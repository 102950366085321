import React from 'react'
import { StyledOption } from './styles'

import PropTypes from 'prop-types'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Option = props => {
  return <StyledOption {...props}>{props.children}</StyledOption>
}

Option.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
  selected: PropTypes.bool,
}
