import PropTypes from 'prop-types'
import React from 'react'
import {
  Label,
  mobTextAreaVariants,
  mobTextVariants,
  TextArea,
} from '../../../shared/index'
import { deskTextAreaVariants } from '../../../shared/TextArea/deskTextAreaVariants'

const { mobInputHeader } = mobTextVariants
const { mobTextArea } = mobTextAreaVariants
const { deskTextArea } = deskTextAreaVariants

export const TextAreaWithHeader = ({
  header,
  subHeader,
  disabled,
  maxLength,
  inputName,
  onChange,
  placeholder,
  placeholderDefault,
  value,
  type,
  inputTestId = 'textarea-id',
}) => {
  const inputType = type || 'text'

  return (
    <>
      <Label mobStyles={mobInputHeader} htmlFor={inputName}>
        {header}
        {subHeader && (
          <>
            {header && <br />}
            {subHeader}
          </>
        )}
      </Label>
      <TextArea
        disabled={disabled}
        type={inputType}
        maxLength={maxLength || 250}
        mobStyles={mobTextArea}
        deskStyles={deskTextArea}
        name={inputName}
        onChange={onChange}
        placeholder={placeholder || placeholderDefault}
        value={value}
        data-testid={inputTestId}
      />
    </>
  )
}

TextAreaWithHeader.propTypes = {
  disabled: PropTypes.bool,
  header: PropTypes.string,
  inputName: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderDefault: PropTypes.string,
  subHeader: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputTestId: PropTypes.string,
}
