import React from 'react'
import Loader from 'react-loader-spinner'
import { Button, mobButtonVariants } from '../../shared/Button'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { mobSubmitButton } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants
const mobLoadingButton = css`
  height: 46px;
  width: 100%;
`

/**
 * Should only be used at the top level. Not for lower level components
 * @returns {*}
 * @constructor
 */
// https://github.com/mhnpd/react-loader-spinner
export const LoadingButton = ({ mobStyles, deskStyles }) => {
  return (
    <Button
      mobStyles={[mobSubmitButton, mobLoadingButton].concat(mobStyles)}
      deskStyles={[deskSubmitButton].concat(deskStyles)}
      data-testid="loading-button"
    >
      <Loader type="ThreeDots" color="#ffffff" height={46} width={100} />
    </Button>
  )
}
