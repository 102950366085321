import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { variantsAndStyles } from '../../utils/variantsAndStyles'

const checkboxStyles = css`
  ${variantsAndStyles()}
`

export const StyledMotionCheckbox = styled(motion.input)`
  ${checkboxStyles}
`

export const StyledCheckbox = styled.input`
  ${checkboxStyles}
`
