import React, { useEffect, useRef, useState } from 'react'
import { css } from 'styled-components'
import 'rc-time-picker/assets/index.css'
import firebase from 'firebase/app'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useDocument } from 'react-firebase-hooks/firestore'

import { collections } from '../../firebaseApp'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import {
  AutoCompleteLocationInput,
  DesktopPageWrapper,
  InputWithHeader,
  LoadingButton,
  LoadingScreen,
  StickyFooter,
  StickyFooterWithButton,
  TextAreaWithHeader,
  TimeInputWithHeader,
} from '../../components'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { EditGigForm, Gig } from '../../classes'
import { convertToString } from '../../utils/convertFirestoreTimestamp'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { mobInputHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

const deskPageCenterWrapper = css`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`

export const EditGigPage = ({ match, currentUserAuth, history }) => {
  const [showLoading, setShowLoading] = useState(false)
  const [gig, setGig] = useState(new Gig())
  const [submitFormDisabled, setSubmitFormDisabled] = useState(true)

  // Grab from the url query, if one cannot be found redirect to home
  const gigId = match.params.gig_id
  if (!gigId) {
    history.push('/')
  }

  const [
    gigDocumentSnapshot,
    gigDocumentSnapshotLoading,
    gigDocumentSnapshotError,
  ] = useDocument(collections.gigs.doc(gigId))

  const toggleSubmitButton = disable => {
    setSubmitFormDisabled(disable)
  }

  // Store the form in the mutable instance container aka useRef
  const editGigForm = useRef(new EditGigForm(gig, toggleSubmitButton))
  useEffect(() => {
    setGig(new Gig(gigDocumentSnapshot))
    if (gigDocumentSnapshot) {
      // Update the mutable container with the gig information
      editGigForm.current = new EditGigForm(
        new Gig(gigDocumentSnapshot),
        toggleSubmitButton
      )
    }
  }, [gigDocumentSnapshot])

  // Handle Submit Gig Edit
  const submitGigEditHandler = () => {
    setShowLoading(true)
    const { gigs } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()

    gigs
      .doc(editGigForm.current.id)
      .set(
        {
          title: editGigForm.current.newTitle,
          lastEdit: serverTimestamp,
          time: {
            date: editGigForm.current.newTime.newDate,
            startTime: editGigForm.current.newTime.newStartTime,
            endTime: editGigForm.current.newTime.newEndTime,
            duration: Number(editGigForm.current.newTime.newDuration),
          },
          location: {
            address: editGigForm.current.newLocation.newAddress,
            latLong: editGigForm.current.newLocation.newLatLong,
            locationMobileImageUrl:
              editGigForm.current.newLocation.newLocationMobileImageUrl,
          },
          description: editGigForm.current.newDescription,
          fixedPriceGig: editGigForm.current.fixedPriceGig,
          fixedPrice: editGigForm.current.fixedPriceGig
            ? Number(editGigForm.current.newFixedPrice)
            : null,
        },
        { merge: true }
      )
      .then(gigDocument => {
        // Redirect to the the created gig
        history.push(`/gig/${editGigForm.current.id}`)
      })
      .catch(error => {
        setShowLoading(false)
        errorHandler({
          error,
          errorId: 'ERROR_EDIT_GIG',
          message: 'Failed to edit gig.',
          type: errorTypes.edit,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'EditGigPage.jsx',
        })
      })
  }

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]} deskStyles={deskPageCenterWrapper}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          Edit Your Gig
        </Text>
        <Text mobStyles={mobInstructionParagraph}>
          Edit gig details. Once an offer and/or proposal is created for your
          gig you may not edit the gig. To edit the gig all offers/proposals
          must be cancelled.
        </Text>
        {gigDocumentSnapshotError && (
          <strong>Error: {gigDocumentSnapshotError}</strong>
        )}
        {gigDocumentSnapshotLoading && <LoadingScreen />}
        {gigDocumentSnapshot && (
          <>
            <InputWithHeader
              header="GIG TITLE"
              inputName="eventTitle"
              onChange={editGigForm.current.updateTitle}
              placeholder={editGigForm.current.title}
            />
            {editGigForm.current.fixedPriceGig && (
              <InputWithHeader
                header="FIXED PRICE AMOUNT"
                type="number"
                inputName="gigFixedPriceAmount"
                onChange={editGigForm.current.updateFixedPrice}
                placeholder={editGigForm.current.fixedPrice}
              />
            )}
            <Text mobStyles={mobInputHeader}>DATE</Text>
            <Calendar
              onChange={editGigForm.current.updateDate}
              value={editGigForm.current.newTime.newDate}
              next2Label={null}
              prev2Label={null}
              className="availability-calendar"
              tileDisabled={({ date }) => {
                return date < new Date()
              }}
            />
            <TimeInputWithHeader
              header="GIG TIMES"
              startTimeHandler={editGigForm.current.updateStartTime}
              endTimeHandler={editGigForm.current.updateEndTime}
              startPlaceholder={convertToString(
                editGigForm.current.time.startTime
              )}
              endPlaceholder={convertToString(editGigForm.current.time.endTime)}
            />

            <Text mobStyles={mobInputHeader}>GIG ADDRESS</Text>
            <AutoCompleteLocationInput
              locationHandler={editGigForm.current.updateAddress}
              placeholder={editGigForm.current.address}
            />
            <TextAreaWithHeader
              header="GIG DESCRIPTION"
              inputName="eventDescription"
              onChange={editGigForm.current.updateDescription}
              placeholderDefault={editGigForm.current.description}
            />
          </>
        )}
      </Box>
      {showLoading && (
        <StickyFooter>
          <LoadingButton />
        </StickyFooter>
      )}
      {!showLoading && (
        <StickyFooterWithButton
          mainButtonDisabled={submitFormDisabled}
          mainButtonHandler={submitGigEditHandler}
          mainButtonText="UPDATE"
        />
      )}
    </DesktopPageWrapper>
  )
}
