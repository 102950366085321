import React, { useState } from 'react'
import { Input, inputVariants } from '../../../shared/Input'
import { Button, mobButtonVariants } from '../../../shared/Button'
import { css } from 'styled-components'
import { isEmail } from 'validator'
import { auth } from '../../../firebaseApp'

const { mobInput } = inputVariants
const { mobSubmitButtonLight, mobLong305x46 } = mobButtonVariants

const loginFacebookStyle = css`
  margin-top: 30px;
  :disabled {
    background-color: ${p => p.theme.colors.lightGray};
    opacity: 0.5 !important;
  }
`

const successButton = css`
  margin-top: 30px;
  background-color: ${p => p.theme.colors.secondary} !important;
`
export const ForgotPasswordForm = ({ setShowForgotPassword }) => {
  const [email, setEmail] = useState('')
  const [sentSuccess, setSentSuccess] = useState(false)
  const [error, setError] = useState(null)

  const handleSendEmail = event => {
    event.preventDefault()
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setSentSuccess(true)
        setTimeout(() => {
          setShowForgotPassword(false)
        }, 5000)
      })
      .catch(error => {
        setError(error)
      })
  }

  return (
    <>
      {!sentSuccess && (
        <>
          <Input
            enableMotion
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
            mobStyles={[mobInput]}
            name="forgot-password-input"
            // Hack to prevent fields from being autocompleted by Chrome
            autoComplete="new-password"
            onChange={event => setEmail(event.target.value)}
            placeholder="Enter Email"
          />
          <Button
            onClick={handleSendEmail}
            disabled={!isEmail(email)}
            enableMotion
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            mobStyles={[
              mobSubmitButtonLight,
              mobLong305x46,
              loginFacebookStyle,
              css`
                margin: 0 0 20px 0;
              `,
            ]}
          >
            RESET PASSWORD
          </Button>
        </>
      )}

      {sentSuccess && (
        <Button
          disabled={!isEmail(email)}
          enableMotion
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          mobStyles={[
            mobSubmitButtonLight,
            mobLong305x46,
            css`
              margin: 0 0 20px 0;
            `,
            successButton,
          ]}
        >
          SENT!
        </Button>
      )}
    </>
  )
}
