import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  inputVariants,
  Label,
  mobTextVariants,
} from '../../../shared/index'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

const { mobInputHeader, mobInputHeaderBold } = mobTextVariants
const { mobInput } = inputVariants

export const InputWithHeader = ({
  headerStyles = {
    mobStyles: [],
    tabStyles: [],
    deskStyles: [],
    lgDeskStyles: [],
  },
  inputStyles = {
    mobStyles: [],
    tabStyles: [],
    deskStyles: [],
    lgDeskStyles: [],
  },
  header,
  subHeader,
  disabled,
  maxLength,
  pattern,
  inputName,
  autoComplete,
  onChange,
  placeholder,
  placeholderDefault,
  value,
  step,
  min,
  max,
  inputTestId,
  hideHeader = false,
  ...props
}) => {
  const inputType = props.type || 'text'

  let selectedHeaderStyle
  if (props.boldHeader) {
    selectedHeaderStyle = mobInputHeaderBold
  } else {
    selectedHeaderStyle = mobInputHeader
  }

  const headerMobStyles =
    headerStyles && Array.isArray(headerStyles.mobStyles)
      ? [selectedHeaderStyle, ...headerStyles.mobStyles]
      : [selectedHeaderStyle, headerStyles.mobStyles]

  const inputMobStyles =
    inputStyles && Array.isArray(inputStyles.mobStyles)
      ? [mobInput, ...inputStyles.mobStyles]
      : [mobInput, inputStyles.mobStyles]

  return (
    <>
      {!hideHeader && (
        <Label
          mobStyles={headerMobStyles}
          tabStyles={headerStyles.tabStyles}
          deskStyles={headerStyles.deskStyles}
          lgDeskStyles={headerStyles.lgDeskStyles}
          htmlFor={inputName}
        >
          {header}
          {subHeader && (
            <>
              <br />
              {subHeader}
            </>
          )}
        </Label>
      )}

      <Input
        disabled={disabled}
        type={inputType}
        maxLength={maxLength}
        pattern={pattern}
        id={inputName}
        name={inputName}
        // Hack to prevent fields from being autocompleted by Chrome
        autoComplete={autoComplete ? '' : 'new-password'}
        onChange={onChange}
        placeholder={placeholder || placeholderDefault}
        value={value}
        mobStyles={inputMobStyles}
        tabStyles={inputStyles.tabStyles}
        deskStyles={inputStyles.deskStyles}
        lgDeskStyles={inputStyles.lgDeskStyles}
        data-testId={inputTestId}
        step={step}
        min={min}
        max={max}
      />
    </>
  )
}

InputWithHeader.propTypes = {
  ...Input.propTypes,
  headerStyles: PropTypes.shape({ ...responsiveStyleAndVariantsProps }),
  inputStyles: PropTypes.shape({ ...responsiveStyleAndVariantsProps }),
  subHeader: PropTypes.string,
  testing: PropTypes.bool,
}
