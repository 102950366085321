import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { errorHandler, errorTypes } from '../../utils/errorHandler'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const { errorMessage, userId, file } = this.props
    errorHandler({
      error,
      errorId: 'ERROR_BOUNDARY_HANDLER',
      message: errorMessage,
      type: errorTypes.errorBoundary,
      userId,
      file,
      additionalData: errorInfo,
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  errorMessage: PropTypes.string,
  file: PropTypes.string,
  userId: PropTypes.string,
}
