import { css } from 'styled-components'

export const deskTextVariants = {
  default: css`
    font-size: 12px;
  `,
  bold: css`
    font-weight: bold;
  `,
  fullWidth: css`
    width: 100%;
  `,
  fullHeight: css`
    height: 100%;
  `,

  textCenter: css`
    text-align: center;
  `,

  deskSectionHeader: css`
    height: 48px;
    width: 100%;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 48px;
    color: ${p => p.theme.fonts.primary};
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 48px;
    text-align; center;
      align-self: center;
  padding-top: 20px;
  `,

  deskPageHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    height: auto;
    width: 100%;
    color: #7c848b;
    font-family: Montserrat;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
  `,
  deskPageSubHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 25px;
    font-weight: bold;
    margin: 25px auto 10px 0;
  `,
  deskPageSectionHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    margin: 5px 0 20px;
    letter-spacing: 0.4px;
  `,

  deskSubmitButton: css`
    height: 40px;
    width: 336px;
  `,
  //
  ///

  ///

  ///
  // CRUFT below

  deskFaqText: css`
    height: auto;
    width: 100%;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 20px;
    text-align: left;
    line-height: unset;
  `,
  deskInstructionParagraph: css`
    height: auto;
    width: 100%;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 18px;
    text-align: left;
    line-height: unset;
  `,

  mobInstructionHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 18px;
    line-height: 20px;
    margin: 0;
  `,
  //   overflow: scroll;
  //     margin-bottom: 35px;
  mobInputHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 15px 0 7.5px;
    opacity: 0.71;
    font-size: 16px;
    line-height: 19px;
  `,
  mobInputHeaderBold: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 15px 0 7.5px;
    opacity: 0.71;
    font-size: 16px;
    line-height: 19px;
    font-weight: 900 !important;
  `,

  deskGigBoxHeader: css`
    text-align: left;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;

    height: 20px;
    width: 623px;
    color: #7c848b;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  `,

  deskGigBoxLocation: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    font-style: italic;
    line-height: 20px;
    margin-bottom: 10px;
  `,
  deskGigDate: css`
    height: 20px;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
  `,
  deskHorizontalRule: css`
    box-sizing: border-box;
    height: 2px;
    width: 100%;
    border: 1px solid ${p => p.theme.colors.gray};
    opacity: 0.3;
    margin: 10px auto;
  `,

  deskGigBoxDescription: css`
    height: 160px;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 3% 0 1%;
    overflow: scroll;
  `,

  deskGigBoxDescriptionMini: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  `,

  // miniLink User Profile Text
  deskUserProfileNameMini: css`
    height: auto;
    width: 100%;
    line-height: 25px;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: auto;
    text-align: center;
    margin: 13px 0 0;
  `,

  deskUserProfileLocationMini: css`
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    height: auto;
    width: 100%;
    line-height: 19px;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 19px;
    text-align: center;
  `,

  deskButtonGroupHeader: css`
    font-size: 30px;
    line-height: 30px;
  `,

  mobTestText: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 25px;
    font-weight: bold;
    line-height: 25px;
    margin: 0 0 10px;
    word-break: break-all;
  `,
  mobGigPaymentDuration: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 3% 0 1%;
    overflow: scroll;
  `,

  // Grand InvoiceRow Text
  mobInvoiceRowGrand: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-weight: bold;
    text-align: left;
    margin: 5px 0 0;
    font-size: 18px;
    line-height: 20px;
  `,
  // Message Box Header
  deskMessageBoxHeader: css`
    height: 29px;
    font-size: 29px;
    line-height: 29px;
  `,
  mobMessageBoxMessage: css`
    width: auto;
    margin: 0 0 10px;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 15px;
    line-height: 20px;
  `,

  // Status Box Text
  mobMessageBoxText: css`
    margin: 0;
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.green};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  `,

  // Notification text
  mobNotificationText: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    width: 90%;
  `,

  deskFaqHeader: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    line-height: unset;
    text-decoration: underline;
  `,
  mobSignUpText: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 30px !important;
    text-align: center;
  `,

  // Centered Profile Text
  deskProfileName: css`
    height: auto;
    width: 100%;
    line-height: 35px;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    margin: 13px auto 0;
    overflow: ellipsis;
    text-align: left;
  `,

  deskProfileLocation: css`
    height: auto;
    width: 100%;
    line-height: 20px;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 10px 0 5px 0;
    font-size: 20px;
    text-align: left;
  `,

  // Left Profile Text
  mobLeftProfileName: css`
    height: auto;
    width: auto;
    line-height: 22px;
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    margin: 5px 0 0;
  `,

  mobLeftProfileLocation: css`
    height: 13px;
    width: auto;
    line-height: 15px;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.secondary};
    margin: 7px 0 0;
    font-size: 15px;
    text-align: left;
  `,

  mobSettingsText: css`
    height: auto;
    width: auto;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0;
  `,

  mobRequestBoxHeader: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 10px;
  `,

  deskDetailName: css`
    height: auto;
    line-height: 25px;
    line-height: 25px;
    color: ${p => p.theme.colors.gray};
    font-size: 18px;
    text-align: left;
    margin: 2px 0 0;
  `,

  deskDetail: css`
    color: ${p => p.theme.colors.primary};
    font-family: 'Open Sans', serif;
    font-weight: bold;
    text-align: left;
  `,
}
