import React from 'react'
import PropTypes from 'prop-types'

import {
  Image,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { RatingModule, RatingPosition } from '../RatingModule'
import { userPropTypes } from '../../classes/Models/User'
import { ProfileDetailsModule } from '../ProfileDetailsModule'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'
import { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

export const ProfileAlignment = {
  left: 1,
  center: 2,
}
const { mobCenteredProfilePhoto, mobLeftProfilePhoto } = mobImageVariants
const {
  mobCenteredProfileLocation,
  mobCenteredProfileName,
  mobLeftProfileLocation,
  mobLeftProfileName,
} = mobTextVariants
const { deskLeftProfilePhoto, deskProfilePhoto } = deskImageVariants
const { column } = mobBoxVariants

const CenteredProfile = user => {
  const history = useHistory()

  return (
    <>
      <Image
        src={user.getProfileImage()}
        mobStyles={[mobCenteredProfilePhoto]}
        deskStyles={[
          deskProfilePhoto,
          css`
            margin: 0 auto;
          `,
        ]}
        id="user-profile-image"
        onClick={() => history.push(ROUTES.PROFILE.base + user.userId)}
      />

      <div id={'user-details-box'}>
        <Text mobStyles={mobCenteredProfileName}>{user.fullName}</Text>
        <Text mobStyles={mobCenteredProfileLocation}>{user.getUserCity()}</Text>
        <RatingModule
          rating={user.rating}
          ratingPosition={RatingPosition.center}
        />
        <ProfileDetailsModule user={user} />
      </div>
    </>
  )
}

const LeftProfile = user => {
  const history = useHistory()

  return (
    <>
      <Image
        src={user.getProfileImage()}
        mobStyles={[mobLeftProfilePhoto]}
        deskStyles={[deskLeftProfilePhoto]}
        onClick={() => history.push(ROUTES.PROFILE.base + user.userId)}
      />
      <Text mobStyles={[mobLeftProfileName]}>{user.fullName}</Text>
      <Text mobStyles={[mobLeftProfileLocation]}>{user.getUserCity()}</Text>
      <RatingModule ratingPosition={RatingPosition.left} />
      <ProfileDetailsModule user={user} />
    </>
  )
}

/**
 *
 * @param {User} user
 * @param profileAlignment
 * @returns {null|*}
 * @constructor
 */
export const ProfileModule = ({ user, profileAlignment }) => {
  switch (profileAlignment) {
    case ProfileAlignment.left:
      return LeftProfile(user)
    case ProfileAlignment.center:
      return CenteredProfile(user)
    default:
      return null
  }
}

ProfileModule.propTypes = {
  ...userPropTypes,
  ratingPosition: PropTypes.number,
}
