import { css } from 'styled-components'

export const mobFormVariants = {
  darkGreyBackground: css`
    // this is just an example
    background-color: ${p => p.theme.colors.darkGray};
  `,
  authInputMobile: css`
    height: 48px;
    width: 309px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
  `,

  mobImageForm: css`
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    width: 90vw;
    height: 90vw;
    :after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  `,
}
