import React, { useState } from 'react'
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore'
import get from 'lodash/get'
import styled from 'styled-components'

import { collections } from '../../firebaseApp'
import { MobileChatItem } from './MobileChatItem'
import { LoadingScreen } from '../../components/LoadingScreen'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { MobileInbox } from './MobileInbox'
import { DesktopInbox } from './DesktopInbox'
import { DesktopChatItem } from './DesktopChatItem'
import { useUserUnreadMessages } from '../../utils/useUserUnreadMessages'
import LazyLoad from 'react-lazyload'

export const EmptyChatInboxText = styled.div`
  width: 100%;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 27px;
  color: ${p => p.theme.fonts.primary};
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 30px;
  text-align: center;
  display: flex;
  margin-top: 50px;
`

export const InboxPage = ({ currentUserAuth }) => {
  const isDesktop = useMediaQuery(desktopQuery)

  const [
    privateUserSnapshot,
    privateUserSnapshotLoading,
    privateUserSnapshotError,
  ] = useDocument(collections.privateUserInformation.doc(currentUserAuth.uid))

  const [
    userChatRooms,
    userChatRoomsLoading,
    userChatRoomsError,
  ] = useCollectionData(
    collections.chatRooms.where(
      'members',
      'array-contains',
      currentUserAuth.uid
    ),
    {
      idField: 'id',
    }
  )
  const [chatRoomsObject, setChatRoomsObject] = useState({})
  const [, unreadObject] = useUserUnreadMessages(currentUserAuth)

  /**
   * Uses the chatroom id to hydrate info about
   * user B (The user the current user is chatting with)
   *
   * It then sorts them with the last updated at the top
   *
   * @param chatRooms
   * @returns {*}
   */
  const getSortedMobileChats = chatRooms => {
    const chatRoomItems = chatRooms.map(chatroom => {
      return (
        <LazyLoad height={100}>
          <MobileChatItem
            key={chatroom.id}
            chatRoomId={chatroom.id}
            currentUserId={currentUserAuth.uid}
            chatRoomsObject={chatRoomsObject}
            setChatRoomsObject={setChatRoomsObject}
            unreadObject={unreadObject}
          />
        </LazyLoad>
      )
    })

    return <Sort>{chatRoomItems}</Sort>
  }

  const getSortedDesktopChats = chatRooms => {
    const chatRoomItems = chatRooms.map(chatroom => {
      return (
        <LazyLoad height={100}>
          <DesktopChatItem
            key={chatroom.id}
            chatRoomId={chatroom.id}
            currentUserId={currentUserAuth.uid}
            chatRoomsObject={chatRoomsObject}
            setChatRoomsObject={setChatRoomsObject}
            unreadObject={unreadObject}
          />
        </LazyLoad>
      )
    })

    return <Sort>{chatRoomItems}</Sort>
  }

  const dependencies = userChatRooms && privateUserSnapshot
  const dependenciesLoading = userChatRoomsLoading || privateUserSnapshotLoading
  const dependenciesError = userChatRoomsError || privateUserSnapshotError
  // TODO: Enable chat inbox search V2-3
  //  {false && <ChatSearchBar />}
  if (dependenciesError) {
    return <strong>Error</strong>
  }

  if (dependenciesLoading) {
    return <LoadingScreen />
  }

  if (dependencies) {
    if (isDesktop) {
      return (
        <DesktopInbox
          getSortedChats={getSortedDesktopChats}
          userChatRooms={userChatRooms}
          currentUserAuth={currentUserAuth}
        />
      )
    } else {
      return (
        <MobileInbox
          getSortedChats={getSortedMobileChats}
          userChatRooms={userChatRooms}
        />
      )
    }
  }
}

/**
 * This component converts its children into an array and then sorts them by
 * some arbitrary property.
 *
 * Currently used to sort the chat threads by the time they were last updated.
 *
 * @param children
 * @returns {Array<Exclude<React.ReactNode, boolean | null | undefined>>}
 * @constructor
 */
const Sort = ({ children }) => {
  return React.Children.toArray(children).sort((a, b) => {
    const chatRoomAId = a.props['chatRoomId']
    const chatRoomBId = b.props['chatRoomId']

    const chatRoomALastTimeUpdated = get(
      a,
      `props.chatRoomsObject.${chatRoomAId}.chatRoomLastUpdated.seconds`,
      `props.chatRoomsObject.${chatRoomAId}.lastMessageSentTime.seconds`,
      0
    )
    const chatRoomBLastTimeUpdated = get(
      b,
      `props.chatRoomsObject.${chatRoomBId}.chatRoomLastUpdated.seconds`,
      `props.chatRoomsObject.${chatRoomBId}.lastMessageSentTime.seconds`,
      0
    )

    return chatRoomALastTimeUpdated > chatRoomBLastTimeUpdated ? -1 : 1
  })
}
