import { css } from 'styled-components'

export const deskInputVariants = {
  darkGreyBackground: css`
    // this is just an example
    background-color: ${p => p.theme.colors.darkGray};
  `,
  authInputMobile: css`
    height: 48px;
    width: 309px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
  `,
  deskInput: css`
    text-indent: 15px;
    width: 100%;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 16px;
    line-height: 22px;
    height: 48px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    color: ${p => p.theme.colors.darkGray};
    margin: 0 0 15px;
    box-shadow: ${p => p.theme.shadow.regular};
    -webkit-appearance: none;
    ::placeholder {
      color: ${p => p.theme.colors.darkGray};
      opacity: 0.5;
    }
  `,
  mobSelectInput: css`
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 16px;
    line-height: 22px;
    padding-left: 15px;
    height: 48px;
    width: 100%;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    color: ${p => p.theme.colors.darkGray};
    margin: 0 0 15px;
    -webkit-appearance: none;
    box-shadow: ${p => p.theme.shadow.regular};
    ::placeholder {
      color: ${p => p.theme.colors.darkGray};
      opacity: 0.5;
    }
  `,

  mobSearchBarInput: css`
    width: 100%;
    margin: 0;
    font-family: ${p => p.theme.fonts.primary};
    font-size: 16px;
    line-height: 22px;
    height: 48px;
    background-color: transparent;
    color: ${p => p.theme.colors.darkGray};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-appearance: none;
    ::placeholder {
      color: ${p => p.theme.colors.darkGray};
      opacity: 0.44;
    }
  `,
}
