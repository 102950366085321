import React, { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Portal } from './Portal'
import {
  buttonPosition,
  ModalBackground,
  ModalContentContainer,
  ModalPositioner,
} from './styles'
import { Button, mobButtonVariants } from '../index'

const CloseButton = ({ handleCloseModal }) => {
  return (
    <Button
      onClick={handleCloseModal}
      mobStyles={[mobButtonVariants.smallCircleButton, buttonPosition]}
    >
      <IconContext.Provider value={{ size: '5em' }}>
        <FaTimes />
      </IconContext.Provider>
    </Button>
  )
}

export const Modal = ({
  children,
  handleCloseModal = () => {},
  isOpen = false,
}) => {
  const modalContentRef = useRef(null)

  const handleClickOutside = event => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      handleCloseModal()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <Portal>
      {isOpen && (
        <ModalBackground>
          <ModalPositioner>
            <ModalContentContainer ref={modalContentRef}>
              <CloseButton handleCloseModal={handleCloseModal} />
              {children}
            </ModalContentContainer>
          </ModalPositioner>
        </ModalBackground>
      )}
    </Portal>
  )
}
