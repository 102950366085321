import { css } from 'styled-components'

export const mobLinkVariants = {
  whiteText: css`
    color: ${p => p.theme.colors.secondary};
  `,
  navLink: css``,
  column: css`
    flex-direction: column;
  `,
  row: css`
    flex-direction: row;
  `,

  // Link Style to look like the mobSubmitButton
  mobButtonLink: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2.4px;
    border-radius: 30px;
    background-color: ${p => p.theme.colors.primary};
    font-size: 14px;
    color: ${p => p.theme.colors.secondary};
    line-height: 40px;
    width: 100%;
    box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
  `,
  mobLong305x46: css`
    height: 46px;
    width: 100%;
  `,

  mobFootLink: css`
    margin-bottom: 25px;
    color: ${p => p.theme.colors.secondary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    :focus {
      outline: thin dotted ${p => p.theme.colors.primary};
    }
    :hover {
      color: ${p => p.theme.colors.secondary};
    }
  `,

  mobIconLinkWrapper: css`
    margin: 15%;
    :focus {
      outline: thin dotted ${p => p.theme.colors.primary};
    }
  `,

  mobProfileLink: css`
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 20px;
    text-align: center;
  `,

  mobInTextLink: css`
    margin: 0;
    text-decoration: underline;
  `,
}
