import { Box, deskBoxVariants, mobBoxVariants } from '../../../shared/Box'
import Loader from 'react-loader-spinner'
import React from 'react'

const { mobImageLoader } = mobBoxVariants
const { deskImageLoader } = deskBoxVariants

export const ImageInputLoader = () => {
  return (
    <Box mobStyles={[mobImageLoader]} deskStyles={[deskImageLoader]}>
      <Loader type="Puff" color="#7c848b" height={300} width={300} />
    </Box>
  )
}
