import React, { useRef, useState } from 'react'
import 'firebase/storage'
import { css } from 'styled-components'
import firebase from 'firebase/app'

import { collections } from '../../firebaseApp'
import {
  Box,
  Button,
  inputVariants,
  mobBoxVariants,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Option,
  Select,
  Text,
} from '../../shared'
import {
  DesktopPageWrapper,
  LoadingButton,
  TextAreaWithHeader,
} from '../../components'
import { SupportRequestForm } from '../../classes/Forms/SupportRequestForm'
import { SupportTicketStatus, SupportTicketType } from '../../constants/enums'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const {
  mobPageHeader,
  mobPageSubHeader,
  mobInstructionParagraph,
} = mobTextVariants
const { mobSubmitButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { mobInputHeader } = mobTextVariants
const { mobSelectInput } = inputVariants

const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

const mobSupportSubmitButton = css`
  margin: 20px 0 40px 0;
`

/**
 * User should be able to create/view support requests.
 * Bugs, Reports against users, Feature Requests, Etc...
 *
 * @constructor
 */
export const GuestSupportPage = () => {
  const [submitFormDisabled, setSubmitFormDisabled] = useState(true)
  const [showLoading, setShowLoading] = useState(false)
  const toggleSubmitButton = disable => {
    setSubmitFormDisabled(disable)
  }

  // Only use ref for Classes and form that will never receive pre-filled data
  const supportRequestForm = useRef(new SupportRequestForm(toggleSubmitButton))
    .current
  const [requestType, setRequestType] = useState(SupportTicketType.general)
  const updateRequestType = event => {
    setRequestType(event.target.value)
  }

  const handleSupportRequestSubmit = event => {
    event.preventDefault()
    setShowLoading(true)

    if (supportRequestForm.validSupportRequestData()) {
      collections.guestSupportTickets
        .add({
          createdBy: supportRequestForm.supportRequestEmail,
          supportRequest: supportRequestForm.supportRequest,
          requestType: Number(requestType),
          status: SupportTicketStatus.received,
          additionalInfo: supportRequestForm.additionalInfo || '',
          createdAt: firebase.firestore.Timestamp.now(),
        })
        .then(ref => {
          setShowLoading(false)
          // TODO: Maybe tick a stat to easily track how many of these we get V2
          // TODO: Clear form V2
        })
        .catch(error => {
          // TODO: Improve showing the error state visually. V2
          errorHandler({
            error,
            errorId: 'ERROR_SUPPORT_FORM',
            message: 'Error while user trying to create a SUPPORT request.',
            type: errorTypes.support,
            file: 'SupportPage.jsx',
          })
        })
    }
  }

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={[deskPageHeader]}>
          Support Center
        </Text>
        <Text
          mobStyles={mobInstructionParagraph}
          deskStyles={[deskInstructionParagraph]}
        >
          Here you can create a support ticket for:
        </Text>
        <ul>
          <li>Canva API bugs.</li>
          <li>Report bugs.</li>
          <li>Request a feature.</li>
          <li>View ongoing disputes with other users.</li>
          <li>View reports against other users.</li>
          <li>Anything else. Literally.</li>
        </ul>
        <Text mobStyles={mobPageSubHeader}>Open Tickets</Text>

        <Text mobStyles={mobInstructionParagraph}>
          Please include all relevant details below. All fields marked with (*)
          are mandatory. The support request must be over 15 characters to
          enable the submit button.
        </Text>
        <Text mobStyles={mobInputHeader}>Issue Type*</Text>
        <Select
          mobStyles={[mobSelectInput]}
          name="accountType"
          onChange={updateRequestType}
          value={requestType}
        >
          <Option value={SupportTicketType.canva}>Canva API</Option>
          <Option value={SupportTicketType.general}>General</Option>
          <Option value={SupportTicketType.bug}>Bug</Option>
          <Option value={SupportTicketType.account}>Account</Option>
          <Option value={SupportTicketType.feature}>Feature Request</Option>
          <Option value={SupportTicketType.identity}>Identity</Option>
          <Option value={SupportTicketType.payment}>Payment</Option>
          <Option value={SupportTicketType.other}>Other</Option>
        </Select>
        <TextAreaWithHeader
          header="Email*"
          inputName="support-request-email"
          placeholderDefault="model@bookamodel.com"
          onChange={supportRequestForm.updateSupportRequestEmail}
        />
        <TextAreaWithHeader
          header="Support Request*"
          inputName="support-request"
          placeholderDefault="Issue, feature request, etc..."
          onChange={supportRequestForm.updateSupportRequest}
        />

        <TextAreaWithHeader
          header="Additional Information"
          inputName="additional-information"
          placeholderDefault="Add extra information about your request here"
          onChange={supportRequestForm.updateAdditionalInfo}
        />
        {showLoading && <LoadingButton mobStyles={mobSupportSubmitButton} />}
        {!showLoading && (
          <Button
            onClick={handleSupportRequestSubmit}
            mobStyles={[mobSubmitButton, mobLong305x46, mobSupportSubmitButton]}
            disabled={submitFormDisabled}
          >
            CREATE SUPPORT TICKET
          </Button>
        )}
      </Box>
    </DesktopPageWrapper>
  )
}
