import { useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

let portalRoot = document.getElementById('modal-portal')
if (!portalRoot) {
  portalRoot = document.createElement('div')
  portalRoot.setAttribute('id', 'modal-portal')
  portalRoot.setAttribute('data-testid', 'modal-portal')
  document.body.appendChild(portalRoot)
}

export const Portal = ({ children }) => {
  const el = useRef(document.createElement('div'))

  useLayoutEffect(() => {
    const currentEl = el.current

    portalRoot.appendChild(currentEl)

    return () => portalRoot.removeChild(currentEl)
  }, [])

  return createPortal(children, el.current)
}
