import { css } from 'styled-components'

export const mobSearchBarIcon = css`
  position: absolute;
  width: 15%;
  height: 50px;
  background-color: ${p => p.theme.fonts.primary};
  left: 85%;
  font-size: 18px;
  color: ${p => p.theme.colors.secondary};

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: ${p => p.theme.shadow.regular};
`

export const mobSearchIcon = css`
  margin: 30% auto 0;
`

export const mobSearchBarWrapper = css`
  position: relative;
`

export const mobModelRequestSearchInput = css`
  width: 100%;
  font-family: ${p => p.theme.fonts.primary};
  font-size: 16px;
  line-height: 22px;
  padding-left: 15px;
  padding-right: 60px;
  height: 48px;
  border-radius: 5px;
  background-color: transparent;
  color: ${p => p.theme.colors.darkGray};
  margin: 0 0 15px;
  box-shadow: ${p => p.theme.shadow.regular};
  ::placeholder {
    color: ${p => p.theme.colors.darkGray};
    opacity: 0.5;
  }
`
