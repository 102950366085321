import firebase from 'firebase/app'
import get from 'lodash/get'

/**
 * User object constructed from the user information Snapshot
 *
 */
export class PrivateUser {
  privateUser
  privateUserData
  userId

  stripeCustomer

  accountConnected
  accountLinkedDate
  accountUnlinkedDate
  approxStripeCustomerCreation
  billingInfoLastUpdate
  hasBillingInfo
  hasStripeCustomer
  paymentMethod
  stripeAccountId
  stripeCustomerId
  onboardingComplete

  identityVerification
  identityVerificationStatus

  chatRooms

  textNotificationsEnabled

  constructor(privateUser) {
    if (privateUser) {
      this.userId = privateUser.id || undefined
      this.privateUser = privateUser
      this.privateUserData =
        this.privateUser && this.privateUser.data
          ? this.privateUser.data()
          : this.privateUser
    }

    // stripeCustomer: {
    //     stripeCustomerId: stripeCustomerId,
    //     hasStripeCustomer: true,
    //     approxStripeCustomerCreation: timestamp,
    //     hasBillingInfo: true,
    //     billingInfoLastUpdate: timestamp,
    // },

    // Stripe Customer Fields
    this.stripeCustomer = get(this.privateUserData, 'stripeCustomer', {})
    this.stripeCustomerId = get(this.stripeCustomer, 'stripeCustomerId', '')
    this.hasStripeCustomer = get(
      this.stripeCustomer,
      'hasStripeCustomer',
      false
    )
    this.approxStripeCustomerCreation = get(
      this.stripeCustomer,
      'approxStripeCustomerCreation',
      firebase.firestore.Timestamp.now()
    )
    this.hasBillingInfo = get(this.stripeCustomer, 'hasBillingInfo', false)
    this.billingInfoOutdated = get(
      this.stripeCustomer,
      'billingInfoOutdated',
      false
    )
    this.billingInfoLastUpdate = get(
      this.stripeCustomer,
      'billingInfoLastUpdate',
      firebase.firestore.Timestamp.now()
    )
    // this.paymentMethod = get(this.stripeCustomer, 'paymentMethod', '')

    // Stripe Account Fields
    this.stripeAccount = get(this.privateUserData, 'stripeAccount', {})
    this.stripeAccountLastUpdated = get(
      this.privateUserData,
      'stripeAccountLastUpdated',
      firebase.firestore.Timestamp.now()
    )
    this.stripeAccountId = get(this.stripeAccount, 'stripeAccountId', null)
    this.accountLinkedDate = get(this.stripeAccount, 'accountLinkedDate', null)
    this.accountConnected = get(this.stripeAccount, 'accountConnected', false)
    this.accountScope = get(this.stripeAccount, 'accountScope', null)
    this.onboardingComplete = get(
      this.stripeAccount,
      'onboardingComplete',
      false
    )
    this.accountLinkCompleted = get(
      this.stripeAccount,
      'accountLinkCompleted',
      firebase.firestore.Timestamp.now()
    )

    this.identityVerification = get(
      this.privateUserData,
      'identityVerification',
      {}
    )
    // This will always display the verification button if the status isn't found
    this.identityVerificationStatus = get(
      this.identityVerification,
      'status',
      0
    )

    this.identityVerified = get(this.identityVerification, 'verified', false)

    this.chatRooms = get(this.privateUserData, 'chatRooms', [])

    //     this.stripeAccount = get(this.privateUserData, 'stripeAccount', {})
    this.phoneVerification = get(this.privateUserData, 'phoneVerification', {})

    this.codeStatus = get(this.phoneVerification, 'codeStatus', null)
    this.unverifiedNumber = get(
      this.phoneVerification,
      'unverifiedNumber',
      null
    )
    this.codeSentAt = get(this.phoneVerification, 'codeSentAt', [])
    this.verifiedAt = get(this.phoneVerification, 'verifiedAt', [])
    this.lastCodeSentAt = get(
      this.phoneVerification,
      'lastCodeSentAt',
      firebase.firestore.Timestamp.now()
    )
    this.verificationAttempt = get(
      this.phoneVerification,
      'verificationAttempt',
      []
    )
    this.number = get(this.phoneVerification, 'number', null)
    this.textNotificationsEnabled = get(
      this.privateUserData,
      'textNotificationsEnabled',
      false
    )
  }

  codeSentInLast30Mins = () => {
    if (!this.lastCodeSentAt) {
      return false
    }
    const halfHourInSeconds = 1800
    const nowSeconds = firebase.firestore.Timestamp.now().seconds
    const lastCodeSentAt = this.lastCodeSentAt.seconds
    return nowSeconds - lastCodeSentAt <= halfHourInSeconds
  }

  getStripeAccountId = () => {
    return this.stripeAccountId
  }

  getUserData = () => {
    return this.privateUserData
  }

  getUserDoc = () => {
    return this.privateUser
  }
}
