import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { LoadingScreen } from '../../components/LoadingScreen'
import { ProfileAlignment, ProfileModule } from '../../modules/ProfileModule'
import { RequestActionModule } from '../../modules/RequestActionModule'
import { CashModule } from '../../modules/CashModule'
import React from 'react'
import {
  Box,
  Image,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared/'
import { Booking, Gig, Request, User } from '../../classes'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'

const { column, mobWrapper } = mobBoxVariants
const { deskPageHeader } = deskTextVariants
const {
  mobPageHeader,
  mobGigBoxLocation,
  mobGigDate,
  mobGigBoxDescription,
} = mobTextVariants
const { mobGigLocationImage } = mobImageVariants
const { deskGigLocationImage } = deskImageVariants

/**
 *
 * @param currentUserAuth
 * @param {Request} request
 * @returns {*}
 * @constructor
 */
export const ViewProposalPageModule = ({ currentUserAuth, request }) => {
  const isOwner = request.isOwner(currentUserAuth.uid)
  const userToDisplayId = isOwner
    ? request.requestCreator
    : request.eventCreator

  const [
    userSnapshot,
    userSnapshotLoading,
    userSnapshotError,
  ] = useDocumentData(collections.userInformation.doc(userToDisplayId), {
    idField: 'id',
  })

  const [gigSnapshot, gigSnapshotLoading, gigSnapshotError] = useDocumentData(
    collections.gigs.doc(request.eventId),
    {
      idField: 'id',
    }
  )

  const [
    operativeSnapshot,
    operativeSnapshotLoading,
    operativeSnapshotError,
  ] = useDocumentData(collections.userInformation.doc(request.operative), {
    idField: 'id',
  })

  const dependencies = userSnapshot && gigSnapshot && operativeSnapshot
  const dependenciesLoading =
    userSnapshotLoading || gigSnapshotLoading || operativeSnapshotLoading
  const dependenciesError =
    userSnapshotError || gigSnapshotError || operativeSnapshotError

  return (
    <DesktopPageWrapper>
      {dependenciesError && <strong>Error: {dependenciesError}</strong>}
      {dependenciesLoading && <LoadingScreen />}
      {dependencies && (
        <Box mobStyles={[column, mobWrapper]}>
          <ProfileModule
            user={new User(userSnapshot)}
            profileAlignment={ProfileAlignment.center}
          />
          <RequestActionModule
            booking={
              new Booking(
                new Gig(gigSnapshot),
                new User(operativeSnapshot),
                request
              )
            }
            currentUserAuth={currentUserAuth}
          />
          <br />
          <br />
          <br />
          <CashModule
            booking={
              new Booking(
                new Gig(gigSnapshot),
                new User(operativeSnapshot),
                request
              )
            }
            currentUserAuth={currentUserAuth}
          />
          <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
            {gigSnapshot.title}
          </Text>
          <Text mobStyles={mobGigDate}>
            {new Gig(gigSnapshot).getDateString()}
          </Text>
          <Text mobStyles={mobGigBoxLocation}>{gigSnapshot.address}</Text>
          <Text mobStyles={mobGigBoxDescription}>
            {gigSnapshot.description}
          </Text>
          <Image
            src={new Gig(gigSnapshot).locationMobileImageUrl}
            mobStyles={mobGigLocationImage}
            deskStyles={deskGigLocationImage}
          />
        </Box>
      )}
    </DesktopPageWrapper>
  )
}
