import React, { useState } from 'react'
import {
  Box,
  Image,
  Link,
  mobBoxVariants,
  mobButtonVariants,
  mobImageVariants,
  Modal,
} from '../../shared/index'
import { css } from 'styled-components'
import { collections } from '../../firebaseApp'
import { uuid } from '../../utils/uuid'
import 'firebase/storage'
import { LicenseImage } from '../../classes/Models/LicenseImage'
import { LicenseImageType } from '../../constants/enums'
import { LicenseImageOwnerModule } from '../../modules/LicenseImageOwnerModule'
import Loader from 'react-loader-spinner'

const { column, center } = mobBoxVariants
const { mobImageSquare } = mobImageVariants
const { mobMiniInformativeButton, mobSubmitButton } = mobButtonVariants

const deleteButtonStyles = css`
  background-color: ${p => p.theme.colors.red};
  width: 75%;
  margin-top: 20px;
`

const mobImageInfoTileStyles = css`
  border-radius: 5px;
  width: 100%;
  margin: 5px 0 5px 0;
  box-shadow: ${p => p.theme.shadow.regular};
  max-height: 150px;
  height: 150px;
  border: 1px solid ${p => p.theme.colors.lightGray};
`

const deskImageInfoTileStyles = css`
  height: 200px;
  width: 40%;
`

const deskButtonStyles = css`
  width: 200px;
  font-size: 16px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
`
const mobModalImageStyles = css`
  object-fit: cover;
  max-width: 90%;
  max-height: 320px;
  box-shadow: ${p => p.theme.shadow.regular};
  border-radius: 5px;
`

const deskModalImageStyles = css`
  object-fit: cover;
  max-width: 680px;
  height: auto;
  max-height: 600px;
  width: 90%;
`

const deskImageThumbnailStyles = css`
  height: 100%;
  width: auto;
`

const mobThumbnailImageStyles = css`
  margin: 0;
  height: 150pxx;
  object-fit: cover;
  max-width: 300px;
  width: 100%;
`

const deskImageWrapperStyles = css`
  min-width: 300px;
`

/**
 * Component that takes an instance of LicenseImage and displays the image
 * and includes a modal to display the large version of the image upon clicking
 *
 * @param {LicenseImage} licenseImage
 * @param {string} currentUserId
 * @returns {*}
 * @constructor
 */
export const LicenseImageWithModal = ({
  licenseImage,
  userId: currentUserId,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const deleteLicenseImageHandler = () => {
    const { userInformation } = collections
    //
    // userInformation
    //   .doc(currentUserId)
    //   .collection('portfolioImages')
    //   .doc(licenseImage.id)
    //   .delete()
    //   .then(() => {
    //     // Only delete the actual image if we successfully delete the reference to the image from the
    //     // user's information
    //     portfolioImagesStorage
    //       .ref()
    //       .child(
    //         `user-portfolio-images/${currentUserId}/user-portfolio-image-${licenseImage.uniqueId}`
    //       )
    //       .delete()
    //       .then(() => {
    //         // image successfully deleted
    //         setModalOpen(false)
    //       })
    //       .catch(error => {
    //         errorHandler({
    //           error,
    //           errorId: 'ERROR_LICENSED_IMAGE_DELETE',
    //           message: 'Error occurred while user trying to delete image.',
    //           type: errorTypes.profile,
    //           userId: currentUserId,
    //           file: 'LicenseImageWithModal.jsx',
    //         })
    //       })
    //   })
    //   .catch(error => {
    //     errorHandler({
    //       error,
    //       errorId: 'ERROR_FETCH_PORTFOLIO_IMAGES',
    //       message: 'Error occurred while fetching portfolio images.',
    //       type: errorTypes.profile,
    //       userId: currentUserId,
    //       file: 'LicenseImageWithModal.jsx',
    //     })
    //   })
  }

  // Error red. Keeping here until I plan to use it
  // #fd5f60

  return (
    <>
      <Box
        mobStyles={mobImageInfoTileStyles}
        deskStyles={deskImageInfoTileStyles}
      >
        <Box deskStyles={[deskImageWrapperStyles]}>
          <Image
            key={uuid()}
            src={licenseImage.largeSizeUrl}
            mobStyles={[mobImageSquare, mobThumbnailImageStyles]}
            deskStyles={deskImageThumbnailStyles}
            onClick={() => {
              setModalOpen(!modalOpen)
            }}
            data-testid="clickable-image-modal"
          />
        </Box>
        <Box
          mobStyles={[
            column,
            center,
            css`
              width: 100%;
            `,
          ]}
        >
          <Link
            to={licenseImage.licenseMonthlyLink}
            mobStyles={[mobMiniInformativeButton]}
            deskStyles={deskButtonStyles}
          >
            Monthly
            <br />
            {licenseImage.getUSDFormattedSubtotal(LicenseImageType.monthly)}
          </Link>
          <Link
            to={licenseImage.licenseExclusiveLink}
            mobStyles={[mobMiniInformativeButton]}
            deskStyles={deskButtonStyles}
          >
            Exclusive
            <br />
            {licenseImage.getUSDFormattedSubtotal(LicenseImageType.exclusive)}
          </Link>
        </Box>
      </Box>

      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => {
          setModalOpen(false)
        }}
      >
        <Box
          mobStyles={[
            column,
            center,
            css`
              width: 100%;
              height: auto;
            `,
          ]}
        >
          <Image
            mobStyles={mobModalImageStyles}
            deskStyles={deskModalImageStyles}
            src={licenseImage.largeSizeUrl}
            onClick={() => {
              setModalOpen(!modalOpen)
            }}
            data-testid="image-in-modal"
          />
          <LicenseImageOwnerModule
            deleteLicenseImageHandler={deleteLicenseImageHandler}
            userId={currentUserId}
            licenseImage={licenseImage}
          />
        </Box>
      </Modal>
    </>
  )
}

const LicenseImageLoader = () => {
  return <Loader type="ThreeDots" color="#ffffff" height={50} width={50} />
}
