import React, { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { CardSection } from './CardSection'
import { Form } from '../../shared/Form'
import { auth, functions } from '../../firebaseApp'
import { AccountSettingsForm } from '../../classes'
import { InputWithHeader } from '../Inputs/InputWithHeader'
import { Button, mobButtonVariants } from '../../shared/Button'
import { css } from 'styled-components'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { LoadingButton } from '../LoadingButton'
import { Box, mobBoxVariants } from '../../shared/Box'

const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants
const { column, row } = mobBoxVariants

/**
 * Stripe form component that tokenizes credit card information
 * sends it to Stripe and then provides us with the payment method
 * string. We then create the customer a Striper Customer instance
 * and use the provided credit card information as their primary payment
 * method.
 *
 * We then grab the customer and search for payment methods later on
 * to find a card to charge.
 *
 * @param props
 * @constructor
 */
export const CustomerForm = ({ flattenedInputs }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [currentUserAuth] = useAuthState(auth)
  const accountSettingsForm = new AccountSettingsForm()
  const generateSetupIntent = functions.httpsCallable('generateSetupIntent')
  const createStripeCustomer = functions.httpsCallable('createStripeCustomer')
  const [customerCreationLoading, setCustomerCreationLoading] = useState(false)

  // You must be inside a component wrapped by injectStripe() to have access to the
  // props.stripe instance
  // https://github.com/stripe/react-stripe-elements#setting-up-your-payment-form-injectstripe
  // This function does the following:
  // 1. Use generateSetupIntent to call Stripe to generate a SetupIntent and use the client_secret from the response
  // 2. Use confirmCardSetup with card data entered by the user and the client_secret to save their card as a PaymentMethod
  // 3. Use createStripeCustomer to intialize the user as a customer in Stripe with the card as their PaymentMethod
  const submitCreateStripeCustomer = async event => {
    setCustomerCreationLoading(true)
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)
    if (accountSettingsForm.validBillingData()) {
      // Needed to setup a payment method for future payments as
      // opposed to just charging the card once
      // https://stripe.com/docs/payments/setup-intents
      try {
        const generateSetupIntentResponse = await generateSetupIntent()
        const clientSecret = generateSetupIntentResponse.data.clientSecret
        const confirmCardSetupResponse = await stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: accountSettingsForm.billingName,
                address: {
                  city: accountSettingsForm.city,
                  country: accountSettingsForm.country,
                  line1: accountSettingsForm.addressLine1,
                  line2: accountSettingsForm.addressLine2,
                  // zip is pulled from the cardElement.
                  //postal_code: accountSettingsForm.zip,
                  state: accountSettingsForm.state,
                },
              },
            },
          }
        )

        if (confirmCardSetupResponse.error) {
          // Display error.message in your UI.
          errorHandler({
            error: confirmCardSetupResponse.error,
            message:
              'Error occurred while updating user billing, likely a user input error.',
            type: errorTypes.billing,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'InjectedCustomerForm.jsx',
          })
        } else {
          const paymentMethod =
            confirmCardSetupResponse.setupIntent.payment_method
          // The setup has succeeded. Display a success message.
          // Create a customer and save the payment method to the newly
          // created customer
          await createStripeCustomer({
            paymentMethod: paymentMethod,
            billingEmail: accountSettingsForm.billingEmail,
            billingName: accountSettingsForm.billingName,
            city: accountSettingsForm.city,
            country: accountSettingsForm.country,
            addressLine1: accountSettingsForm.addressLine1,
            addressLine2: accountSettingsForm.addressLine2,
            state: accountSettingsForm.state,
          })
          setCustomerCreationLoading(false)
        }
      } catch (error) {
        errorHandler({
          error,
          errorId: 'ERROR_UPDATING_USER_BILLING',
          message:
            'Error occurred while updating user billing, likely an api/code error.',
          type: errorTypes.billing,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'InjectedCustomerForm.jsx',
        })
      }
    } else {
      // TODO: Eventually we can tell them what is wrong with the form.
      // form incomplete so do nothing
    }
  }

  if (flattenedInputs) {
    return (
      <>
        <Form id="stripe-checkout-form" onSubmit={submitCreateStripeCustomer}>
          <Box deskStyles={[row]} mobStyles={[column]}>
            <Box
              deskStyles={[
                column,
                css`
                  flex: 1;
                  padding: 25px 10px 0 25px;
                `,
              ]}
              mobStyles={[
                column,
                css`
                  flex: 1;
                `,
              ]}
            >
              <CardSection />

              <InputWithHeader
                header="BILLING NAME"
                inputName="billingName"
                onChange={accountSettingsForm.updateBillingName}
                placeholderDefault={
                  'Credit C. Holder' || currentUserAuth.emailVerified
                }
              />
              <InputWithHeader
                header="ADDRESS LINE 2"
                inputName="addressLine2"
                onChange={accountSettingsForm.updateAddressLine2}
                placeholderDefault="Floor, Apt, ect"
              />
              <InputWithHeader
                header="STATE"
                inputName="state"
                onChange={accountSettingsForm.updateState}
                placeholderDefault="Example: CA"
              />
            </Box>
            <Box
              deskStyles={[
                column,
                css`
                  flex: 1;
                  padding: 25px 10px 0 25px;
                `,
              ]}
              mobStyles={[
                column,
                css`
                  flex: 1;
                  padding: 25px 10px 0 10px;
                `,
              ]}
            >
              <InputWithHeader
                header="EMAIL ADDRESS"
                inputName="billingEmail"
                onChange={accountSettingsForm.updateBillingEmail}
                placeholderDefault="email@address.com"
                value={currentUserAuth.email}
              />
              <InputWithHeader
                header="ADDRESS LINE 1"
                inputName="addressLine1"
                onChange={accountSettingsForm.updateAddressLine1}
                placeholderDefault="Street name"
              />

              <InputWithHeader
                header="CITY"
                inputName="city"
                onChange={accountSettingsForm.updateCity}
                placeholderDefault="Example: San Diego"
              />
              <InputWithHeader
                header="COUNTRY"
                inputName="country"
                disabled={true}
                placeholderDefault="US"
              />
            </Box>
          </Box>
        </Form>
        {!customerCreationLoading && (
          <Box
            deskStyles={[
              column,
              css`
                padding: 40px 10px 0 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              `,
            ]}
            mobStyles={[
              column,
              css`
                padding: 40px 10px 0 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              `,
            ]}
          >
            <Button
              deskStyles={[deskSubmitButton]}
              mobStyles={[
                mobSubmitButton,
                mobLong305x46,
                css`
                  width: 100%;
                `,
              ]}
              type="submit"
              form="stripe-checkout-form"
            >
              SUBMIT
            </Button>
          </Box>
        )}
        {customerCreationLoading && (
          <Box
            deskStyles={[
              column,
              css`
                padding: 40px 10px 0 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              `,
            ]}
          >
            <LoadingButton />
          </Box>
        )}
      </>
    )
  }

  return (
    <>
      <Form id="stripe-checkout-form" onSubmit={submitCreateStripeCustomer}>
        <CardSection />
        <InputWithHeader
          header="EMAIL ADDRESS"
          inputName="billingEmail"
          onChange={accountSettingsForm.updateBillingEmail}
          placeholderDefault="email@address.com"
          value={currentUserAuth.email}
        />
        <InputWithHeader
          header="BILLING NAME"
          inputName="billingName"
          onChange={accountSettingsForm.updateBillingName}
          placeholderDefault={
            'Credit C. Holder' || currentUserAuth.emailVerified
          }
        />
        <InputWithHeader
          header="ADDRESS LINE 1"
          inputName="addressLine1"
          onChange={accountSettingsForm.updateAddressLine1}
          placeholderDefault="Street name"
        />
        <InputWithHeader
          header="ADDRESS LINE 2"
          inputName="addressLine2"
          onChange={accountSettingsForm.updateAddressLine2}
          placeholderDefault="Floor, Apt, ect"
        />
        <InputWithHeader
          header="CITY"
          inputName="city"
          onChange={accountSettingsForm.updateCity}
          placeholderDefault="Example: San Diego"
        />
        <InputWithHeader
          header="STATE"
          inputName="state"
          onChange={accountSettingsForm.updateState}
          placeholderDefault="Example: CA"
        />
        <InputWithHeader
          header="COUNTRY"
          inputName="country"
          disabled={true}
          placeholderDefault="US"
        />
      </Form>
      {!customerCreationLoading && (
        <Button
          deskStyles={[deskSubmitButton]}
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              width: 100%;
            `,
          ]}
          type="submit"
          form="stripe-checkout-form"
        >
          SUBMIT
        </Button>
      )}
      {customerCreationLoading && <LoadingButton />}
    </>
  )
}

//      <Box
//         deskStyles={[
//           column,
//           css`
//             padding: 40px 10px 0 50px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//           `,
//         ]}
//       >
