import React from 'react'
import { AccountType } from '../../constants/enums'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { LoadingScreen } from '../../components/LoadingScreen'
import { GigBox, GigBoxType } from '../../components/GigBox'
import { Gig } from '../../classes'
import { Text } from '../../shared/Text'
import { mobLinkVariants } from '../../shared/Link'
import { MessageBox } from '../../components/MessageBox'
import { Box, mobBoxVariants } from '../../shared/Box'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { mobProfileLink } = mobLinkVariants
const { column, row } = mobBoxVariants
const { deskPageHeader, textCenter, fullWidth } = deskTextVariants

/**
 *
 * @param {User} user
 * @param updateBookingCart
 * @returns {*}
 * @constructor
 */
export const ActiveGigs = ({ user, currentUserAuth }) => {
  if (user.accountType !== AccountType.agency || !currentUserAuth) {
    return null
  }

  const viewingOwnProfile = currentUserAuth.uid === user.userId

  const [allUserGigs, allUserGigsLoading, allUserGigsError] = useCollectionData(
    collections.gigs.where('createdBy', '==', user.userId),
    {
      idField: 'id',
    }
  )

  return (
    <>
      <Text
        mobStyles={[mobProfileLink]}
        deskStyles={[deskPageHeader, fullWidth, textCenter]}
      >
        ACTIVE GIGS
      </Text>
      {allUserGigsError && <strong>Error: {allUserGigsError}</strong>}
      {allUserGigsLoading && <LoadingScreen />}
      {allUserGigs && (
        <>
          {allUserGigs.length <= 0 && (
            <MessageBox
              header="No Active Gigs"
              message={
                viewingOwnProfile
                  ? 'You have not created any gigs'
                  : 'This user does not currently have any active gigs.'
              }
            />
          )}
          <Box
            mobStyles={[column]}
            deskStyles={[
              row,
              css`
                justify-content: space-evenly;
                flex-wrap: wrap;
              `,
            ]}
          >
            {allUserGigs.map((eventDocumentSnapshot, index) => (
              <GigBox
                key={index}
                gig={new Gig(eventDocumentSnapshot)}
                index={index}
                boxType={GigBoxType.fullLink}
                deskStyles={css`
                  width: 30%;
                `}
              />
            ))}
          </Box>
        </>
      )}
    </>
  )
}
