// PROD-READY-MOBILE
import React from 'react'
import styled, { css } from 'styled-components'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import TimeAgo from 'react-timeago'
import { collections } from '../../firebaseApp'
import { Image } from '../../shared/Image'
import { Box, mobBoxVariants } from '../../shared/Box'
import { LoadingScreen } from '../../components/LoadingScreen'
import { User } from '../../classes'

/**
 * Component that holds the actual ChatItem info
 * This is nested so we can take advantage of
 * react-firebase-hooks which we cannot do above
 * this component because hook need queries first
 *
 * @param {ChatRoom} chatRoom
 * @param {string} userBId
 * @returns {*}
 * @constructor
 */

const { column, row, fullWidth } = mobBoxVariants

const mobChatRecipientImage = css`
  height: 70px;
  width: 70px;
  min-width: 70px;
  border-radius: 100%;
  box-shadow: 0 4px 8px 2px rgba(173, 165, 165, 0.5);
`

const moneyServiceName = {
  1: 'CashApp',
  2: 'Paypal',
  3: 'Venmo',
  4: 'Zelle',
}

export const MoneyRequestItemDetails = ({ requestSnapshot }) => {
  const [
    userBSnapshot,
    userBSnapshotLoading,
    userBSnapshotError,
  ] = useDocumentData(
    collections.userInformation.doc(requestSnapshot.operative)
  )

  if (userBSnapshotLoading || userBSnapshotError) {
    return <LoadingScreen />
  }

  const userB = new User(userBSnapshot)

  const getUserDefaultService = user => {
    for (let service in user.moneyServices) {
      if (user.moneyServices[Number(service)].default) {
        return moneyServiceName[Number(service)]
      }
    }

    const randomCashService = Object.keys(user.moneyServices)[0]
    return moneyServiceName[Number(randomCashService)]
  }

  let statusString
  switch (requestSnapshot.status) {
    case 6:
      statusString = 'Paid'
      break
    case 7:
      statusString = 'Pay Now'
      break
    default:
      statusString = 'Unknown'
  }

  return (
    <>
      <Image
        mobStyles={[mobChatRecipientImage]}
        src={userB.getProfileImage()}
      />
      <Box mobStyles={[column, fullWidth]}>
        <Box mobStyles={[row, fullWidth]}>
          <MoneyRequestText>{userB.fullName}</MoneyRequestText>
          <RequestReceived>
            <TimeAgo
              date={
                new Date(
                  (requestSnapshot.releasedByOwner
                    ? requestSnapshot.releasedByOwner.seconds
                    : 1) * 1000
                )
              }
            />
          </RequestReceived>
        </Box>
        <Box mobStyles={[row, fullWidth]}>
          <MoneyRequestText>
            {new Intl.NumberFormat('en', {
              style: 'currency',
              currency: 'USD',
            }).format(requestSnapshot.stripeOperativePay / 100)}
          </MoneyRequestText>
          <PaidStatusText paid={requestSnapshot.status === 6}>
            {statusString}
          </PaidStatusText>
        </Box>
        <MoneyRequestText>
          {getUserDefaultService(userBSnapshot)}
        </MoneyRequestText>
      </Box>
    </>
  )
}

export const MoneyRequestText = styled.text`
  width: 100%;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 0 10px 2.5px 10px;
  overflow: hidden;
`

export const PaidStatusText = styled.text`
  height: 15px;
  width: 50%;
  color: ${p => (p.paid ? 'green' : 'red')};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding: 0px 10px 2.5px 10px;
`

export const RequestReceived = styled.text`
  height: 15px;
  width: auto;
  color: ${p => p.theme.colors.darkGray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  white-space: nowrap;
`
