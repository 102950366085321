import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { uuid } from '../../../utils/uuid'

import {
  mobAutoCompleteDropdown,
  mobAutoCompleteSelection,
  mobAutoCompleteSelectionWrapper,
  mobAutoCompleteTextArea,
  mobAutoCompleteWrapper,
} from './styles'
import Script from 'react-load-script'
import {
  Box,
  Button,
  mobBoxVariants,
  Text,
  TextArea,
} from '../../../shared/index'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'

const { column } = mobBoxVariants

export const AutoCompleteLocationInput = ({
  locationHandler,
  placeholder,
  inputName,
}) => {
  const googleAPIKey = 'AIzaSyAR4vHdSSnLiQzZYXSt8QGjwIYPGAg9U-s'

  // Used to display current address in input
  const [address, setAddress] = useState('')
  // Used to keep current address in input up-to-date
  const handleAddressChange = address => {
    setAddress(address)
  }

  const handleAddressSelect = address => {
    setAddress(address)
    createLocationObject(address)
  }

  const constructStaticMapUrl = latLong => {
    const googleStaticMapApi = 'https://maps.googleapis.com/maps/api/staticmap?'
    const center = `center=${latLong.lat},${latLong.lng}`
    const marker = `&markers=${latLong.lat},${latLong.lng}`
    const zoom = `&zoom=14`
    const size = `&size=500x400`
    const key = `&key=${googleAPIKey}`

    const googleMapImageUrl =
      googleStaticMapApi + center + zoom + size + marker + key
    return googleMapImageUrl

    // BAM logo tiny url https://tinyurl.com/y6ca2sxb
    //&size=500x400&scale=1
    // center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap
    // &markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
    // &markers=color:red%7Clabel:C%7C40.718217,-73.998284
    // &key=YOUR_API_KEY
  }

  const createLocationObject = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLong => {
        const imageUrl = constructStaticMapUrl(latLong)

        locationHandler({
          eventAddress: address,
          eventLatLong: latLong,
          eventLocationMobileImageUrl: imageUrl,
        })
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_CREATING_LOCATION_OBJECT_FOR_GIGS',
          message: 'Error while creating location object for gigs',
          type: errorTypes.gig,
          file: 'GoogleLoginButton.jsx',
        })
      })
  }

  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [scriptError, setScriptError] = useState(false)

  const handleScriptCreate = () => setScriptLoaded(false)

  const handleScriptError = () => setScriptError(true)

  const handleScriptLoad = () => setScriptLoaded(true)

  const renderAutoComplete = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
  }) => (
    <Box mobStyles={[mobAutoCompleteWrapper]}>
      <TextArea
        mobStyles={[mobAutoCompleteTextArea]}
        placeholder={placeholder || 'Enter Address'}
        {...getInputProps()}
        name={inputName}
      />

      <Box mobStyles={[column, mobAutoCompleteDropdown]}>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion, index) => (
          <Button
            mobStyles={[mobAutoCompleteSelectionWrapper]}
            {...getSuggestionItemProps(suggestion)}
            key={uuid()}
            data-testid={`address-autocomplete-${index}`}
          >
            <Text mobStyles={[mobAutoCompleteSelection]}>
              {suggestion.description}
            </Text>
          </Button>
        ))}
      </Box>
    </Box>
  )

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`}
        onCreate={handleScriptCreate}
        onError={handleScriptError}
        onLoad={handleScriptLoad}
      />
      {scriptLoaded && (
        <PlacesAutocomplete
          value={address}
          onChange={handleAddressChange}
          onSelect={handleAddressSelect}
          debounce={500}
        >
          {renderAutoComplete}
        </PlacesAutocomplete>
      )}
    </>
  )
}
