import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import get from 'lodash/get'
import styled from 'styled-components'

import { collections } from '../../firebaseApp'
import { LoadingScreen } from '../../components/LoadingScreen'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { MoneyRequestDetailsModule } from './MoneyRequestDetailsModule'
import { DesktopMoneyRequestItem } from './DesktopMoneyRequestItem'
import LazyLoad from 'react-lazyload'
import { RequestStatus } from '../../constants/enums'

export const EmptyChatInboxText = styled.div`
  width: 100%;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 27px;
  color: ${p => p.theme.fonts.primary};
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 30px;
  text-align: center;
  display: flex;
  margin-top: 50px;
`

export const MoneyRequestPage = ({ currentUserAuth, user }) => {
  const isDesktop = useMediaQuery(desktopQuery)

  const [
    allMoneyRequests,
    allMoneyRequestsLoading,
    allMoneyRequestsError,
  ] = useCollectionData(
    collections.activeRequests.where('status', 'in', [
      RequestStatus.completed,
      RequestStatus.awaitingBAM,
    ]),
    {
      idField: 'id',
    }
  )

  if (!user.admin) {
    return null
  }

  // const getSortedMobileMoneyRequests = moneyRequests => {
  //   const moneyRequestItems = moneyRequests.map(moneyRequest => {
  //     return (
  //       <LazyLoad height={100}>
  //         <MobileChatItem
  //           key={moneyRequest.id}
  //           chatRoomId={moneyRequest.id}
  //           currentUserId={currentUserAuth.uid}
  //           chatRoomsObject={moneyRequestsObject}
  //           setChatRoomsObject={setMoneyRequestsObject}
  //           unreadObject={unreadObject}
  //         />
  //       </LazyLoad>
  //     )
  //   })
  //
  //   return moneyRequestItems
  //   // return <Sort>{moneyRequestItems}</Sort>
  // }

  const getSortedDesktopMoneyRequests = activeRequests => {
    const activeRequestsItems = activeRequests.map(activeRequest => {
      return (
        <LazyLoad height={100}>
          <DesktopMoneyRequestItem
            key={activeRequest.id}
            activeRequest={activeRequest}
          />
        </LazyLoad>
      )
    })

    return activeRequestsItems
  }

  const dependencies = allMoneyRequests
  const dependenciesLoading = allMoneyRequestsLoading
  const dependenciesError = allMoneyRequestsError

  if (dependenciesError) {
    return <strong>Error</strong>
  }

  if (dependenciesLoading) {
    return <LoadingScreen />
  }

  console.log(allMoneyRequests.length)

  if (dependencies) {
    if (isDesktop) {
      return (
        <MoneyRequestDetailsModule
          getSortedDesktopMoneyRequests={getSortedDesktopMoneyRequests}
          allMoneyRequests={allMoneyRequests}
          currentUserAuth={currentUserAuth}
        />
      )
    } else {
      return null
    }
  }
}

/**
 * This component converts its children into an array and then sorts them by
 * some arbitrary property.
 *
 * Currently used to sort the chat threads by the time they were last updated.
 *
 * @param children
 * @returns {Array<Exclude<React.ReactNode, boolean | null | undefined>>}
 * @constructor
 */
const Sort = ({ children }) => {
  return React.Children.toArray(children).sort((a, b) => {
    const chatRoomAId = a.props['chatRoomId']
    const chatRoomBId = b.props['chatRoomId']

    const chatRoomALastTimeUpdated = get(
      a,
      `props.chatRoomsObject.${chatRoomAId}.chatRoomLastUpdated.seconds`,
      `props.chatRoomsObject.${chatRoomAId}.lastMessageSentTime.seconds`,
      0
    )
    const chatRoomBLastTimeUpdated = get(
      b,
      `props.chatRoomsObject.${chatRoomBId}.chatRoomLastUpdated.seconds`,
      `props.chatRoomsObject.${chatRoomBId}.lastMessageSentTime.seconds`,
      0
    )

    return chatRoomALastTimeUpdated > chatRoomBLastTimeUpdated ? -1 : 1
  })
}
