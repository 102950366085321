import React from 'react'
import PropTypes from 'prop-types'
import { StyledMainImage } from './styles'

export const MainImage = ({
  imgSrc,
  children,
  mobHeight,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  padding,
}) => (
  <StyledMainImage
    sourceImage={imgSrc}
    mobHeight={mobHeight}
    borderBottomLeftRadius={borderBottomLeftRadius}
    borderBottomRightRadius={borderBottomRightRadius}
    padding={padding}
  >
    {children}
  </StyledMainImage>
)

MainImage.propTypes = {
  children: PropTypes.element,
  sourceImage: PropTypes.string,
  imgSrc: PropTypes.string,
  mobHeight: PropTypes.string,
  borderBottomLeftRadius: PropTypes.string,
  borderBottomRightRadius: PropTypes.string,
  padding: PropTypes.string,
}
