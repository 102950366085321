import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import get from 'lodash/get'
import { collections, db, functions } from '../../../firebaseApp'
import { uuid } from '../../../utils/uuid'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import firebase from 'firebase/app'
import { RequestActionType, RequestStatus } from '../../../constants/enums'
import { MiniAccordion } from '../../../components/MiniAccordion'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { LoadingButton } from '../../../components/LoadingButton'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import {
  offerAccepted,
  offerDeclined,
} from '../../../utils/notificationHandlers'

const {
  mobStatusButton,
  acceptButton,
  pendingButton,
  declineButton,
  canceledButton,
  fullOpacity,
} = mobButtonVariants
const { mobRequestBoxHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const mobSevenDayReminderText = css`
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-weight: bold;
  margin: 0 0 10px;
  margin: 20px 5px 5px 5px;
  text-align: center;
`

export const deskSevenDayReminderText = css`
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
`

export const OperativeWithPendingOffer = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const accordionState = useRecoilValue(requestActionAccordionState)
  const { activeRequests } = collections
  const acceptAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.accept
  const declineAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.decline
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [deniedLoading, setDeniedLoading] = useState(false)
  /**
   * Called by a model with an active offer. This function will:
   * 1. Ensure the request in in the pending state.
   * 2. Capture the paymentintent AKA charge the gig owners card
   * 3. Update request status to Accepted and save the Payment response to the request
   * 4. Log the Acceptance in the Request history
   */
  const acceptOfferRequestHandler = () => {
    setAcceptLoading(true)
    const bookingId = get(booking, 'event.id')
    const requestId = get(booking, 'request.requestId')
    const capturePaymentIntent = functions.httpsCallable('capturePaymentIntent')

    capturePaymentIntent({
      requestId: requestId,
      duration: booking.request.duration,
      operativeRate: booking.request.operativeRate,
      idempotencyKey: uuid(),
    })
      .then(() => {
        offerAccepted(booking.request.eventCreator)
        setAcceptLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_ACCEPT_BOOKING_GIG',
          message: `Failed to accept booking id: ${bookingId}, request id: ${requestId}`,
          type: errorTypes.gig,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'RequestActionModule.jsx',
        })
      })
  }

  const declineOfferRequestHandler = () => {
    setDeniedLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const batch = db.batch()
    const requestReference = activeRequests.doc(requestId)

    batch.set(
      requestReference,
      {
        status: RequestStatus.declined,
        operativeWithdrewAt: serverTimestamp,
      },
      { merge: true }
    )

    batch.set(
      requestReference.collection('history').doc(),
      {
        note: `${currentUserAuth.uid} declined this offer. ${booking.gig.createdBy}.`,
        timestamp: serverTimestamp,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        offerDeclined(booking.request.eventCreator)
        setDeniedLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_DECLINING_OFFER',
          message: `Failed to decline offer for user for request id: ${requestId}`,
          type: errorTypes.cancellation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'OperativeWithPendingOffer.jsx',
        })
      })
  }

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  const today = new Date()
  const expirationDate = new Date(
    booking.request.paymentResponse.created * 1000
  ).addDays(7)
  const offerValid = today < expirationDate
  const dateString = new Date(booking.request.paymentResponse.created * 1000)
    .addDays(7)
    .toLocaleDateString()
  const timeString = new Date(booking.request.paymentResponse.created * 1000)
    .addDays(7)
    .toLocaleTimeString()

  // Model accepting or declining an offer
  return (
    <>
      {!offerValid && (
        <>
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Status
          </Text>
          <Button
            mobStyles={[mobStatusButton, canceledButton, fullOpacity]}
            disabled={true}
          >
            EXPIRED
          </Button>
          <Text
            mobStyles={[mobSevenDayReminderText]}
            deskStyles={[deskSevenDayReminderText]}
          >
            This offer has expired:
            <br />
            {dateString} at {timeString}
          </Text>
        </>
      )}
      {offerValid && (
        <>
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Status
          </Text>
          <Button
            mobStyles={[mobStatusButton, pendingButton, fullOpacity]}
            disabled={true}
          >
            PENDING
          </Button>
          <Text
            mobStyles={[
              mobRequestBoxHeader,
              css`
                margin: 20px 5px 5px 5px;
                text-align: center;
              `,
            ]}
            deskStyles={[deskButtonGroupHeader]}
          >
            Available Actions
          </Text>
          <Text
            mobStyles={[mobSevenDayReminderText]}
            deskStyles={[deskSevenDayReminderText]}
          >
            NOTE: This offer will automatically expire in one week.
            <br />
            {dateString} at {timeString}
          </Text>
          {acceptLoading && !deniedLoading && (
            <LoadingButton
              mobStyles={[acceptButton]}
              deskStyles={[
                css`
                  width: unset;
                `,
              ]}
            />
          )}
          {!acceptLoading && !deniedLoading && (
            <MiniAccordion
              closedButtonText="ACCEPT"
              openButtonText="CANCEL"
              onClick={() => {
                updateAccordionState(RequestActionType.accept)
              }}
              open={acceptAccordionOpen}
              buttonMobStyles={[mobStatusButton, acceptButton]}
            >
              <Button
                mobStyles={[mobStatusButton, acceptButton]}
                onClick={acceptOfferRequestHandler}
              >
                CONFIRM ACCEPT
              </Button>
            </MiniAccordion>
          )}
          {deniedLoading && !acceptLoading && (
            <LoadingButton
              mobStyles={[declineButton]}
              deskStyles={[
                css`
                  width: unset;
                `,
              ]}
            />
          )}
          {!acceptLoading && !deniedLoading && (
            <MiniAccordion
              closedButtonText="DECLINE"
              openButtonText="CANCEL"
              onClick={() => {
                updateAccordionState(RequestActionType.decline)
              }}
              open={declineAccordionOpen}
              buttonMobStyles={[mobStatusButton, declineButton]}
            >
              <Button
                mobStyles={[mobStatusButton, declineButton]}
                onClick={declineOfferRequestHandler}
              >
                CONFIRM DECLINE
              </Button>
            </MiniAccordion>
          )}
        </>
      )}
    </>
  )
}
