import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

export let config = {}

export const isProduction = process.env.NODE_ENV === 'production'
// process.env.NODE_ENV === 'development'
// process.env.NODE_ENV === 'test'

if (!isProduction) {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
    projectId: process.env.REACT_APP_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_APP_ID_DEV,
  }
} else if (process.env.NODE_ENV === 'production') {
  config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
    databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
    projectId: process.env.REACT_APP_PROJECT_ID_PROD,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
    appId: process.env.REACT_APP_APP_ID_PROD,
  }
}

export const firebaseApp = firebase.initializeApp(config)
export const auth = firebaseApp.auth()
export const db = firebaseApp.firestore()
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const facebookProvider = new firebase.auth.FacebookAuthProvider()
export const emailProvider = new firebase.auth.EmailAuthProvider()
export const storage = firebase.storage()

// Firebase Storage Variables
const portfolioImageStorageUrl = isProduction
  ? 'gs://supernova-prod-portfolio-images'
  : 'gs://portfolio-images-vzzso'
const profileImageStorageUrl = isProduction
  ? 'gs://supernova-prod-profile-images'
  : 'gs://profile-images-id5kj'
const supportImageStorageUrl = isProduction
  ? 'gs://supernova-prod-support-images'
  : 'gs://supernova-dev-support-tickets'

export const portfolioImagesStorage = firebase
  .app()
  .storage(portfolioImageStorageUrl)
export const profileImageStorage = firebase
  .app()
  .storage(profileImageStorageUrl)
export const bugImageStorage = firebase.app().storage(supportImageStorageUrl)

// NOT USING THIS ONE IN PROD YET
export const licenseImageStorage = firebase
  .app()
  .storage('gs://licensable-images')

export const collections = {
  gigs: db.collection('events'),
  deletedGigs: db.collection('deleted-events'),
  userInformation: db.collection('user-information'),
  waitlist: db.collection('waitlist'),
  activeRequests: db.collection('active-requests'),
  notifications: db.collection('notifications'),
  licenseImages: db.collection('license-images'),
  blogPosts: db.collection('blog-posts'),
  announcements: db.collection('announcements'),
  moneyRequests: db.collection('money-requests'),

  errors: db.collection('errors-storage'),
  accessCodes: db.collection('access-codes'),
  deletedAccounts: db.collection('deleted-users'),

  // Experimental
  chatRooms: db.collection('chat-rooms'),
  userChats: db.collection('user-chats'),

  // BE CAREFUL WITH THIS DATASET. DON'T WANT TO MISTAKENLY RENDER PRIVATE INFO
  privateUserInformation: db.collection('private-user-information'),

  // Admin Only. This data is write-only for this app.
  supportTickets: db.collection('support-tickets'),
  guestSupportTickets: db.collection('guest-support-tickets'),
  questions: db.collection('questions'),
  faqRequests: db.collection('faq-requests'),
}

// Initialize Cloud Functions through Firebase
export const functions = firebase.functions()
