import React from 'react'
import { css } from 'styled-components'
import MobileSignupMainImage from '../../assets/photos/mobile_signup_page_main.png'
import DesktopSignupMainImage from '../../assets/photos/desktop_signup_page_main.png'
import GreyBAMLogo from '../../assets/logos/grey-b-logo.svg'
import { useHistory } from 'react-router-dom'

import { EmailSignUpForm } from '../../components'
import {
  Box,
  Image,
  Link,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { ROUTES } from '../../constants/routes'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'

const { mobFormBox } = mobBoxVariants
const { column } = mobBoxVariants
const { mobGreyBamLogo } = mobImageVariants
const { deskGreyBamLogo } = deskImageVariants
const { mobPageHeader, mobSignUpText } = mobTextVariants
const { deskPageHeader } = deskTextVariants

// this also acts as a div
const mobBackgroundImageStyles = css`
  min-height: 510px;
  align-items: center;
  justify-content: center;
  background-size: 100% 45%;
`

const deskBackgroundImageStyles = css`
  background-size: 100% auto;
  height: 150vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: 80vh 100vh !important;
  background-size: 95vh 150vh !important;
  background-position: left;
  justify-content: flex-end;
`

export const haveAnAccountTextMobStyles = css`
  margin-top: 15px;
  margin-right: 15px;
`

export const loginLinkStyles = css`
  color: ${p => p.theme.colors.blueGray};
`

export const mobSignupFormBox = css`
  margin-bottom: 100px;
  margin-top: 250px;
`

export const deskSignupFormBox = css`
  width: 35vw;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 5vw;
`

export const mobSignupPageHeader = css`
  margin: 0 auto 10px;
  font-size: 28px;
`

export const mobLoginLinkText = css`
  margin-left: 10px;
`

export const SignUpPage = ({ currentUserAuth }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery(desktopQuery)

  if (currentUserAuth) {
    history.push(ROUTES.PROFILE.link)
  }

  return (
    <>
      <Image
        enableUseAsBackground
        src={isDesktop ? DesktopSignupMainImage : MobileSignupMainImage}
        mobStyles={mobBackgroundImageStyles}
        deskStyles={deskBackgroundImageStyles}
      >
        <Box
          mobStyles={[column, mobFormBox, mobSignupFormBox]}
          deskStyles={[deskSignupFormBox]}
        >
          <Image
            src={GreyBAMLogo}
            mobStyles={[mobGreyBamLogo]}
            deskStyles={deskGreyBamLogo}
          />
          <Text
            mobStyles={[mobPageHeader, mobSignupPageHeader]}
            deskStyles={deskPageHeader}
          >
            Create an account.
          </Text>
          <EmailSignUpForm nextPageLink={ROUTES.ACCOUNT_SETTINGS.link} />
          <Text mobStyles={[mobSignUpText, haveAnAccountTextMobStyles]}>
            Already have an account?
            <Link
              mobStyles={[loginLinkStyles, mobLoginLinkText]}
              to={ROUTES.SIGN_IN.link}
            >
              <strong>Log In</strong>
            </Link>
          </Text>
        </Box>
      </Image>
    </>
  )
}
