import React from 'react'

import { StyledMotionTable, StyledTable } from './styles'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

export const Table = ({ children, ...props }) => {
  if (props.enableMotion) {
    return <StyledMotionTable {...props}>{children}</StyledMotionTable>
  }

  return <StyledTable {...props}>{children}</StyledTable>
}

Table.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
