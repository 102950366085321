import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'

/**
 * EditGigForm is a class that take an existing gig and compares the updated data
 * to the current data before updating.
 *
 */
export class EditLicenseImageForm {
  licenseImageDocument
  licenseImageData
  id

  createdBy
  uploadedAt
  monthlyAvailable
  monthlyPrice
  exclusiveAvailable
  exclusivePrice
  orderMap
  status
  tags
  uniqueId
  originalImageUrl
  originalUrl
  largeSizeUrl

  licenseMonthlyLink
  licenseExclusiveLink

  /**
   *
   * @param {LicenseImage} licenseImage
   * @param {function} submitButtonDisabled
   */
  constructor(licenseImage, submitButtonDisabled) {
    this.licenseImage = licenseImage
    this.submitButtonDisabled = submitButtonDisabled
    this.id = licenseImage.id

    this.monthlyPrice = licenseImage.monthlyPrice
    this.exclusivePrice = licenseImage.exclusivePrice
    this.tags = licenseImage.tags
    this.newMonthlyPrice = licenseImage.monthlyPrice
    this.newExclusivePrice = licenseImage.exclusivePrice
    this.newTags = licenseImage.tags
  }

  updateMonthly = event => {
    if (
      !isEmpty(trim(event.target.value)) &&
      event.target.value !== this.monthlyPrice
    ) {
      this.newMonthlyPrice = event.target.value
      this.submitButtonDisabled(false)
    } else {
      this.submitButtonDisabled(true)
    }
  }

  updateExclusive = event => {
    if (
      !isEmpty(trim(event.target.value)) &&
      event.target.value !== this.exclusivePrice
    ) {
      this.newExclusivePrice = event.target.value
      this.submitButtonDisabled(false)
    } else {
      this.submitButtonDisabled(true)
    }
  }
}
