import { css } from 'styled-components'

export const headingVariants = {
  mobSectionTitle: css`
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.75px;
    line-height: 29px;
  `,
  whiteBold: css`
    color: ${p => p.theme.colors.secondary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    text-align: center;
  `,
  blackBold: css`
    color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: bold;
    text-align: center;
  `,
}
