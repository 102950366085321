import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  Image,
  Link,
  mobBoxVariants,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
} from '../../../../shared'
import React, { useReducer, useState } from 'react'
import DancingAlert from '../../../../assets/lottie/5457-alert-notification'
import { useLottie } from 'lottie-react'
import { motion } from 'framer-motion'
import { css } from 'styled-components'
import { Text } from '../../../../shared/Text'
import Calendar from 'react-calendar'
import { MessageBox } from '../../../../components/MessageBox'
import BronnerSmallLogo from '../../../../assets/logos/bronner_small_logo.jpg'
import { InputWithHeader } from '../../../../components/Inputs/InputWithHeader'
import { deskTextVariants } from '../../../../shared/Text/deskTextVariants'
import { whiteLabelMotionVariants } from '../WhiteLabelExperienceRouter'
import { useUserAccountType } from '../../../../utils/useUserAccountType'
import { collections } from '../../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../../utils/errorHandler'
import firebase from 'firebase/app'
import { AccountType } from '../../../../constants/enums'
import { ROUTES } from '../../../../constants/routes'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const { column, row } = mobBoxVariants
const { mobInstructionParagraph } = mobTextVariants
const { mobInTextLink } = mobLinkVariants
const { mobSubmitButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { deskSubmitButton } = deskTextVariants
const { mobInputHeader } = mobTextVariants
const { mobMessageBoxHeader } = mobTextVariants

const deskMainHeaderStyles = css`
  font-size: 35px;
`

const mobHeader = css`
  height: auto;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: bold;
`
const firstDay = new Date(2021, 7, 28)
const secondDay = new Date(2021, 7, 29)
const thirdDay = new Date(2021, 7, 30)
const conventionDates = [firstDay, secondDay, thirdDay]

export const CollectDetailsSlide = ({ currentUserAuth, user }) => {
  const history = useHistory()
  const accountType = user.accountType
  const [availableDates, setAvailableDates] = useState([])
  const [name, setName] = useState(user.fullName)
  const [error, setError] = useState(null)
  const [boothNumber, setBoothNumber] = useState(null)
  const [numberOfModels, setNumberOfModels] = useState(null)
  const [modelTypes, setModelTypes] = useState({
    hair: user.modelTypes.hair,
    makeup: user.modelTypes.makeup,
    nails: user.modelTypes.nails,
    skin: user.modelTypes.skin,
  })
  
  
  const [
    bronnerGigs,
    bronnerGigsLoading,
    bronnerGigsError,
  ] = useCollectionData(
    collections.gigs
      .where('createdBy', '==', currentUserAuth.uid)
      .where('bronnerBrosIBS', "==", true),
    {
      idField: 'id',
    }
  )
  
  const isModel = accountType === AccountType.model
  const isInstructor = accountType === AccountType.instructor
  const isExhibitor = accountType === AccountType.exhibitor
  
  let datesToUse = []
  
  if(isModel) {
    datesToUse = user.availableDates ? [
      ...user.availableDates.map(dateMillis => new Date(dateMillis))
    ] : []
  }
  

  const [datesAvailable, datesAvailableDispatch] = useReducer(
    (myArray, { type, value }) => {
      switch (type) {
        case 'add':
          return [...myArray, value]
        case 'remove':
          return myArray.filter((_, index) => index !== value)
        default:
          return myArray
      }
    },
    datesToUse
  )
  
  const options = {
    animationData: DancingAlert,
    loop: true,
    autoplay: true,
    style: {
      height: 100,
      margin: 0,
    },
  }
  const { View } = useLottie(options)
  
  if(isInstructor || isExhibitor) {
    if(!bronnerGigs) {
      return null
    }
  }

  const employerCreatedGigAlready = (date) => {
    if(bronnerGigs) {
      const calDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      ).getTime()
  
      const currentUserGigDates = bronnerGigs.map(gig => {
        let gigDate = new Date(gig.time.date.seconds * 1000)
        return (
          new Date(
            gigDate.getFullYear(),
            gigDate.getMonth(),
            gigDate.getDate()
          ).getTime()
        )
      })
  
      return currentUserGigDates.some(
        currentUserGig => currentUserGig === calDate
      )
    } else {
      return false
    }
  }

  const defaultCalendarProps = {
    onChange: date => {
      const jsDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const dateSelectedAlready = datesAvailable.some(
        d => jsDate.getTime() === d.getTime()
      )
      if (dateSelectedAlready) {
        const dateIndex = datesAvailable.findIndex(d => {
          return d.valueOf() === jsDate.valueOf()
        })
        datesAvailableDispatch({ type: 'remove', value: dateIndex })
      } else if (
        !dateSelectedAlready &&
        conventionDates.some(
          conventionDay => conventionDay.getTime() === jsDate.getTime()
        )
      ) {
        datesAvailableDispatch({ type: 'add', value: jsDate })
      }
    },
    tileDisabled: ({ date }) => {
      return !isConventionDay(date) || employerCreatedGigAlready(date)
    },
    tileClassName: ({ date }) => {
      const conventionDay = isConventionDay(date)
      if (isExhibitor || isInstructor) {
        if(employerCreatedGigAlready(date)) {
          return
        }
      }
      
      if (conventionDay) {
        const available = datesAvailable.some(availableDate => {
          return (
            new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            ).getTime() === availableDate.getTime()
          )
        })

        if (available) {
          return 'available-day'
        } else {
          return 'unavailable-day'
        }
      }
    },
    next2Label: null,
    nextLabel: null,
    prev2Label: null,
    prevLabel: null,
    className: 'availability-calendar',
    activeStartDate: new Date(2021, 7, 1),
  }

  const isConventionDay = date => {
    const calDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    )
    return conventionDates.some(
      conventionDay => conventionDay.getTime() === calDate.getTime()
    )
  }


  const { gigs } = collections

  const handleGigCreation = (name, gigDate, gigDateStart, gigDateEnd, accountTypeString) => {
    
    const description = `${accountTypeString} is looking for models
    interested in the following: ${Object.keys(modelTypes).join(", ")}.`
    
    // BRONNER BROS. Beauty Show:
    gigs
      .add({
        title: name,
        time: {
          date: firebase.firestore.Timestamp.fromDate(gigDate),
          startTime: firebase.firestore.Timestamp.fromDate(gigDateStart),
          endTime: firebase.firestore.Timestamp.fromDate(gigDateEnd),
          duration: 9,
        },
        location: {
          address: '900 Convention Center Boulevard, New Orleans, LA, USA',
          latLong: {
            lat: 29.9415141,
            lng: -90.0635827,
          },
          locationMobileImageUrl:
            'https://maps.googleapis.com/maps/api/staticmap?center=29.9415141,-90.0635827&zoom=14&size=500x400&markers=29.9415141,-90.0635827&key=AIzaSyAR4vHdSSnLiQzZYXSt8QGjwIYPGAg9U-s',
        },
        description: description,
        acceptingProposals: true,
        createdAt: firebase.firestore.Timestamp.now(),
        status: 1,
        createdBy: currentUserAuth.uid,
        applicants: 0,
        visibleInSearch: true,
        fixedPriceGig: true,
        fixedPrice: 20000,
        modelTypes: modelTypes,
        bronnerBrosIBS: true,
        modelsRequested: numberOfModels
      })
      .then(gigDocument => {})
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_SUBMIT_GIG',
          message: 'Failed to submit gig.',
          type: errorTypes.creation,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'CreateGigPage.jsx',
        })
      })
  }

  const handleDetailsSubmit = async ads => {
    let serverTimestamp = firebase.firestore.Timestamp.now()
    const userInfoDocumentRef = collections.userInformation.doc(
      currentUserAuth.uid
    )

    if (isExhibitor) {
      // Update the profile information
      userInfoDocumentRef
        .update(
          {
            'profileDetails.fullName': name,
            boothNumber_bb: boothNumber,
          },
        )
        .then(() => {
          // history.push(ROUTES.W_PAYMENT.link)
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_SUBMIT_PROFILE_DETAILS',
            message: 'Failed to submit new profile details',
            type: errorTypes.profile,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'UserProfilePage.jsx',
          })
        })

      await ads.map(d => {
        handleGigCreation(
          name,
          d,
          new Date(d.getFullYear(), d.getMonth(), d.getDay(), 8),
          new Date(d.getFullYear(), d.getMonth(), d.getDay(), 17),
          "Exhibitor"
        )
      })

      history.push('/w/payment')
    } else if (isInstructor) {
      // Update the profile information
      userInfoDocumentRef
        .update(
          {
            'profileDetails.fullName': name,
          },
        )
        .then(() => {
          // history.push(ROUTES.W_PAYMENT.link)
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_SUBMIT_PROFILE_DETAILS',
            message: 'Failed to submit new profile details',
            type: errorTypes.profile,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'UserProfilePage.jsx',
          })
        })
  
      await ads.map(d => {
        handleGigCreation(
          name,
          d,
          new Date(d.getFullYear(), d.getMonth(), d.getDay(), 7),
          new Date(d.getFullYear(), d.getMonth(), d.getDay(), 16),
          "Instructor"
        )
      })
  
      history.push('/w/payment')
    } else if (isModel) {
      // Update the profile information
      const availableDatesEpochs = ads.map(ad => {
        return ad.getTime()
      })

      userInfoDocumentRef
        .update(
          {
            'profileDetails.fullName': name,
            availableDates: firebase.firestore.FieldValue.arrayUnion(
              ...availableDatesEpochs
            ),
            modelTypes: modelTypes,
          }
        )
        .then(() => {
          // history.push(ROUTES.W_PAYMENT.link)
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_SUBMIT_PROFILE_DETAILS',
            message: 'Failed to submit new profile details',
            type: errorTypes.profile,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'UserProfilePage.jsx',
          })
        })

      history.push(ROUTES.W_MODEL_PAYMENT.link)
    }
  }
  const toggleDateAvailabilityHandler = date => {
    let dateEpochNumber = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).getTime()

    const { userInformation } = collections

    firebase
      .firestore()
      .runTransaction(transaction => {
        return transaction
          .get(userInformation.doc(user.userId))
          .then(userDocument => {
            // let transactionUser = new User(userDocument)
            let availableDates = user.availableDates

            if (!availableDates.includes(dateEpochNumber)) {
              transaction.update(userInformation.doc(user.userId), {
                availableDates: firebase.firestore.FieldValue.arrayUnion(
                  dateEpochNumber
                ),
              })
            } else {
              transaction.update(userInformation.doc(user.userId), {
                availableDates: firebase.firestore.FieldValue.arrayRemove(
                  dateEpochNumber
                ),
              })
            }
          })
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_AVAILABILITY',
          message: 'Error occurred while getting user availability.',
          type: errorTypes.profile,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'AvailabilityModule.jsx',
        })
      })
  }

  let nameInputText
  let nameInputTextPlaceholder
  let modelTypeText
  let availableDatesText
  let availableDatesSelectedText
  switch (accountType) {
    case AccountType.model:
      nameInputText = 'Full Name'
      nameInputTextPlaceholder = user.fullName
      modelTypeText = 'Select your model types.'
      availableDatesText = 'Please select the days you are available.'
      availableDatesSelectedText = `You are available ${
        datesAvailable.length
      } day${datesAvailable.length === 1 ? '' : 's'}.`
      break
    case AccountType.exhibitor:
      nameInputText = 'Company Name'
      nameInputTextPlaceholder =  user.fullName
      modelTypeText = 'Select the model types you need.'
      availableDatesText = 'Please select the days you need models.'
      availableDatesSelectedText = `You need models for ${
        datesAvailable.length
      } day${datesAvailable.length === 1 ? '' : 's'}.`
      break
    case AccountType.instructor:
      nameInputText = 'Instructor Name'
      nameInputTextPlaceholder = user.fullName
      modelTypeText = 'Select the model types you need.'
      availableDatesText = 'Please select the days you need models.'
      availableDatesSelectedText = `You need models for ${datesAvailable.length} day${datesAvailable.length === 1 ? '' : 's'}.`
      break
    default:
  }

  const dateIsAvailable = date => {
    let calDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    return user.availableDates.includes(calDate.getTime())
  }
  
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box
        deskStyles={[
          row,
          css`
            flex: 1;
            padding: 50px 0 0 50px;
          `,
        ]}
        mobStyles={[
          column,
          css`
            flex: 1;
            padding: 50px 0 0 20px;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobHeader,
            css`
              margin: 0;
            `,
          ]}
          deskStyles={deskMainHeaderStyles}
        >
          Enter account details.
        </Text>
      </Box>
      <Box deskStyles={[row]} mobStyles={[column]}>
        <Box
          deskStyles={[
            column,
            css`
              flex: 1;
              padding: 10px 10px 0 50px;
            `,
          ]}
          mobStyles={[column,
            css`
              flex: 1;
              padding: 10px 20px 0 20px;
            `,
          ]}
        >
          <Text mobStyles={mobInstructionParagraph}>
            {availableDatesText}
            <br />
            <br />
            {availableDatesSelectedText}
          </Text>
          {isModel && (
            <Calendar
              {...defaultCalendarProps}
              tileClassName={({ date }) => {
                if (dateIsAvailable(date)) {
                  return 'available-day'
                } else if (isConventionDay(date)) {
                  return 'unavailable-day'
                }
              }}
              onClickDay={date => {
                toggleDateAvailabilityHandler(date)
              }}
            />
          )}
          {(isExhibitor || isInstructor) && (
            <Calendar
              {...defaultCalendarProps}
              tileClassName={({ date }) => {
                const conventionDay = isConventionDay(date)
                if (isExhibitor || isInstructor) {
                  if(employerCreatedGigAlready(date)) {
                    return
                  }
                }
                if (conventionDay) {
                  const available = datesAvailable.some(availableDate => {
                    return (
                      new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      ).getTime() === availableDate.getTime()
                    )
                  })

                  if (available) {
                    return 'available-day'
                  } else {
                    return 'unavailable-day'
                  }
                }
              }}
            />
          )}
          <Text
            mobStyles={[
              mobMessageBoxHeader,
              css`
                margin: 20px 0 5px 0;
                text-align: center;
                font-size: 24px;
              `,
            ]}
            deskStyles={[
              css`
                font-size: 22px;
              `,
            ]}
          >
            Working hours for each day are <u>8AM</u> to <u>5PM</u> CST
          </Text>
          {(isExhibitor || isInstructor) && (
            <Text
              mobStyles={[
                mobMessageBoxHeader,
                css`
                margin: 5px 0;
                text-align: center;
                font-size: 24px;
              `,
              ]}
              deskStyles={[
                css`
                font-size: 22px;
              `,
              ]}
            >
              All Models will be paid a flat rate of <u>$200 per day</u>.
            </Text>
          )}

        </Box>
        <Box
          deskStyles={[
            column,
            css`
              flex: 2;
              justify-content: center;
              align-items: center;
              padding: 10px 10px 10px 10px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 2;
              justify-content: center;
              align-items: center;
              padding: 10px 20px 10px 20px;
            `,
          ]}
        >
          <Text mobStyles={mobInstructionParagraph}>{modelTypeText}</Text>
          <Box deskStyles={[row]} mobStyles={[column]}>
            <Box
              deskStyles={[
                column,
                css`
                  flex: 0.6;
                  padding: 20px;
                  border: 1px solid ${p => p.theme.colors.lightGray};
                  border-radius: 5px;
                  box-shadow: ${p => p.theme.shadow.regular};
                `,
              ]}
              mobStyles={[
                column,
                css`
                  padding: 20px;
                  border: 1px solid ${p => p.theme.colors.lightGray};
                  border-radius: 5px;
                  box-shadow: ${p => p.theme.shadow.regular};
                `,
              ]}
            >
              <ModelTypeCheckbox
                typeText={'HAIR 💇🏽‍♂️'}
                onChangeHandler={() => {
                  setModelTypes(prevState => {
                    return {
                      ...prevState,
                      hair: !modelTypes.hair,
                    }
                  })
                }}
                checked={modelTypes.hair}
              />
              <ModelTypeCheckbox
                typeText={'SKIN 💆🏼'}
                onChangeHandler={() => {
                  setModelTypes(prevState => {
                    return {
                      ...prevState,
                      skin: !modelTypes.skin,
                    }
                  })
                }}
                checked={modelTypes.skin}
              />
              <ModelTypeCheckbox
                typeText={'MAKE-UP 💄'}
                onChangeHandler={() => {
                  setModelTypes(prevState => {
                    return {
                      ...prevState,
                      makeup: !modelTypes.makeup,
                    }
                  })
                }}
                checked={modelTypes.makeup}
              />
              <ModelTypeCheckbox
                typeText={'NAILS 💅🏾'}
                onChangeHandler={() => {
                  setModelTypes(prevState => {
                    return {
                      ...prevState,
                      nails: !modelTypes.nails,
                    }
                  })
                }}
                checked={modelTypes.nails}
              />
            </Box>
            <Box
              deskStyles={[
                column,
                css`
                  flex: 1;
                  cursor: pointer;
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                `,
              ]}
              mobStyles={[
                css`
                  margin-top: 25px;
                `
              ]}
            >
              <MessageBox
                header="Attention"
                message="Instructors and Exhibitors are demonstrating and displaying new products and techniques that may require them to cut and/or color your hair as well as apply chemicals, cleansers and/or make up to your hair and skin including your nails."
                subMessage={View}
                wrapperStyles={{
                  mobStyles: [
                    css`
                      margin: 0 10px;
                    `,
                  ],
                  tabStyles: [],
                  deskStyles: [],
                  lgDeskStyles: [],
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          deskStyles={[
            column,
            css`
              flex: 0.15;
              justify-content: center;
              align-items: center;
              padding: 10px 50px 0 10px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 0.15;
              justify-content: center;
              align-items: center;
              padding: 10px 10px 0 10px;
            `,
          ]}
        >
          <Image
            src={BronnerSmallLogo}
            deskStyles={css`
              height: 10vw;
              width: 10vw;
            `}
            mobStyles={css`
              height: 40vw;
              width: 40vw;
            `}
          />
        </Box>
      </Box>
      <Box deskStyles={[row]}  mobStyles={[column]}>
        <Box
          deskStyles={[
            column,
            css`
              flex: 1;
              padding: 5px 50px 0 50px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 1;
              padding: 5px 20px 0 20px;
            `,
          ]}
        >
          <InputWithHeader
            header={nameInputText}
            inputName="companyName"
            onChange={event => {
              setName(event.target.value)
            }}
            placeholderDefault={nameInputTextPlaceholder}
          />
          {isExhibitor && (
            <InputWithHeader
              header="BOOTH NUMBER"
              inputName="boothNumber"
              onChange={event => {
                setBoothNumber(event.target.value)
              }}
              value={boothNumber}
              placeholderDefault={user.boothNumber_bb}
              placeholder="Example: 250"
              type={'number'}
            />
          )}
        </Box>
        <Box
          deskStyles={[
            column,
            css`
              flex: 1;
              padding: 5px 50px 0 50px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 1;
              padding: 5px 20px 0 20px;
            `,
          ]}
        >
          <InputWithHeader
            header="EMAIL ADDRESS"
            inputName="email"
            onChange={() => {}}
            placeholderDefault={`Example: company@name.com`}
            value={currentUserAuth.email}
            disabled={true}
          />
          {!isModel && (
            <InputWithHeader
              header="MODELS NEEDED PER DAY"
              inputName="modelPerDay"
              placeholderDefault={`Example: 10`}
              onChange={event => {
                setNumberOfModels(event.target.value)
              }}
              value={numberOfModels}
              type={'number'}
            />
          )}
        </Box>
      </Box>
      <Box
        deskStyles={[
          column,
          css`
            padding: 40px 10px 0 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          `,
        ]}

        mobStyles={[
          column,
          css`
            padding: 40px 10px 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          `,
        ]}
      >
        <Button
          disabled={(isInstructor || isExhibitor) ? bronnerGigs.length >= 3 : false }
          mobStyles={[mobSubmitButton, mobLong305x46]}
          deskStyles={deskSubmitButton}
          onClick={() => {
            handleDetailsSubmit(datesAvailable)
          }}
        >
          SUBMIT
        </Button>
        <Text
          mobStyles={[
            mobInstructionParagraph,
            css`
              text-align: center;
            `,
          ]}
        >
          For more information visit{' '}
          <Link
            mobStyles={[mobInstructionParagraph, mobInTextLink]}
            deskStyles={[
              mobInstructionParagraph,
              mobInTextLink,
              css`
                font-size: 18px;
              `,
            ]}
            to={'https://bronnerbros.com/'}
          >
            bronnerbros.com
          </Link>
        </Text>
      </Box>
    </motion.div>
  )
}

const ModelTypeCheckbox = ({ typeText, checked, onChangeHandler }) => {
  const history = useHistory()

  return (
    <Box
      mobStyles={[
        css`
          display: flex;
          align-items: center;
        `,
      ]}
    >
      <Checkbox
        mobStyles={[
          css`
            /* Double-sized Checkboxes */
            -ms-transform: scale(3); /* IE */
            -moz-transform: scale(3); /* FF */
            -webkit-transform: scale(3); /* Safari and Chrome */
            -o-transform: scale(3); /* Opera */
            transform: scale(3);
            margin-right: 50px;
            margin-left: 10px;
          `,
        ]}
        onChange={() => {
          onChangeHandler(!checked)
        }}
        checked={checked}
      />

      <Text mobStyles={[mobInputHeader]}>{typeText}</Text>
    </Box>
  )
}
