import { Button, mobButtonVariants } from '../../shared/Button'
import React, { useEffect, useRef, useState } from 'react'
import { Box, mobBoxVariants } from '../../shared/Box'
import { css } from 'styled-components'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { TagInput } from '../../components/Inputs/TagInput'
import { mobTextVariants, Text } from '../../shared/Text'
import { LicenseImageType } from '../../constants/enums'
import { EditLicenseImageForm } from '../../classes/Forms/EditLicenseImageForm'
import { collections } from '../../firebaseApp'
import firebase from 'firebase/app'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { Toggle } from '../../components/Toggle'
import { LicenseImage } from '../../classes/Models/LicenseImage'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, center } = mobBoxVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { mobHorizontalRule } = mobTextVariants
const { deskSubmitButton } = deskTextVariants

const deleteButtonStyles = css`
  background-color: ${p => p.theme.colors.red};
  width: 100%;
  margin-top: 25px;
  margin-bottom: 30px;
`

const deskButtonStyles = css`
  width: 200px;
  font-size: 16px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
`

const mobImageOwnerModule = css`
  padding: 0 20px 0 20px;
`

const acceptColor = css`
  background-color: #70bd74;
  color: #ffffff;
`

/**
 *
 * @param {function} deleteLicenseImageHandler
 * @param {LicenseImage} licenseImage
 * @param {string} userId
 * @returns {null|*}
 * @constructor
 */
export const LicenseImageOwnerModule = ({
  deleteLicenseImageHandler,
  licenseImage,
  userId,
}) => {
  const renderOwnerModule = licenseImage.isUserOwner(userId)
  const [submitFormDisabled, setSubmitFormDisabled] = useState(true)
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)

  const toggleSubmitButton = disable => {
    setSubmitFormDisabled(disable)
  }

  if (!renderOwnerModule) {
    return null
  }

  const editLicenseImageForm = useRef(
    new EditLicenseImageForm(licenseImage, toggleSubmitButton)
  )
  const [imageTags, setImageTags] = useState(
    editLicenseImageForm.current.newTags
  )

  useEffect(() => {
    editLicenseImageForm.current.newTags = imageTags
  }, [imageTags])

  const handleLicenseImageEditSubmit = () => {
    collections.licenseImages
      .doc(licenseImage.id)
      .set(
        {
          lastUpdated: firebase.firestore.Timestamp.now(),
          exclusivePrice:
            Number(editLicenseImageForm.current.newExclusivePrice) * 100,
          monthlyPrice:
            Number(editLicenseImageForm.current.newMonthlyPrice) * 100,
          tags: imageTags,
        },
        { merge: true }
      )
      .then(() => {
        setShowUpdateSuccess(true)
        setTimeout(() => {
          setShowUpdateSuccess(false)
        }, 2500)
        // Reset the image form
        // imageInput.value = ''
        // setFileName('')
        // setMonthlyPrice(null)
        // setExclusivePrice(null)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_EDITING_LICENSABLE_PHOTO',
          message: 'Error while user editing licensable photo.',
          type: errorTypes.edit,
          user: userId,
          file: 'LicenseImageOwnerModule.jsx',
        })
      })
  }

  const toggleMonthlyLicenseAvailable = () => {
    const { licenseImages } = collections
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(licenseImages.doc(licenseImage.id))
        .then(licenseImageDocumentSnapshot => {
          const transactionLicenseImage = new LicenseImage(
            licenseImageDocumentSnapshot
          )
          // Only show if one of the license options are enabled
          const showInSearch =
            !transactionLicenseImage.monthlyAvailable ||
            transactionLicenseImage.exclusiveAvailable
          transaction.update(licenseImages.doc(licenseImage.id), {
            monthlyAvailable: !transactionLicenseImage.monthlyAvailable,
            visibleInSearch: showInSearch,
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_EDITING_MONTHLY_LICENSE_AVAILABLE',
            message: 'Error while updating monthly license available.',
            type: errorTypes.edit,
            user: userId,
            file: 'LicenseImageOwnerModule.jsx',
          })
        })
    })
  }

  const toggleExclusiveLicenseAvailable = () => {
    const { licenseImages } = collections
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(licenseImages.doc(licenseImage.id))
        .then(licenseImageDocumentSnapshot => {
          const transactionLicenseImage = new LicenseImage(
            licenseImageDocumentSnapshot
          )

          // Only show if one of the license options are enabled
          const showInSearch =
            !transactionLicenseImage.exclusiveAvailable ||
            transactionLicenseImage.monthlyAvailable

          transaction.update(licenseImages.doc(licenseImage.id), {
            exclusiveAvailable: !transactionLicenseImage.exclusiveAvailable,
            visibleInSearch: showInSearch,
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_EDITING_EXCLUSIVE_LICENSE_AVAILABLE',
            message: 'Error while updating exclusive license available.',
            type: errorTypes.edit,
            user: userId,
            file: 'LicenseImageOwnerModule.jsx',
          })
        })
    })
  }

  return (
    <Box mobStyles={[column, mobImageOwnerModule]}>
      <InputWithHeader
        header="MONTHLY PRICE"
        inputName="monthly-price"
        onChange={editLicenseImageForm.current.updateMonthly}
        placeholderDefault={licenseImage.getUSDFormattedSubtotal(
          LicenseImageType.monthly
        )}
        type="number"
      />
      <Toggle
        label="Monthly license available."
        onChangeHandler={toggleMonthlyLicenseAvailable}
        checked={licenseImage.monthlyAvailable}
      />
      <InputWithHeader
        header="EXCLUSIVE PRICE"
        inputName="exclusive-price"
        onChange={editLicenseImageForm.current.updateExclusive}
        placeholderDefault={licenseImage.getUSDFormattedSubtotal(
          LicenseImageType.exclusive
        )}
        type="number"
      />
      <Toggle
        label="Exclusive license available."
        onChangeHandler={toggleExclusiveLicenseAvailable}
        checked={licenseImage.exclusiveAvailable}
      />
      <br />
      <TagInput imageTags={imageTags} setImageTags={setImageTags} />
      <Box
        mobStyles={[
          center,
          css`
            padding: 15px 0 15px 0;
          `,
        ]}
      >
        {showUpdateSuccess && (
          <Button
            disabled={submitFormDisabled}
            mobStyles={[mobSubmitButton, mobLong305x46, acceptColor]}
            deskStyles={deskSubmitButton}
            onClick={handleLicenseImageEditSubmit}
          >
            UPDATED
          </Button>
        )}
        {!showUpdateSuccess && (
          <Button
            disabled={submitFormDisabled}
            mobStyles={[mobSubmitButton, mobLong305x46]}
            deskStyles={deskSubmitButton}
            onClick={handleLicenseImageEditSubmit}
          >
            UPDATE IMAGE
          </Button>
        )}
      </Box>
      <Text mobStyles={mobHorizontalRule} />
      <Button
        mobStyles={[mobSubmitButton, deleteButtonStyles]}
        deskStyles={deskButtonStyles}
        onClick={deleteLicenseImageHandler}
      >
        DELETE PHOTO
      </Button>
    </Box>
  )
}
