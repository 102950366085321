import { css } from 'styled-components'

export const deskButtonVariants = {
  authButtonDark: css`
    background-color: ${p => p.theme.colors.darkGray};
    box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
    color: ${p => p.theme.colors.secondary};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    text-align: center;
  `,
  authButtonLight: css`
    border: 1px solid ${p => p.theme.colors.darkGray};
    background-color: ${p => p.theme.colors.secondary};
    color: ${p => p.theme.colors.darkGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    text-align: center;
    font-family: ${p => p.theme.fonts.secondary};
  `,
  darkHover: css`
    transition: all 0.2s ease-in;
    background-color: ${p => p.theme.colors.darkGray};
    transform: scale(1.025);
    cursor: pointer;
  `,
  darkRounded: css`
    border-radius: 32px;
    box-shadow: 0 6px 10px 0 rgba(112, 189, 116, 0.15);
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: ${p => p.theme.colors.secondary};
    background-color: ${p => p.theme.colors.primary};
  `,
  deskLong325x52: css`
    height: 52px;
    width: 325px;
  `,
  landingPageButton: css`
    box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
    background-color: ${p => p.theme.colors.primary};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 14px;
    font-weight: bold;
    color: ${p => p.theme.colors.secondary};
    border-radius: 30px;
  `,
  mobLong305x46: css`
    height: 46px;
    width: 305px;
  `,
  mobSmall154x42: css`
    height: 42px;
    width: 154px;
  `,
  deskSubmitButton: css`
    border-radius: 100px;
    background-color: #070c17;
    font-size: 25px;
    height: 75px;
    width: 450px;
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    background-color: ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.secondary};
    box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
    :disabled {
      opacity: 0.5;
    }
  `,

  mobSubmitButtonLight: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    border-radius: 30px;
    background-color: ${p => p.theme.colors.secondary};
    font-size: 14px;
    color: ${p => p.theme.colors.primary};
    height: 40px;
    width: 336px;
    border: 1px solid ${p => p.theme.colors.darkGray};
    margin: 0 auto;
    :disabled {
      opacity: 0.5;
    }
  `,
  mobSkipButton: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    background-color: transparent;
    font-size: 14px;
    color: ${p => p.theme.colors.primary};
    margin: 0 auto;
  `,
  rounded: css`
    border-radius: 30px;
  `,
  tabLong296x48: css`
    height: 48px;
    width: 296px;
  `,
  transparentBgWhiteBorder: css`
    background-color: rgb(0, 0, 0, 0);
    border: 1px solid ${p => p.theme.colors.secondary};
    border-radius: 40px;
    color: ${p => p.theme.colors.secondary};
    font-size: 12px;
  `,

  // Mobile Profile Button
  mobProfileButtonFull: css`
    height: auto;
    width: auto;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin: 2% auto 3%;
    text-align: left;
    display: inline;
    padding: 15px 15px 0px 15px;
    :focus {
      border: 2px solid ${p => p.theme.colors.primary};
    }
  `,

  mobButton: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    border-radius: 30px;
    font-size: 14px;
    height: 45px;
    width: 100%;
    margin: 10px 0 10px 0;
  `,

  smallCircleButton: css`
    border-radius: 50%;
    border: 2px solid ${p => p.theme.colors.primary};
    display: flex;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
  `,

  // Event View Buttons
  mobClearNotificationButton: css`
    height: 100%;
    width: auto;
    background-color: transparent;
  `,

  // Button meant only to be informative. No opacity changes
  mobInformativeButton: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    border-radius: 30px;
    background-color: ${p => p.theme.colors.primary};
    font-size: 14px;
    color: ${p => p.theme.colors.secondary};
    height: 40px;
    width: 336px;
    box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
    margin: 0 auto;
  `,

  mobMiniInformativeButton: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 16px;
    border-radius: 30px;
    background-color: ${p => p.theme.colors.primary};
    font-size: 16px;
    color: ${p => p.theme.colors.secondary};
    height: 35px;
    width: 95%;
    box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
    margin: 10px auto;
  `,

  mobButtonSpacer: css`
    margin: 30px 0 30px 0;
  `,

  mobFullWidth: css`
    width: 100%;
  `,

  mobStatusButton: css`
    font-family: Montserrat, serif;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 15px;
    border-radius: 30px;
    font-size: 14px;
    height: 45px;
    width: 100%;
    margin: 10px 0 10px 0;
    color: ${p => p.theme.colors.secondary};
    box-sizing: border-box;
  `,

  confirmButton: css`
    background-color: ${p => p.theme.colors.primary};
  `,

  completedButton: css`
    background-color: ${p => p.theme.colors.primary};
  `,

  acceptButton: css`
    background-color: ${p => p.theme.colors.green};
  `,

  ongoingButton: css`
    background-color: ${p => p.theme.colors.lightBlue};
  `,

  pendingButton: css`
    background-color: ${p => p.theme.colors.yellow};
  `,

  declineButton: css`
    background-color: ${p => p.theme.colors.lightRed};
  `,

  disputeButton: css`
    background-color: ${p => p.theme.colors.purple};
  `,

  canceledButton: css`
    background-color: ${p => p.theme.colors.blueGray};
  `,

  redButton: css`
    background-color: ${p => p.theme.colors.red};
  `,
}
