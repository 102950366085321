import { useEffect, useState } from 'react'
import { errorHandler, errorTypes } from './errorHandler'
import { AccountType } from '../constants/enums'
import { collections } from '../firebaseApp'
import { User } from '../classes'

export const useUserAccountType = currentUserAuth => {
  const [userAccountType, setUserAccountType] = useState(null)

  const getUserInformation = async currentUserAuth => {
    return collections.userInformation
      .doc(currentUserAuth.uid)
      .onSnapshot(currentUserDocument => {
        const user = new User(currentUserDocument)
        setUserAccountType(user.accountType)
      })
  }

  const handleGetAccountType = async () => {
    await getUserInformation(currentUserAuth)
  }

  useEffect(() => {
    if (currentUserAuth) {
      handleGetAccountType().catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_FETCHING_USER_ACCOUNT_TYPE',
          message: 'Error while trying to retrieve user info',
          type: errorTypes.customHook,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'useUserAccountType.js',
        })
      })
    } else {
      setUserAccountType(AccountType.guest)
    }
  }, [currentUserAuth])

  return userAccountType
}
