import React from 'react'
import { uuid } from '../../utils/uuid'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useHistory } from 'react-router-dom'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared'
import { collections } from '../../firebaseApp'
import {
  DesktopPageWrapper,
  GigBox,
  GigBoxType,
  LoadingScreen,
  MessageBox,
  StickyFooterWithButton,
} from '../../components'
import { ROUTES } from '../../constants/routes'
import { Gig } from '../../classes'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

/**
 * Users should be able to see all of their currently active gigs here.
 *
 * TODO: Eventually we should include a tab or something so they can view gigs in diff states
 *
 * @returns {*}
 * @constructor
 */
export const ViewMyGigsPage = ({ currentUserAuth }) => {
  const history = useHistory()

  const [
    allActiveBookings,
    allActiveBookingsLoading,
    allActiveBookingsError,
  ] = useCollectionData(
    collections.gigs.where('createdBy', '==', currentUserAuth.uid),
    {
      idField: 'id',
    }
  )

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          My Created Gigs
        </Text>
        {allActiveBookingsError && (
          <strong>Error: {allActiveBookingsError}</strong>
        )}
        {allActiveBookingsLoading && <LoadingScreen />}
        {allActiveBookings && (
          <>
            {allActiveBookings.length <= 0 && (
              <MessageBox
                header="No Gigs"
                message="Your gigs will appear here once you create one."
              />
            )}
            {allActiveBookings.map((gigDocumentSnapshot, index) => (
              <GigBox
                key={uuid()}
                gig={new Gig(gigDocumentSnapshot)}
                index={index}
                boxType={GigBoxType.fullLink}
              />
            ))}
          </>
        )}
      </Box>

      <StickyFooterWithButton
        mainButtonHandler={() => {
          history.push(ROUTES.CREATE_GIG.link)
        }}
        mainButtonText="CREATE NEW"
      />
    </DesktopPageWrapper>
  )
}
