import React from 'react'

import { socialIcons } from './constants'
import IconContainer from '../../pages/Landing/IconContainer'
import { mobBoxVariants } from '../../shared'
import { Box } from '../../shared/Box'
import { mobLandingFooter, mobLandingFooterIconWrapper } from './styles'

const { column, row } = mobBoxVariants

const LandingFooter = () => (
  <Box mobStyles={[column, mobLandingFooter]}>
    <Box mobStyles={[row, mobLandingFooterIconWrapper]}>
      <IconContainer icons={socialIcons} />
    </Box>
    <p>© 2019 BOOK A MODEL. All Rights Reserved.</p>
  </Box>
)

export default LandingFooter
