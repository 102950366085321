import { Box, mobBoxVariants } from '../../../../shared/Box'
import { inputVariants } from '../../../../shared/Input'
import { mobTextVariants, Text } from '../../../../shared/Text'
import { mobLinkVariants } from '../../../../shared/Link'
import { Button, mobButtonVariants } from '../../../../shared/Button'
import { deskTextVariants } from '../../../../shared/Text/deskTextVariants'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import { css } from 'styled-components'
import { Select } from '../../../../shared/Select'
import { AccountType } from '../../../../constants/enums'
import { Image } from '../../../../shared/Image'
import BronnerSmallLogo from '../../../../assets/logos/bronner_small_logo.jpg'
import {
  imageSignUpInStyles, mobImageSignUpInStyles,
  whiteLabelMotionVariants,
} from '../WhiteLabelExperienceRouter'
import { collections } from '../../../../firebaseApp'
import firebase from 'firebase/app'
import { errorHandler, errorTypes } from '../../../../utils/errorHandler'

const { column, row } = mobBoxVariants
const { mobSelectInput } = inputVariants
const { mobInstructionParagraph } = mobTextVariants
const { mobInTextLink } = mobLinkVariants
const { mobSubmitButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const { deskSubmitButton } = deskTextVariants
const { mobInputHeader } = mobTextVariants
const { mobMessageBoxHeader } = mobTextVariants

const deskMainHeaderStyles = css`
  font-size: 35px;
`

const mobHeader = css`
  height: auto;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: bold;
`

export const SelectAccountTypeSlide = ({ currentUserAuth }) => {
  const history = useHistory()
  const [accountType, setAccountType] = useState(AccountType.none)

  const handleAccountTypeChange = () => {
    const { userInformation } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()

    // Dont let users set their account type to guest
    if (accountType === AccountType.guest || accountType === AccountType.none) {
      return
    }

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(currentUserAuth.uid))
        .then(userDocument => {
          transaction.update(userInformation.doc(currentUserAuth.uid), {
            accountType: Number(accountType),
            accountTypeLastUpdated: serverTimestamp,
          })
        })
        .then(result => {
          history.push('/w/details')
        })
        .catch(error =>
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ACCOUNT_TYPE_CHANGE',
            message: 'Error while updating account type.',
            type: errorTypes.profile,
            user: currentUserAuth.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        )
    })
  }

  // TODO: Finish the methods in this thing
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box
        deskStyles={[
          row,
          css`
            min-height: 500px;
          `,
        ]}
        mobStyles={[
          column,
          css`
            min-height: 500px;
          `,
        ]}
      >
        <Box
          mobStyles={[
            column,
            css`
              flex: 1;
              padding: 50px 20px 0 20px;
            `,
          ]}
          deskStyles={[
            column,
            css`
              flex: 1;
              padding: 50px 50px 0 50px;
            `,
          ]}
        >
          <Text
            mobStyles={[
              mobHeader,
              css`
                margin: 0;
              `,
            ]}
            deskStyles={deskMainHeaderStyles}
          >
            Select your account type.
          </Text>
          <Text mobStyles={mobInstructionParagraph}>
            Change your account type.
          </Text>
          <Text
            mobStyles={[
              mobMessageBoxHeader,
              css`
                margin: 5px 0;
                font-size: 24px;
              `,
            ]}
            deskStyles={[
              css`
                font-size: 20px;
              `,
            ]}
          >
            Please click below to change the account type.
          </Text>
          <Select
            mobStyles={[mobSelectInput]}
            name="accountType"
            onChange={event => {
              setAccountType(Number(event.target.value))
            }}
            value={accountType}
            options={[
              { value: AccountType.none, label: 'None' },
              { value: AccountType.model, label: 'Model' },
              { value: AccountType.exhibitor, label: 'Exhibitor' },
              { value: AccountType.instructor, label: 'Instructor' },
            ]}
            data-testid="account-type-select"
          />
          <Button
            disabled={accountType === AccountType.none}
            mobStyles={[mobSubmitButton, mobLong305x46]}
            deskStyles={deskSubmitButton}
            onClick={handleAccountTypeChange}
          >
            CONFIRM ACCOUNT TYPE
          </Button>
        </Box>
        <Box
          mobStyles={[
            column,
            css`
              flex: 1;
              justify-content: center;
              align-items: center;
            `,
          ]}
        >
          <Image src={BronnerSmallLogo} deskStyles={imageSignUpInStyles} mobStyles={mobImageSignUpInStyles}/>
        </Box>
      </Box>
    </motion.div>
  )
}
