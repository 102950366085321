import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { collections } from '../../firebaseApp'
import { LoadingScreen } from '../../components'
import styled, { css } from 'styled-components'
import { convertSeconds } from '../../utils/convertFirestoreTimestamp'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { up } from 'styled-breakpoints'

const {
  mobGigBoxDescriptionMini,
  mobGigBoxHeader,
  mobHorizontalRule,
  mobPageHeader,
  mobInstructionParagraph,
} = mobTextVariants
const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

const { column, mobWrapper, row, center } = mobBoxVariants

export const BlogPage = ({ currentUserAuth, match, user }) => {
  const [blogPosts, blogPostsLoading, blogPostsError] = useCollectionData(
    collections.blogPosts,
    {
      idField: 'id',
    }
  )

  return (
    <Box mobStyles={[column, mobWrapper, center]}>
      {blogPostsError && <strong>Error: {blogPostsError}</strong>}
      {blogPostsLoading && <LoadingScreen />}
      {blogPosts && (
        <>
          <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
            Blog
          </Text>
          <Text
            mobStyles={[mobInstructionParagraph]}
            deskStyles={[
              deskInstructionParagraph,
              css`
                text-align: center;
              `,
            ]}
          >
            Come here to see site announcements!
          </Text>
          {blogPosts.map((post, index) => {
            return createBlogPost(post.title, post.body, post.createdAt, index)
          })}
        </>
      )}
    </Box>
  )
}

const BlogPostContainer = styled.div`
  height: auto;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.lightGray};
  border-radius: 5px;
  background-color: ${p => p.theme.colors.secondary};
  box-shadow: ${p => p.theme.shadow.regular};
  padding: 15px 15px 0px 15px;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 25px;
  ${up('desktop')} {
    width: 50%;
  }
`

const SignatureText = styled.p`
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  flex: 1;
`

const SignatureDate = styled.p`
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
`

const createBlogPost = (title, body, createdAt, index) => {
  return (
    <BlogPostContainer key={index}>
      <Text mobStyles={mobGigBoxHeader}>{title}</Text>
      <Text mobStyles={mobHorizontalRule} />
      <Text mobStyles={mobGigBoxDescriptionMini}>{body}</Text>

      <Box mobStyles={[row]}>
        <SignatureText>- Book A Model Team</SignatureText>
        <SignatureDate>{convertSeconds(createdAt.seconds)}</SignatureDate>
      </Box>
    </BlogPostContainer>
  )
}
