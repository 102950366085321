import React from 'react'
import { uuid } from '../../utils/uuid'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, mobTextVariants, Text } from '../../shared/'
import { collections } from '../../firebaseApp'
import { LoadingScreen } from '../../components/LoadingScreen'
import { mobBoxVariants } from '../../shared/index'
import { Request } from '../../classes'
import { RequestBox } from '../../components/RequestBox'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { MessageBox } from '../../components/MessageBox'
import { RequestStatus, RequestType } from '../../constants/enums'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

export const ViewReceivedOffersPage = ({ currentUserAuth }) => {
  const [
    userReceivedOffers,
    userReceivedOffersLoading,
    userReceivedOffersError,
  ] = useCollectionData(
    collections.activeRequests
      .where('operative', '==', currentUserAuth.uid)
      .where('requestType', '==', RequestType.offer),

    {
      idField: 'id',
    }
  )

  const pendingFilter = request => request.status === RequestStatus.pending
  const awaitingFilter = request => request.status === RequestStatus.awaitingBAM
  const acceptedFilter = request => request.status === RequestStatus.accepted
  const completedFilter = request => request.status === RequestStatus.completed
  const disputedFilter = request => request.status === RequestStatus.disputed
  const inactiveFilter = request =>
    request.status === RequestStatus.inactive ||
    request.status === RequestStatus.declined ||
    request.status === RequestStatus.canceled ||
    request.status === RequestStatus.deleted ||
    request.status === RequestStatus.notAvailable

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          Offers you have received.
        </Text>
        <Text
          mobStyles={mobInstructionParagraph}
          deskStyles={deskInstructionParagraph}
        >
          These are offers you have received.
        </Text>
        {userReceivedOffersError && (
          <strong>Error: {userReceivedOffersError}</strong>
        )}
        {userReceivedOffersLoading && <LoadingScreen />}
        {userReceivedOffers && (
          <>
            {userReceivedOffers.length <= 0 && (
              <MessageBox
                header="No Offers"
                message="Offers you receive will appear here."
              />
            )}
            {userReceivedOffers
              .filter(awaitingFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {userReceivedOffers.filter(pendingFilter).map(receivedOfferData => {
              return (
                <RequestBox
                  key={uuid()}
                  request={new Request(receivedOfferData)}
                  currentUserAuth={currentUserAuth}
                />
              )
            })}
            {userReceivedOffers
              .filter(acceptedFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {userReceivedOffers
              .filter(completedFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {userReceivedOffers
              .filter(disputedFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {userReceivedOffers
              .filter(inactiveFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
          </>
        )}
      </Box>
    </DesktopPageWrapper>
  )
}
