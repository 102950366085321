import { useEffect, useState } from 'react'
import { errorHandler, errorTypes } from './errorHandler'
import { collections } from '../firebaseApp'

import values from 'lodash/values'

export const useUserUnreadMessages = currentUserAuth => {
  // const [userUnreadMessages, setUserUnreadMessages] = useState(0)
  const [userUnreadMessages, setUserUnreadMessages] = useState({})
  let chatroomUnreadCount = {}

  const getUnreadMessageCount = async currentUserAuth => {
    return collections.chatRooms
      .where('members', 'array-contains', currentUserAuth.uid)
      .get()
      .then(userChatrooms => {
        setUserUnreadMessages(0)

        userChatrooms.docs.map(chatroom => {
          collections.chatRooms
            .doc(chatroom.id)
            .collection('messages')
            .where('seen', '==', false)
            .onSnapshot(unreadMessages => {
              const unreadCount = unreadMessages.docs.filter(
                message => message.data().from !== currentUserAuth.uid
              ).length
              chatroomUnreadCount[chatroom.id] = unreadCount
              setUserUnreadMessages(prevState => {
                return {
                  ...prevState,
                  [chatroom.id]: unreadCount,
                }
              })
            })
        })
      })
  }

  const handleUnreadMessageCount = async () => {
    await getUnreadMessageCount(currentUserAuth)
  }

  useEffect(() => {
    if (currentUserAuth) {
      handleUnreadMessageCount().catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_FETCHING_USER_UNREAD_MESSAGES',
          message: 'Error while trying to retrieve user unread message info',
          type: errorTypes.customHook,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'useUserUnreadMessages.js.js',
        })
      })
    } else {
      setUserUnreadMessages(0)
    }
  }, [currentUserAuth])

  return [
    values(userUnreadMessages).reduce((a, b) => a + b, 0),
    userUnreadMessages,
  ]
}
