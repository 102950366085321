import React from 'react'
import PropTypes from 'prop-types'
import { Box, deskBoxVariants, mobBoxVariants, Text } from '../../shared/index'
import { mobDetail, mobDetailName, mobModelDetailsContainer } from './styles'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const { column } = mobBoxVariants
const { deskDetailsContainer } = deskBoxVariants
const { deskDetailName, deskDetail } = deskTextVariants

export const Detail = ({ detail, text, url }) => {
  const details = url ? <StyledA href={detail}>{detail}</StyledA> : detail

  return (
    <Box
      mobStyles={[column, mobModelDetailsContainer]}
      deskStyles={[deskDetailsContainer]}
    >
      <Text mobStyles={mobDetail} deskStyles={[deskDetail]}>
        {details}
      </Text>
      <Text mobStyles={mobDetailName} deskStyles={[deskDetailName]}>
        {text}
      </Text>
    </Box>
  )
}

const StyledA = styled(motion.a)`
  overflow-wrap: anywhere;
`
