import get from 'lodash/get'
import 'firebase/storage'

/**
 * UpdateProfileForm is
 *
 */
export class UpdateProfileForm {
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT

  // We keep a value to the current value and the value they have currently typed in
  // to possibly compare before submitting. Currently not implemented
  // Fields all profiles have
  currentName
  currentZipCode
  currentDescription
  fullName
  zipCode
  // Will never be directly modified by the user
  city

  // Algolia Defined
  // https://www.algolia.com/doc/guides/managing-results/refine-results/geolocation/#geo-search
  _geoloc
  description

  // Model Fields
  currentHeight
  currentWeight
  currentGender
  currentRace
  currentRate

  height
  weight
  gender
  race
  hourlyRate

  // Agency Fields
  currentAgencyType
  currentIndustry
  currentWebsiteUrl
  currentYearFounded
  currentRepresentative

  agencyType
  industry
  websiteUrl
  yearFounded
  representative

  // Photographer fields
  currentPhotoSpecialty
  currentEquipment

  photoSpecialty
  equipment

  // Photographer fields
  currentMakeupSpecialty

  makeupSpecialty

  constructor(
    userSnapshot,
    currentName = '',
    currentHeight = 0,
    currentWeight = 0,
    currentGender = '',
    currentRace = '',
    currentZip = 0,
    currentRate = 0,
    currentAgencyType = '',
    currentIndustry = '',
    currentWebsiteUrl = '',
    currentYearFounded = 2020,
    currentRepresentative = '',
    currentDescription = '',
    currentEquipment = '',
    currentPhotoSpecialty = '',
    currentMakeupSpecialty = ''
  ) {
    const selectedUserData =
      userSnapshot && userSnapshot.data ? userSnapshot.data() : userSnapshot

    if (userSnapshot) {
      this.currentName = get(
        selectedUserData,
        'profileDetails.fullName',
        currentName
      )
      this.currentZipCode = get(
        selectedUserData,
        'profileDetails.zipCode',
        currentZip
      )

      // Two for is the description is long
      this.currentDescription = get(
        selectedUserData,
        'profileDetails.description',
        currentDescription
      )
      this.description = get(
        selectedUserData,
        'profileDetails.description',
        currentDescription
      )

      // Agency Fields
      this.currentAgencyType = get(
        selectedUserData,
        'profileDetails.agencyType',
        currentAgencyType
      )
      this.currentIndustry = get(
        selectedUserData,
        'profileDetails.industry',
        currentIndustry
      )
      this.currentWebsiteUrl = get(
        selectedUserData,
        'profileDetails.websiteUrl',
        currentWebsiteUrl
      )
      this.currentYearFounded = get(
        selectedUserData,
        'profileDetails.yearFounded',
        currentYearFounded
      )
      this.currentRepresentative = get(
        selectedUserData,
        'profileDetails.representative',
        currentRepresentative
      )

      // Model Fields
      this.currentHeight = get(
        selectedUserData,
        'profileDetails.height',
        currentHeight
      )
      this.currentWeight = get(
        selectedUserData,
        'profileDetails.weight',
        currentWeight
      )
      this.currentGender = get(
        selectedUserData,
        'profileDetails.gender',
        currentGender
      )
      this.currentRace = get(
        selectedUserData,
        'profileDetails.race',
        currentRace
      )

      // Photographer fields
      // photoSpecialty
      this.currentPhotoSpecialty = get(
        selectedUserData,
        'profileDetails.photoSpecialty',
        currentPhotoSpecialty
      )

      this.currentEquipment = get(
        selectedUserData,
        'profileDetails.equipment',
        currentEquipment
      )

      // Makeup field
      this.currentMakeupSpecialty = get(
        selectedUserData,
        'profileDetails.makeupSpecialty',
        currentMakeupSpecialty
      )

      // Divide by 100 to get the $ amount
      this.currentRate =
        get(selectedUserData, 'profileDetails.hourlyRate', currentRate) / 100
    }
  }

  updateName = event => {
    this.fullName = event.target.value
  }

  validZipUpdate = () => {
    return (
      this.zipCode !== this.currentZipCode &&
      this.zipCode !== undefined &&
      /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.zipCode)
    )
  }

  updateZip = event => {
    this.zipCode = event.target.value
  }

  updateHeight = event => {
    this.height = event.target.value
  }

  updateWeight = event => {
    this.weight = event.target.value
  }

  updateGender = event => {
    this.gender = event.target.value
  }

  updateRace = event => {
    this.race = event.target.value
  }

  // Convert $ to pennies for easy Stripe processing
  updateRate = event => {
    this.hourlyRate = event.target.value * 100
  }

  updateAgencyType = event => {
    this.agencyType = event.target.value
  }

  updatePhotoSpecialty = event => {
    this.photoSpecialty = event.target.value
  }

  updateEquipment = event => {
    this.equipment = event.target.value
  }

  updateIndustry = event => {
    this.industry = event.target.value
  }

  updateMakeupSpecialty = event => {
    this.makeupSpecialty = event.target.value
  }

  updateWebsiteUrl = event => {
    this.websiteUrl = event.target.value
  }

  updateYearFounded = event => {
    this.yearFounded = event.target.value
  }

  updateRepresentative = event => {
    this.representative = event.target.value
  }

  updateDescription = event => {
    this.description = event.target.value
  }

  getHumanReadableHeight = () => {
    if (this.height) {
      return '49298fj'
    } else {
      return 'Not Set'
    }
  }

  // Set of all fields to inspect before creating the profile data from submit
  profileFieldNames = new Set([
    'fullName',
    'height',
    'weight',
    'gender',
    'race',
    'zipCode',
    'hourlyRate',
    'agencyType',
    'industry',
    'websiteUrl',
    'yearFounded',
    'representative',
    'description',
  ])

  stringFields = new Set([
    'fullName',
    'agencyType',
    'industry',
    'websiteUrl',
    'representative',
    'description',
    'race',
  ])

  /**
   * This method prepares the data for submission to firestore by
   * removing all undefined values. If no data is found it returns undefined
   */
  getProfileData = () => {
    let fieldsToUpdate = {}
    // Use Lodash to see if the form has new data before submitting. If it submits an empty
    // object it will clear all profileDetails :-/
    this.profileFieldNames.forEach(fieldName => {
      let fieldValue = get(this, fieldName, undefined)
      if (fieldValue) {
        if (this.stringFields.has(fieldName)) {
          fieldsToUpdate[fieldName] = fieldValue
        } else {
          // Force fields to be Number because JS is dumb and keeps converting them to strings
          fieldsToUpdate[fieldName] = Number(fieldValue)
        }
      } else {
      }
    })

    return fieldsToUpdate
  }
}
