import React from 'react'
import 'firebase/storage'
import MoneyFalling from '../../assets/lottie/13398-money-stack'
import { useLottie } from 'lottie-react'
import { MessageBox } from '../MessageBox'

/**
 * StripeAccountModule is DEPRECATED
 */
export const StripeAccountModule = () => {
  
  const options = {
    animationData: MoneyFalling,
    loop: true,
    autoplay: true,
    style: {
      height: 150,
      margin: 0,
    },
  }
  const { View } = useLottie(options)
  
  return (
    <MessageBox
      header="Cash Services"
      message={"Book A Model is switching to Cash Services for payment dispersal. " +
      "In order to make the payment process easier we now allow users to receive payments Cash Services. " +
      "\nYou can selected from the following: CashApp, PayPal, Venmo, or Zelle."}
      subMessage={View}
    />
  )
}
