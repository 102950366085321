import { Box, mobBoxVariants } from '../../shared/Box'
import React from 'react'
import { EmptyChatInboxText } from './InboxPage'

const { column } = mobBoxVariants

export const MobileInbox = ({ userChatRooms, getSortedChats }) => {
  return (
    <Box mobStyles={[column]}>
      {userChatRooms.length <= 0 && (
        <EmptyChatInboxText>
          Chats will appear here once you have an active booking request.
        </EmptyChatInboxText>
      )}
      {getSortedChats(userChatRooms)}
    </Box>
  )
}
