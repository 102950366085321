import styled from 'styled-components'
import { getFontSize, variantsAndStyles } from '../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

export const StyledLabel = styled.label`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${getFontSize}
  ${variantsAndStyles()}
`

export const StyledMotionLabel = styled(motion.label)`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;

  ${getFontSize}
  ${variantsAndStyles()}
`
