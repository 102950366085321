import React, { useState } from 'react'
import {
  Box,
  Button,
  mobBoxVariants,
  mobButtonVariants,
  TextArea,
} from '../../../shared/index'
import { css } from 'styled-components'
import { ChatRoom } from '../../../classes/Models/ChatRoom'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'

const { column, center, mobChatInputWrapper } = mobBoxVariants
const { mobSubmitButton } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants

export const ChatInput = ({
  chatRoomSnapshot,
  currentUserId,
  setLastMessageHeight,
}) => {
  const chatRoom = new ChatRoom(chatRoomSnapshot)
  const [inputValue, setInputValue] = useState('')
  const [rows, setRows] = useState(1)
  const minRows = 1
  const maxRows = 10
  const textareaLineHeight = 24

  const handleChange = event => {
    // How many rows before the user typed this value
    const previousRows = event.target.rows
    event.target.rows = minRows // reset number of rows in textarea

    // http://rocha.la/JavaScript-bitwise-operators-in-practice
    // tl;dr Bit operator that does Math.floor faster
    // Implementation ref: https://codepen.io/liborgabrhel/pen/eyzwOx
    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight) - 1
    setLastMessageHeight(150 + (textareaLineHeight * rows - textareaLineHeight))
    event.target.scrollTop = event.target.scrollHeight

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows
      event.target.scrollTop = event.target.scrollHeight
    }

    setInputValue(event.target.value)
    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  const handleSendMessage = () => {
    chatRoom.sendMessage(inputValue, currentUserId, () => {
      resetChatInput()
    })
  }

  const resetChatInput = () => {
    setInputValue('')
    setRows(minRows)
  }

  return (
    <Box mobStyles={[center]}>
      <Box
        mobStyles={[column, mobChatInputWrapper]}
        deskStyles={css`
          width: 80%;
        `}
      >
        <TextArea
          name={'search-inbox'}
          onChange={handleChange}
          placeholder={'Type Message'}
          rows={rows}
          value={inputValue}
          mobStyles={[
            css`
              width: 100%;
              opacity: 0.44;
              color: ${p => p.theme.colors.darkGray};
              font-family: ${p => p.theme.fonts.primary};
              font-size: 14px;
              letter-spacing: 0;
              line-height: 24px;
              overflow: auto;
              height: auto;
              padding: 15px;
              resize: none;
            `,
          ]}
        />
        <Box
          mobStyles={[
            css`
              font-size: 15px;
              align-items: center;
              padding: 0px 10px 0px 10px;
              justify-content: flex-end;
              margin: 10px;
            `,
          ]}
        >
          <Button
            onClick={handleSendMessage}
            mobStyles={[
              mobSubmitButton,
              css`
                height: 36px;
                width: 100px;
              `,
            ]}
            deskStyles={deskSubmitButton}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
