import React from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { Request } from '../../classes'
import { LoadingScreen } from '../../components/LoadingScreen'
import { ViewProposalPageModule } from './ViewProposalPageModule'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'

export const ViewProposalPage = ({ currentUserAuth, match }) => {
  const history = useHistory()
  const [
    proposalSnapshot,
    proposalSnapshotLoading,
    proposalSnapshotError,
  ] = useDocumentData(
    collections.activeRequests.doc(match.params.proposal_id),
    {
      idField: 'id',
    }
  )

  // IF THEY ARE NOT THE OPERATIVE OR gig OWNER DO NOT RENDER
  if (proposalSnapshot) {
    const request = new Request(proposalSnapshot)
    if (
      currentUserAuth.uid !== request.operative &&
      currentUserAuth.uid !== request.eventCreator
    ) {
      history.push(ROUTES.HOME.link)
    }
  }

  return (
    <>
      {proposalSnapshotError && <strong>Error: {proposalSnapshotError}</strong>}
      {proposalSnapshotLoading && <LoadingScreen />}
      {proposalSnapshot && (
        <ViewProposalPageModule
          request={new Request(proposalSnapshot)}
          currentUserAuth={currentUserAuth}
        />
      )}
    </>
  )
}
