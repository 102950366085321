import { css } from 'styled-components'

export const mobModelRatingContainer = css`
  width: 100%;
  margin-top: 7px;
`

export const mobModelRating = css`
  width: auto;
`
