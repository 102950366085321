import React, { useEffect, useRef, useState } from 'react'
import { collections } from '../../firebaseApp'
import isEmpty from 'lodash/isEmpty'
import 'firebase/storage'
import { useHistory } from 'react-router-dom'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import { css } from 'styled-components'
import { useRecoilValue } from 'recoil'

import {
  Box,
  Button, inputVariants, Link,
  mobBoxVariants,
  mobButtonVariants, mobLinkVariants,
  mobTextVariants, Select,
  Text,
} from '../../shared'
import { AccountType, UpdateProfileType } from '../../constants/enums'
import { UpdateProfileForm } from '../../classes/Forms/UpdateProfileForm'
import {
  DesktopPageWrapper,
  LoadingScreen,
  StickyFooter,
  StickyFooterWithButton,
} from '../../components'
import { ROUTES } from '../../constants/routes'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { updateProfileHeaderState, updateProfileState } from '../../store'
import {
  accountSelectOptions,
  AgencyUpdateProfileModule, CompletedIcon,
  ModelUpdateProfileModule, NotCompletedIcon,
  ProfileImageUpdateModule,
} from '../../modules'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { PhotographerUpdateProfileModule } from '../../modules/PhotographerUpdateProfileModule'
import { MakeUpArtistUpdateProfileModule } from '../../modules/MakeUpArtistUpdateProfileModule'
import { PortfolioImagesUpdateModule } from '../../modules/PortfolioImagesUpdateModule'
import { UpdateOnboarding } from '../../components/BookAModelOnboarding'
import { InfluencerUpdateProfileModule } from '../../modules/InfluencerUpdateProfileModule'
import axios from 'axios'
import { MessageBox } from '../../components/MessageBox'
import { convertSeconds } from '../../utils/convertFirestoreTimestamp'
import firebase from 'firebase'
import { StepStatusRow } from '../../modules/GeneralSettingsModule'

const acceptColor = css`
  background-color: #70bd74;
  color: #ffffff;
`

const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobPageSubHeader } = mobTextVariants
const { deskSubmitButton, deskPageHeader } = deskTextVariants
const { deskPageSubHeader } = deskTextVariants
const { mobSelectInput } = inputVariants
const {
  mobInstructionParagraph,
  mobInstructionHeader,
  mobHorizontalRule,
} = mobTextVariants
const { mobInTextLink } = mobLinkVariants
const { row, fullWidth } = mobBoxVariants


export const UpdateProfilePage = ({ match, user, currentUserAuth }) => {
  const history = useHistory()
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)
  const firstTimeSetup =
    useRecoilValue(updateProfileState).type === UpdateProfileType.setup
  const pageHeader = useRecoilValue(updateProfileHeaderState)

  // FIXME: USe recoil to control the state of the profile that is getting updated to avoid
  // using the dumb route params
  const managedUser = match.path === ROUTES.UPDATE_MANAGED_USERS.link
  let userGettingUpdatedId
  if (managedUser) {
    userGettingUpdatedId = match.params.user_id
  } else {
    userGettingUpdatedId = currentUserAuth.uid
  }

  const [userSnapshot, userSnapshotLoading, userSnapshotError] = useDocument(
    collections.userInformation.doc(userGettingUpdatedId)
  )
  const [
    portfolioImagesSnapshot,
    portfolioImagesSnapshotLoading,
    portfolioImagesSnapshotError,
  ] = useCollection(
    collections.userInformation
      .doc(userGettingUpdatedId)
      .collection('portfolioImages')
  )

  let profileForm = useRef(new UpdateProfileForm(userSnapshot))

  useEffect(() => {
    profileForm.current = new UpdateProfileForm(userSnapshot)
  }, [userSnapshot])

  // var isValidZip = ;
  const buildCityFromResponse = googleResponse => {
    let city
    let state
    for (let i = 0; i < googleResponse.length; i++) {
      if (googleResponse[i].types.includes('locality')) {
        city = googleResponse[i].short_name
      }

      if (googleResponse[i].types.includes('administrative_area_level_1')) {
        state = googleResponse[i].short_name
      }
    }
    return `${city}, ${state}`
  }

  const handleProfileDetailsSubmit = async event => {
    event.preventDefault()
    const userInfoDocumentRef = collections.userInformation.doc(
      userGettingUpdatedId
    )
    const updatedProfileInfo = profileForm.current.getProfileData()
    if (!isEmpty(updatedProfileInfo)) {
      let updateData
      if (profileForm.current.validZipUpdate()) {
        // TODO: Update to prod
        const googleAPIKey = 'AIzaSyAR4vHdSSnLiQzZYXSt8QGjwIYPGAg9U-s'
        const googleGeoResponse = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${profileForm.current.zipCode}&sensor=true&key=${googleAPIKey}`
        )
        const city = buildCityFromResponse(
          googleGeoResponse.data.results[0].address_components
        )
        updatedProfileInfo['city'] = city
        const geoLocData = {
          lat: googleGeoResponse.data.results[0].geometry.location.lat,
          lng: googleGeoResponse.data.results[0].geometry.location.lng,
        }

        updateData = {
          profileDetails: updatedProfileInfo,
          _geoloc: geoLocData, //profileForm.current._geoloc,
        }
      } else {
        updateData = { profileDetails: updatedProfileInfo }
      }

      userInfoDocumentRef
        .set(updateData, { merge: true })
        .then(() => {
          setShowUpdateSuccess(true)
          setTimeout(() => {
            setShowUpdateSuccess(false)
          }, 5000)
          if (firstTimeSetup) {
            history.push('/profile')
          }
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_SUBMIT_PROFILE_DETAILS',
            message: 'Failed to submit new profile details',
            type: errorTypes.profile,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'UserProfilePage.jsx',
          })
        })
    } else {
      // 'Empty form submitted'
    }
  }
  
  const handleAccountTypeChange = () => {
    const { userInformation } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()
    
    // Dont let users set their account type to guest
    if (user.accountType === AccountType.guest) {
      return
    }
    
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          transaction.update(userInformation.doc(user.userId), {
            accountType: Number(accountType),
            accountTypeLastUpdated: serverTimestamp,
          })
        })
        .then(result => {
          // return reassessVisibleInSearch()
        })
        .catch(error =>
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ACCOUNT_TYPE_CHANGE',
            message: 'Error while updating account type.',
            type: errorTypes.profile,
            user: user && user.uid,
            file: 'GeneralSettingsModule.jsx',
          })
        )
    })
  }

  const dependenciesLoading =
    userSnapshotLoading || portfolioImagesSnapshotLoading
  const dependenciesError = userSnapshotError || portfolioImagesSnapshotError
  const dependenciesReady = userSnapshot && portfolioImagesSnapshot
  const [accountType, setAccountType] = useState(user.accountType)

  const noProfileType = user.accountType === 1

  return (
    <>
      <UpdateOnboarding user={user} />

      <DesktopPageWrapper>
        <>
          {dependenciesError && <strong>Error: {userSnapshotError}</strong>}
          {dependenciesLoading && <LoadingScreen />}
          {dependenciesReady && (
            <Box mobStyles={[column, mobWrapper]}>
              <Text mobStyles={[mobPageHeader]} deskStyles={deskPageHeader}>
                {pageHeader}
              </Text>
              <Text
                mobStyles={[mobPageSubHeader]}
                deskStyles={[deskPageSubHeader]}
              >
                Profile Details
              </Text>
              {noProfileType && (
                
                <>
                  <MessageBox
                    header="ACCOUNT TYPE NOT SET"
                    message="To update your profile details you must select a profile type. This can can be done by visiting the settings page and looking under General Settings."
                    subMessage={'Click to go to the settings page.'}
                    onClick={() => {
                      history.push(ROUTES.ACCOUNT_SETTINGS.link)
                    }}
                  />
                  <StepStatusRow
                    stepText={'Account Type Selected'}
                    icon={user.hasNoAccountType() ? NotCompletedIcon : CompletedIcon}
                  />
                  <Text mobStyles={mobInstructionParagraph}>
                    Change your account type. Your account type may be changed once a
                    week. Please file a{' '}
                    <Link
                      mobStyles={[mobInstructionParagraph, mobInTextLink]}
                      deskStyles={[
                        mobInstructionParagraph,
                        mobInTextLink,
                        css`
                font-size: 18px;
              `,
                      ]}
                      to={ROUTES.SUPPORT.link}
                    >
                      support
                    </Link>{' '}
                    ticket for exceptions.
                    <br />
                    {user.updatedAccountInLastWeek() &&
                    `Disabled until: ${convertSeconds(
                      user.accountTypeLastUpdated.seconds + 604800
                    )}`}
                  </Text>
                  <Select
                    mobStyles={[mobSelectInput]}
                    name="accountType"
                    onChange={event => {
                      setAccountType(Number(event.target.value))
                    }}
                    value={accountType}
                    disabled={user.updatedAccountInLastWeek()}
                    options={accountSelectOptions}
                    data-testid="account-type-select"
                  />
                  <Button
                    disabled={user.updatedAccountInLastWeek()}
                    mobStyles={[mobSubmitButton, mobLong305x46]}
                    deskStyles={deskSubmitButton}
                    onClick={handleAccountTypeChange}
                  >
                    UPDATE ACCOUNT TYPE
                  </Button>
                </>
              )}
              <ModelUpdateProfileModule user={user} profileForm={profileForm} />
              <AgencyUpdateProfileModule
                user={user}
                profileForm={profileForm}
              />
              <PhotographerUpdateProfileModule
                user={user}
                profileForm={profileForm}
              />
              <MakeUpArtistUpdateProfileModule
                user={user}
                profileForm={profileForm}
              />
              <InfluencerUpdateProfileModule
                user={user}
                profileForm={profileForm}
              />
              <ProfileImageUpdateModule user={user} />
              <PortfolioImagesUpdateModule
                user={user}
                portfolioImagesSnapshot={portfolioImagesSnapshot}
              />
            </Box>
          )}

          {showUpdateSuccess && (
            <StickyFooter>
              <Button
                mobStyles={[mobSubmitButton, mobLong305x46, acceptColor]}
                deskStyles={deskSubmitButton}
              >
                UPDATED
              </Button>
            </StickyFooter>
          )}
          {!showUpdateSuccess && (
            <StickyFooterWithButton
              mainButtonHandler={handleProfileDetailsSubmit}
              mainButtonText={firstTimeSetup ? 'SAVE & CONTINUE' : 'SAVE'}
              secondaryButton={firstTimeSetup}
              secondaryButtonText="SKIP"
              mainButtonId={'update-profile-submit'}
            />
          )}
        </>
      </DesktopPageWrapper>
    </>
  )
}
