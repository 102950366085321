import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Box, mobBoxVariants } from '../../../../shared/Box'
import { css } from 'styled-components'
import { EmailSignUpForm } from '../../../../components/Auth/EmailSignUpForm'
import { Image } from '../../../../shared/Image'
import BronnerSmallLogo from '../../../../assets/logos/bronner_small_logo.jpg'
import { Button } from '../../../../shared/Button'
import { EmailLoginForm } from '../../../../components/Auth/EmailLoginForm'
import React from 'react'
import {
  imageSignUpInStyles, mobImageSignUpInStyles,
  whiteLabelMotionVariants,
} from '../WhiteLabelExperienceRouter'
import { Text } from '../../../../shared/Text'

const { column, row } = mobBoxVariants

const deskMainHeaderStyles = css`
  font-size: 35px;
`

const mobHeader = css`
  height: auto;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: bold;
`
export const SignUpSlide = () => {
  const history = useHistory()

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box
        deskStyles={[
          row,
          css`
            padding: 50px 50px 0 50px;
          `,
        ]}
        mobStyles={[
          column,
          css`
            padding: 50px 0px 0 0px;
          `,
        ]}
      >
        <Box
          deskStyles={[
            column,
            css`
              flex: 1;
              padding: 50px 50px 0 50px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 1;
              padding: 50px 20px 0 20px;
            `,
          ]}
        >
          <Text
            mobStyles={[
              mobHeader,
              css`
                margin: 0;
              `,
            ]}
            deskStyles={deskMainHeaderStyles}
          >
            Sign Up.
          </Text>
          <EmailSignUpForm nextPageLink={'/w/account-type'} />
        </Box>
        <Box
          mobStyles={[
            column,
            css`
              flex: 1;
              margin-top: 25px;
              justify-content: center;
              align-items: center;
            `,
          ]}
        >
          <Image src={BronnerSmallLogo} deskStyles={imageSignUpInStyles} mobStyles={mobImageSignUpInStyles}/>
        </Box>
      </Box>
    </motion.div>
  )
}

export const LoginSlide = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box
        deskStyles={[
          row,
          css`
            padding: 50px 50px 0 50px;
          `,
        ]}
        mobStyles={column}
      >
        <Box
          mobStyles={[
            column,
            css`
              flex: 1;
              margin-top: 25px;
              justify-content: center;
              align-items: center;
            `,
          ]}
        >
          <Image src={BronnerSmallLogo} deskStyles={imageSignUpInStyles} mobStyles={mobImageSignUpInStyles}/>
        </Box>
        <Box
          deskStyles={[
            css`
              flex: 1;
              padding: 50px 50px 0 50px;
            `,
          ]}
          mobStyles={[
            column,
            css`
              flex: 1;
              padding: 50px 20px 0 20px;
            `,
          ]}
        >
          <Text
            mobStyles={[
              mobHeader,
              css`
                margin: 0;
              `,
            ]}
            deskStyles={deskMainHeaderStyles}
          >
            Log In.
          </Text>
          <EmailLoginForm nextPageLink={'/w/gigs'} />
        </Box>
      </Box>
    </motion.div>
  )
}
