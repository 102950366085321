import { IconContext } from 'react-icons'
import { FaTimesCircle } from 'react-icons/fa'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, collections } from '../../firebaseApp'
import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'
import { uuid } from '../../utils/uuid'

import { Box, mobBoxVariants } from '../../shared/Box'
import { mobTextVariants, Text } from '../../shared/Text'
import React, { useEffect, useState } from 'react'
import { Button, mobButtonVariants } from '../../shared/Button'
import { errorHandler, errorTypes } from '../../utils/errorHandler'

const { column, row, mobNotificationBox, mobNotification } = mobBoxVariants
const { mobNotificationText } = mobTextVariants
const { mobClearNotificationButton } = mobButtonVariants

// TODO Use or delete
export const NotificationsModule = () => {
  // Grab Authenticated User
  const [currentUserAuth] = useAuthState(auth)
  const [notifications, setNotifications] = useState([])
  const [
    userNotificationsSnapshot,
    userNotificationsSnapshotLoading,
    userNotificationsSnapshotError,
  ] = useCollection(
    collections.notifications
      .doc(currentUserAuth.uid)
      .collection('notifications')
      .orderBy('createdAt', 'asc')
  )

  useEffect(() => {
    setNotifications([])
    if (userNotificationsSnapshot) {
      userNotificationsSnapshot.docs.map(notif => {
        setNotifications(currentNotifs => [...currentNotifs, notif])
      })
    }
  }, [userNotificationsSnapshot])

  const clearNotification = notificationId => {
    const { notifications } = collections
    const notificationReference = notifications
      .doc(currentUserAuth.uid)
      .collection('notifications')
      .doc(notificationId)

    firebase
      .firestore()
      .runTransaction(transaction => {
        return transaction
          .get(notificationReference)
          .then(notificationDocument => {
            if (!notificationDocument.data().cleared) {
              transaction.update(notificationReference, {
                cleared: true,
                clearedAt: firebase.firestore.Timestamp.now(),
              })
            }
          })
          .catch(error => {
            errorHandler({
              error,
              errorId: 'ERROR_CLEAR_USER_NOTIFICATIONS_REFERENCE',
              message: `Error occurred while trying to clear notifications, failed to fetch notification document for notification id: ${notificationId}`,
              type: errorTypes.notifications,
              user: currentUserAuth && currentUserAuth.uid,
              file: 'NotificationsModule.jsx',
            })
          })
      })
      .then(result => {
        return
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_CLEAR_USER_NOTIFICATIONS',
          message: 'Error occurred while trying to clear notifications.',
          type: errorTypes.notifications,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'NotificationsModule.jsx',
        })
      })
  }

  const createNotification = notificationDocument => {
    if (!notificationDocument.data().cleared) {
      return (
        <Box mobStyles={[row, mobNotification]} key={uuid()}>
          <Text mobStyles={[mobNotificationText]}>
            {notificationDocument.data().message}
          </Text>

          <Button
            mobStyles={[mobClearNotificationButton]}
            onClick={() => {
              clearNotification(notificationDocument.id)
            }}
            value={notificationDocument.id}
          >
            <IconContext.Provider
              value={{
                size: '2em',
              }}
            >
              <FaTimesCircle />
            </IconContext.Provider>
          </Button>
        </Box>
      )
    } else {
      return null
    }
  }

  return (
    <>
      {userNotificationsSnapshotError && (
        <strong>Error: {userNotificationsSnapshotError}</strong>
      )}
      {userNotificationsSnapshotLoading && <span>Loading...</span>}
      {userNotificationsSnapshot && (
        <Box mobStyles={[column, mobNotificationBox]}>
          {notifications.map((notificationDocument, index) => {
            return createNotification(notificationDocument, index)
          })}
        </Box>
      )}
    </>
  )
}
