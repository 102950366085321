import React from 'react'
import { css } from 'styled-components'
import {
  Box,
  deskBoxVariants,
  Heading,
  Image,
  mobBoxVariants,
} from '../../../shared/index'
import HomePageMainImageMobile from '../../../assets/photos/home_page_header_mobile.png'
import HomePageMainImage from '../../../assets/photos/home_page_header.png'
import { LightSearchBar } from '../../../components/SearchBar'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../../utils/responsiveVars'
import { up } from 'styled-breakpoints'
import { useHistory } from 'react-router-dom'
import { WhiteLabelCompany } from '../whiteLabel/WhiteLabelCompany'

const mobHeader = css`
  height: auto;
  width: 300px;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: bold;
  margin-top: 90px;
`

const mobSubHeader = css`
  height: auto;
  width: 285px;
  opacity: 0.86;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  margin-top: 15px;
`

const moduleWrapper = css`
  height: 350px;
  ${up('desktop')} {
    height: 600px;
    margin: -1px;
  }
`

const deskMainHeaderStyles = css`
  width: 600px;
  font-size: 50px;
`

const deskSubHeaderStyles = css`
  width: auto;
  font-size: 18px;
`

const mobHeadingsWrapper = css`
  margin-top: 20px;
  width: 90%;
  justify-content: flex-start;
  padding-left: 32px;
`

const { column } = mobBoxVariants
const { deskHomeWrapper } = deskBoxVariants

export const HeaderModule = ({ whiteLabelEnabled }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery(desktopQuery)

  return (
    <Image
      enableUseAsBackground={true}
      src={isDesktop ? HomePageMainImage : HomePageMainImageMobile}
      mobStyles={[column, moduleWrapper]}
    >
      <span id="header" />
      <Box
        mobStyles={[column, mobHeadingsWrapper]}
        deskStyles={deskHomeWrapper}
      >
        {isDesktop &&  (
          <Heading
            mobStyles={mobHeader}
            headingTag="h4"
            deskStyles={deskMainHeaderStyles}
          >
            We are building the worlds largest model & creatives agency.
          </Heading>
        )}
        {whiteLabelEnabled && !isDesktop && (
          <>
            <br />
            <br />
            <WhiteLabelCompany />
          </>
        )}
        <Heading mobStyles={mobSubHeader} deskStyles={deskSubHeaderStyles}>
          Join a global community of contributors.
        </Heading>
        <LightSearchBar history={history} />
        {whiteLabelEnabled && isDesktop && <WhiteLabelCompany />}
      </Box>
    </Image>
  )
}
