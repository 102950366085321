import React from 'react'
import { useSetRecoilState } from 'recoil'
import { requestActionAccordionState } from '../../store'
import { OperativeWithPendingOffer } from './ButtonGroups/OperativeWithPendingOffer'
import { OperativeWithAcceptedOffer } from './ButtonGroups/OperativeWithAcceptedOffer'
import {
  AwaitingBAMRequest,
  CanceledRequest,
  CompletedRequest,
  DeclinedRequest,
  DisputedRequest,
  UnknownRequest,
} from './ButtonGroups/SharedRequestStatuses'
import { OwnerWithPendingOffer } from './ButtonGroups/OwnerWithPendingOffer'
import { OwnerWithAcceptedOffer } from './ButtonGroups/OwnerWithAcceptedOffer'
import { OperativeWithPendingProposal } from './ButtonGroups/OperativeWithPendingProposal'
import { OperativeWithAcceptedProposal } from './ButtonGroups/OperativeWithAcceptedProposal'
import { OwnerWithPendingProposal } from './ButtonGroups/OwnerWithPendingProposal'
import { OwnerWithAcceptedProposal } from './ButtonGroups/OwnerWithAcceptedProposal'

/**
 *
 * @param {Booking} booking
 * @param currentUserAuth
 * @returns {null|*}
 * @constructor
 */
export const RequestActionModule = ({ booking, currentUserAuth }) => {
  const userIsOperative = booking.request.isOperative(currentUserAuth.uid)
  const isRequestCreator = booking.request.isRequestCreator(currentUserAuth.uid)
  const isGigCreator = booking.request.isGigCreator(currentUserAuth.uid)
  const setAccordionState = useSetRecoilState(requestActionAccordionState)

  const updateAccordionState = requestActionType => {
    setAccordionState(previousAccordionState => {
      if (
        previousAccordionState.requestId === booking.request.requestId &&
        previousAccordionState.type === requestActionType
      ) {
        return {
          ...previousAccordionState,
          user: null,
          type: null,
        }
      } else {
        return {
          ...previousAccordionState,
          requestId: booking.request.requestId,
          type: requestActionType,
        }
      }
    })
  }

  // Offer Components
  if (booking.request.pending && userIsOperative && booking.request.isOffer()) {
    // Model accepting or declining an offer
    return (
      <OperativeWithPendingOffer
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.accepted &&
    userIsOperative &&
    booking.request.isOffer()
  ) {
    return (
      <OperativeWithAcceptedOffer
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.pending &&
    isRequestCreator &&
    booking.request.isOffer()
  ) {
    return (
      <OwnerWithPendingOffer
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.accepted &&
    isRequestCreator &&
    booking.request.isOffer()
  ) {
    return (
      <OwnerWithAcceptedOffer
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.pending &&
    userIsOperative &&
    booking.request.isProposal()
  ) {
    return (
      <OperativeWithPendingProposal
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.accepted &&
    userIsOperative &&
    booking.request.isProposal()
  ) {
    return (
      <OperativeWithAcceptedProposal
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.pending &&
    isGigCreator &&
    booking.request.isProposal()
  ) {
    return (
      <OwnerWithPendingProposal
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  } else if (
    booking.request.accepted &&
    isGigCreator &&
    booking.request.isProposal()
  ) {
    return (
      <OwnerWithAcceptedProposal
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  }
  // Universal views no matter the request type. Or who is viewing it both parties should see
  // the same exact view from here down
  else if (booking.request.declined) {
    return <DeclinedRequest />
  } else if (booking.request.awaitingBAM) {
    return <AwaitingBAMRequest />
  } else if (booking.request.disputed) {
    return <DisputedRequest />
  } else if (booking.request.canceled || booking.request.deleted) {
    return <CanceledRequest />
  } else if (booking.request.completed) {
    return (
      <CompletedRequest
        booking={booking}
        currentUserAuth={currentUserAuth}
        updateAccordionState={updateAccordionState}
      />
    )
  }
  // None of above matched
  else {
    return (
      <UnknownRequest booking={booking} currentUserAuth={currentUserAuth} />
    )
  }
}
