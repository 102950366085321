import firebase from 'firebase/app'
import get from 'lodash/get'

/**
 * Intagram object constructed from the user information instagram field
 *
 */
export class Instagram {
  constructor(instagramObject, instagramHandle = 'Not Linked') {
    this.instagramData = instagramObject
    this.accessToken = get(this.instagramData, 'accessToken', '') || ''
    this.dateAccountLinked =
      get(
        this.instagramData,
        'dateAccountLinked',
        firebase.firestore.Timestamp.now()
      ) || firebase.firestore.Timestamp.now()
    this.instagramHandle =
      get(this.instagramData, 'instagramHandle', instagramHandle) || ''
    this.instagramProfilePicUrl =
      get(this.instagramData, 'instagramProfilePicUrl', '') || ''
    this.lastTokenUpdate =
      get(
        this.instagramData,
        'lastTokenUpdate',
        firebase.firestore.Timestamp.now()
      ) || firebase.firestore.Timestamp.now()
    this.tokenExpiration = get(this.instagramData, 'tokenExpiration', '') || ''
    this.tokenType = get(this.instagramData, 'tokenType', '') || ''
    this.useInstagramProfilePhoto =
      get(this.instagramData, 'useInstagramProfilePhoto', '') || ''
    this.instagramUserId = get(this.instagramData, 'userId', '') || ''
    this.hasInstagramProfilePhoto =
      get(this.instagramData, 'hasInstagramProfilePhoto', false) || false
  }
}
