import { uuid } from '../../utils/uuid'
import React from 'react'
import 'firebase/storage'
import { mobBoxVariants } from '../../shared'
import 'react-image-crop/dist/ReactCrop.css'
import { ImageWithModal } from '../../components/ImageWithModal'
import { Box } from '../../shared/Box'
import { PortfolioImageInput } from '../../components/Inputs/PortfolioImageInput'

const { mobMultiImageWrapper, column, center } = mobBoxVariants

export const PortfolioImagesUpdateModule = ({
  user,
  portfolioImagesSnapshot,
}) => {
  return (
    <>
      <PortfolioImageInput user={user} />
      <Box mobStyles={[mobMultiImageWrapper, center]} deskStyles={column}>
        {portfolioImagesSnapshot.docs
          .filter(imgDoc => !imgDoc.data().deleted)
          .map(imageDocument => {
            return (
              <ImageWithModal
                key={uuid()}
                imageDocument={imageDocument}
                userId={user.userId}
              />
            )
          })}
      </Box>
    </>
  )
}
