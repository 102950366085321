import React from 'react'
import Switch from 'react-switch'
import 'firebase/storage'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared'
import { css } from 'styled-components'

const { row } = mobBoxVariants
const { mobInstructionParagraph } = mobTextVariants

/**
 * Toggle switch to control various things
 *
 * @param props
 * @constructor
 */
export const Toggle = ({ label, checked, onChangeHandler }) => {
  return (
    <Box
      mobStyles={[
        row,
        css`
          width: 100%;
        `,
      ]}
    >
      <Text mobStyles={[mobInstructionParagraph]}>{label}</Text>
      <label style={{ marginTop: '20px' }}>
        <Switch onChange={onChangeHandler} checked={checked} />
      </label>
    </Box>
  )
}
