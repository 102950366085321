import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { StyledImage } from './styles'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'
import { Box } from '../Box'

const ImageBackgroundStyles = css`
  background-image: url(${p => p.imageUrl});
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Image = ({ enableUseAsBackground, mobStyles, ...props }) => {
  // since an image can't have children, if we want to use image as background we need to use a div.
  if (enableUseAsBackground) {
    // since mobStyles can be array or just a css string, we need a check to spread in the case of an array
    const bgMobStyles = Array.isArray(props.mobStyles)
      ? [ImageBackgroundStyles, ...mobStyles]
      : [ImageBackgroundStyles, mobStyles]

    return (
      <Box imageUrl={props.src} mobStyles={bgMobStyles} role="img" {...props}>
        {props.children}
      </Box>
    )
  }

  return <StyledImage mobStyles={mobStyles} {...props} />
}

Image.propTypes = {
  ...responsiveStyleAndVariantsProps,
  enableUseAsBackground: PropTypes.bool,
}
