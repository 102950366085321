import styled from 'styled-components'
import { getFontSize, variantsAndStyles } from '../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

export const StyledText = styled.p`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;

  ${getFontSize}
  ${variantsAndStyles()}
`

export const StyledMotionText = styled(motion.p)`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;

  ${getFontSize}
  ${variantsAndStyles()}
`
