/**
 * Used to determine who created the request
 * Offer would signify an agency OFFERING a gig to a model by requesting to book them
 * Proposal would signify a model making a PROPOSAL to get booked for a gig
 *
 */
export const RequestType = {
  offer: 1,
  proposal: 2,
  notAvailable: 11,
}

export const RequestStatus = {
  inactive: 0,
  pending: 1,
  accepted: 2,
  declined: 3,
  canceled: 4,
  disputed: 5,
  completed: 6,
  awaitingBAM: 7,
  deleted: 10,
  notAvailable: 11,
}

// These DO NOT have to match the values above since they are only for the request action buttons
export const RequestActionType = {
  accept: 1,
  hire: 2,
  releaseFunds: 3,
  cancel: 4,
  withdraw: 5,
  dispute: 6,
  decline: 7,
  review: 8,
  complete: 10,
}

export const GigStatus = {
  // canceled,
  // active,
  // accepted,
  // declined,
  // closed,
  deleted: 10,
  notAvailable: 11,
}

export const Gender = {
  female: 10,
  male: 20,
  other: 30,
  none: 40,
}

export const Race = {
  none: 0,
  black: 10,
  white: 20,

  pnts: 1000,
}

export const AccountType = {
  // All routes available to guests can be seen by
  // all account types
  guest: 0,
  // These users have created accounts but did not set their account type yet
  none: 1,

  // Agency Type Accounts. Maybe there will be new subsets in the future
  agency: 10,
  exhibitor: 11,
  instructor: 12,

  // Creative account types
  model: 20,
  photographer: 21,
  makeupArtist: 22,
  influencer: 23,

  // DEPRECATED: delete after a I make sure it is not being used
  creative: 3,

  // Advestiser account types
  advertiser: 40,

  // Only Assign Manually
  admin: 99,
  developer: 100,
}

// Leaving gaps just in case we want to add extra type
// Like breaking education down into videos & articles instead of a broad category
export const SearchType = {
  reserved: 0,
  creative: 20,
  images: 30,
  gig: 40,
  education: 60,
}

// Rating should be set to not set until user has 5 ratings.
export const UserRating = {
  zeroStar: 0,
  halfStar: 1,
  oneStar: 2,
  oneAndHalfStar: 3,
  twoStar: 4,
  twoAndHalfStar: 5,
  threeStar: 6,
  threeAndHalfStar: 7,
  fourStar: 8,
  fourAndHalfStar: 9,
  fiveStar: 10,
  notSet: 100,
}

//requestStatus: 1, // 1 == pending   2 == Accepted   3 == Declined  0 == Inactive  4 == Cancelled
export const IdentityVerificationStatus = {
  notStarted: 0,
  processing: 10,
  complete: 20,
  denied: 30,
  error: 100,
}

export const InformativeMessageType = {
  RESERVED: 0,
  general: 1,
  success: 2,
  info: 3,
  danger: 4,
  caution: 4,
}

export const LicenseImageStatus = {
  inactive: 0,
  active: 1,
  purchasedExclusive: 2,
  purchasedMonthly: 3,
  deleted: 10,
  notAvailable: 11,
}

export const LicenseImageType = {
  monthly: 1,
  exclusive: 2,
}

export const SupportTicketType = {
  general: 0,
  payment: 1,
  bug: 2,
  account: 3,
  feature: 4,
  identity: 5,
  refund: 6,
  dispute: 7,
  canva: 8,
  other: 100,
}

export const SupportTicketStatus = {
  invalid: 0,
  received: 1,
  pending: 2,
  resolved: 3,
  cancelled: 4,
}

export const UpdateProfileType = {
  setup: 0,
  regular: 1,
  managed: 2,
}

export const ManagedUserAccordionType = {
  profile: 1,
  settings: 2,
}
