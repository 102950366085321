import React from 'react'

import { MessageBox } from '../../components/MessageBox'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { Request } from '../../classes'

/**\
 *
 * This component will be shown to operative once they have some kind of relationship
 * with a gig. i.e. if they made a proposal for it, this component should display
 * a message to remind them of that.
 *
 * @param {Gig} gig
 * @param {User} currentUser
 * @param currentUserRequests
 * @returns {null|*}
 * @constructor
 */
export const GigInformationCenterModule = ({
  gig,
  currentUser,
  currentUserRequests,
}) => {
  const history = useHistory()
  const userIsGigOwner = gig.createdBy === currentUser.userId

  let fixedPriceMessage
  if (gig.fixedPriceGig) {
    fixedPriceMessage = (
      <MessageBox
        header="Fixed Price Gig"
        message={`Getting hired for this gig will pay ${gig.operativeFixedPayDisplay} after fees and does not factor in your personal rate.`}
      />
    )
  } else {
    fixedPriceMessage = null
  }

  let operativeRequestMessage
  if (!currentUserRequests.length <= 0 && !currentUserRequests.length > 1) {
    // Guaranteed to be there due to the checks above
    const userRequest = new Request(currentUserRequests[0])
    if (userRequest.isProposal() && !userIsGigOwner) {
      operativeRequestMessage = (
        <>
          <MessageBox
            header="You submitted a proposal."
            message={'You created a proposal to be selected for this gig!'}
            subMessage={'Click to view your proposal.'}
            onClick={() => {
              history.push(ROUTES.PROPOSAL.base + userRequest.requestId)
            }}
          />
          {fixedPriceMessage}
        </>
      )
    } else if (userRequest.isOffer() && !userIsGigOwner) {
      operativeRequestMessage = (
        <>
          <MessageBox
            header="You have an offer."
            message={
              'Congratulations! The creator of this gig has sent you an offer!'
            }
            onClick={() => {
              history.push(ROUTES.OFFER.base + userRequest.requestId)
            }}
          />
          {fixedPriceMessage}
        </>
      )
    } else {
      operativeRequestMessage = null
    }
  } else {
    operativeRequestMessage = null
  }

  if (userIsGigOwner) {
    return null
  } else {
    return (
      <>
        {operativeRequestMessage}
        {fixedPriceMessage}
      </>
    )
  }
}
