import React from 'react'
import PropTypes from 'prop-types'
import { Box, mobBoxVariants } from '../../shared'
import { css } from 'styled-components'

const deskStylesOuter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  ${p => p.additionalStyles}
`

const deskStylesInner = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  flex-direction: column;

  ${p => p.additionalStyles}
`

const { column } = mobBoxVariants

export const DesktopPageWrapper = ({
  children,
  additionalOuterStyles,
  additionalInnerStyles,
}) => {
  return (
    <Box
      deskStyles={deskStylesOuter}
      mobStyles={[column]}
      additionalStyles={additionalOuterStyles}
    >
      <Box
        deskStyles={deskStylesInner}
        mobStyles={[column]}
        additionalStyles={additionalInnerStyles}
      >
        {children}
      </Box>
    </Box>
  )
}

DesktopPageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  additionalOuterStyles: PropTypes.object,
  additionalInnerStyles: PropTypes.object,
}
