import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import { Gig } from '../index'

/**
 * EditGigForm is a class that take an existing gig and compares the updated data
 * to the current data before updating.
 *
 */
export class EditGigForm {
  title
  description
  time = {
    date: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    ),
    startTime: undefined,
    endTime: undefined,
    duration: 0,
  }
  location = {
    address: '',
    latLong: {
      lat: 0,
      lng: 0,
    },
    locationMobileImageUrl: '',
  }

  gig

  newTitle
  newDescription
  newTime = {
    newDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    ),
    newStartTime: undefined,
    newEndTime: undefined,
    newDuration: 0,
  }
  newLocation = {
    newAddress: '',
    newLatLong: {
      lat: 0,
      lng: 0,
    },
    newLocationMobileImageUrl: '',
  }

  id

  fixedPriceGig
  fixedPrice
  newFixedPrice

  /**
   *
   * @param {Gig} gig
   * @param {function} submitButtonDisabled
   * @param {function} updateDateValue
   */
  constructor(gig, submitButtonDisabled) {
    this.gig = gig
    this.submitButtonDisabled = submitButtonDisabled
    this.id = gig.id

    // These fields must match up with CreateGigForm
    // Existing Data
    this.title = this.gig.title
    this.description = this.gig.description
    this.fixedPriceGig = this.gig.fixedPriceGig
    this.fixedPrice = this.gig.fixedPrice
    this.time.startTime = new Date(this.gig.startTime.seconds * 1000) //this.gig.startTime
    this.time.endTime = new Date(this.gig.endTime.seconds * 1000) //this.gig.endTime
    this.address = this.gig.address
    this.time.date = new Date(this.gig.date.seconds * 1000)

    this.location.address = this.gig.address
    this.location.latLong = this.gig.latLong
    this.location.locationMobileImageUrl = this.gig.locationMobileImageUrl

    // Updated data
    this.newTitle = this.gig.title
    this.newFixedPrice = this.gig.fixedPrice
    this.newDescription = this.gig.description
    this.newTime.newStartTime = new Date(this.gig.startTime.seconds * 1000) //this.gig.startTime
    this.newTime.newEndTime = new Date(this.gig.endTime.seconds * 1000) //this.gig.endTime
    this.newTime.newDate = new Date(this.gig.date.seconds * 1000)
    this.newTime.newDuration = this.gig.duration

    this.newLocation.newAddress = this.gig.address
    this.newLocation.newLatLong = this.gig.latLong
    this.newLocation.newLocationMobileImageUrl = this.gig.locationMobileImageUrl
  }

  updateTitle = event => {
    this.newTitle = event.target.value
    this.validateTitle()
  }

  updateAddress = locationObject => {
    this.newLocation.newAddress = locationObject.eventAddress
    this.newLocation.newLatLong = locationObject.eventLatLong
    this.newLocation.newLocationMobileImageUrl =
      locationObject.eventLocationMobileImageUrl
    this.validateLocation()
  }

  updateDescription = event => {
    this.newDescription = event.target.value
    this.validateDescription()
  }

  updateDate = date => {
    this.newTime.newDate = date

    if (this.newTime.newStartTime !== undefined) {
      this.updateStartTime(moment(this.newTime.newStartTime))
    }
    if (this.newTime.newEndTime !== undefined) {
      this.updateEndTime(moment(this.newTime.newEndTime))
    }
    this.validateDate()
  }

  updateEndTime = time => {
    this.newTime.newEndTime = this.momentChangedToDate(time)
    this.validateTime()
  }

  updateStartTime = time => {
    this.newTime.newStartTime = this.momentChangedToDate(time)
    this.validateTime()
  }

  updateFixedPrice = event => {
    this.newFixedPrice = Number(event.target.value)
    this.validateFixedPrice()
  }

  momentChangedToDate = time => {
    return new Date(
      time
        .toDate()
        .setFullYear(
          this.time.date.getFullYear(),
          this.time.date.getMonth(),
          this.time.date.getDate()
        )
    )
  }

  addGigDuration = (gigStart, gigEnd) => {
    let start = moment(gigStart)
    let end = moment(gigEnd)

    this.newTime.newDuration = end.diff(start, 'hours', true).toFixed(2)
  }

  timeRangeIsLogical = (startTime, endTime) => {
    return startTime.getTime() < endTime.getTime()
  }

  /**
   * If they are editing at least one field let them submit the form
   *
   * @returns {boolean} Indicates if the form can be submitted yet
   */
  validateEditEventForm = () => {
    // Run all validations???
  }

  validateTitle = () => {
    if (this.title !== this.newTitle && !isEmpty(trim(this.newTitle))) {
      this.submitButtonDisabled(false)
      return true
    }
    this.submitButtonDisabled(true)
    return false
  }

  validateFixedPrice = () => {
    if (
      this.fixedPrice !== this.newFixedPrice &&
      !isEmpty(trim(this.fixedPrice))
    ) {
      this.submitButtonDisabled(false)
      return true
    }
    this.submitButtonDisabled(true)
    return false
  }

  validateDescription = () => {
    if (
      this.description !== this.newDescription ||
      !isEmpty(trim(this.newDescription))
    ) {
      this.submitButtonDisabled(false)
      return true
    }

    this.submitButtonDisabled(true)
    return false
  }

  validateLocation = () => {
    if (
      this.location.address !== this.newLocation.newAddress ||
      isEmpty(trim(this.newLocation.newAddress))
    ) {
      this.submitButtonDisabled(false)
      return true
    }

    this.submitButtonDisabled(true)
    return false
  }

  validateTime = () => {
    if (
      this.time.startTime !== this.newTime.newStartTime ||
      this.time.endTime !== this.newTime.newEndTime
    ) {
      if (
        this.timeRangeIsLogical(
          this.newTime.newStartTime,
          this.newTime.newEndTime
        )
      ) {
        // Calculate Duration at the last moment
        this.addGigDuration(this.newTime.newStartTime, this.newTime.newEndTime)
        this.submitButtonDisabled(false)
        return true
      } else {
        this.submitButtonDisabled(true)
        return false
      }
    } else {
      this.submitButtonDisabled(true)
      return false
    }
  }

  validateDate = () => {
    if (this.time.date !== this.newTime.newDate) {
      this.submitButtonDisabled(false)
      return true
    }

    this.submitButtonDisabled(true)
    return false
  }
}
