import React from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { Request } from '../../classes'
import { LoadingScreen } from '../../components/LoadingScreen'
import { ViewOfferPageModule } from './ViewOfferPageModule'
import { useHistory } from 'react-router-dom'

/**
 * This pages pushes the actual page down an extra level so we can load the offer request first
 * which gives use the ids to other needed documents and makes the page load faster
 *
 * @param currentUserAuth
 * @param match
 * @returns {*}
 * @constructor
 */
export const ViewOfferPage = ({ currentUserAuth, match }) => {
  const history = useHistory()
  const [
    offerSnapshot,
    offerSnapshotLoading,
    offerSnapshotError,
  ] = useDocumentData(collections.activeRequests.doc(match.params.offer_id), {
    idField: 'id',
  })

  return (
    <>
      {offerSnapshotError && <strong>Error: {offerSnapshotError}</strong>}
      {offerSnapshotLoading && <LoadingScreen />}
      {offerSnapshot && (
        <ViewOfferPageModule
          request={new Request(offerSnapshot)}
          currentUserAuth={currentUserAuth}
        />
      )}
    </>
  )
}
