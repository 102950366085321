import React from 'react'

import {
  mobConfirmBookingInvoiceMoney,
  mobConfirmBookingInvoiceRow,
  mobGigBoxDescription,
  mobGigBoxHorizontalRule,
} from './styles'
import { Box, mobTextVariants, Text } from '../../shared'
import { InvoiceLineType, InvoiceRow } from '../../components/InvoiceRow'


const { mobPageHeader } = mobTextVariants

export const CashModule = ({ booking, currentUserAuth, creatingBooking }) => {
  const userIsOwner = booking.request.isOwner(currentUserAuth.uid)
  const userIsOperative = booking.request.isOperative(currentUserAuth.uid)

  const FullCashModule = (
    <>
      <Box mobStyles={mobConfirmBookingInvoiceRow}>
        Gig Duration
        <Box mobStyles={mobConfirmBookingInvoiceMoney}>
          {booking.gig.duration} Hours
        </Box>
      </Box>

      {!booking.gig.fixedPriceGig && (
        <>
          <Box mobStyles={mobConfirmBookingInvoiceRow}>
            Base Model
            <Box mobStyles={mobConfirmBookingInvoiceMoney}>
              $
              {Number(booking.operative.getUserHourlyRate()) *
                booking.gig.duration}
            </Box>
          </Box>
          <Text mobStyles={mobGigBoxDescription}>
            {booking.gig.duration} hours @ $
            {booking.operative.getUserHourlyRate()}
            /hr
          </Text>
        </>
      )}
      <Text mobStyles={mobGigBoxHorizontalRule} />
      <InvoiceRow
        description="Subtotal"
        money={booking.getBookingSubtotalAgencyDisplay()}
        lineType={InvoiceLineType.Regular}
      />
      <InvoiceRow
        description="Service Fees"
        money={booking.getBookingFeesAgencyDisplay()}
        lineType={InvoiceLineType.Regular}
      />
      <InvoiceRow
        description="Total Booking Amount"
        money={booking.getBookingGrandTotalAgencyDisplay()}
        lineType={InvoiceLineType.Grand}
      />
      <br />
      <br />
    </>
  )

  const OperativeCashModule = (
    <>
      <Text>Your Take</Text>
      {!booking.gig.fixedPriceGig && (
        <Text mobStyles={[mobPageHeader, mobGigBoxDescription]}>
          {booking.gig.duration} hours @ $
          {booking.operative.getUserHourlyRate()}
          /hr
        </Text>
      )}
      <Text mobStyles={mobGigBoxHorizontalRule} />
      <InvoiceRow
        description="Total Worth"
        money={booking.getBookingGrossPayOperativeDisplay()}
        lineType={InvoiceLineType.Regular}
      />
      <InvoiceRow
        description="Service Fees"
        money={booking.getBookingFeesOperativeDisplay()}
        lineType={InvoiceLineType.Regular}
      />
      <InvoiceRow
        description="How much you will make:"
        money={booking.getBookingPayOperativeDisplay()}
        lineType={InvoiceLineType.Grand}
      />
      <br />
      <br />
    </>
  )

  if (userIsOwner || creatingBooking) {
    return FullCashModule
  } else if (userIsOperative) {
    return OperativeCashModule
  } else {
    return null
  }
}
