import React from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  Heading,
  headingVariants,
  mobBoxVariants,
  mobTextVariants,
} from '../../../shared/index'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { motion } from 'framer-motion'
import { up } from 'styled-breakpoints'
import { Text } from '../../../shared/Text'

const { row, column, center } = mobBoxVariants
const { mobSectionTitle } = headingVariants
const { deskSectionHeader } = deskTextVariants
const { mobSectionHeader } = mobTextVariants

const mobCircle = css`
  border-radius: 100%;
  height: 54px;
  width: 54px;
  background: linear-gradient(225deg, #464646 0%, #000000 99.92%);
  box-shadow: 0 4px 8px 2px rgba(163, 163, 163, 0.5);
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 24px;
  font-weight: 600;
`

const deskCircle = css`
  border-radius: 100%;
  height: 100px;
  width: 100px;
  background: linear-gradient(225deg, #464646 0%, #000000 99.92%);
  box-shadow: 0 4px 8px 2px rgba(163, 163, 163, 0.5);
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 24px;
  font-weight: 600;
`

const mobBulletRowWrapper = css`
  margin-bottom: 23px;
`

const mobBulletsWrapper = css`
  padding: 23px 36px 21px 36px;
`

const mobBulletText = css`
  color: ${p => p.theme.colors.darkGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.47px;
  padding-left: 22px;
`
const deskBulletText = css`
  color: ${p => p.theme.colors.darkGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.47px;
  padding-left: 22px;
`

const mobBulletTextBox = css`
  justify-content: center;
`

const deskBulletTextBox = css`
  justify-content: center;
`

const CircleWrapper = styled(motion.div)`
  ${up('desktop')} {
    display: unset;
    justify-content: center;
    display: flex;
    height: width: 100%;
    margin-bottom: 20px;
  }
`

const deskBulletWrapper = css`
  justify-content: space-between;
  padding: 50px 100px 50px 100px;
  align-items: baseline;
`

const howItWorksInputs = [
  { text: 'Sign up for an account.' },
  { text: 'Search for models or gigs.' },
  { text: 'Make meaningful connections.' },
]

const Circle = ({ children }) => (
  <Box
    enableMotion
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    mobStyles={mobCircle}
    deskStyles={deskCircle}
  >
    {children}
  </Box>
)

const RenderHowItWorksBullets = () => (
  <>
    {howItWorksInputs.map((item, index) => (
      <Box
        mobStyles={[row, mobBulletRowWrapper]}
        deskStyles={[
          column,
          css`
            flex: 1;
          `,
        ]}
        key={index}
      >
        <CircleWrapper>
          <Circle>{`${index + 1}`}</Circle>
        </CircleWrapper>
        <Box mobStyles={mobBulletTextBox} deskStyles={[deskBulletTextBox]}>
          <Text mobStyles={mobBulletText} deskStyles={[deskBulletText]}>
            {item.text}
          </Text>
        </Box>
      </Box>
    ))}
  </>
)

export const HowItWorksModule = () => {
  return (
    <Box mobStyles={[column]}>
      <Box mobStyles={[center]}>
        <Heading
          mobStyles={[mobSectionHeader]}
          deskStyles={[
            deskSectionHeader,
            css`
              margin-bottom: 50px;
            `,
          ]}
        >
          How It Works
        </Heading>
      </Box>

      <Box
        mobStyles={[column, mobBulletsWrapper]}
        deskStyles={[row, center, deskBulletWrapper]}
      >
        <RenderHowItWorksBullets />
      </Box>
    </Box>
  )
}
