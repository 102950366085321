import React, { useState } from 'react'
import 'firebase/storage'
import {
  Box,
  Image,
  Link,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { css } from 'styled-components'
import BlackBAMLogo from '../../assets/logos/black-logo.svg'
import BAMLogo from '../../assets/logos/logo.svg'
import { ROUTES } from '../../constants/routes'
import {
  mobMenuContainer,
  mobMenuItemStyle,
  mobMenuWrapper,
  mobNavWrapperStyles,
} from './mobNavStyles'
import { getMobMenuItems } from './utils'
import { useHistory } from 'react-router-dom'
import { User } from '../../classes'
import { useDocument } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { RatingModule, RatingPosition } from '../RatingModule'
import { useUserUnreadMessages } from '../../utils/useUserUnreadMessages'
import { IconContext } from 'react-icons'
import { theme } from '../../theme'
import { IoMdMail } from 'react-icons/io'

const { row, mobWrapper } = mobBoxVariants

const hamburgerMenuPositioning = css`
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0 10px;
`

const logoSize = css`
  width: 180px;
  margin-bottom: 15px;
`

export const MenuItem = ({ onClick, delay, children }) => {
  return (
    <Box mobStyles={mobMenuWrapper} delay={delay}>
      <Box mobStyles={mobMenuItemStyle} onClick={onClick} delay={delay}>
        {children}
      </Box>
    </Box>
  )
}

export const MobileNavigationModule = ({
  match,
  userAccountType,
  currentUserAuth,
}) => {
  const history = useHistory()
  const [navigationOpen, setNavigationOpen] = useState(false)
  const absolutePositioning = [
    ROUTES.HOME.link,
    ROUTES.SIGN_IN.link,
    ROUTES.SIGN_UP.link,
    ROUTES.LICENSING.link,
    '/w/payment',
    '/w/account-type',
    '/w/details',
    '/w/signup',
    '/w/login',
    '/w/gigs',

  ].includes(match.path)

  const fixedPositioning = [
    ROUTES.CHAT_THREAD.link,
    ROUTES.INBOX.link,
  ].includes(match.path)

  const hamburgerLineStyle = {
    background: absolutePositioning ? '#ffffff' : '#000000',
  }

  const toggleNav = () => setNavigationOpen(!navigationOpen)

  return (
    <>
      <Box
        mobStyles={[row, mobWrapper, mobNavWrapperStyles]}
        absolutePositioning={absolutePositioning}
        fixedPositioning={fixedPositioning}
      >
        <Box>
          <Image
            src={absolutePositioning ? BAMLogo : BlackBAMLogo}
            mobStyles={logoSize}
            onClick={() => {
              history.push(ROUTES.HOME.link)
            }}
          />
        </Box>
        <Box mobStyles={hamburgerMenuPositioning}>
          <Box
            className={navigationOpen ? 'open' : ''}
            onClick={toggleNav}
            id="hamburger-icon"
            data-testid="mobile-hamburger-button"
            role="button"
          >
            <span style={hamburgerLineStyle} />
            <span style={hamburgerLineStyle} />
            <span style={hamburgerLineStyle} />
            <span style={hamburgerLineStyle} />
          </Box>
        </Box>
      </Box>

      <Menu open={navigationOpen} fixedPositioning={fixedPositioning}>
        {currentUserAuth && (
          <MenuProfileBox
            currentUserAuth={currentUserAuth}
            history={history}
            toggleNav={toggleNav}
          />
        )}
        {getMobMenuItems(userAccountType).map((route, index) => {
          return (
            <MenuItem key={index} delay={`${index * 0.1}s`} onClick={toggleNav}>
              <Link to={route.link}>{route.text}</Link>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const Menu = ({ open, children, fixedPositioning }) => {
  return (
    <Box
      mobStyles={mobMenuContainer}
      fixedPositioning={fixedPositioning}
      open={open}
    >
      {open && <div>{children}</div>}
    </Box>
  )
}

const { column, center, mobProfileBoxRequest } = mobBoxVariants
const { mobUserProfileImageRequest } = mobImageVariants
const { mobUserProfileLocationMini, mobUserProfileNameMini } = mobTextVariants

const MenuProfileBox = ({ currentUserAuth, history, toggleNav }) => {
  const [unreadCount] = useUserUnreadMessages(currentUserAuth)
  const [
    currentUserSnapshot,
    currentUserSnapshotLoading,
    currentUserSnapshotError,
  ] = useDocument(collections.userInformation.doc(currentUserAuth.uid))

  if (currentUserSnapshotLoading || currentUserSnapshotError) {
    return null
  }

  const user = new User(currentUserSnapshot)

  return (
    // WILL ONLY EVER BE ON MOBILE
    <Box mobStyles={[row, mobProfileBoxRequest]}>
      <Box
        onClick={() => {
          toggleNav()
          history.push(ROUTES.PROFILE.link + '/' + currentUserAuth.uid)
        }}
        mobStyles={[
          css`
            width: 100px;
            height: 100px;
          `,
          center,
        ]}
      >
        <Image
          src={user.getProfileImage()}
          mobStyles={[mobUserProfileImageRequest]}
        />
      </Box>
      <Box
        mobStyles={[
          center,
          column,
          css`
            width: auto;
            flex: 1;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobUserProfileNameMini,
            css`
              font-size: auto;
              line-height: 20px;
              margin-top: 0;
              text-align: left;
            `,
          ]}
        >
          {user.fullName}
        </Text>
        <Text
          mobStyles={[
            mobUserProfileLocationMini,
            css`
              text-align: left;
            `,
          ]}
        >
          {user.getUserCity()}
        </Text>
        <RatingModule
          rating={user.rating}
          ratingPosition={RatingPosition.left}
        />
      </Box>
      <Box
        mobStyles={[
          center,
          column,
          css`
            width: auto;
            justify-content: flex-end;
          `,
        ]}
      >
        {unreadCount > 0 && (
          <Box
            enableMotion
            animate={{
              scale: [1, 1.15, 1],
            }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              loop: Infinity,
            }}
            onClick={() => {
              toggleNav()
              history.push(ROUTES.INBOX.link)
            }}
            mobStyles={[
              row,
              css`
                max-height: 50px;
                cursor: pointer;
                width: 75px;
                height: 100%;
                align-items: center;
                justify-content: center;
              `,
            ]}
          >
            <IconContext.Provider
              value={{
                color: theme.colors.blueGray,
                size: '2em',
              }}
            >
              <IoMdMail />
            </IconContext.Provider>
            <Text
              mobStyles={css`
                color: ${theme.colors.blueGray};
              `}
            >
              {unreadCount}
            </Text>
          </Box>
        )}
        <Link
          to={ROUTES.LOG_OUT.link}
          mobStyles={css`
            margin: 10px;
            font-weight: bold;
          `}
        >
          Logout
        </Link>
      </Box>
    </Box>
  )
}
