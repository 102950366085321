import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { variantsAndStyles } from '../../utils/variantsAndStyles'

const inputStyles = css`
  border: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  ${variantsAndStyles()}
`

export const StyledMotionInput = styled(motion.input)`
  ${inputStyles}
`

export const StyledInput = styled.input`
  ${inputStyles}
`
