import { css } from 'styled-components'

import HomePageMainImage from '../../../assets/photos/home_page_header.png'

// Start Panel 1 Styles
export const mobHeader = css`
  height: 96px;
  width: 260px;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 50px;
`

export const mobSubHeader = css`
  height: 20px;
  width: 285px;
  opacity: 0.86;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  line-height: 20px;
  margin-top: 15px;
`

export const mobHeaderWrapper = css`
  background-size: contain;
  background-image: url(${HomePageMainImage});
  height: 385px;
  padding: 32px 8px 32px 8px;
`

export const deskHeaderWrapper = css`
  height: 385px;
  background-size: cover;
  color: ${p => p.theme.colors.secondary};
  background-image: url(${HomePageMainImage});
  padding: 32px 8px 32px 8px;
`

export const mobSecondPanelHeading = css`
  height: 29px;
  width: 100%;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 24px;
  color: ${p => p.theme.fonts.primary};
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 29px;
`

export const deskSecondPanelHeading = css`
  height: 48px;
  width: 100%;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 48px;
  color: ${p => p.theme.fonts.primary};
  font-weight: 600;
  letter-spacing: -0.75px;
  line-height: 48px;
`

export const mobSecondPanel = css`
  height: 96px;
  width: 100%;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 54px;
  font-weight: bold;
  line-height: 50px;
  margin-top: 50px;
  margin-bottom: 1%;
`

export const mobileSecondPanelWrapper = css`
  background-size: contain;
  background-color: ${p => p.theme.fonts.lightGray};
  height: 385px;
  padding: 32px 8px 32px 8px;
  text-align: center;
`

export const deskSecondPanelWrapper = css`
  height: 100%;
  background-size: cover;
  background-color: ${p => p.theme.fonts.lightGray};
  color: ${p => p.theme.colors.secondary};
  padding: 32px 8px 32px 8px;
  margin: -1px;
  text-align: center;
`

export const deskContainer = css`
  width: 80vw;
  background-size: cover;
  background-color: ${p => p.theme.fonts.lightRed};
  color: ${p => p.theme.colors.secondary};
  padding: 32px 8px 32px 8px;
  margin: 0 auto;
  text-align: center;
`

export const creativeCommunityBox = css`
  width: 30%;
  background-color: ${p => p.theme.fonts.blue};
  padding: 32px 8px 32px 8px;
  margin: 0 auto;
  border: 5px solid ${p => p.theme.colors.primary};
`
// End Panel 2 Styles
