import React from 'react'
import { StyledSelect } from './styles'

import PropTypes from 'prop-types'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'
import { Option } from '../Option'

export const Select = ({ options, children, ...props }) => {
  const renderOptions = () =>
    options.map(({ value, label, disabled }) => (
      <Option value={value} key={value} disabled={disabled}>
        {label}
      </Option>
    ))

  return (
    <StyledSelect {...props}>
      {options && renderOptions()}
      {children}
    </StyledSelect>
  )
}

Select.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    })
  ),
}
