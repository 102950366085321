import React from 'react'
import PropTypes from 'prop-types'
import { StyledRadioButton } from './styles'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const RadioButton = ({
  children,
  index = 0,
  label = null,
  value,
  additionalValues = null, //can be use if you prefer to pass in an object/array as the value
  handleRadioClick = () => {},
  checked,
  ...props
}) => {
  return (
    <>
      <label>
        <StyledRadioButton
          type="radio"
          checked={checked}
          value={value}
          onClick={e => handleRadioClick(e, index, additionalValues)}
          onChange={e => handleRadioClick(e, index, additionalValues)}
          {...props}
        />
        {label && label}
      </label>
      <br />
    </>
  )
}

RadioButton.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
  icon: PropTypes.any,
}
