import React from 'react'
import { Image } from '../../shared/Image'
import LicenseBackgroundImage from '../../assets/photos/license-bg.png'
import { up } from 'styled-breakpoints'
import {
  Box,
  Button,
  deskBoxVariants,
  mobBoxVariants,
  Table,
  TableBody,
  TableHead,
  TD,
  TH,
  TR,
} from '../../shared'
import { css } from 'styled-components'
import { Footer } from '../../components/Footer'
import { Heading } from '../../shared/Heading'
import { Text } from '../../shared/Text'
import { useTable } from 'react-table'
import '../Home/modules/styles.css'

const { column, row } = mobBoxVariants
const { deskHomeWrapper } = deskBoxVariants

const moduleWrapper = css`
  height: 450px;
  background-position: center;

  ${up('desktop')} {
    height: 900px;
    background-position: cover;

    margin: -1px;
  }
`
const mobHeader = css`
  height: auto;
  width: 300px;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: bold;
  margin-top: 90px;
`

const mobSubHeader = css`
  height: auto;
  width: 285px;
  opacity: 0.86;
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  margin-top: 15px;
`

const deskMainHeaderStyles = css`
  width: 500px;
  font-size: 50px;
  margin-top: 150px;
`

const deskSubHeaderStyles = css`
  width: 500px;
  font-size: 18px;
`

const mobHeadingsWrapper = css`
  margin-top: 20px;
  width: 90%;
  justify-content: flex-start;
  padding-left: 32px;
`

// Currently "Coming Soon"
const mobGetStartedButton = css`
  font-family: Montserrat, serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  line-height: 15px;
  border-radius: 30px;
  background-color: transparent;
  font-size: 16px;
  color: ${p => p.theme.colors.secondary};
  height: 50px;
  width: 200px;
  border: 1px solid white;
  margin-top: 40px;
`
const deskGetStartedButton = css`
  height: 50px;
  width: 300px;
  font-size: 16px;
`

export const LicensePage = () => {
  return (
    <>
      <Image
        enableUseAsBackground={true}
        src={LicenseBackgroundImage}
        mobStyles={[column, moduleWrapper]}
      >
        <Box
          mobStyles={[column, mobHeadingsWrapper]}
          deskStyles={deskHomeWrapper}
        >
          <Heading
            mobStyles={mobHeader}
            headingTag="h4"
            deskStyles={deskMainHeaderStyles}
          >
            Learn how to license images.
          </Heading>
          <Heading mobStyles={mobSubHeader} deskStyles={deskSubHeaderStyles}>
            <br />
            We are still working on this section. Soon you will be able to make
            money by licensing your images directly on Bookamodel.com. Check
            back soon!
            <br />
          </Heading>

          <Button
            deskStyles={[deskGetStartedButton]}
            mobStyles={[mobGetStartedButton]}
          >
            COMING SOON
          </Button>
        </Box>
      </Image>
      <LicensingInformationSection />
      <Footer />
    </>
  )
}

const mobLicenseHeader = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 30px;
  font-weight: 600;
  margin: 0;
`

const deskLicenseHeader = css`
  font-size: 33px;
`

const mobLicenseText = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 16px;
  font-weight: 500;
`

const deskLicenseText = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 26px;
  font-weight: 500;
`

const mobLicenseBox = css`
  width: 100%;
  justify-content: center;
  text-align: left;
  padding: 25px;
`
const deskLicenseBox = css`
  width: 100%;
  justify-content: center;
  text-align: left;
  padding: 10px 50px;
`

const LicensingInformationSection = () => {
  return (
    <>
      <Box
        mobStyles={[column]}
        deskStyles={[
          row,
          css`
            padding: 50px;
          `,
        ]}
      >
        <Box mobStyles={[column, mobLicenseBox]} deskStyles={deskLicenseBox}>
          <Text mobStyles={mobLicenseHeader} deskStyles={deskLicenseHeader}>
            Standard License
          </Text>
          <Text mobStyles={mobLicenseText} deskStyles={deskLicenseText}>
            Odio pellentesque diam volutpat commodo sed egestas egestas
            fringilla. Velit dignissim sodales ut eu sem. Sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper. Gravida arcu ac tortor
            dignissim convallis aenean et tortor at. Scelerisque varius morbi
            enim nunc faucibus. Proin libero nunc consequat interdum.
          </Text>
        </Box>
        <Box mobStyles={[column, mobLicenseBox]} deskStyles={deskLicenseBox}>
          <Text mobStyles={mobLicenseHeader} deskStyles={deskLicenseHeader}>
            Professional License
          </Text>
          <Text mobStyles={mobLicenseText} deskStyles={deskLicenseText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet
            tellus cras adipiscing enim eu turpis egestas. Vitae auctor eu augue
            ut lectus arcu bibendum at. Quis commodo odio aenean sed adipiscing
            diam. At volutpat diam ut venenatis tellus.{' '}
          </Text>
        </Box>
      </Box>
      <Box
        deskStyles={[
          column,
          css`
            padding: 25px 150px;
          `,
        ]}
        mobStyles={[
          css`
            padding: 25px 10px;
            justify-content: center;
          `,
        ]}
      >
        <LicenseTable />
      </Box>
      <Box
        mobStyles={[column]}
        deskStyles={[
          row,
          css`
            padding: 50px;
          `,
        ]}
      >
        <Box mobStyles={[column, mobLicenseBox]} deskStyles={deskLicenseBox}>
          <Text mobStyles={mobLicenseHeader} deskStyles={deskLicenseHeader}>
            Users
          </Text>
          <Text mobStyles={mobLicenseText} deskStyles={deskLicenseText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque
            laoreet suspendisse interdum consectetur libero id faucibus. Sit
            amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar.
            Nisl nisi scelerisque eu ultrices. At auctor urna nunc id cursus
            metus aliquam eleifend. Sagittis purus sit amet volutpat consequat
            mauris nunc congue nisi. Cras fermentum odio eu feugiat pretium nibh
            ipsum consequat. Fames ac turpis egestas sed tempus urna et.
            Volutpat lacus laoreet non curabitur gravida. Duis ut diam quam
            nulla. Arcu odio ut sem nulla. Sit amet dictum sit amet justo donec
            enim. At varius vel pharetra vel turpis. Velit ut tortor pretium
            viverra suspendisse potenti nullam. Sem viverra aliquet eget sit
            amet tellus. Ornare arcu odio ut sem. Laoreet sit amet cursus sit
            amet dictum. Blandit aliquam etiam erat velit. Non enim praesent
            elementum facilisis leo vel fringilla
          </Text>
        </Box>
        <Box mobStyles={[column, mobLicenseBox]} deskStyles={deskLicenseBox}>
          <Text mobStyles={mobLicenseHeader} deskStyles={deskLicenseHeader}>
            How to Choose the Right License
          </Text>
          <Text mobStyles={mobLicenseText} deskStyles={deskLicenseText}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque
            laoreet suspendisse interdum consectetur libero id faucibus. Sit
            amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar.
            Nisl nisi scelerisque eu ultrices. At auctor urna nunc id cursus
            metus aliquam eleifend. Sagittis purus sit amet volutpat consequat
            mauris nunc congue nisi. Cras fermentum odio eu feugiat pretium nibh
            ipsum consequat. Fames ac turpis egestas sed tempus urna et.
            Volutpat lacus laoreet non curabitur gravida. Duis ut diam quam
            nulla. Arcu odio ut sem nulla. Sit amet dictum sit amet justo donec
            enim. At varius vel pharetra vel turpis. Velit ut tortor pretium
            viverra suspendisse potenti nullam. Sem viverra aliquet eget sit
            amet tellus. Ornare arcu odio ut sem. Laoreet sit amet cursus sit
            amet dictum. Blandit aliquam etiam erat velit. Non enim praesent
            elementum facilisis leo vel fringilla
          </Text>
        </Box>
      </Box>
    </>
  )
}

const LicenseTable = () => {
  let data = [
    {
      col1: 'Perpetual License Duration',
      col2: 'True',
      col3: 'True',
    }, // Object with feature, standard info, pro info
    {
      col1: 'Worldwide Use',
      col2: 'True',
      col3: 'True',
    },
    {
      col1: 'Unlimited Digital Use',
      col2: 'True',
      col3: 'True',
    },
    {
      col1: 'Print Runs',
      col2: '500K',
      col3: 'Unlimited',
    },
    {
      col1: 'Legal Indemnification',
      col2: 'Up to $10K',
      col3: 'Up to $250K',
    },
    {
      col1: 'Out-of-home Advertising',
      col2: 'True',
      col3: 'True',
    },
  ]

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Standard',
        accessor: 'col2',
      },
      {
        Header: 'Professional',
        accessor: 'col3',
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  const mobLicenseTable = css`
    border-radius: 10px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    text-align: center;
    border-collapse: collapse;
  `

  const mobTableData = css`
    padding: 17.5px;
    max-width: 30vw;
    width: 30vw;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-size: 13px;
    font-weight: 500;
  `
  const deskTableData = css`
    font-size: 18px;
    padding: 17.5px;
    max-width: 20vw;
    width: 20vw;
  `

  const mobTableHeader = css`
    height: 30px;
    line-height: 30px;
    width: 100%;
    color: ${p => p.theme.colors.blueGray};
    font-family: ${p => p.theme.fonts.secondary};
    font-weight: 600;
    margin: 0;
  `

  const deskTableHeader = css`
    font-size: 33px;
    height: 40px;
    line-height: 40px;
  `

  return (
    <>
      <Table
        mobStyles={[mobLicenseTable]}
        {...getTableProps()}
        id={'license-features-table'}
      >
        <TableHead
          mobStyles={css`
            border-bottom: 1.5px gainsboro solid;
            border-radius: 10px;
          `}
        >
          {headerGroups.map(headerGroup => (
            <TR {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TH {...column.getHeaderProps()}>
                  <Text mobStyles={mobTableHeader} deskStyles={deskTableHeader}>
                    {column.render('Header')}
                  </Text>
                </TH>
              ))}
            </TR>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TR {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  let leftAlign =
                    index === 0
                      ? css`
                          text-align: left;
                        `
                      : ''

                  return (
                    <TD
                      mobStyles={[mobTableData, leftAlign]}
                      deskStyles={[deskTableData]}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TD>
                  )
                })}
              </TR>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
