/**
 * Function that converts firetore timestamps
 *
 * @param time
 */
export const convertTime = time => {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const formattedDate = new Date(time.seconds * 1000).toLocaleDateString(
    undefined,
    dateOptions
  )

  return `${formattedDate}`
}

export const convertSeconds = seconds => {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const formattedDate = new Date(seconds * 1000).toLocaleDateString(
    undefined,
    dateOptions
  )
  return `${formattedDate}`
}

export const convertToString = time => {
  // const timeAsMoment = moment.unix(time.seconds)
  const meridiem = time.getHours() >= 12 ? 'PM' : 'AM'
  const hours = time.getHours() >= 12 ? time.getHours() - 12 : time.getHours()
  const minutes = time.getMinutes() === 0 ? '00' : time.getMinutes()

  return `${hours}:${minutes} ${meridiem}`
}
