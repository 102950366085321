import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Box, mobTextVariants, Text } from '../../shared/'
import { collections } from '../../firebaseApp'
import { LoadingScreen } from '../../components/LoadingScreen'
import { mobBoxVariants } from '../../shared/index'
import { Request } from '../../classes'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { MessageBox } from '../../components/MessageBox'
import { RequestBox } from '../../components/RequestBox'
import { uuid } from '../../utils/uuid'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { RequestStatus } from '../../constants/enums'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

export const ViewCreatedOffersPage = ({ currentUserAuth }) => {
  const [
    allUserCreatedOffers,
    allUserCreatedOffersLoading,
    allUserCreatedOffersError,
  ] = useCollectionData(
    collections.activeRequests.where(
      'requestCreator',
      '==',
      currentUserAuth.uid
    ),
    {
      idField: 'id',
    }
  )

  const pendingFilter = request => request.status === RequestStatus.pending
  const awaitingFilter = request => request.status === RequestStatus.awaitingBAM
  const acceptedFilter = request => request.status === RequestStatus.accepted
  const completedFilter = request => request.status === RequestStatus.completed
  const disputedFilter = request => request.status === RequestStatus.disputed
  const inactiveFilter = request =>
    request.status === RequestStatus.inactive ||
    request.status === RequestStatus.declined ||
    request.status === RequestStatus.canceled ||
    request.status === RequestStatus.deleted ||
    request.status === RequestStatus.notAvailable

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          Offers you have created.
        </Text>
        <Text
          mobStyles={mobInstructionParagraph}
          deskStyles={deskInstructionParagraph}
        >
          Requests you have created to hire users for your gigs.
        </Text>
        {allUserCreatedOffersError && (
          <strong>Error: {allUserCreatedOffersError}</strong>
        )}
        {allUserCreatedOffersLoading && <LoadingScreen />}
        {allUserCreatedOffers && (
          <>
            {allUserCreatedOffers.length <= 0 && (
              <MessageBox
                header="No Offers"
                message="Offers you create will appear here."
              />
            )}
            {allUserCreatedOffers
              .filter(awaitingFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {allUserCreatedOffers
              .filter(pendingFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {allUserCreatedOffers
              .filter(acceptedFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {allUserCreatedOffers
              .filter(completedFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {allUserCreatedOffers
              .filter(disputedFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
            {allUserCreatedOffers
              .filter(inactiveFilter)
              .map(receivedOfferData => {
                return (
                  <RequestBox
                    key={uuid()}
                    request={new Request(receivedOfferData)}
                    currentUserAuth={currentUserAuth}
                  />
                )
              })}
          </>
        )}
      </Box>
    </DesktopPageWrapper>
  )
}
