import React from 'react'

import { StyledButton, StyledMotionButton } from './styles'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Button = props => {
  if (props.enableMotion) {
    return <StyledMotionButton {...props}>{props.children}</StyledMotionButton>
  }

  return <StyledButton {...props}>{props.children}</StyledButton>
}

Button.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
