import React from 'react'
import styled, { css } from 'styled-components'
import { Box, mobBoxVariants } from '../../../shared'
import { Text } from '../../../shared/Text'
import { deskSecondPanelHeading, mobSecondPanelHeading } from './styles'
import { up } from 'styled-breakpoints'
import ModelImage from '../../../assets/photos/creative_community_model.png'
import PhotographersImage from '../../../assets/photos/creative_community_photographers.png'
import MakeupImage from '../../../assets/photos/creative_community_makeup.png'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { AccountType, SearchType } from '../../../constants/enums'
import { useSetRecoilState } from 'recoil'
import { searchState } from '../../../store'

const { center, column, row, mobWrapper } = mobBoxVariants

const CommunityCardImage = styled(motion.div)`
  background-image: url(${props => props.sourceImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;

  height: 316px;
  width: 338px;
  border: 1px solid #f0f0f1;
  display: flex;
  padding: 5px;
  margin-bottom: 5%;

  ${up('desktop')} {
    height: 300px;
  }
`

const CommunityCardText = styled.p`
  margin: 0;
  width: 95%;
  height: 95%;
  color: ${p => p.theme.colors.secondary};
  font-weight: 600;
  font-size: 18px;
  font-family: ${p => p.theme.fonts.primary};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const deskCardWrapper = css`
  width: 100%;
  justify-content: space-evenly;
`

export const communityBoxes = [
  { image: ModelImage, text: 'Models', accountType: AccountType.model },
  {
    image: PhotographersImage,
    text: 'Photographers',
    accountType: AccountType.photographer,
  },
  {
    image: MakeupImage,
    text: 'Makeup Artist',
    accountType: AccountType.makeupArtist,
  },
]

export const CreativeCommunityModule = () => {
  const history = useHistory()
  const setSearch = useSetRecoilState(searchState)

  const redirectToSearch = accountType => {
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        type: SearchType.creative,
        accountType: {
          model: accountType === AccountType.model,
          photographer: accountType === AccountType.photographer,
          makeupArtist: accountType === AccountType.makeupArtist,
        },
      }
    })
    history.push(ROUTES.SEARCH.link)
  }

  return (
    <Box mobStyles={[center, column, mobWrapper]}>
      <Text
        mobStyles={[mobSecondPanelHeading]}
        deskStyles={[deskSecondPanelHeading]}
      >
        Creative Community
      </Text>
      <Box mobStyles={[center, column]} deskStyles={[row, deskCardWrapper]}>
        {communityBoxes.map(({ image, text, accountType }, index) => {
          return (
            <CommunityCardImage
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.11 }}
              sourceImage={image}
              key={index}
              onClick={
                accountType
                  ? () => {
                      redirectToSearch(accountType)
                    }
                  : null
              }
            >
              <CommunityCardText>{text}</CommunityCardText>
            </CommunityCardImage>
          )
        })}
      </Box>
    </Box>
  )
}
