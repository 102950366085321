import React from 'react'
import { FaFingerprint, FaTimes } from 'react-icons/fa'
import PropTypes from 'prop-types'

import { Box, deskBoxVariants, mobBoxVariants, Text } from '../../shared'
import { Detail } from '../../components/Detail'
import { userPropTypes } from '../../classes/Models/User'
import { AccountType } from '../../constants/enums'
import { css } from 'styled-components'
import { mobDetail } from '../../components/Detail/styles'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { IconContext } from 'react-icons'
import { theme } from '../../theme'

const {
  mobDetailVerticalRule,
  mobDetailsBoxContainer,
  mobDetailsBox,
  column,
} = mobBoxVariants

const { deskDetailsBoxContainer } = deskBoxVariants
const { deskDetail } = deskTextVariants

export const inchesToHumanReadable = heightInches => {
  const inches = heightInches % 12
  const feet = Math.floor(heightInches / 12)
  return `${feet}' ${inches}"`
}

/**
 * Profile details for user profiles. Each user account type have a different
 * set of details. Maximum of 4 per detail box.
 *
 * @param {User} user
 * @param publicProfile
 * @returns {null|*}
 * @constructor
 */
export const ProfileDetailsModule = ({ user }) => {
  switch (user.accountType) {
    case AccountType.model:
      return (
        <>
          <Box
            mobStyles={[column, mobDetailsBoxContainer]}
            deskStyles={[deskDetailsBoxContainer]}
          >
            <Box mobStyles={mobDetailsBox}>
              <Detail
                detail={inchesToHumanReadable(user.height)}
                text="Height"
              />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.weight + 'lbs'} text="Weight" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.getGender()} text="Gender" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={user.getUserHourlyRate()}
                text="Hourly Rate ($)"
              />
            </Box>
          </Box>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={'Model'} text="Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={user.instagram.instagramHandle}
                text="Instagram"
              />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.websiteUrl} text="Website" url />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={
                  user.identityVerified ? (
                    <FaFingerprint />
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: theme.colors.red,
                        size: '1em',
                      }}
                    >
                      <FaTimes />
                    </IconContext.Provider>
                  )
                }
                text="Identity"
              />
            </Box>
          </Box>
          {getUserDescription(user)}
        </>
      )
    case AccountType.agency:
      return (
        <>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={user.industry} text="Industry" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.yearFounded} text="Founded" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.representative} text="Representative" />
            </Box>
          </Box>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={'Agency'} text="Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.agencyType} text="Agency Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.websiteUrl} text="Website" url />
            </Box>
          </Box>
          {getUserDescription(user)}
        </>
      )
    case AccountType.photographer:
      return (
        <>
          <Box
            mobStyles={[column, mobDetailsBoxContainer]}
            deskStyles={[deskDetailsBoxContainer]}
          >
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={user.photoSpecialty} text="Specialty" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.equipment} text="Equipment" />
            </Box>
          </Box>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={'Photographer'} text="Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={user.instagram.instagramHandle}
                text="Instagram"
              />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.websiteUrl} text="Website" url />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={<FaFingerprint />} text="Identity" />
            </Box>
          </Box>
          {getUserDescription(user)}
        </>
      )
    case AccountType.makeupArtist:
      return (
        <>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={'Makeup Artist'} text="Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.photoSpecialty} text="Specialty" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={user.instagram.instagramHandle}
                text="Instagram"
              />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.websiteUrl} text="Website" url />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={<FaFingerprint />} text="Identity" />
            </Box>
          </Box>
          {getUserDescription(user)}
        </>
      )
    case AccountType.influencer:
      return (
        <>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={'Influencer / Content Creator'} text="Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={user.instagram.instagramHandle}
                text="Instagram"
              />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={user.websiteUrl} text="Website" url />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail detail={<FaFingerprint />} text="Identity" />
            </Box>
          </Box>
          {getUserDescription(user)}
        </>
      )
    case AccountType.developer || AccountType.admin:
      return (
        <>
          <Box mobStyles={[column, mobDetailsBoxContainer]}>
            <Box mobStyles={mobDetailsBox}>
              <Detail detail={'Admin'} text="Type" />
              <Box mobStyles={mobDetailVerticalRule} />
              <Detail
                detail={user.instagram.instagramHandle}
                text="Instagram"
              />
            </Box>
          </Box>
        </>
      )
    default:
      return null
  }
}

const getUserDescription = user => {
  if (user.description) {
    return (
      <Box mobStyles={[column, mobDetailsBoxContainer]}>
        <Box
          mobStyles={mobDetailsBox}
          deskStyles={css`
            max-width: 30vw;
          `}
        >
          <Text mobStyles={mobDetail} deskStyles={[deskDetail]}>
            {user.description}
          </Text>
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

ProfileDetailsModule.propTypes = {
  ...userPropTypes,
  ratingPosition: PropTypes.number,
}
