/**
 * Function that converts gig timestamps into human-readable strings
 * when displaying gig info
 *
 * @param eventDate timestamp object
 * @param startTime timestamp object
 * @param endTime timestamp object
 */
export const convertDateAndTimeForView = (eventDate, startTime, endTime) => {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const timeOptionsWithoutMinutes = {
    hour: 'numeric',
    hour12: true,
  }

  const zeroStartTimeMinutes =
    new Date(startTime.seconds * 1000).getMinutes() === 0
  const zeroEndTimeMinutes = new Date(endTime.seconds * 1000).getMinutes() === 0

  let startOptions
  let endOptions

  if (zeroStartTimeMinutes) {
    startOptions = timeOptionsWithoutMinutes
  } else {
    startOptions = timeOptions
  }

  if (zeroEndTimeMinutes) {
    endOptions = timeOptionsWithoutMinutes
  } else {
    endOptions = timeOptions
  }

  const formattedDate = new Date(eventDate.seconds * 1000).toLocaleDateString(
    undefined,
    dateOptions
  )
  const formattedStartTime = new Date(
    startTime.seconds * 1000
  ).toLocaleTimeString(undefined, startOptions)
  const formattedEndTime = new Date(endTime.seconds * 1000).toLocaleTimeString(
    undefined,
    endOptions
  )

  return `${formattedDate} | ${formattedStartTime} -  ${formattedEndTime}`
}
