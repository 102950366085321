import isNil from 'lodash/isNil'
import trim from 'lodash/trim'

/**
 * FaqForm is a helper class to provide validation and helpers
 * for the FAQ form
 *
 */
export class FaqForm {
  constructor(submitButtonDisabled) {
    this.submitButtonDisabled = submitButtonDisabled
    this.question = ''
    this.additionalInfo = null
  }

  updateQuestion = event => {
    this.question = event.target.value
    this.validFaqData()
  }

  updateAdditionalInfo = event => {
    this.additionalInfo = event.target.value
    this.validFaqData()
  }

  /**
   * This method makes sure the user provides at least a question before submitting
   */
  validFaqData = () => {
    trim(this.question)

    if (!isNil(this.question) && this.question.length > 30) {
      this.submitButtonDisabled(false)
      return true
    } else {
      this.submitButtonDisabled(true)
      return false
    }
  }
}
