import { selector } from 'recoil'
import { searchState } from '../'
import { SearchType } from '../../constants/enums'

// To search an index in Algolia you must have the index name. We have one index per entity.
// i.e. dev_USERS for users, dev_EVENTS for events, etc.
export const searchTypeState = selector({
  key: 'searchTypeState',
  get: ({ get }) => {
    const searchTypeMap = new Map()

    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    ) {
      searchTypeMap.set(SearchType.gig, {
        indexName: 'dev_EVENTS',
        placeholder: 'Search for gigs',
      })
      searchTypeMap.set(SearchType.images, {
        indexName: 'dev_LICENSE_IMAGES',
        placeholder: 'Search for images',
      })
      searchTypeMap.set(SearchType.creative, {
        indexName: 'dev_USERS',
        placeholder: 'Search for creatives',
      })
    } else if (process.env.NODE_ENV === 'production') {
      searchTypeMap.set(SearchType.gig, {
        indexName: 'prod_EVENTS',
        placeholder: 'Search for gigs',
      })
      searchTypeMap.set(SearchType.images, {
        indexName: 'dev_LICENSE_IMAGES',
        placeholder: 'Search for images',
      })
      searchTypeMap.set(SearchType.creative, {
        indexName: 'prod_USERS',
        placeholder: 'Search for creatives',
      })
    }

    const search = get(searchState)
    switch (Number(search.type)) {
      case SearchType.gig:
        return searchTypeMap.get(SearchType.gig)
      case SearchType.images:
        return searchTypeMap.get(SearchType.images)
      case SearchType.creative:
        return searchTypeMap.get(SearchType.creative)
      // This case should not be hit
      default:
        return searchTypeMap.get(SearchType.gig)
    }
  },
})
