import React from 'react'

import { Box, mobBoxVariants } from '../../shared/'
import { SearchBar } from '../../components/SearchBar'
import { SearchResultsModule } from '../../modules/SearchResultsModule'
import { css } from 'styled-components'
import { SearchFilters } from '../../components/SearchFilters'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'

const { column, row } = mobBoxVariants

export const SearchPage = ({ currentUserAuth }) => {
  const isDesktop = useMediaQuery(desktopQuery)

  return (
    <Box
      deskStyles={[
        row,
        css`
          height: 92vh;
        `,
      ]}
    >
      {isDesktop && (
        <>
          <Box
            deskStyles={[
              css`
                width: 26%;
                height: 100%;
              `,
            ]}
          />
          <Box
            deskStyles={[
              column,
              css`
                position: fixed;
                top: 100px;
                height: 100%;
                width: 25%;
                overflow: scroll;
                box-shadow: 0 0 4px 2px rgba(202, 201, 201, 0.5);
              `,
            ]}
          >
            <SearchFilters />
          </Box>
        </>
      )}
      <Box
        mobStyles={[
          column,
          css`
            height: auto;
            width: ${isDesktop ? '75%' : '100%'};
          `,
        ]}
      >
        <SearchBar currentUserAuth={currentUserAuth} />
        <SearchResultsModule />
      </Box>
    </Box>
  )
}
