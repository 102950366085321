import React from 'react'
import { MdNewReleases } from 'react-icons/md'
import { css, useTheme } from 'styled-components'
import { IconContext } from 'react-icons'

import {
  Box,
  Button,
  deskBoxVariants,
  Image,
  Link,
  mobBoxVariants,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { useHistory } from 'react-router-dom'
import BronnerSmallLogo from '../../assets/logos/bronner_small_logo.jpg'

const {
  mobGigBoxDescriptionMini,
  mobGigBoxHeader,
  mobGigBoxLocation,
  mobGigDate,
  mobHorizontalRule,
  mobGigBoxDescription,
} = mobTextVariants
const {
  deskGigBoxDescriptionMini,
  deskGigBoxHeader,
  deskGigBoxLocation,
  deskGigDate,
} = deskTextVariants
const { mobGigBox, column, fullHeight, row } = mobBoxVariants
const { deskGigBox } = deskBoxVariants
const { mobProfileButtonFull } = mobButtonVariants

export const GigBoxType = {
  fullLink: 1,
  miniLink: 2,
  fullButton: 3,
  
  fullLinkRequestBox: 20,
}

/**
 * This component builds various different types of boxes for gigs
 *
 *  FullLinkBox: full-length box used in most of the pages
 *  MiniLinkBox: Slightly condensed box for search pages
 *  FullButtonBox: full-length box that is a button used in the select gig page
 *
 * @param props
 * @constructor
 */
export const GigBox = ({
                         gig,
                         boxType,
                         index,
                         handleEventSelectChange,
                         deskStyles,
                       }) => {
  const history = useHistory()
  
  const NewIcon = ({ event }) => {
    const theme = useTheme()
    if (event.createdInLastWeek()) {
      return null
    }
    
    return (
      <Box
        mobStyles={[
          css`
            position: absolute;
            left: 90%;
          `,
        ]}
      >
        <IconContext.Provider
          value={{
            color: theme.colors.primary,
            size: '1.5em',
          }}
        >
          <MdNewReleases/>
        </IconContext.Provider>
      </Box>
    )
  }
  
  const FullLinkBox = (
    <Button
      enableMotion
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.5 },
      }}
      whileTap={{ scale: 0.9 }}
      onClick={() => {
        history.push('/gig/' + gig.id)
      }}
      mobStyles={[mobGigBox, column]}
      deskStyles={[deskGigBox, deskStyles]}
      key={index}
    >
      <Box mobStyles={[row]}>
        
        <Text mobStyles={mobGigBoxHeader}>{gig.title}</Text>
        {gig.bronnerBrosIBS && (
          <Image
            src={BronnerSmallLogo}
            deskStyles={css`
                height: 5vw;
                width: 5vw;
              `}
            mobStyles={css`
                height: 15vw;
                width: 15vw;
              `}
          />
        )}
      </Box>
      <Text mobStyles={mobGigDate}>{gig.getDateString()}</Text>
      <Text mobStyles={mobHorizontalRule}/>
      <Text mobStyles={mobGigBoxDescription}>{gig.description}</Text>
      <Text mobStyles={mobGigBoxLocation}>{gig.address}</Text>
    </Button>
  )
  
  // This is the one used for the search page
  // This is the one used for the search page
  const MiniLinkBox = (
    <Link
      to={'/gig/' + gig.id}
      mobStyles={[mobGigBox, column]}
      deskStyles={[deskGigBox]}
      key={index}
    >
      <Box mobStyles={[column]}>
        <Box mobStyles={[row]}>
          <Text
            mobStyles={[
              mobGigBoxHeader,
              css`
                flex: 1;
              `,
            ]}
            deskStyles={[deskGigBoxHeader]}
          >
            {gig.title}
          </Text>
          {gig.bronnerBrosIBS && (
            <Image
              src={BronnerSmallLogo}
              deskStyles={css`
                height: 5vw;
                width: 5vw;
              `}
            />
          )}
        </Box>
        
        <Text mobStyles={mobGigDate} deskStyles={[deskGigDate]}>
          {gig.getDateString()}
        </Text>
        <Text mobStyles={mobHorizontalRule}/>
      </Box>
      <Box>
        <Text
          mobStyles={mobGigBoxDescriptionMini}
          deskStyles={[deskGigBoxDescriptionMini]}
        >
          {gig.description}
        </Text>
      </Box>
      <Box
        mobStyles={[
          fullHeight,
          css`
            align-items: flex-end;
          `,
        ]}
      >
        <Text mobStyles={[mobGigBoxLocation]} deskStyles={[deskGigBoxLocation]}>
          {gig.address}
        </Text>
      </Box>
    </Link>
  )
  
  const FullButtonBox = (
    <Button
      mobStyles={mobProfileButtonFull}
      key={index}
      onClick={handleEventSelectChange}
      value={gig.id}
    >
      <Text mobStyles={mobGigBoxHeader}>{gig.title}</Text>
      <Text mobStyles={mobGigDate}>{gig.getDateString()}</Text>
      <Text mobStyles={mobHorizontalRule}/>
      <Text mobStyles={mobGigBoxDescription}>{gig.description}</Text>
      <Text mobStyles={mobGigBoxLocation}>{gig.address}</Text>
    </Button>
  )
  
  switch (boxType) {
    case GigBoxType.fullLink:
      return FullLinkBox
    case GigBoxType.miniLink: // This is the one used for the search page
      return MiniLinkBox
    case GigBoxType.fullButton:
      return FullButtonBox
    default:
      return FullLinkBox
  }
}
