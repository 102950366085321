import { User } from '../../classes'
import { Box, mobBoxVariants } from '../../shared/Box'
import styled, { css } from 'styled-components'
import { Image } from '../../shared/Image'
import { MessageLastUpdated, RecipientName } from '../InboxPage/ChatItemDetails'
import TimeAgo from 'react-timeago'
import React from 'react'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'

const { row, column, fullWidth } = mobBoxVariants
const mobMessageBox = (currentUserIsSender, lastMessageHeight, lastMessage) => {
  return css`
    align-self: ${currentUserIsSender ? 'flex-end' : ''};
    height: auto;
    width: 87%;
    align-items: center;
    box-sizing: border-box;
    ${lastMessage ? lastMessageHeight.toString() + 'px' : '10px'} 0;
    margin: 10px 0 10px 0;
    padding: 10px 15px 10px 10px;
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
  `
}

const mobMessageProfileImage = css`
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 100%;
  box-shadow: ${p => p.theme.shadow.regular};
  align-self: flex-start;
`

const mobNameAndTimeBox = css`
  width: 95%;
`

export const Message = ({
  messageSnapshot,
  currentUserId,
  currentUserSnapshot,
  userBSnapshot,
  lastMessage,
  lastMessageHeight,
}) => {
  const history = useHistory()
  const currentUserIsSender = currentUserId === messageSnapshot.data().from
  const userToDisplay = currentUserIsSender
    ? new User(currentUserSnapshot)
    : new User(userBSnapshot)

  return (
    <>
      <Box
        mobStyles={[
          mobMessageBox(currentUserIsSender, lastMessageHeight, lastMessage),
        ]}
      >
        <Image
          mobStyles={[mobMessageProfileImage]}
          src={userToDisplay.getProfileImage()}
          onClick={() => {
            history.push(ROUTES.USER_PROFILE.base + userToDisplay.userId)
          }}
        />
        <Box mobStyles={[column, fullWidth]}>
          <Box mobStyles={[row, mobNameAndTimeBox]}>
            <RecipientName>
              {currentUserIsSender ? 'You' : userToDisplay.fullName}
            </RecipientName>
            <MessageLastUpdated>
              <TimeAgo
                date={new Date(messageSnapshot.data().createdAt.seconds * 1000)}
              />
            </MessageLastUpdated>
          </Box>
          <MessageText>{messageSnapshot.data().body}</MessageText>
        </Box>
      </Box>
    </>
  )
}

const MessageText = styled.text`
  height: auto;
  width: 236px;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 0 10px 0 10px;
  text-overflow: ellipsis;
`
