import React from 'react'
import PropTypes from 'prop-types'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/index'

const { mobInvoiceRowGrand } = mobTextVariants
const { mobRow, mobInvoiceRowMoney, mobInvoiceRowMoneyGrand } = mobBoxVariants

export const InvoiceLineType = {
  Regular: 1,
  // RESERVED: 2,
  // RESERVED: 3,
  // RESERVED: 4,
  Grand: 5,
}

/**
 * This component builds various different types of boxes for gigs
 *
 *  Regular: regular sized row
 *  Grand: row with enlarged text
 *
 * @param props
 * @constructor
 */
export const InvoiceRow = ({ money, description, lineType }) => {
  let moneyToDisplay = money
  if (typeof money !== "string") {
    moneyToDisplay =  'Error please make a support ticket.';
  }
  
  const regular = (
    <Box mobStyles={mobRow}>
      {description}
      <Box mobStyles={mobInvoiceRowMoney}>{moneyToDisplay}</Box>
    </Box>
  )

  const grand = (
    <Box mobStyles={mobRow}>
      <Text mobStyles={mobInvoiceRowGrand}>{description}</Text>
      <Box mobStyles={mobInvoiceRowMoneyGrand}>{moneyToDisplay}</Box>
    </Box>
  )

  return lineType ? grand : regular
};
