import React from 'react'
import PropTypes from 'prop-types'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { LoadingScreen } from '../../components/LoadingScreen'
import { Box, mobBoxVariants } from '../../shared/Box'
import { mobTextVariants, Text } from '../../shared/Text'
import { TextAreaWithHeader } from '../../components/Inputs/TextAreaWithHeader'
import { LoadingButton } from '../../components/LoadingButton'
import { Button, mobButtonVariants } from '../../shared/Button'
import { mobLinkVariants } from '../../shared/Link'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { mobSubmitButton } = mobButtonVariants
const { mobLong305x46 } = mobLinkVariants
const {
  deskPageHeader,
  deskInstructionParagraph,
  deskSubmitButton,
  deskFaqText,
} = deskTextVariants

export const FaqContent = ({
  questionsSnapshotError,
  questionsSnapshotLoading,
  questionsSnapshot,
  questions,
  createFaqQuestion,
  faqForm,
  showLoading,
  handleFaqRequest,
  submitFormDisabled,
  currentUserAuth,
}) => {
  return (
    <DesktopPageWrapper>
      <>
        {questionsSnapshotError && (
          <strong>Error: {questionsSnapshotError}</strong>
        )}
        {questionsSnapshotLoading && <LoadingScreen />}
        {questionsSnapshot && (
          <Box mobStyles={[column, mobWrapper]}>
            <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
              Frequently Asked Questions
            </Text>
            <Text
              mobStyles={mobInstructionParagraph}
              deskStyles={deskInstructionParagraph}
            >
              List of questions and answers to improve your experience!
            </Text>

            {questions.map(questionDocument => {
              return createFaqQuestion(questionDocument)
            })}
            {currentUserAuth && (
              <>
                <Text
                  mobStyles={mobInstructionParagraph}
                  deskStyles={deskFaqText}
                  data-testid="question-not-submitted"
                >
                  Question not listed? Submit a question here and we will answer
                  it promptly and possibly add it to the list!
                </Text>
                <TextAreaWithHeader
                  header="Question*"
                  inputName="question"
                  placeholderDefault="How do I...?"
                  onChange={faqForm.updateQuestion}
                  test-dataid="how-do-i-text"
                />
                <TextAreaWithHeader
                  header="Additional Comments"
                  inputName="additional-comments"
                  placeholderDefault="Add extra information about your question here"
                  onChange={faqForm.updateAdditionalInfo}
                  data-testid="additional-comments-text"
                />
                <br />
                <br />
                {showLoading && <LoadingButton />}
                {!showLoading && (
                  <Button
                    onClick={handleFaqRequest}
                    mobStyles={[mobSubmitButton, mobLong305x46]}
                    deskStyles={deskSubmitButton}
                    disabled={submitFormDisabled}
                  >
                    SUBMIT QUESTION
                  </Button>
                )}
              </>
            )}
            <br />
            <br />
            <br />
          </Box>
        )}
      </>
    </DesktopPageWrapper>
  )
}

FaqContent.propTypes = {
  createFaqQuestion: PropTypes.func,
  faqForm: PropTypes.object,
  handleFaqRequest: PropTypes.func,
  questions: PropTypes.array,
  questionsSnapshot: PropTypes.object,
  questionsSnapshotError: PropTypes.object,
  questionsSnapshotLoading: PropTypes.bool,
  showLoading: PropTypes.bool,
  submitFormDisabled: PropTypes.bool,
}
