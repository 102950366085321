import React from 'react'
import { Box, Image, mobBoxVariants, mobTextVariants } from '../../../shared'
import { Text } from '../../../shared/Text'
import { deskSecondPanelHeading, mobSecondPanelHeading } from './styles'
import { Frame, Scroll } from 'framer'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import BlackBAMLogo from '../../../assets/logos/black-b-logo.svg'
import TimeAgo from 'react-timeago'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collections } from '../../../firebaseApp'

const { mobGigBoxHeader, mobHorizontalRule } = mobTextVariants
const { column, mobWrapper, row, center } = mobBoxVariants

export const CastingModule = () => {
  const [
    announcements,
    announcementsLoading,
    announcementsError,
  ] = useCollectionData(
    collections.announcements.orderBy('createdAt', 'desc'),

    {
      idField: 'id',
    }
  )

  if (announcementsLoading || announcementsError) {
    return null
  }

  if (announcements.length <= 0) {
    return null
  }

  return (
    <Box
      mobStyles={[
        center,
        column,
        mobWrapper,
        css`
          margin-bottom: 5vh;
        `,
      ]}
    >
      <Text
        mobStyles={[mobSecondPanelHeading]}
        deskStyles={[deskSecondPanelHeading]}
      >
        Check These Out
      </Text>
      <Box
        mobStyles={[
          center,
          column,
          css`
            height: 400px;
          `,
        ]}
      >
        <Scroll direction={'horizontal'} height={450} width={'100%'}>
          <Frame height={450} background="FFF" width={'100%'}>
            <Box mobStyles={[center, row, mobWrapper]}>
              {announcements.map((casting, index) => {
                if (casting.deleted !== true && casting.hidden !== true) {
                  return createAnnouncement(casting, index)
                }
              })}
            </Box>
          </Frame>
        </Scroll>
      </Box>
    </Box>
  )
}

export const AnnouncementContainer = styled.div`
  height: 300px;
  width: 300px;
  border: 1px solid ${p => p.theme.colors.lightGray};
  border-radius: 5px;
  background-color: ${p => p.theme.colors.secondary};
  box-shadow: ${p => p.theme.shadow.regular};
  padding: 15px 15px 0px 15px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 15px;

  display: flex;
  ${up('desktop')} {
    height: 400px;
    width: 400px;
    margin: 35px;
  }
`

export const SignatureDate = styled.p`
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
  ${up('desktop')} {
    font-size: 20px;
  }
`

export const mobGreyBamLogo = css`
  width: 35px;
  margin-bottom: auto;
`

export const deskGreyBamLogo = css`
  width: 50px;
`

export const announcementDescription = css`
  height: 100px;
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
  margin: 3% 0 1%;
  overflow: scroll;
  flex: 1 1 auto;
  text-align: left;
`

const createAnnouncement = ({ title, body, createdAt }, index) => {
  return (
    <AnnouncementContainer key={index}>
      <Box mobStyles={[row]}>
        <Text
          mobStyles={[
            mobGigBoxHeader,
            css`
              height: auto;
              font-size: 20px !important;
            `,
          ]}
          deskStyles={[
            mobGigBoxHeader,
            css`
              font-size: 25px !important;
            `,
          ]}
        >
          {title}
        </Text>
        <Image
          src={BlackBAMLogo}
          mobStyles={[mobGreyBamLogo]}
          deskStyles={deskGreyBamLogo}
        />
      </Box>
      <Text mobStyles={mobHorizontalRule} />
      <Text mobStyles={announcementDescription}>{body}</Text>
      <Box
        mobStyles={[
          row,
          css`
            justify-content: flex-end;
          `,
        ]}
      >
        <SignatureDate>
          Posted <TimeAgo date={new Date(createdAt.seconds * 1000)} />
        </SignatureDate>
      </Box>
    </AnnouncementContainer>
  )
}
