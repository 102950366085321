import React, { useState } from 'react'
import { Box, mobBoxVariants } from '../../shared/Box'
import { css } from 'styled-components'
import { ChatInput } from '../../components/Inputs/ChatInput'
import { collections } from '../../firebaseApp'
import { useDocument } from 'react-firebase-hooks/firestore'
import { LoadingScreen } from '../../components/LoadingScreen'
import { MessageContainer } from './MessageContainer'
import { chatThreadState } from '../../store'
import { useRecoilValue } from 'recoil'
import get from 'lodash/get'

const { mobWrapper, column } = mobBoxVariants

const chatInputContainer = css`
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`
const deskChatInputContainer = css`
  width: 75vw;
`

export const ChatThreadPage = ({ match, currentUserAuth }) => {
  const [lastMessageHeight, setLastMessageHeight] = useState(150)
  const chatThreadId = useRecoilValue(chatThreadState)

  const matchParam = get(match, 'params.chat_id', null)
  const chatToDisplay = matchParam ? matchParam : chatThreadId

  const [
    chatRoomSnapshot,
    chatRoomSnapshotLoading,
    chatRoomSnapshotError,
  ] = useDocument(collections.chatRooms.doc(chatToDisplay))

  return (
    <>
      {chatRoomSnapshotError && <strong>Error: {chatRoomSnapshotError}</strong>}
      {chatRoomSnapshotLoading && <LoadingScreen />}
      {chatRoomSnapshot && (
        <>
          <MessageContainer
            lastMessageHeight={lastMessageHeight}
            chatRoomSnapshot={chatRoomSnapshot}
          />
          <Box
            mobStyles={[column, mobWrapper, chatInputContainer]}
            deskStyles={[deskChatInputContainer]}
          >
            <ChatInput
              chatRoomSnapshot={chatRoomSnapshot}
              currentUserId={currentUserAuth.uid}
              setLastMessageHeight={setLastMessageHeight}
            />
          </Box>
        </>
      )}
    </>
  )
}
