import React from 'react'
import PropTypes from 'prop-types'

import { mobTextVariants, Text } from '../../shared/Text'
import { Image, mobImageVariants } from '../../shared/Image'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { mobGigLocationImage } = mobImageVariants
const { deskGigLocationImage } = deskImageVariants
const {
  mobInstructionParagraph,
  mobGigBoxLocation,
  mobGigDate,
  mobGigBoxHeader,
} = mobTextVariants
const {
  deskInstructionParagraph,
  deskGigBoxLocation,
  deskGigDate,
  deskGigBoxHeader,
} = deskTextVariants

/**
 *
 * @param {Gig} gig
 * @returns {*}
 * @constructor
 */
export const GigModule = ({ gig }) => {
  const { title, description, address, locationMobileImageUrl } = gig

  return (
    <>
      <Text mobStyles={mobGigBoxHeader} deskStyles={deskGigBoxHeader}>
        {title}
      </Text>
      <Text mobStyles={mobGigDate} deskStyles={deskGigDate}>
        {gig.getDateString()}
      </Text>
      <Text
        mobStyles={[mobInstructionParagraph]}
        deskStyles={deskInstructionParagraph}
      >
        {description}
      </Text>
      <Text mobStyles={mobGigBoxLocation} deskStyles={deskGigBoxLocation}>
        {address}
      </Text>
      <Image
        src={locationMobileImageUrl}
        mobStyles={mobGigLocationImage}
        deskStyles={deskGigLocationImage}
      />
    </>
  )
}

GigModule.propTypes = {
  event: PropTypes.shape({
    address: PropTypes.string,
    getDateString: PropTypes.func,
    description: PropTypes.string,
    locationMobileImageUrl: PropTypes.string,
    title: PropTypes.string,
  }),
}
