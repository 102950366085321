import React from 'react'
import PropTypes from 'prop-types'

import { StyledMotionText, StyledText } from './styles'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Text = ({
  fontSize = {
    mob: '16px',
    tab: '18px',
    desk: '18px',
    lgDesk: '18px',
  },
  children,
  enableMotion,
  ...props
}) => {
  if (enableMotion) {
    return (
      <StyledMotionText fontSize={fontSize} {...props}>
        {children}
      </StyledMotionText>
    )
  }

  return (
    <StyledText fontSize={fontSize} {...props}>
      {children}
    </StyledText>
  )
}

Text.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
}
