import { useHistory } from 'react-router-dom'
import DancingAlert from '../../../../assets/lottie/5457-alert-notification'
import { useLottie } from 'lottie-react'
import { motion } from 'framer-motion'
import { Box, mobBoxVariants } from '../../../../shared/Box'
import { css } from 'styled-components'
import { Text } from '../../../../shared/Text'
import { Image } from '../../../../shared/Image'
import BronnerSmallLogo from '../../../../assets/logos/bronner_small_logo.jpg'
import { IconContext } from 'react-icons'
import { theme } from '../../../../theme'
import React from 'react'
import { whiteLabelMotionVariants } from '../WhiteLabelExperienceRouter'
import { HiArrowCircleLeft, HiArrowCircleRight } from 'react-icons/hi'
import Redirect from 'react-router-dom/es/Redirect'
import { AccountType } from '../../../../constants/enums'
import { ROUTES } from '../../../../constants/routes'

const { column, row } = mobBoxVariants

export const MainSlide = ({ currentUserAuth, user }) => {
  const history = useHistory()
  const options = {
    animationData: DancingAlert,
    loop: true,
    autoplay: true,
    style: {
      height: 200,
      margin: 0,
    },
  }
  const { View } = useLottie(options)
  
  
  let isModel
  if(user) {
    if (Number(user.accountType) === Number(AccountType.none)) {
      return <Redirect from="/" to={ROUTES.W_ACCOUNT_TYPE.link}/>
    }
    isModel = user.accountType === AccountType.model
  }
  
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={whiteLabelMotionVariants}
    >
      <Box
        deskStyles={[
          column,
          css`
            min-height: 500px;
          `,
        ]}
        mobStyles={[column]}
      >
        <Box
          mobStyles={[
            column,
            css`
              padding: 25px 25px 0 25px;
            `,
          ]}
          deskStyles={[
            row,
            css`
              padding: 50px 50px 0 50px;
            `,
          ]}
        >
          <Text
            mobStyles={[mobAccountTypeHeader]}
            deskStyles={[deskAccountTypeHeader, css`flex: 1;`]}
          >
            BRONNER BROS. INTERNATIONAL BEAUTY SHOW
            <br/>
            When: August 28th to 30th, 2021
            <br/>
            Where: <u>Ernest N. Morial Convention Center New Orleans, LA</u>
          </Text>
          <Box mobStyles={css`justify-content: center; `}>
            <Image src={BronnerSmallLogo} deskStyles={imageStyles} mobStyles={mobImageStyles}/>
          </Box>
        </Box>
        <Box mobStyles={[column]} deskStyles={[row]}>
          <Box mobStyles={[column, mobLicenseBox]} deskStyles={deskLicenseBox}>
            <Text
              mobStyles={mobAccountTypeHeader}
              deskStyles={deskAccountTypeHeader}
            >
              Models
            </Text>
            <Text mobStyles={mobAttentionText} deskStyles={deskAttentionText}>
              Instructors and Exhibitors are demonstrating and displaying new
              products and techniques that may require them to cut and/or color
              your hair as well as apply chemicals, cleansers and/or make up to
              your hair and skin including your nails. If selected, you will be
              contacted by the exhibitor or instructor directly via Book A
              Model.
            </Text>
          </Box>
          <Box mobStyles={[column, mobLicenseBox]} deskStyles={deskLicenseBox}>
            <Text
              mobStyles={mobAccountTypeHeader}
              deskStyles={deskAccountTypeHeader}
            >
              Exhibitors & Instructors
            </Text>
            <Text mobStyles={mobAttentionText} deskStyles={deskAttentionText}>
              Instructors and Exhibitors, there is a flat rate{' '}
              <u>per model per day</u>. Negotiations are not permitted for this
              GIG. Once a model has been selected and assigned, you will be
              given their Book A Model contact information to begin working with
              them.
            </Text>
          </Box>
        </Box>
        {View}
        <Box
          deskStyles={[row, css`padding: 0px 50px 0 50px;`]}
          mobStyles={[row, css`padding: 0px 15px 0 15px;`]}
        >
          <Text
            enableMotion
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.11 }}
            mobStyles={mobHeader}
            deskStyles={[
              deskMainHeaderStyles,
              css`
                  justify-content: flex-start;
                  align-items: center;
                  display: flex;
                  flex: 1;
                  cursor: pointer;
                `,
            ]}
            onClick={() => {
              currentUserAuth
                ? history.push('/w/details')
                : history.push('/w/login')
            }}
          >
            <IconContext.Provider
              value={{
                color: theme.colors.primary,
                size: '1.5em',
              }}
            >
              <HiArrowCircleLeft/>
            </IconContext.Provider>
            {currentUserAuth ? 'Your Details' : 'Log In'}
          </Text>
          <Text
            enableMotion
            whileTap={{ scale: 0.9 }}
            whileHover={{ scale: 1.11 }}
            mobStyles={[mobHeader,
              css`
                justify-content: flex-end;
                align-items: center;
                display: flex;
                flex: 1;
                cursor: pointer;
              `,
            ]}
            deskStyles={[
              deskMainHeaderStyles,
              css`
                justify-content: flex-end;
                align-items: center;
                display: flex;
                flex: 1;
                cursor: pointer;
              `,
            ]}
            onClick={() => {
              if(currentUserAuth) {
                if(isModel) {
                  history.push('/w/gigs')
                } else {
                  history.push(ROUTES.VIEW_MY_GIGS.link)
                }
              } else {
                history.push('/w/signup')
              }
            }}
          >
            {currentUserAuth ? (isModel ? 'Apply!' : "View Your Gigs") : 'Sign Up'}
            <IconContext.Provider
              value={{
                color: theme.colors.primary,
                size: '1.5em',
              }}
            >
              <HiArrowCircleRight/>
            </IconContext.Provider>
          </Text>
        </Box>
      </Box>
    </motion.div>
  )
}

const deskMainHeaderStyles = css`
  font-size: 35px;
`

const mobHeader = css`
  height: auto;
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 20px;
  font-weight: bold;
`

const mobAccountTypeHeader = css`
  color: ${p => p.theme.colors.primary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`

const deskAccountTypeHeader = css`
  font-size: 25px;
`

const mobAttentionText = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 16px;
  font-weight: 500;
`

const deskAttentionText = css`
  height: auto;
  width: 100%;
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 20px;
  font-weight: 500;
`

const mobLicenseBox = css`
  width: 100%;
  text-align: left;
  padding: 25px;
`
const deskLicenseBox = css`
  width: 100%;
  text-align: left;
  padding: 10px 50px;
`
const imageStyles = css`
  height: 115px;
  cursor: pointer;
`

const mobImageStyles = css`
  height: 115px;
  max-width: 100px !important;
  cursor: pointer;
`