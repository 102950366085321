import { css } from 'styled-components'
import React, { useState } from 'react'

import {
  Button,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import { MiniAccordion } from '../../../components/MiniAccordion'
import {
  RequestActionType,
  RequestStatus,
  SupportTicketStatus,
  SupportTicketType,
} from '../../../constants/enums'
import { useRecoilValue } from 'recoil'
import { requestActionAccordionState } from '../../../store'
import { TextAreaWithHeader } from '../../../components/Inputs/TextAreaWithHeader'
import { collections, db } from '../../../firebaseApp'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import get from 'lodash/get'
import { InputWithHeader } from '../../../components/Inputs/InputWithHeader'
import { theme } from '../../../theme'
import { getRatingStars } from '../../RatingModule'
import { Box } from '../../../shared/Box'
import firebase from 'firebase/app'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { LoadingButton } from '../../../components/LoadingButton'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'

const {
  mobStatusButton,
  canceledButton,
  declineButton,
  disputeButton,
  ongoingButton,
  completedButton,
  fullOpacity,
} = mobButtonVariants
const { mobButtonGroupHeader } = mobTextVariants
const { deskButtonGroupHeader } = deskTextVariants

export const AwaitingBAMRequest = () => {
  const history = useHistory()

  // One of the users has disputed the request
  return (
    <>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, disputeButton, fullOpacity]}
        disabled={true}
      >
        WAITING
      </Button>
    </>
  )
}

export const DisputedRequest = () => {
  const history = useHistory()

  // One of the users has disputed the request
  return (
    <>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, disputeButton, fullOpacity]}
        disabled={true}
      >
        DISPUTED
      </Button>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 20px 5px 5px 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Available Actions
      </Text>
      <Button
        mobStyles={[mobStatusButton, completedButton]}
        onClick={() => {
          history.push(ROUTES.SUPPORT.link)
        }}
      >
        VIEW SUPPORT TICKET
      </Button>
    </>
  )
}
export const DeclinedRequest = () => {
  // One of the users has disputed the request
  return (
    <>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, declineButton, fullOpacity]}
        disabled={true}
      >
        DECLINED
      </Button>
    </>
  )
}
export const CanceledRequest = () => {
  // One of the users has disputed the request
  return (
    <>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, canceledButton, fullOpacity]}
        disabled={true}
      >
        CANCELED
      </Button>
    </>
  )
}

const mobReviewText = css`
  color: ${p => p.theme.colors.blueGray};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 20px;
  line-height: 20px;
  margin: 20px 5px 5px 5px;
  text-align: center;
`

const deskReviewText = css`
  font-size: 24px;
  line-height: 24px;
`

export const CompletedRequest = ({
  booking,
  currentUserAuth,
  updateAccordionState,
}) => {
  const { userInformation, activeRequests, gigs } = collections
  const accordionState = useRecoilValue(requestActionAccordionState)
  const disputeAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.dispute
  const reviewAccordionOpen =
    accordionState.requestId === booking.request.requestId &&
    accordionState.type === RequestActionType.review

  const [reviewLoading, setReviewLoading] = useState(false)
  const [disputeLoading, setDisputeLoading] = useState(false)

  const [disputeText, setDisputeText] = useState('')
  const [reviewText, setReviewText] = useState('')
  const [reviewRating, setReviewRating] = useState(null)

  const isGigOwner = booking.request.eventCreator === currentUserAuth.uid
  const isGigOperative = booking.request.operative === currentUserAuth.uid

  // Users can leave reviews independently of each other
  const showReviewForm =
    (isGigOwner && !booking.request.ownerRatingForOperativeComplete) ||
    (isGigOperative && !booking.request.operativeReviewForOwner)

  // Both users cannot dispute the same request at the same time
  const showDisputeForm = !booking.request.disputed

  const handleDisputeCreation = () => {
    setDisputeLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()

    collections.supportTickets
      .add({
        createdBy: currentUserAuth.uid,
        supportRequest: disputeText,
        requestType: SupportTicketType.dispute,
        status: SupportTicketStatus.received,
        createdAt: serverTimestamp,
        relatedUser: booking.request.getUserB(currentUserAuth.uid),
      })
      .then(supportTicketRef => {
        const batch = db.batch()
        const requestReference = activeRequests.doc(requestId)
        batch.set(
          requestReference,
          {
            status: RequestStatus.disputed,
            disputedAt: serverTimestamp,
            disputedBy: currentUserAuth.uid,
            supportTicketId: supportTicketRef.id,
          },
          { merge: true }
        )

        batch.commit().catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_USER_DISPUTING',
            message: `Failed to dispute request id: ${requestId}`,
            type: errorTypes.dispute,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'CompletedRequest.jsx',
          })
        })

        setDisputeLoading(false)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_DISPUTE_CREATION',
          message:
            'Error while user trying to create a SUPPORT Dispute request.',
          type: errorTypes.support,
          file: 'CompletedRequest.jsx',
        })
      })
  }

  const handleReview = () => {
    setReviewLoading(true)
    const requestId = get(booking, 'request.requestId')
    const serverTimestamp = firebase.firestore.Timestamp.now()
    const requestReference = activeRequests.doc(requestId)
    const userWritingReview = currentUserAuth.uid
    const isGigOwner = booking.request.isOwner(userWritingReview)

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(activeRequests.doc(requestId))
        .then(() => {
          if (isGigOwner) {
            transaction.set(
              requestReference,
              {
                ownerReviewForOperative: reviewText,
                ownerReviewedAt: serverTimestamp,
                ownerRatingForOperative: Number(reviewRating),
                ownerRatingForOperativeComplete: true,
              },
              { merge: true }
            )
          } else {
            transaction.set(
              requestReference,
              {
                operativeReviewForOwner: reviewText,
                operativeReviewedAt: serverTimestamp,
                operativeRatingForOwner: Number(reviewRating),
                operativeRatingForOwnerComplete: true,
              },
              { merge: true }
            )
          }
        })
        .then(() => {
          setReviewLoading(false)
        })
        .catch(error => {
          // errorHandler({
          //   error,
          //   errorId: 'ERROR_DISABLING_PROFILE_TOUR',
          //   message: 'Error while disabling the profile tour.',
          //   type: errorTypes.onboarding,
          //   user: user && user.uid,
          //   file: 'BAMProfileOnboarding.jsx',
          // })
        })
    })
  }

  return (
    <>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, completedButton, fullOpacity]}
        disabled={true}
      >
        COMPLETED
      </Button>
      {!showReviewForm && (
        <>
          <Button
            mobStyles={[mobStatusButton, ongoingButton, fullOpacity]}
            disabled={true}
          >
            REVIEW COMPLETE
          </Button>
          <Box
            mobStyles={css`
              width: 100%;
              justify-content: center;
              margin-top: 20px;
            `}
          >
            {getRatingStars(
              isGigOwner
                ? booking.request.ownerRatingForOperative
                : booking.request.operativeRatingForOwner,
              theme,
              '2.75em'
            )}
          </Box>
          <Text mobStyles={mobReviewText} deskStyles={deskReviewText}>
            {isGigOwner
              ? booking.request.ownerReviewForOperative
              : booking.request.operativeReviewForOwner}
          </Text>
        </>
      )}
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 20px 5px 5px 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Available Actions
      </Text>

      {showReviewForm && (
        <>
          {reviewLoading && !disputeLoading && (
            <LoadingButton
              mobStyles={[ongoingButton]}
              deskStyles={[
                css`
                  width: unset;
                `,
              ]}
            />
          )}
          {!reviewLoading && !disputeLoading && (
            <MiniAccordion
              closedButtonText="LEAVE REVIEW"
              openButtonText="CANCEL"
              onClick={() => {
                updateAccordionState(RequestActionType.review)
              }}
              open={reviewAccordionOpen}
              buttonMobStyles={[mobStatusButton, ongoingButton]}
            >
              <StarInput
                reviewRating={reviewRating}
                setReviewRating={setReviewRating}
              />
              <TextAreaWithHeader
                header="REVIEW"
                inputName="review"
                onChange={event => {
                  setReviewText(event.target.value)
                }}
                value={reviewText}
                placeholderDefault="How was the experience?"
              />
              <Button
                mobStyles={[mobStatusButton, completedButton]}
                onClick={handleReview}
                disabled={reviewRating === null}
              >
                SUBMIT
              </Button>
            </MiniAccordion>
          )}
        </>
      )}
      {showDisputeForm && (
        <>
          {disputeLoading && !reviewLoading && (
            <LoadingButton
              mobStyles={[disputeButton]}
              deskStyles={[
                css`
                  width: unset;
                `,
              ]}
            />
          )}
          {!reviewLoading && !disputeLoading && (
            <MiniAccordion
              closedButtonText="DISPUTE"
              openButtonText="CLOSE"
              onClick={() => {
                updateAccordionState(RequestActionType.dispute)
              }}
              open={disputeAccordionOpen}
              buttonMobStyles={[mobStatusButton, disputeButton]}
            >
              <TextAreaWithHeader
                header="DISPUTE"
                inputName="dispute"
                onChange={event => {
                  setDisputeText(event.target.value)
                }}
                value={disputeText}
                placeholderDefault="Tell us what happened."
              />
              <Button
                mobStyles={[mobStatusButton, disputeButton]}
                onClick={handleDisputeCreation}
                disabled={disputeText.length < 50}
              >
                SUBMIT DISPUTE
              </Button>
            </MiniAccordion>
          )}
        </>
      )}
    </>
  )
}

export const UnknownRequest = ({ booking, currentUserAuth }) => {
  // One of the users has disputed the request
  return (
    <>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Status
      </Text>
      <Button
        mobStyles={[mobStatusButton, canceledButton, fullOpacity]}
        disabled={true}
      >
        ERROR
      </Button>
      <Text
        mobStyles={[
          mobButtonGroupHeader,
          css`
            margin: 20px 5px 5px 5px;
          `,
        ]}
        deskStyles={deskButtonGroupHeader}
      >
        Available Actions
      </Text>
      <Button mobStyles={[mobStatusButton, canceledButton]} onClick={() => {}}>
        CREATE SUPPORT TICKET
      </Button>
    </>
  )
}

const StarInput = ({ reviewRating, setReviewRating }) => {
  return (
    <>
      <Box
        mobStyles={css`
          width: 100%;
          justify-content: center;
          margin-top: 20px;
        `}
      >
        {getRatingStars(reviewRating, theme, '2.75em')}
      </Box>
      <br />
      <InputWithHeader
        header="RATING"
        inputName="review-rating"
        type="number"
        onChange={event => {
          const rating = event.target.value
          if (rating > 100) {
            setReviewRating(100)
          } else if (rating < 0) {
            setReviewRating(0)
          } else {
            setReviewRating(rating)
          }
        }}
        value={reviewRating}
        placeholderDefault="0 to 100"
        step={'5'}
        min="0"
        max="100"
      />
    </>
  )
}
