import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons'
import algoliasearch from 'algoliasearch'
import { FaSearch } from 'react-icons/fa'
import { css, useTheme } from 'styled-components'

import {
  Box,
  Input,
  inputVariants,
  mobBoxVariants,
  Select,
  selectVariants,
} from '../../shared/index'
import { AccountType, Gender, SearchType } from '../../constants/enums'
import { useRecoilState, useRecoilValue } from 'recoil'
import { searchState, searchTypeState } from '../../store'
import { useDebouncedCallback } from 'use-debounce'

const { mobSearchBar, row } = mobBoxVariants
const { mobSearchBarInput } = inputVariants
const { mobSearchSelectInput } = selectVariants

export const searchDropdownOptions = [
  { value: SearchType.creative, label: 'Users' },
  { value: SearchType.images, label: 'Images', disabled: true },
  { value: SearchType.gig, label: 'Gigs' },
  { value: SearchType.education, label: 'Learning', disabled: true },
]

/**
 * This component takes input from users and passes it to the Algolia search index.
 *
 * Heavily Documenting the Search Components due to complex and how foundational Search is
 *
 * @param props
 * @constructor
 */
export const SearchBar = ({ currentUserAuth }) => {
  const theme = useTheme()

  const [search, setSearch] = useRecoilState(searchState)
  const [searchInput, setSearchInput] = useState(
    search.query ? search.query : null
  )

  const { indexName, placeholder } = useRecoilValue(searchTypeState)
  // This key is safe to use on the client
  // https://www.algolia.com/apps/VSAAK8S3A7/api-keys/all
  const algoliaClient = algoliasearch(
    'VSAAK8S3A7',
    '1134b5bc298300d15c865195eb0a05f4'
  )
  const index = algoliaClient.initIndex(indexName)

  useEffect(() => {
    // If the type or query changes clear hits
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        hits: [],
      }
    })

    index
      .search(search.query, {
        filters: buildSearchFilter(search, currentUserAuth),
        aroundLatLng: buildGivenLocationFilter(search),
        // aroundRadius: 100, // 40.2336 km === 25 miles
        aroundLatLngViaIP: search.nearMe,
      })
      .then(response => {
        setSearch(previousSearch => {
          return {
            ...previousSearch,
            hits: response.hits,
          }
        })
      })
  }, [
    search.type,
    search.query,
    search.gender,
    search.rate,
    search.height,
    search.weight,
    search.accountType,
    search.givenLocation,
    search.nearMe,
    search.eventDate,
    search.fixedPrice,

    // FIXME: Temporary filters
    search.bronnerBrosIBS,
  ])

  const handleSearchTypeChange = event => {
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        type: Number(event.target.value),
      }
    })
  }
  const debounced = useDebouncedCallback(
    value => {
      setSearch(previousSearch => {
        return {
          ...previousSearch,
          query: value,
        }
      })
    },
    1000
    // , { maxWait: 2500 }
  )

  return (
    <Box
      mobStyles={[
        mobSearchBar,
        css`
          margin: 20px;
        `,
      ]}
    >
      <Box
        mobStyles={[
          row,
          css`
            width: 40%;
          `,
        ]}
      >
        <Select
          mobStyles={mobSearchSelectInput}
          onChange={handleSearchTypeChange}
          value={search.type}
          id="search-type-select"
          options={searchDropdownOptions}
        />
        <Box
          mobStyles={[
            css`
              font-size: 15px;
              box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1);
              align-items: center;
              padding: 0px 10px 0px 5px;
            `,
          ]}
        />
      </Box>
      <Box
        mobStyles={[
          row,
          css`
            width: 60%;
          `,
        ]}
      >
        <Box
          mobStyles={[
            css`
              font-size: 15px;
              align-items: center;
              padding: 0px 10px 0px 10px;
            `,
          ]}
        >
          <IconContext.Provider
            value={{
              color: theme.colors.gray,
            }}
          >
            <FaSearch />
          </IconContext.Provider>
        </Box>
        <Input
          type="select"
          mobStyles={[mobSearchBarInput]}
          placeholder={placeholder}
          value={searchInput}
          onChange={e => {
            setSearchInput(e.target.value)
            debounced.callback(e.target.value)
          }}
        />
      </Box>
    </Box>
  )
}

const buildGivenLocationFilter = search => {
  // aroundLatLng
  // '40.71, -74.01'
  if (search.givenLocation.lat && search.givenLocation.lng) {
    return `${search.givenLocation.lat}, ${search.givenLocation.lng}`
  } else {
    return ''
  }
}

const buildSearchFilter = (search, currentUserAuth) => {
  if (search.type === SearchType.creative) {
    const filters = [
      buildVisibilityFilter(),
      buildNotCurrentUserFilter(currentUserAuth),
      buildGenderFilter(search),
      buildWeightFilter(search),
      buildAccountTypeFilter(search),
      buildRateFilter(search),
      buildHeightFilter(search),
    ]
      .filter(filter => filter !== '')
      .join(' AND ')

    return filters
  } else if (search.type === SearchType.gig) {
    const filters = [
      buildEventDateFilter(search),
      buildFixedPriceFilter(search),
      buildBronnerFilter(search),
    ]
      .filter(filter => filter !== '')
      .join(' AND ')

    return filters
  }
}

const buildBronnerFilter = search => {
  if (search.bronnerBrosIBS) {
    return `(bronnerBrosIBS=1)`
  } else {
    return ''
  }
}

const buildFixedPriceFilter = search => {
  if (search.fixedPrice) {
    return `(fixedPriceGig=1)`
  } else {
    return ''
  }
}

const buildVisibilityFilter = () => {
  return `(visibleInSearch=1)`
}

/**
 * Does not return current user
 *
 * @param currentUserAuth
 * @returns {string}
 */
const buildNotCurrentUserFilter = currentUserAuth => {
  if (currentUserAuth) {
    return `(NOT objectID:${currentUserAuth.uid})`
  } else {
    return ``
  }
}

const buildGenderFilter = search => {
  let genderFilters = []
  for (let genderFilter in search.gender) {
    if (
      search.gender.hasOwnProperty(genderFilter) &&
      search.gender[genderFilter]
    ) {
      genderFilters.push(`profileDetails.gender=${Gender[genderFilter]}`)
    }
  }
  if (genderFilters.length <= 0) {
    return ''
  } else if (genderFilters.length === 1) {
    return `(${genderFilters[0]})`
  } else {
    return `(${genderFilters.join(' OR ')})`
  }
}

const buildRateFilter = search => {
  if (search.rate.min && search.rate.max) {
    return `(profileDetails.hourlyRate: ${search.rate.min} TO ${search.rate.max})`
  } else if (search.rate.min) {
    return `(profileDetails.hourlyRate > ${search.rate.min})`
  } else if (search.rate.max) {
    return `(profileDetails.hourlyRate < ${search.rate.max})`
  } else {
    return ''
  }
}

const buildHeightFilter = search => {
  if (search.height.min && search.height.max) {
    return `(profileDetails.height: ${search.height.min} TO ${search.height.max})`
  } else if (search.height.min) {
    return `(profileDetails.height >= ${search.height.min})`
  } else if (search.height.max) {
    return `(profileDetails.height <= ${search.height.max})`
  } else {
    return ''
  }
}

const buildWeightFilter = search => {
  if (search.weight.min && search.weight.max) {
    return `(profileDetails.weight: ${search.weight.min} TO ${search.weight.max})`
  } else if (search.weight.min) {
    return `(profileDetails.weight >= ${search.weight.min})`
  } else if (search.weight.max) {
    return `(profileDetails.weight <= ${search.weight.max})`
  } else {
    return ''
  }
}

const buildAccountTypeFilter = search => {
  let accountTypeFilters = []
  for (let accountTypeFilter in search.accountType) {
    if (
      search.accountType.hasOwnProperty(accountTypeFilter) &&
      search.accountType[accountTypeFilter]
    ) {
      accountTypeFilters.push(`accountType=${AccountType[accountTypeFilter]}`)
    }
  }
  if (accountTypeFilters.length <= 0) {
    return ''
  } else if (accountTypeFilters.length === 1) {
    return `(${accountTypeFilters[0]})`
  } else {
    return `(${accountTypeFilters.join(' OR ')})`
  }
}

const buildEventDateFilter = search => {
  if (search.eventDate.start && search.eventDate.end) {
    const startSeconds = search.eventDate.start.getTime() / 1000
    const endSeconds = search.eventDate.end.getTime() / 1000
    return `time.date._seconds: ${startSeconds} TO ${endSeconds}`
  } else if (search.eventDate.start) {
    const startDate = new Date(search.eventDate.start.setHours(0, 0, 0, 0))
    const endDate = new Date(search.eventDate.start.setHours(23, 59, 59, 999))
    const startSeconds = Math.floor(startDate.getTime() / 1000)
    const endSeconds = Math.floor(endDate.getTime() / 1000)

    return `time.date._seconds: ${startSeconds} TO ${endSeconds}`
  } else {
    return ''
  }
}
