import styled, { css } from 'styled-components'
import { variantsAndStyles } from '../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

const buttonStyles = css`
  border: none;
  box-sizing: border-box;

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: thin dotted ${p => p.theme.colors.primary};
  }

  ${variantsAndStyles()}
`

export const StyledButton = styled.button`
  ${buttonStyles}
`

export const StyledMotionButton = styled(motion.button)`
  ${buttonStyles}
`
