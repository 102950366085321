import React from 'react'
import { css } from 'styled-components'

import {
  Box,
  Heading,
  headingVariants,
  Image,
  mobBoxVariants,
  mobButtonVariants,
} from '../../../shared/index'
import ImageBg from '../../../assets/photos/community_module_bg.png'

const { column, center } = mobBoxVariants
const { mobLong305x46, transparentBgWhiteBorder } = mobButtonVariants
const { whiteBold } = headingVariants

const mobModuleWrapper = css`
  background-image: url(${ImageBg});
  height: auto;
  background-position: left;
  background-size: cover;
  background-size: 100vw 50vw;
`

const deskModuleWrapper = css`
  height: 600px;
`

const mobButtonStyle = css`
  color: ${p => p.theme.colors.secondary};
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 52px;
  letter-spacing: 2.4px;
`

const mobSubHeading = css`
  color: ${p => p.theme.colors.secondary};
  font-size: 15px;
  font-family: ${p => p.theme.fonts.secondary};
  margin-bottom: 10px;
`

const deskSubHeading = css`
  color: ${p => p.theme.colors.secondary};
  font-size: 40px;
  width: 600px;
  font-family: ${p => p.theme.fonts.secondary};
  margin-bottom: 10px;
`

const deskHeading = css`
  color: ${p => p.theme.colors.secondary};
  font-size: 55px;
  width: 600px;
  font-family: ${p => p.theme.fonts.secondary};
  margin-bottom: 10px;
`

const mobHeadingWrapper = css`
  margin-top: 45px;
  margin-bottom: 10px;
`

const itemSpacing = css`
  padding: 12px;
  max-width: 1200px;
`

export const GlobalCommunityModule = () => {
  return (
    <Box
      mobStyles={[column, center, mobModuleWrapper]}
      deskStyles={deskModuleWrapper}
    >
      <Box mobStyles={mobHeadingWrapper} deskStyles={itemSpacing}>
        <Heading
          mobStyles={whiteBold}
          fontSize={{ mob: '32px' }}
          deskStyles={deskHeading}
        >
          Our Mission
        </Heading>
      </Box>
      <Box mobStyles={center} deskStyles={itemSpacing}>
        <Heading mobStyles={mobSubHeading} deskStyles={deskSubHeading}>
          We help creatives monetize their content, book gigs and learn about
          the industry.
        </Heading>
      </Box>
      <Box deskStyles={itemSpacing}></Box>
      <Image />
    </Box>
  )
}

// <Button
//   mobStyles={[mobLong305x46, transparentBgWhiteBorder, mobButtonStyle]}
// >
//   LEARN MORE
// </Button>
