import React from 'react'
import { mobBoxVariants, mobLinkVariants } from '../../shared/index'
import { css } from 'styled-components'

import { Box } from '../../shared'
import { Link } from '../../shared/Link'
import { MiniAccordion } from '../../components/MiniAccordion'
import { Toggle } from '../../components/Toggle'
import { collections } from '../../firebaseApp'
import firebase from 'firebase/app'
import { User } from '../../classes'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { ManagedUserAccordionType } from '../../constants/enums'
import { useRecoilState } from 'recoil'
import { managedUserAccordionState } from '../../store'

const { column } = mobBoxVariants
const { mobButtonLink } = mobLinkVariants

export const ManageUserModule = ({ user }) => {
  const [accordionState, setAccordionState] = useRecoilState(
    managedUserAccordionState
  )

  const handleVisibleInSearchChange = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)
          transaction.update(userInformation.doc(user.userId), {
            visibleInSearch: !transactionUser.isVisbleInSearch(),
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_VISIBLE_IN_SEARCH_OPTION',
            message: 'Error while updating visible in search option.',
            type: errorTypes.profile,
            user: user.userId,
            file: 'GeneralSettingsModule.jsx',
          })
        })
    })
  }

  const handleAcceptingBookingRequestsChange = () => {
    const { userInformation } = collections

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInformation.doc(user.userId))
        .then(userDocument => {
          const transactionUser = new User(userDocument)
          transaction.update(userInformation.doc(user.userId), {
            acceptingBookingRequests: !transactionUser.isAcceptingBookingRequests(),
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ACCEPTING_BOOKING_REQUESTS_OPTION',
            message: 'Error while updating accepting booking requests option.',
            type: errorTypes.profile,
            user: user.userId,
            file: 'GeneralSettingsModule.jsx',
          })
        })
    })
  }

  const updateAccordionState = managedUserAccordionType => {
    setAccordionState(previousAccordionState => {
      if (
        previousAccordionState.user === user.userId &&
        previousAccordionState.type === managedUserAccordionType
      ) {
        return {
          ...previousAccordionState,
          user: null,
          type: null,
        }
      } else {
        return {
          ...previousAccordionState,
          user: user.userId,
          type: managedUserAccordionType,
        }
      }
    })
  }

  return (
    <Box
      mobStyles={[
        column,
        css`
          flex: 1 1 auto;
          justify-content: flex-end;
          padding-bottom: 20px;
        `,
      ]}
    >
      <Link to={'/'} mobStyles={[mobButtonLink]}>
        VIEW DETAILS
      </Link>

      <Link
        to={'/update-managed-profile/' + user.userId}
        mobStyles={[
          mobButtonLink,
          css`
            margin-top: 10px;
          `,
        ]}
      >
        EDIT PROFILE
      </Link>

      <MiniAccordion
        closedButtonText="EDIT SETTINGS"
        openButtonText="CLOSE SETTINGS"
        type={ManagedUserAccordionType.settings}
        onClick={() => {
          updateAccordionState(ManagedUserAccordionType.settings)
        }}
        open={
          accordionState.type === ManagedUserAccordionType.settings &&
          accordionState.user === user.userId
        }
        buttonMobStyles={[
          mobButtonLink,
          css`
            margin-top: 10px;
          `,
        ]}
      >
        <Toggle
          label="Search Visibility. Prevent this user from showing in search."
          onChangeHandler={handleVisibleInSearchChange}
          checked={user.visibleInSearch}
        />
        <Toggle
          label="Accepting booking requests."
          onChangeHandler={handleAcceptingBookingRequestsChange}
          checked={user.acceptingBookingRequests}
        />
      </MiniAccordion>
    </Box>
  )
}
