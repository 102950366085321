import React, { useEffect, useState } from 'react'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { functions } from '../../firebaseApp'
import styled, { css } from 'styled-components'
import { convertSeconds } from '../../utils/convertFirestoreTimestamp'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { up } from 'styled-breakpoints'
import { AccountType } from '../../constants/enums'
import moment from 'moment'

const {
  mobGigBoxDescriptionMini,
  mobGigBoxHeader,
  mobHorizontalRule,
  mobPageHeader,
  mobInstructionParagraph,
} = mobTextVariants
const { deskPageHeader, deskInstructionParagraph } = deskTextVariants

const { column, mobWrapper, row, center } = mobBoxVariants

/**
 *
 * @param currentUserAuth
 * @param match
 * @param user {User}
 * @returns {*}
 * @constructor
 */
export const AdminPage = ({ currentUserAuth, match, user, history }) => {
  if (
    user.accountType !== AccountType.admin &&
    user.accountType !== AccountType.developer
  ) {
    history.push('/')
  }

  const today = moment()
  const minus1 = moment(today).subtract(1, 'days')
  const minus2 = moment(minus1).subtract(1, 'days')
  const minus3 = moment(minus2).subtract(1, 'days')
  const minus4 = moment(minus3).subtract(1, 'days')
  const minus5 = moment(minus4).subtract(1, 'days')
  const minus6 = moment(minus5).subtract(1, 'days')
  const minus7 = moment(minus6).subtract(1, 'days')

  const [userInfo, setUserInfo] = useState(null)
  const retrieveUserStats = functions.httpsCallable('retrieveUserStats')

  useEffect(() => {
    if (
      user.accountType === AccountType.admin ||
      user.accountType === AccountType.developer
    ) {
      retrieveUserStats()
        .then(userStat => {
          setUserInfo(userStat.data.users)
        })
        .catch(userStatResultError => {})
    }
  }, [])

  const postCreateDate = userInfo => {
    // let pdate = new Date(Date.parse(userInfo[0].metadata.creationTime))
    return userInfo.map(user => user.metadata.creationTime)
  }

  /**
   *
   * @param userInfo
   * @param date {Moment}
   * @returns {number|*}
   */
  const findUsersCreatedThisDay = (userInfo, date) => {
    let totalCreated = 0
    userInfo.forEach(user => {
      let userCreationDate = moment(Date.parse(user.metadata.creationTime))
      if (moment(date).isSame(userCreationDate, 'day')) {
        totalCreated = totalCreated + 1
      }
    })

    if (totalCreated > 0) {
      return totalCreated
    } else {
      return 0
    }
  }

  return (
    <>
      {userInfo && (
        <Box mobStyles={[column, mobWrapper, center]}>
          <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
            Users
          </Text>
          <BlogPostContainer>
            <JugText>
              TOTAL USERS
              <br />
              <strong>{userInfo.length - 5}</strong>
            </JugText>
          </BlogPostContainer>
          <BlogPostContainer>
            <JugText>
              Sign Ups Today
              <br />
              <strong>{findUsersCreatedThisDay(userInfo, today)}</strong>
            </JugText>
          </BlogPostContainer>

          <JugText>
            Sign Ups ({minus1.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus1)}</strong>
          </JugText>
          <JugText>
            Sign Ups ({minus2.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus2)}</strong>
          </JugText>
          <JugText>
            Sign Ups ({minus3.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus3)}</strong>
          </JugText>
          <JugText>
            Sign Ups ({minus4.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus4)}</strong>
          </JugText>
          <JugText>
            Sign Ups ({minus5.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus5)}</strong>
          </JugText>
          <JugText>
            Sign Ups ({minus6.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus6)}</strong>
          </JugText>
          <JugText>
            Sign Ups ({minus7.format('MM-DD-YYYY')})
            <br />
            <strong>{findUsersCreatedThisDay(userInfo, minus7)}</strong>
          </JugText>
        </Box>
      )}
    </>
  )
}

const JugText = ({ children }) => {
  return (
    <Text
      mobStyles={[mobInstructionParagraph]}
      deskStyles={[
        deskInstructionParagraph,
        css`
          text-align: center;
        `,
      ]}
    >
      {children}
    </Text>
  )
}

const BlogPostContainer = styled.div`
  height: auto;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.lightGray};
  border-radius: 5px;
  background-color: ${p => p.theme.colors.secondary};
  box-shadow: ${p => p.theme.shadow.regular};
  padding: 15px 15px 0px 15px;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 25px;
  ${up('desktop')} {
    width: 50%;
  }
`

const SignatureText = styled.p`
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  flex: 1;
`

const SignatureDate = styled.p`
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
`

const createBlogPost = (title, body, createdAt, index) => {
  return (
    <BlogPostContainer key={index}>
      <Text mobStyles={mobGigBoxHeader}>{title}</Text>
      <Text mobStyles={mobHorizontalRule} />
      <Text mobStyles={mobGigBoxDescriptionMini}>{body}</Text>

      <Box mobStyles={[row]}>
        <SignatureText>- Book A Model Team</SignatureText>
        <SignatureDate>{convertSeconds(createdAt.seconds)}</SignatureDate>
      </Box>
    </BlogPostContainer>
  )
}
