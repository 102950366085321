import * as React from 'react'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { auth, collections } from '../../firebaseApp'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Button } from '../../shared/Button'
import styled, { css } from 'styled-components'
import { Box } from '../../shared/Box'
import { mobTextVariants, Text } from '../../shared/Text'
import firebase from 'firebase/app'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import TimeAgo from 'react-timeago'
import { useHistory } from 'react-router-dom'
import { up } from 'styled-breakpoints'
import { useRecoilState } from 'recoil'
import { generalState } from '../../store'

const { mobInstructionParagraph } = mobTextVariants

export const Notifications = () => {
  const [appState, setAppState] = useRecoilState(generalState)
  const [currentUserAuth] = useAuthState(auth)
  const [
    userNotifications,
    userNotificationsLoading,
    userNotificationsError,
  ] = useCollectionData(
    collections.notifications
      .doc(currentUserAuth.uid)
      .collection('notifications')
      .where('cleared', '==', false)
      .where('deleted', '==', false),
    {
      idField: 'id',
    }
  )
  const [disableTour, setDisableTour] = useState(false)

  if (
    userNotificationsLoading ||
    userNotificationsError ||
    !appState.showNotifications
  ) {
    return null
  }

  // Only display notifications that have not been cleared or deleted
  const displayNotification = notification => {
    return !notification.cleared && !notification.deleted
  }

  const numberOfUnclearedNotifs = notifications => {
    return notifications.filter(notif => {
      return displayNotification(notif)
    }).length
  }

  // Ok so the notif thingy has 3 states
  // If it is 6th notif or above  AND

  return (
    <>
      {userNotifications && (
        <div className="container">
          {userNotifications.length >= 1}
          <NotificationsContainer>
            <AnimatePresence initial={false}>
              {userNotifications.map((notif, index) => {
                if (index < 4 && displayNotification(notif)) {
                  return buildNotification(notif, currentUserAuth, index)
                }
                if (index === 4) {
                  return (
                    <NotificationBox
                      key={index}
                      positionTransition
                      initial={{ opacity: 0, y: 50, scale: 0.3 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{
                        opacity: 0,
                        scale: 0.5,
                        transition: { duration: 0.2 },
                      }}
                    >
                      <Box
                        mobStyles={[
                          css`
                            flex: 1;
                            flex-direction: column;
                          `,
                        ]}
                      >
                        <Text
                          mobStyles={[
                            mobInstructionParagraph,
                            css`
                              margin: 10px 20px 20px 20px;
                            `,
                          ]}
                        >
                          You have{' '}
                          {numberOfUnclearedNotifs(userNotifications) - 4} more
                          unseen notifications.
                        </Text>
                        <Text
                          mobStyles={[
                            mobInstructionParagraph,
                            css`
                              margin: 10px 20px 20px 20px;
                              cursor: pointer;
                            `,
                          ]}
                          onClick={() => {
                            setAppState(previousAppState => {
                              return {
                                ...previousAppState,
                                showNotifications: false,
                              }
                            })
                          }}
                        >
                          <u>Hide All</u>
                        </Text>
                      </Box>
                      <Box
                        mobStyles={[
                          css`
                            align-items: flex-start;
                            margin: 10px;
                          `,
                        ]}
                      ></Box>
                    </NotificationBox>
                  )
                }
              })}
            </AnimatePresence>
          </NotificationsContainer>
        </div>
      )}
    </>
  )
}

export const NotificationBox = styled(motion.div)`
  width: 300px;
  background: white;
  margin: 10px;
  box-shadow: 0 0 20px 20px rgba(202, 201, 201, 0.5);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  ${up('desktop')} {
    width: 600px;
  }
`

const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;
`

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)

export const CloseButton = ({ close }) => (
  <Button
    onClick={close}
    className="close"
    mobStyles={[
      css`
        background: white;
        border: none;
        outline: none;
        -webkit-appearance: none;
        cursor: pointer;
        border-radius: 10px;
      `,
    ]}
  >
    <svg width="25" height="25" viewBox="0 0 23 23">
      <Path d="M 3 16.5 L 17 2.5" />
      <Path d="M 3 2.5 L 17 16.346" />
    </svg>
  </Button>
)

const buildNotification = (notification, currentUserAuth, index) => {
  const history = useHistory()

  const clearNotification = notificationId => {
    const { notifications } = collections
    const notificationReference = notifications
      .doc(currentUserAuth.uid)
      .collection('notifications')
      .doc(notificationId)

    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(notificationReference)
        .then(notificationDocument => {
          transaction.update(notificationReference, {
            cleared: true,
          })
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_CLEARING_NOTIFICATION',
            message: 'Error while clearing a notification.',
            type: errorTypes.notifications,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'Notifications.jsx',
          })
        })
    })
  }

  return (
    <NotificationBox
      key={index}
      positionTransition
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.2 },
      }}
    >
      <Box
        mobStyles={[
          css`
            flex: 1;
          `,
        ]}
        onClick={() => {
          history.push(notification.link)
        }}
      >
        <Text
          mobStyles={[
            mobInstructionParagraph,
            css`
              margin: 10px 0px 20px 20px;
              cursor: pointer;
            `,
          ]}
        >
          {notification.message}
          <br />
          <br />
          <TimeAgo date={new Date(notification.createdAt.seconds * 1000)} />
        </Text>
      </Box>
      <Box
        mobStyles={[
          css`
            align-items: flex-start;
            margin: 10px;
          `,
        ]}
      >
        <CloseButton
          close={() => {
            clearNotification(notification.id)
          }}
        />
      </Box>
    </NotificationBox>
  )
}
