import styled from 'styled-components'
import { variantsAndStyles } from '../../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

export const StyledTable = styled.table`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${variantsAndStyles()}
`

export const StyledMotionTable = styled(motion.table)`
  font-family: ${p => p.theme.fonts.primary};
  font-weight: normal;
  box-sizing: border-box;

  ${variantsAndStyles()}
`
