import { Box, deskBoxVariants, mobBoxVariants } from '../../shared/Box'
import { AvailabilityModule } from '../../modules/AvailabilityModule'
import { Text } from '../../shared/Text'
import { Image, mobImageVariants } from '../../shared/Image'
import { RequestToBookButton } from './RequestToBookButton'
import React, { useState } from 'react'
import { mobLinkVariants } from '../../shared/Link'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'
import { ProfileDetailsModule } from '../../modules/ProfileDetailsModule'
import { RatingModule, RatingPosition } from '../../modules/RatingModule'
import { css } from 'styled-components'
import { MessageBox } from '../../components/MessageBox'
import { useRecoilValue } from 'recoil'
import { generalState } from '../../store'
import { Button, mobButtonVariants } from '../../shared/Button'
import { deskButtonVariants } from '../../shared/Button/deskButtonVariants'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { ProfileOnboarding } from '../../components/BookAModelOnboarding'
import { Modal } from '../../shared/Modal'
import { ActiveGigs } from './ActiveGigs'
import { AdminSection } from './AdminSection'
import { AccountType } from '../../constants/enums'
import { collections } from '../../firebaseApp'
import firebase from 'firebase/app'
import { errorHandler, errorTypes } from '../../utils/errorHandler'

const { row, column, mobMultiImageWrapper } = mobBoxVariants
const { mobImageSquare } = mobImageVariants
const { mobProfileLink } = mobLinkVariants
const { deskWrapper, deskProfileDetailsContainer } = deskBoxVariants
const {
  deskProfileName,
  deskProfileLocation,
  deskPageHeader,
  textCenter,
  fullWidth,
} = deskTextVariants
const { deskProfilePhoto, deskImageSquare } = deskImageVariants
const { center, mobBoxSpacer } = mobBoxVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskSubmitButton } = deskButtonVariants

/**
 *
 * @param {User} user
 * @param currentUserAuth
 * @param portfolioImagesData
 * @param updateBookingCart
 * @constructor
 */
export const DeskProfileModule = ({
  user,
  currentUserAuth,
  portfolioImagesData,
  updateBookingCart,
  userAccountType,
  viewingOwnProfile,
}) => {
  const appState = useRecoilValue(generalState)
  const history = useHistory()

  return (
    <>
      <ProfileOnboarding user={user} />
      <Box mobStyles={[row]} deskStyles={deskWrapper}>
        <Image
          src={user.getProfileImage()}
          deskStyles={[deskProfilePhoto]}
          id="user-profile-image"
        />
        <div id="user-details-box">
          <Box deskStyles={[column, deskProfileDetailsContainer]}>
            <Text deskStyles={deskProfileName}>{user.fullName}</Text>
            <Text deskStyles={deskProfileLocation}>{user.getUserCity()}</Text>
            <RatingModule
              rating={user.rating}
              ratingPosition={RatingPosition.left}
            />
            <ProfileDetailsModule user={user} publicProfile={true} />
          </Box>
        </div>
        <Box
          deskStyles={css`
            flex: 1;
            justify-content: flex-end;
            margin-top: 25px;
          `}
        >
          <AvailabilityModule currentUserAuth={currentUserAuth} user={user} />
        </Box>
      </Box>
      <Box mobStyles={[column]} deskStyles={deskWrapper}>
        <RequestToBookButton
          user={user}
          updateBookingCart={updateBookingCart}
          currentUserAuth={currentUserAuth}
        />
      </Box>
      <Box mobStyles={[column]} deskStyles={deskWrapper}>
        <AdminSection user={user} currentUserAuth={currentUserAuth} />
      </Box>
      {viewingOwnProfile && (
        <Box mobStyles={[column]} deskStyles={deskWrapper}>
          <Box mobStyles={[center, mobBoxSpacer]}>
            <Button
              enableMotion
              animate={{
                scale: [0.95, 1, 0.95],
              }}
              transition={{
                duration: 2.5,
                ease: 'easeInOut',
                loop: Infinity,
              }}
              mobStyles={[mobSubmitButton, mobLong305x46]}
              deskStyles={deskSubmitButton}
              onClick={() => {
                history.push(ROUTES.UPDATE_PROFILE.link)
              }}
            >
              UPDATE PROFILE
            </Button>
          </Box>
        </Box>
      )}

      <ActiveGigs user={user} currentUserAuth={currentUserAuth} />

      <Box
        mobStyles={[column]}
        deskStyles={deskWrapper}
        id="user-portfolio-images-text"
      >
        <Text
          mobStyles={[mobProfileLink]}
          deskStyles={[deskPageHeader, fullWidth, textCenter]}
        >
          PORTFOLIO IMAGES
        </Text>
        {portfolioImagesData.length <= 0 && (
          <MessageBox
            header="NO PORTFOLIO IMAGES ADDED"
            message={
              user.viewingOwnProfile(currentUserAuth.uid)
                ? 'You have not added any portfolio images. Visit the update profile page to add a few!.'
                : 'This user has not added any portfolio images yet. Check back soon!.'
            }
          />
        )}
        {portfolioImagesData.length > 0 && (
          <Box mobStyles={mobMultiImageWrapper}>
            {portfolioImagesData.map((imageDocument, index) => {
              return (
                <PortfolioImageWithModal
                  user={user}
                  imageDocument={imageDocument}
                  index={index}
                  userAccountType={userAccountType}
                />
              )
            })}
          </Box>
        )}
      </Box>
    </>
  )
}

export const PortfolioImageWithModal = ({
  imageDocument,
  index,
  user,
  userAccountType,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const deleteButtonStyles = css`
    background-color: ${p => p.theme.colors.red};
    width: 75%;
    margin-top: 20px;
  `

  const adminDeletePortfolio = (userId, imageDocumentId) => {
    // Modal must be closed before deletion or it will lock the scroll on the document body
    setModalOpen(false)
    const { userInformation } = collections
    userInformation
      .doc(userId)
      .collection('portfolioImages')
      .doc(imageDocumentId)
      .set(
        {
          deleted: true,
          deletedAt: firebase.firestore.Timestamp.now(),
        },
        { merge: true }
      )
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_ADMIN_PID',
          message: 'There was an error.',
          type: errorTypes.profile,
          userId: 'admin',
          file: 'PortfolioImageWithModal.jsx',
        })
      })
  }

  return (
    <>
      <Image
        enableUseAsBackground={true}
        key={index}
        src={
          imageDocument.largeSizeUrl
            ? imageDocument.largeSizeUrl
            : imageDocument.croppedUrl
        }
        mobStyles={[mobImageSquare]}
        deskStyles={[deskImageSquare]}
        onClick={() => setModalOpen(!modalOpen)}
      />
      <Modal
        isOpen={modalOpen}
        handleCloseModal={() => {
          setModalOpen(false)
        }}
      >
        <Box
          mobStyles={[
            column,
            center,
            css`
              width: 100%;
            `,
          ]}
        >
          <Image
            src={imageDocument.url}
            deskStyles={[
              css`
                max-height: 75%;
              `,
            ]}
            mobStyles={[
              css`
                max-width: 95%;
              `,
            ]}
            onClick={() => setModalOpen(!modalOpen)}
          />
          {userAccountType &&
            (userAccountType === AccountType.admin ||
              userAccountType === AccountType.developer) && (
              <Button
                mobStyles={[mobSubmitButton, deleteButtonStyles]}
                deskStyles={deskSubmitButton}
                onClick={() => {
                  adminDeletePortfolio(user.userId, imageDocument.id)
                }}
              >
                DELETE PHOTO
              </Button>
            )}
        </Box>
      </Modal>
    </>
  )
}

// TODO: Keep for when we enable license image
/*
<br />
<ActiveEvents user={user} currentUserAuth={currentUserAuth} />
<Text
  enableMotion
  whileHover={{
    scale: 1.2,
    transition: { duration: 1 },
  }}
  whileTap={{ scale: 0.9 }}
  mobStyles={[mobProfileLink]}
  onClick={redirectToUserLicenseImages}
>
  CLICK TO VIEW IMAGES AVAILABLE FOR LICENSING
</Text>


*/
