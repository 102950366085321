import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'
import React from 'react'
import { StyledForm, StyledMotionForm } from './styles'

export const Form = props => {
  if (props.enableMotion) {
    return <StyledMotionForm {...props}>{props.children}</StyledMotionForm>
  }

  return <StyledForm {...props}>{props.children}</StyledForm>
}

Form.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
