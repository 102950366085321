import { Box, mobBoxVariants } from '../../shared/Box'
import { css } from 'styled-components'
import React from 'react'
import { EmptyChatInboxText } from './MoneyRequestPage'
import { useRecoilValue } from 'recoil'
import { activeRequestState } from '../../store/states/activeRequestState'
import { MoneyRequestOverviewPage } from './MoneyRequestOverviewPage'

const { column, row } = mobBoxVariants

export const MoneyRequestDetailsModule = ({
  allMoneyRequests,
  getSortedDesktopMoneyRequests,
  currentUserAuth,
}) => {
  const activeRequest = useRecoilValue(activeRequestState)

  return (
    <Box
      deskStyles={[
        row,
        css`
          min-height: 92.5vh;
        `,
      ]}
    >
      <Box mobStyles={[column, deskChatItemSection]}>
        {allMoneyRequests.length <= 0 && (
          <EmptyChatInboxText>No Money Request.</EmptyChatInboxText>
        )}
        {getSortedDesktopMoneyRequests(allMoneyRequests)}
      </Box>
      <Box mobStyles={[column, deskChatThreadSection]}>
        {activeRequest && (
          <MoneyRequestOverviewPage
            currentUserAuth={currentUserAuth}
            activeRequest={activeRequest}
          />
        )}
      </Box>
    </Box>
  )
}

const deskChatItemSection = css`
  flex: 3;
  overflow: auto;
  max-height: 100vh;
`
const deskChatThreadSection = css`
  flex: 8;
  box-shadow: ${p => p.theme.shadow.regular};
  overflow: auto;
  max-height: 100vh;
`
