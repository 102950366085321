import React from 'react'
import { uuid } from '../../utils/uuid'
import { useCollection } from 'react-firebase-hooks/firestore'

import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/'
import { collections } from '../../firebaseApp'
import { StickyFooterWithButton } from '../../components/StickyFooter'
import { LoadingScreen } from '../../components/LoadingScreen'
import { ProfileBox, ProfileBoxType } from '../../components/ProfileBox'
import { ROUTES } from '../../constants/routes'
import { ManageUserModule } from '../../modules'
import { ManagedUserKeyInfoModule } from '../../modules/ManagedUserKeyInfoModule'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { User } from '../../classes'
import { MessageBox } from '../../components/MessageBox'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { mobInformationalBox } = mobBoxVariants
const { deskPageHeader } = deskTextVariants

export const ManageUsersPage = ({ history, currentUserAuth }) => {
  const [
    allManagedUsersSnapshot,
    allManagedUsersSnapshotLoading,
    allManagedUsersSnapshotError,
  ] = useCollection(
    collections.userInformation.where('managedBy', '==', currentUserAuth.uid)
  )

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          Manage Your Creatives{' '}
        </Text>
        <Text mobStyles={mobInstructionParagraph}>
          Here you can create agency managed creative accounts. These accounts
          function just like normal creative accounts. They can be booked and
          you can create proposals on their behalf.
        </Text>
        {allManagedUsersSnapshotError && (
          <strong>Error: {allManagedUsersSnapshotError}</strong>
        )}
        {allManagedUsersSnapshotLoading && <LoadingScreen />}
        {allManagedUsersSnapshot && (
          <>
            {allManagedUsersSnapshot.empty && (
              <MessageBox
                header="No Creatives"
                message="Creatives you create and manage will appear here."
              />
            )}
            {allManagedUsersSnapshot.docs.map(userSnapshot => (
              <Box
                to={'/profile/' + userSnapshot.id}
                mobStyles={[mobInformationalBox, column]}
                key={uuid()}
              >
                <ProfileBox
                  user={new User(userSnapshot)}
                  boxType={ProfileBoxType.MiniHorizontal}
                />
                <ManagedUserKeyInfoModule user={new User(userSnapshot)} />
                <ManageUserModule user={new User(userSnapshot)} />
              </Box>
            ))}
          </>
        )}
      </Box>

      <StickyFooterWithButton
        mainButtonHandler={() => {
          history.push(ROUTES.CREATE_CREATIVE.link)
        }}
        mainButtonText="NEW CREATIVE"
      />
    </DesktopPageWrapper>
  )
}
