import React, { useState } from 'react'
import 'firebase/storage'
import { css } from 'styled-components'

import { auth } from '../../firebaseApp'
import {
  Box,
  Button,
  mobBoxVariants,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import {
  CompletedIcon,
  mobTaskText,
  NotCompletedIcon,
} from '../GeneralSettingsModule'
import { LoadingButton } from '../../components/LoadingButton'

const { mobSubmitButton } = mobButtonVariants
const { mobInstructionParagraph } = mobTextVariants
const { deskSubmitButton } = deskTextVariants
const { row } = mobBoxVariants
const { mobLong305x46 } = mobLinkVariants
const acceptColor = css`
  background-color: #70bd74;
  color: #ffffff;
`

/**
 *
 * @param currentUserAuth
 * @param {AccountSettingsForm} accountSettingsForm
 * @returns {*}
 * @constructor
 */
export const AccountEmailModule = ({
  currentUserAuth,
  accountSettingsForm,
}) => {
  const [verifyEmailSent, setVerifyEmailSent] = useState(false)
  const [emailUpdated, setEmailUpdated] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [emailUpdating, setEmailUpdating] = useState(false)

  const submitUpdateEmailAddress = () => {
    if (accountSettingsForm.newEmailAddress) {
      setEmailUpdating(true)
      auth
        .signInWithEmailAndPassword(
          currentUserAuth.email,
          accountSettingsForm.currentPassword
        )
        .then(() => {
          currentUserAuth
            .updateEmail(accountSettingsForm.newEmailAddress)
            .then(result => {
              setEmailUpdated(true)
              setEmailUpdating(false)

              setTimeout(() => {
                setEmailUpdated(false)
              }, 2000)
            })
            .catch(error => {
              errorHandler({
                error,
                errorId: 'ERROR_EMAIL_UPDATE',
                message: 'Error while user updating email',
                type: errorTypes.auth,
                user: currentUserAuth && currentUserAuth.uid,
                file: 'AccountEmailModule.jsx',
              })
            })
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_EMAIL_UPDATE_LOGIN',
            message:
              'Error occurred while user trying to authenticate to change email',
            type: errorTypes.auth,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'AccountEmailModule.jsx',
          })
        })
    }
  }

  const sendEmailVerificationEmail = event => {
    if (currentUserAuth.email && !currentUserAuth.emailVerified) {
      setEmailSending(true)
      currentUserAuth
        .sendEmailVerification()
        .then(result => {
          setEmailSending(false)
          setVerifyEmailSent(true)
          setTimeout(() => {
            setVerifyEmailSent(false)
          }, 2000)
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_ACCOUNT_VERIFICATION_EMAIL',
            message: 'Error occurred while trying to send verifications email.',
            type: errorTypes.auth,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'AccountEmailModule.jsx',
          })
        })
    }
  }

  return (
    <>
      <Text mobStyles={mobInstructionParagraph}>
        Your email address & phone number are used to send you important
        information, reset password, etc.
      </Text>
      <Box
        mobStyles={[
          row,
          css`
            width: 100%;
          `,
        ]}
      >
        <Text mobStyles={mobTaskText}>Email Verified</Text>
        <label style={{ marginTop: '5px' }}>
          {currentUserAuth.emailVerified ? CompletedIcon : NotCompletedIcon}{' '}
        </label>
      </Box>

      {emailSending && (
        <LoadingButton
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              margin: 30px 0;
            `,
          ]}
        />
      )}
      {!verifyEmailSent && !emailSending && !currentUserAuth.emailVerified && (
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              margin: 30px 0;
            `,
          ]}
          deskStyles={deskSubmitButton}
          onClick={sendEmailVerificationEmail}
          disabled={currentUserAuth.emailVerified}
        >
          VERIFY EMAIL
        </Button>
      )}
      {verifyEmailSent && !emailSending && (
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            acceptColor,
            css`
              margin: 30px 0;
            `,
          ]}
          deskStyles={deskSubmitButton}
          disabled={currentUserAuth.emailVerified}
        >
          SENT
        </Button>
      )}
      <InputWithHeader
        header="Email"
        inputName="email"
        onChange={accountSettingsForm.updateNewEmailAddress}
        placeholderDefault={currentUserAuth.email || 'Not Set'}
      />
      <InputWithHeader
        header="Password"
        inputName="password"
        type="password"
        autoComplete={true}
        onChange={accountSettingsForm.updateCurrentPassword}
        placeholderDefault="Enter Current Password"
      />

      {emailUpdating && (
        <LoadingButton
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              margin: 30px 0;
            `,
          ]}
        />
      )}
      {!emailUpdated && !emailUpdating && (
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            css`
              margin: 30px 0;
            `,
          ]}
          deskStyles={deskSubmitButton}
          onClick={submitUpdateEmailAddress}
        >
          UPDATE EMAIL
        </Button>
      )}
      {emailUpdated && (
        <Button
          mobStyles={[
            mobSubmitButton,
            mobLong305x46,
            acceptColor,
            css`
              margin: 30px 0;
            `,
          ]}
          deskStyles={deskSubmitButton}
          onClick={submitUpdateEmailAddress}
        >
          EMAIL UPDATED
        </Button>
      )}
    </>
  )
}
