import styled from 'styled-components'

import { variantsAndStyles } from '../../utils/variantsAndStyles'

export const StyledRadioButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: ${p => p.theme.colors.secondary};
  color: ${p => p.theme.colors.darkGray};
  top: 10px;
  height: 30px;
  width: 30px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 7px;
  margin-left: 0px;
  outline: none;

  :checked::before {
    position: absolute;
    font: 13px/1 'Open Sans', sans-serif;
    font-weight: 900;
    left: 11px;
    top: 7px;
    content: '\\02143';
    transform: rotate(40deg);
  }

  :hover {
    background-color: ${p => p.theme.colors.lightGray};
  }
  :checked {
    background-color: ${p => p.theme.colors.secondary};
  }
  ${variantsAndStyles()}
`
