import { selector } from 'recoil'
import { updateProfileState } from '../'
import { UpdateProfileType } from '../../constants/enums'

// To search an index in Algolia you must have the index name. We have one index per entity.
// i.e. dev_USERS for users, dev_EVENTS for events, etc.
export const updateProfileHeaderState = selector({
  key: 'updateProfileHeaderState',
  get: ({ get }) => {
    const updateProfile = get(updateProfileState)
    switch (Number(updateProfile.type)) {
      case UpdateProfileType.regular:
        return 'Update your profile.'
      case UpdateProfileType.managed:
        return "Update your user's profile."
      case UpdateProfileType.setup:
        return 'Setup your profile.'
      // This case should not be hit
      default:
        return 'Update your profile.'
    }
  },
})
