import React, { useState } from 'react'
import 'firebase/storage'

import {
  Box,
  Button,
  mobBoxVariants,
  mobLinkVariants,
  mobTextVariants,
  Text,
} from '../../shared/index'
import { css } from 'styled-components'
import { PrivateUser } from '../../classes'
import { Stripe } from './Stripe'
import { CustomerForm } from './CustomerForm'
import { functions } from '../../firebaseApp'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import {
  CompletedIcon,
  mobTaskText,
  NotCompletedIcon,
} from '../../modules/GeneralSettingsModule'
import { LoadingButton } from '../LoadingButton'

const { mobInstructionParagraph, mobInstructionHeader } = mobTextVariants
const { mobLong305x46, mobButtonLink } = mobLinkVariants
const { row } = mobBoxVariants

const mobStripeButton = css`
  background-color: ${p => p.theme.colors.blue};
  margin: 10px 0 10px 0;
`

/**
 * Stripe module for user to link payment details. The payment details then
 * can be used for paying for booking, image licensing, subscriptions, etc.
 *
 * CUSTOMERS PAY US.
 *
 * @constructor
 */
export const StripeCustomerModule = ({
  privateUserSnapshot,
  flattenedInputs,
}) => {
  const generateCustomerPortalLink = functions.httpsCallable(
    'generateCustomerPortalLink'
  )
  const privateUser = new PrivateUser(privateUserSnapshot)
  const [generatingPortalLink, setGeneratingPortalLink] = useState(false)

  // https://stripe.com/docs/billing/subscriptions/customer-portal
  // https://stripe.com/docs/billing/subscriptions/integrating-customer-portal
  const redirectToCustomerPortal = () => {
    setGeneratingPortalLink(true)
    generateCustomerPortalLink({
      stripeAccountId: privateUser.stripeCustomerId,
    })
      .then(response => {
        window.open(response.data.customerPortalUrl)
        setGeneratingPortalLink(false)
        // window.location.replace(response.data.customerPortalUrl)
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_REDIRECTING_STRIPE_CUSTOMER_PORTAL',
          message: 'Error while redirecting to Stripe Customer Portal',
          type: errorTypes.billing,
          user: privateUser && privateUser.userId,
          file: 'StripeCustomerModule.jsx',
        })
      })
  }

  return (
    <>
      {!privateUser.hasStripeCustomer && (
        <>
          <Text mobStyles={mobInstructionHeader}>
            Create a Stripe Customer to send payments.
          </Text>
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;
              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Stripe Customer Created</Text>
            <label style={{ marginTop: '5px' }}>{NotCompletedIcon}</label>
          </Box>
          <Text mobStyles={mobInstructionParagraph}>
            We use Stripe to process payments. Please setup your Stripe Customer
            by providing your payment details below. Once you submit a Stripe
            Customer will be created for you and used to make purchases within
            the application.
            <br />
            <br />
            <strong>
              {' '}
              Note: Book A Model™ will never store your credit card details. It
              is stored securely in Stripe. To learn more about how your
              sensitive data is stored in Stripe visit{' '}
              <a href={'https://stripe.com/docs/security/stripe#encryption'}>
                here
              </a>
              .
            </strong>
          </Text>
          <Stripe>
            <CustomerForm flattenedInputs={flattenedInputs} />
          </Stripe>
        </>
      )}
      {privateUser.hasStripeCustomer && (
        <>
          <Text mobStyles={mobInstructionHeader}>
            Stripe Customer to send payments.
          </Text>
          <Box
            mobStyles={[
              row,
              css`
                width: 100%;

              `,
            ]}
          >
            <Text mobStyles={mobTaskText}>Stripe Customer Created</Text>
            <label style={{ marginTop: '5px' }}>{CompletedIcon}</label>
          </Box>
          <Text mobStyles={mobInstructionParagraph}>
            Thank you for submitting your payment information! Come here to
            visit your Stripe Customer Portal for the latest payment
            information. You can update/delete your current payment details. You
            may also add more payment methods, view purchase history, and see
            subscriptions and/or cancel them.
          </Text>
          {!generatingPortalLink && (
            <Button
              mobStyles={[mobButtonLink, mobLong305x46, mobStripeButton]}
              onClick={redirectToCustomerPortal}
            >
              VIEW STRIPE CUSTOMER PORTAL
            </Button>
          )}
          {generatingPortalLink && (
            <LoadingButton
              mobStyles={mobStripeButton}
              deskStyles={[
                css`
                  width: 100%;
                `,
              ]}
            />
          )}
        </>
      )}
    </>
  )
}
