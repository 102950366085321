import isNil from 'lodash/isNil'
import trim from 'lodash/trim'

/**
 * SupportRequestForm is a helper class to provide validation and helpers
 * for the Support Request form
 *
 */
export class SupportRequestForm {
  constructor(submitButtonDisabled) {
    this.submitButtonDisabled = submitButtonDisabled
    this.supportRequest = ''
    this.supportRequestEmail = ''
    this.additionalInfo = null
  }

  updateSupportRequest = event => {
    this.supportRequest = event.target.value
    this.validSupportRequestData()
  }

  updateSupportRequestEmail = event => {
    this.supportRequestEmail = event.target.value
    this.validSupportRequestData()
  }

  updateAdditionalInfo = event => {
    this.additionalInfo = event.target.value
    this.validSupportRequestData()
  }

  /**
   * This method makes sure the user provides at least a question before submitting
   */
  validSupportRequestData = () => {
    trim(this.supportRequest)

    if (!isNil(this.supportRequest) && this.supportRequest.length > 15) {
      this.submitButtonDisabled(false)
      return true
    } else {
      this.submitButtonDisabled(true)
      return false
    }
  }
}
