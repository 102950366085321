import { Box, mobBoxVariants } from '../../shared/Box'
import { css } from 'styled-components'
import React from 'react'
import { EmptyChatInboxText } from './InboxPage'
import { ChatThreadPage } from '../ChatThreadPage'
import { useRecoilValue } from 'recoil'
import { chatThreadState } from '../../store'

const { column, row } = mobBoxVariants

export const DesktopInbox = ({
  userChatRooms,
  getSortedChats,
  currentUserAuth,
}) => {
  const chatThreadId = useRecoilValue(chatThreadState)

  return (
    <Box deskStyles={[row, css`min-height: 92.5vh`]}>
      <Box mobStyles={[column, deskChatItemSection]}>
        {userChatRooms.length <= 0 && (
          <EmptyChatInboxText>
            Chats will appear here once you have an active booking request.
          </EmptyChatInboxText>
        )}
        {getSortedChats(userChatRooms)}
      </Box>
      <Box mobStyles={[column, deskChatThreadSection]}>
        {chatThreadId && <ChatThreadPage currentUserAuth={currentUserAuth} />}
      </Box>
    </Box>
  )
}

const deskChatItemSection = css`
  flex: 3;
  overflow: auto;
  max-height: 100vh;
`
const deskChatThreadSection = css`
  flex: 8;
  box-shadow: ${p => p.theme.shadow.regular};
  overflow: auto;
  max-height: 100vh;
`
