import { mobTextVariants, Text } from '../../shared/Text'
import { Button, mobButtonVariants } from '../../shared/Button'
import React from 'react'
import { Box, mobBoxVariants } from '../../shared/Box'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { RequestStatus } from '../../constants/enums'
import { MessageBox } from '../MessageBox'
import { hasAtLeastOneService } from '../../modules/CashServiceModule'
import DancingAlert from '../../assets/lottie/5457-alert-notification'
import { useLottie } from 'lottie-react'

const { mobSubmitButton, mobLong305x46, mobButtonSpacer } = mobButtonVariants
const { mobHorizontalRule } = mobTextVariants
const { center } = mobBoxVariants
const { deskSubmitButton } = deskTextVariants

/**
 *
 * @param gig {Gig}
 * @param userId
 * @param handleProposalSubmit
 * @returns {null|*}
 * @constructor
 */
export const ProposalForm = ({ gig, user, handleProposalSubmit }) => {
  const renderProposalForm = gig.renderProposalForm(user.userId)
  const userHasRequest = request => request.operative === user.userId
  const userHasCompletedRequest = request =>
    request.status === RequestStatus.completed

  const hasAtLeastOneCashService = hasAtLeastOneService(user.moneyServices)


  const fixedPriceGig = gig.fixedPriceGig

  const userRateSet = Number(user.hourlyRate) > 0
  const options = {
    animationData: DancingAlert,
    loop: true,
    autoplay: true,
    style: {
      height: 100,
      margin: 0,
    },
  }
  const { View } = useLottie(options)

  const [
    allActiveBookings,
    allActiveBookingsLoading,
    allActiveBookingsError,
  ] = useCollectionData(
    collections.activeRequests
      .where('eventId', '==', gig.id)
      .where('operative', '==', user.userId),
    {
      idField: 'id',
    }
  )

  if (!gig.acceptingProposals) {
    return (
      <MessageBox
        header="Proposals Closed"
        message="This gig is currently not accepting applicants."
      />
    )
  }

  if (
    !renderProposalForm ||
    allActiveBookingsLoading ||
    allActiveBookingsError
  ) {
    return null
  }

  return (
    <>
      {allActiveBookings && !allActiveBookings.some(userHasRequest) && (
        <>
          <Text mobStyles={mobHorizontalRule} />
          {!hasAtLeastOneCashService && (
            <MessageBox
              header="No Cash Services"
              message="To apply for an event you need to add a Cash Service on the settings page."
              subMessage={View}
            />
          )}
          {!userRateSet && !fixedPriceGig && (
            <MessageBox
              header="No Rate Set"
              message={`To apply you need to have an hourly rate set, or only apply to flat rate gigs. \n Your Current Rate: $${Number(
                user.hourlyRate
              )}`}
              subMessage={View}
            />
          )}

          {(userRateSet || (hasAtLeastOneCashService && fixedPriceGig))
          && (
            <Box mobStyles={[center]}>
              <Button
                mobStyles={[mobSubmitButton, mobLong305x46, mobButtonSpacer]}
                deskStyles={deskSubmitButton}
                onClick={handleProposalSubmit}
              >
                APPLY
              </Button>
            </Box>
          )}
        </>
      )}
      {allActiveBookings.some(userHasCompletedRequest) && (
        <MessageBox
          header="Completed"
          message="You have already completed this gig."
        />
      )}
    </>
  )
}
