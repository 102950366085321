import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import firebase from 'firebase/app'

import { Button, mobButtonVariants } from '../../shared/Button'
import {
  Box,
  deskBoxVariants,
  Image,
  Link,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text
} from '../../shared'
import { collections } from '../../firebaseApp'
import { GigStatus, RequestStatus } from '../../constants/enums'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { ROUTES } from '../../constants/routes'
import { Toggle } from '../../components/Toggle'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { Gig, User } from '../../classes'
import { MiniAccordion } from '../../components/MiniAccordion'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { MessageBox } from '../../components/MessageBox'
import { RatingModule, RatingPosition } from "../RatingModule";
import { inchesToHumanReadable } from "../ProfileDetailsModule";
import { mobDetail, mobDetailName, mobModelDetailsContainer } from "../../components/Detail/styles";
import { motion } from "framer-motion";

const {mobButton} = mobButtonVariants
const {mobMessageBoxHeader} = mobTextVariants
const {deskMessageBoxHeader} = deskTextVariants
const {
  column,
  row,
  center,
  mobProfileBoxMini,
  mobProfileBoxRequest,
  mobProfileBoxDetails,
} = mobBoxVariants
const {deskProfileBoxMini, deskProfileBoxDetails} = deskBoxVariants
const {mobUserProfileImageMini, mobUserProfileImageRequest} = mobImageVariants
const {mobUserProfileLocationMini, mobUserProfileNameMini} = mobTextVariants
const {
  deskUserProfileLocationMini,
  deskUserProfileNameMini,
} = deskTextVariants
const { deskDetailsContainer } = deskBoxVariants
const { deskDetailName, deskDetail } = deskTextVariants


const editButton = css`
  background-color: transparent;
  color: ${p => p.theme.colors.primary};
  box-sizing: border-box;
  border: 0.5px solid ${p => p.theme.colors.darkGray};
`

const deleteButton = css`
  background-color: transparent;
  color: ${p => p.theme.colors.red};
  box-sizing: border-box;
  border: 1px solid ${p => p.theme.colors.red};
`

const mobCenterLinkText = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const mobManageGigBox = css`
  padding: 20px;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.lightGray};
  margin: 0 auto 3%;
  height: auto;
  border-radius: 5px;
  box-shadow: ${p => p.theme.shadow.regular};
`

/**
 * This module is rendered when a user views a gig that they own
 *
 * @param {Gig} gig
 * @param currentUserAuth
 * @param {User} currentUser
 * @returns {null|*}
 * @constructor
 */
export const GigManagementModule = ({gig, currentUserAuth, currentUser}) => {
  // Silently don't display
  if (!gig || !currentUserAuth) {
    return null
  }
  
  const [
    allActiveBookings,
    allActiveBookingsLoading,
    allActiveBookingsError,
  ] = useCollectionData(
    collections.activeRequests
      .where('eventId', '==', gig.id)
      .where('eventCreator', '==', currentUserAuth.uid)
      .where('status', 'in', [
        RequestStatus.accepted,
        RequestStatus.awaitingBAM,
        RequestStatus.pending,
        RequestStatus.disputed,
      ]),
    {
      idField: 'id',
    }
  )
  const {gigs, deletedGigs} = collections
  const [deleteAccordionOpen, setDeleteAccordionOpen] = useState(false)
  
  const deleteGigHandler = () => {
    let serverTimestamp = firebase.firestore.Timestamp.now()
    firebase
      .firestore()
      .runTransaction(transaction => {
        return transaction.get(gigs.doc(gig.id)).then(eventDocument => {
          transaction.set(deletedGigs.doc(gig.id), eventDocument.data())
          
          transaction.update(deletedGigs.doc(gig.id), {
            status: GigStatus.deleted,
            deletedAt: serverTimestamp,
          })
          
          transaction.delete(gigs.doc(gig.id))
        })
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_DELETE_GIG',
          message: `Error occurred while getting user trying to delete gig for gig id: ${gig.id}`,
          type: errorTypes.gig,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'GigManagementModule.jsx',
          additionalData: gig,
        })
      })
    
    return Promise.resolve('Acceptance Transaction Resolved!')
  }
  
  const handleAcceptingProposals = () => {
    const {gigs} = collections
    
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(gigs.doc(gig.id))
        .then(eventDocument => {
          const transactionEvent = new Gig(eventDocument)
          
          transaction.update(gigs.doc(gig.id), {
            acceptingProposals: !transactionEvent.acceptingProposals,
          })
        })
        .then(result => {
          return
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_UPDATE_ACCEPTING_PROPOSALS_OPTION',
            message: 'Error while updating accepting proposals option.',
            type: errorTypes.profile,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'GigManagementModule.jsx',
          })
        })
    })
  }
  
  if (allActiveBookingsLoading || allActiveBookingsError) {
    return null
  }
  
  if (gig.isGigOwner(currentUserAuth.uid)) {
    return (
      <Box mobStyles={[column, mobManageGigBox]}>
        <Text
          mobStyles={[
            mobMessageBoxHeader,
            css`
              margin: 20px;
              text-align: center;
            `,
          ]}
          deskStyles={[deskMessageBoxHeader]}
        >
          Manage Your Gig
        </Text>
        {gig.fixedPriceGig && (
          <MessageBox
            header="Fixed Price Gig"
            message={`This gig will only pay ${gig.fixedPriceDisplay} per model.`}
          />
        )}
        {allActiveBookings.length > 0 && (
          <MessageBox
            header="Edit/Delete Disabled"
            message="All active requests must be resolved to edit or delete a gig. Resolved includes completed, canceled, or declined. You can also withdraw and decline all active requests for this gig."
          />
        )}
        {allActiveBookings.length <= 0 && (
          <>
            <Link
              mobStyles={[mobButton, editButton, mobCenterLinkText]}
              deskStyles={css`
                font-size: 14px;
              `}
              to={ROUTES.EDIT_GIG.base + gig.id}
            >
              EDIT
            </Link>
            <MiniAccordion
              closedButtonText="DELETE"
              openButtonText="CANCEL"
              onClick={() => {
                setDeleteAccordionOpen(!deleteAccordionOpen)
              }}
              open={deleteAccordionOpen}
              buttonMobStyles={[mobButton, deleteButton]}
            >
              <Button
                mobStyles={[mobButton, deleteButton]}
                onClick={deleteGigHandler}
              >
                CONFIRM DELETION
              </Button>
            </MiniAccordion>
          </>
        )}
        
        <Toggle
          label="Accepting proposals. If you disable this you can still directly send requests to users."
          onChangeHandler={handleAcceptingProposals}
          checked={gig.acceptingProposals}
        />
        <Text
          mobStyles={[
            mobMessageBoxHeader,
            css`
              margin: 20px;
              text-align: center;
            `,
          ]}
          deskStyles={[deskMessageBoxHeader]}
        >
          Applicants
        </Text>
        <Box
          mobStyles={[
            css`
  display: flex;
  flex-wrap: wrap;
            `,
          ]}
        >
          {allActiveBookings.map((requestDoc, index) => {
              return <ProfileBoxWithFetch request={requestDoc} key={requestDoc.id}/>
          })}
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

const ProfileBoxWithFetch = ({request}) => {
  const [
    userData,
    userLoading,
    userError,
  ] = useDocumentData(
    collections.userInformation.doc(request.operative)
    ,
    {
      idField: 'id',
    }
  )
  
  
  if (userLoading || userError) {
    return null
  }
  
  const user = new User(userData)
  
  return (
    <Link
      to={ROUTES.PROFILE.link + '/' + user.userId}
      mobStyles={[css`
        background-color: ${p => p.theme.colors.secondary};
        padding: 25px 20px 25px 20px;
        width: 31%;
         justify-content: space-between;
          border: 1px solid ${p => p.theme.colors.lightGray};
  margin: 0 auto 3%;
  height: auto;
  border-radius: 5px;
  box-shadow: ${p => p.theme.shadow.regular};
      `]}
    >
  
  
      <Box
        mobStyles={[css`
        width: 100%;
            align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
        `]}>
        <Box
          mobStyles={[
            css`
            width: 100px;
            height: 100px;
            min-width: 100px;
            min-height: 100px;
          `,
            center,
          ]}
        >
          <Image
            src={user.getProfileImage()}
            mobStyles={css`
              margin: 0;
    height: 100%;
    width: 100%;
    color: ${p => p.theme.colors.blueGray};
    border-radius: 100%;
    background-size: cover;
    transform: scaleX(-1);
          `}
          />
        </Box>
        
      </Box>
      

      <Box
        mobStyles={[
          center,
          column,
          css`
            width: 100%;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobUserProfileNameMini,
            css`
              font-size: auto;
              line-height: 20px;
              margin-top: 0;
              text-align: center;
            `,
          ]}
        >
          {user.fullName}
        </Text>
        <Text
          mobStyles={[
            mobUserProfileLocationMini,
            css`
              text-align: center;
            `,
          ]}
        >
          {user.getUserCity()}
        </Text>
        <RatingModule
          rating={user.rating}
          ratingPosition={RatingPosition.center}
        />
        <Text
          mobStyles={mobUserProfileLocationMini}
          deskStyles={[deskUserProfileLocationMini]}
        >
          {user.getUserCity()}
        </Text>
        <Box
          mobStyles={[mobProfileBoxDetails]}
          deskStyles={[css`
              display: flex;
    padding: 2px;
    align-items: center;
    justify-content: space-evenly;
          `, row]}
        >
          <Detail detail={inchesToHumanReadable(user.height)} text="Height" />
          <Detail detail={user.weight} text="Weight" />
          <Detail detail={user.getGender()} text="Gender" />
          <Detail detail={user.getUserHourlyRate()} text="Rate" />
        </Box>
      </Box>
    </Link>
  )
}


const Detail = ({ detail, text, url }) => {
  const details = url ? <StyledA href={detail}>{detail}</StyledA> : detail
  
  return (
    <Box
      mobStyles={[column, mobModelDetailsContainer]}
      deskStyles={[css`
          align-items; space-evenly;
          padding: 4px;
      `]}
    >
      <Text mobStyles={css`
        margin: 0;
  color: ${p => p.theme.colors.primary};
  font-family: 'Open Sans', serif;
  font-weight: bold;
  font-size: 5px;
  text-align: left;
  overflow-wrap: break-word;
      `} deskStyles={[css`
    height: auto;
    line-height: 25px;
    line-height: 25px;
    color: ${p => p.theme.colors.gray};
    font-size: .6em;
    text-align: left;
    margin: 2px 0 0;
  `]}>
        {details}
      </Text>
      <Text mobStyles={[css`
      
  color: ${p => p.theme.colors.gray};
  font-size: 25em;
  text-align: left;
  margin: 2px 0 0;
      `]} deskStyles={[css`
          height: auto;
    color: ${p => p.theme.colors.gray};
    font-size: .5em;
    text-align: left;
    margin: 2px 0 0;
      `]}>
        {text}
      </Text>
    </Box>
  )
}


const StyledA = styled(motion.a)`
  overflow-wrap: anywhere;
`
