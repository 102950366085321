import { css } from 'styled-components'

export const mobGigBoxHorizontalRule = css`
  box-sizing: border-box;
  height: 2px;
  width: auto;
  border: 1px solid #979797;
  opacity: 0.3;
  margin: 10px auto;
`

export const mobConfirmBookingInvoiceRow = css`
  width: auto;
`

export const mobConfirmBookingInvoiceMoney = css`
  margin-left: auto;
  margin-right: 0;
  color: ${p => p.theme.colors.gray};
  font-family: 'Open Sans', serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
`

export const mobGigBoxDescription = css`
  height: auto;
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 15px;
  line-height: 20px;
  margin: 3% 0 1%;
  overflow: scroll;
`
