import React from 'react'
import PropTypes from 'prop-types'
import { Button, mobButtonVariants } from '../../shared/index'
import { StickyFooter } from './StickyFooter'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { Text } from '../../shared/Text'

const { mobSubmitButton, mobLong305x46, mobSkipButton } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants

// Used to make the Footer Sticky
// https://stackoverflow.com/questions/40515142/how-to-make-a-sticky-footer-in-react
export const StickyFooterWithButton = ({
  mainButtonDisabled,
  mainButtonHandler,
  mainButtonFormId,
  mainButtonType,
  mainButtonText,
  mainButtonId,
  secondaryButton,
  secondaryButtonText,
  errorText,
}) => {
  return (
    <StickyFooter>
      {errorText && (
        <Text
          mobStyles={css`
            color: red;
            margin: -10px 15px 20px 15px;
            font-weight: bold;
          `}
        >
          {errorText}
        </Text>
      )}
      <Button
        disabled={mainButtonDisabled || false}
        mobStyles={[mobSubmitButton, mobLong305x46]}
        deskStyles={deskSubmitButton}
        onClick={mainButtonHandler || ''}
        form={mainButtonFormId}
        type={mainButtonType || ''}
        id={mainButtonId}
      >
        {mainButtonText}
      </Button>
      {secondaryButton && (
        <Button
          mobStyles={[
            mobSkipButton,
            css`
              margin-top: 15px;
            `,
          ]}
        >
          {secondaryButtonText}
        </Button>
      )}
    </StickyFooter>
  )
}

StickyFooterWithButton.propTypes = {
  mainButtonDisabled: PropTypes.bool,
  mainButtonFormId: PropTypes.string,
  mainButtonHandler: PropTypes.func,
  mainButtonText: PropTypes.string,
  mainButtonType: PropTypes.string,
  secondaryButton: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
}
