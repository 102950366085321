import React, { useState } from 'react'
import { css } from 'styled-components'

import {
  Button,
  Image,
  inputVariants,
  mobBoxVariants,
  mobButtonVariants,
  mobLinkVariants,
  mobTextVariants,
  Select,
  Text,
} from '../../shared'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import { collections } from '../../firebaseApp'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { MessageBox } from '../../components/MessageBox'
import { LoadingButton } from '../../components/LoadingButton'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { Box } from '../../shared/Box'
import firebase from 'firebase/app'
import { useLottie } from 'lottie-react'
import DancingAlert from '../../assets/lottie/5457-alert-notification'

const { mobSubmitButton } = mobButtonVariants
const { mobInstructionParagraph } = mobTextVariants
const { deskSubmitButton } = deskTextVariants
const { row, column } = mobBoxVariants
const { mobSelectInput } = inputVariants
const { mobLong305x46 } = mobLinkVariants

export const hasAtLeastOneService = moneyServices => {
  let hasOne = false
  for (let service in moneyServices) {
    const userHasAddedService = moneyServices[service].added
    const userHasRemovedService = moneyServices[service].removed === true
    if (userHasAddedService && !userHasRemovedService) {
      hasOne = true
    }
  }
  return hasOne
}

export const CashServiceModule = ({ currentUserAuth, user, rightButton }) => {
  const [emailUpdated, setEmailUpdated] = useState(false)
  const [emailUpdating, setEmailUpdating] = useState(false)
  const [moneyServiceType, setMoneyServiceType] = useState(null)
  const [cashTagLikeUsername, setCashTagLikeUsername] = useState(null)
  const options = {
    animationData: DancingAlert,
    loop: true,
    autoplay: true,
    style: {
      height: 100,
      margin: 0,
    },
  }
  const { View } = useLottie(options)

  const submitAddCashService = () => {
    if (!cashTagLikeUsername) {
      return
    }

    const userInfoDocumentRef = collections.userInformation.doc(
      currentUserAuth.uid
    )
    userInfoDocumentRef
      .set(
        {
          moneyServices: {
            [moneyServiceType]: {
              default: false,
              username: cashTagLikeUsername,
              added: new Date(),
              removed: false,
            },
          },
        },
        { merge: true }
      )
      .then(() => {})
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_SUBMIT_PROFILE_DETAILS',
          message: 'Failed to submit new profile details',
          type: errorTypes.profile,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'UserProfilePage.jsx',
        })
      })
  }

  let hasMoneyService = false
  const getServicePlaceholder = serviceNumber => {
    switch (Number(serviceNumber)) {
      case 1:
        return 'Enter your CashTag'
      case 2:
        return 'Enter your Paypal link or user name'
      case 3:
        return 'Enter your Venmo username'
      case 4:
        return 'Enter you Zelle phone number or username'
      default:
        return 'Enter your money service username'
    }
  }
  const getServiceLogo = serviceNumber => {
    switch (Number(serviceNumber)) {
      case 1:
        return 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png'
      case 2:
        return 'http://pngimg.com/uploads/paypal/paypal_PNG22.png'
      case 3:
        return 'https://cdn1.venmo.com/marketing/images/branding/downloads/venmo_logo_blue.svg'
      case 4:
        return 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Zelle_logo.svg'
      default:
    }
  }

  const userMoneyServices = servicesObject => {
    let moneyServiceArray = []
    for (let service in servicesObject) {
      if (!servicesObject[service].removed) {
        hasMoneyService = true
        moneyServiceArray.push(
          <MoneyServiceItem
            service={service}
            cashTagLikeUsername={servicesObject[service].username}
            defaultService={servicesObject[service].default}
            imgSrc={getServiceLogo(service)}
            userId={currentUserAuth.uid}
          />
        )
      }
    }
    return moneyServiceArray
  }

  const disabledMoneyOption = service => {
    if (user.moneyServices.hasOwnProperty(service)) {
      const userHasAddedService = user.moneyServices[service].added
      const userHasRemovedService = user.moneyServices[service].removed === true
      return userHasAddedService && !userHasRemovedService
    } else {
      return false
    }
  }

  return (
    <>
      {!hasAtLeastOneService(user.moneyServices) && (
        <MessageBox
          header="Add a Cash Service"
          message="To receive your earnings we need at least one Cash Service added."
        />
      )}
      {userMoneyServices(user.moneyServices)}
      <MessageBox
        header="Double and Triple check"
        message="To prevent lost funds, please ensure your Cash Service addresses are correct."
        subMessage={View}
      />
      <Text mobStyles={mobInstructionParagraph}>
        To expedite the Payment process we allow members to add the Money
        Service of their choice to receive payment. Please ensure your payment
        address is correct before accepting a gig.
      </Text>

      <Select
        mobStyles={[mobSelectInput]}
        name="accountType"
        onChange={event => {
          setMoneyServiceType(Number(event.target.value))
        }}
        value={moneyServiceType}
        options={[
          { value: 0, label: 'Not Set' },
          { value: 1, label: 'CashApp', disabled: disabledMoneyOption(1) },
          { value: 2, label: 'Paypal', disabled: disabledMoneyOption(2) },
          { value: 3, label: 'Venmo', disabled: disabledMoneyOption(3) },
          { value: 4, label: 'Zelle', disabled: disabledMoneyOption(4) },
        ]}
        data-testid="account-type-select"
      />
      {moneyServiceType && (
        <InputWithHeader
          onChange={event => {
            setCashTagLikeUsername(event.target.value)
          }}
          placeholderDefault={getServicePlaceholder(moneyServiceType)}
          hideHeader={true}
          value={cashTagLikeUsername}
        />
      )}
      <Box deskStyles={row}>
        <Box deskStyles={[row, css`flex: 1`]}>
          {emailUpdating && (
            <LoadingButton
              mobStyles={[
                mobSubmitButton,
                mobLong305x46,
                css`
              margin: 30px 0;
            `,
              ]}
            />
          )}
          {!emailUpdated && !emailUpdating && (
            <Button
              mobStyles={[
                mobSubmitButton,
                mobLong305x46,
                css`
              margin: 30px 0;
            `,
              ]}
              deskStyles={deskSubmitButton}
              onClick={submitAddCashService}
            >
              ADD MONEY SERVICE
            </Button>
          )}
        </Box>
        <Box deskStyles={[row, css`flex: 1`]}>
          {rightButton}
        </Box>
        </Box>


    </>
  )
}
const { mobMessageBoxMessage, mobMessageBoxHeader } = mobTextVariants

const mobMoneyServiceButton = css`
  height: 42px;
  width: 100%;
  font-family: Montserrat, serif;
  font-weight: bold;
  letter-spacing: 2.4px;
  line-height: 15px;
  border-radius: 30px;
  background-color: ${p => p.theme.colors.primary};
  font-size: 14px;
  color: ${p => p.theme.colors.secondary};
  box-shadow: 0 6px 10px 0 rgba(54, 54, 54, 0.5);
  :disabled {
    opacity: 0.5;
  }
`

const MoneyServiceItem = ({
  service,
  imgSrc,
  cashTagLikeUsername,
  defaultService,
  userId,
}) => {
  const userInfoDocumentRef = collections.userInformation.doc(userId)

  const handleMakeDefault = () => {
    firebase.firestore().runTransaction(transaction => {
      return transaction
        .get(userInfoDocumentRef)
        .then(userInfoDocument => {
          const currentMoneyServices = userInfoDocument.data().moneyServices
          let updatedMoneyServices = {}

          for (let serviceNumber in currentMoneyServices) {
            if (Number(service) === Number(serviceNumber)) {
              updatedMoneyServices[serviceNumber] = {
                ...currentMoneyServices[serviceNumber],
                default: true,
              }
            } else if (currentMoneyServices[serviceNumber].default) {
              updatedMoneyServices[serviceNumber] = {
                ...currentMoneyServices[serviceNumber],
                default: false,
                removedAsDefault: new Date(),
              }
            } else {
              updatedMoneyServices[serviceNumber] = {
                ...currentMoneyServices[serviceNumber],
                default: false,
              }
            }
          }

          transaction.update(userInfoDocumentRef, {
            moneyServices: updatedMoneyServices,
          })
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_MAKING_MONEY_SERVICE_DEF',
            message: 'Error while money service default.',
            type: errorTypes.cash,
            user: userId,
            file: 'CashServiceModule.jsx',
          })
        })
    })
  }

  const handleRemove = () => {
    userInfoDocumentRef
      .set(
        {
          moneyServices: {
            [service]: {
              removed: true,
              removedAt: new Date(),
              default: false,
            },
          },
        },
        { merge: true }
      )
      .then(() => {})
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_SUBMIT_PROFILE_DETAILS',
          message: 'Failed to submit new profile details',
          type: errorTypes.profile,
          user: userId,
          file: 'UserProfilePage.jsx',
        })
      })
  }

  return (
    <Box
      mobStyles={[
        css`
          width: 100%;
          padding: 15px;
          width: 100%;
          border: 1px solid ${p => p.theme.colors.lightGray};
          margin: 0 auto 3%;
          height: auto;
          border-radius: 5px;
          box-shadow: ${p => p.theme.shadow.regular};
        `,
      ]}
    >
      <Box mobStyles={[column]}>
        <Image
          mobStyles={css`
            width: 100px;
            height: 100px;
          `}
          src={imgSrc}
        />
      </Box>
      <Box
        mobStyles={[
          column,
          css`
            flex: 1;
            align-content: center;
          `,
        ]}
      >
        <Text
          mobStyles={[
            mobMessageBoxHeader,
            css`
              text-align: center;
              flex: 1;
            `,
          ]}
        >
          {cashTagLikeUsername}
        </Text>
        <Box mobStyles={[row, css``]}>
          <Box
            mobStyles={[
              column,
              css`
                flex: 1;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 10px;
              `,
            ]}
          >
            <Button
              mobStyles={[mobMoneyServiceButton]}
              onClick={() => {
                handleMakeDefault()
              }}
            >
              {defaultService ? 'Default' : 'Make Default'}
            </Button>
          </Box>
          <Box
            mobStyles={[
              column,
              css`
                flex: 1;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 10px;
              `,
            ]}
          >
            <Button
              mobStyles={[
                mobMoneyServiceButton,
                css`
                  background-color: tomato;
                `,
              ]}
              onClick={() => {
                handleRemove()
              }}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
