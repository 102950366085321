import React, { useEffect, useState } from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { css } from 'styled-components'
import { Gig, LicenseImage, User } from '../../classes'
import { Box, InfiniteScroll } from '../../shared'
import { MessageBox } from '../../components/MessageBox'
import { searchState } from '../../store'
import { useRecoilValue } from 'recoil'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { SearchType } from '../../constants/enums'
import { LicenseImageWithModal } from '../../components/LicenseImageWithModal'
import { uuid } from '../../utils/uuid'
import { GigBox, GigBoxType } from '../../components/GigBox'
import { ProfileBox, ProfileBoxType } from '../../components/ProfileBox'

const mobInfiniteScrollStyles = css`
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
  overflow: unset;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0 20px 0 20px;
`

const deskInfiniteScrollStyles = css`
  justify-content: space-evenly;
`

const loaderWrapper = css`
  justify-content: center;
  width: 100%;
`

export const SearchResultsModule = () => {
  const resultsToLoadAtATime = 9
  const [itemsToDisplay, setItemsToDisplay] = useState([])
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(
    resultsToLoadAtATime
  )

  const search = useRecoilValue(searchState)

  useEffect(() => {
    setItemsToDisplay([])
    search.hits.slice(0, numItemsToDisplay).map((resultJSON, index) => {
      setItemsToDisplay(resultNodes => {
        return [...resultNodes, build(search.type, resultJSON, index)]
      })
    })
  }, [search.hits, numItemsToDisplay])

  const addMoreResults = () => {
    // Timeout to simulate loading
    setTimeout(function () {
      setNumItemsToDisplay(num => {
        return num + resultsToLoadAtATime
      })
    }, 500)
  }

  if (search.hits.length < 1) {
    return (
      <Box
        mobStyles={mobInfiniteScrollStyles}
        deskStyles={deskInfiniteScrollStyles}
      >
        <NoResultsMessage />
      </Box>
    )
  }

  const hasMore = numItemsToDisplay < search.hits.length
  // https://www.npmjs.com/package/react-infinite-scroll-component
  // https://www.npmjs.com/package/react-loader-spinner
  // https://mhnpd.github.io/react-loader-spinner/?path=/story/loader--threedots
  // HACKS: In order to make sure the user always has room to scroll down and trigger the
  // load more method a dummy div is placed under the results as long as there are more results
  return (
    <InfiniteScroll
      // Set the length of the data.This will unlock the subsequent calls to next.
      // This is important field to render the next data
      dataLength={itemsToDisplay.length}
      next={addMoreResults}
      hasMore={hasMore} // it tells the InfiniteScroll component on whether to call next function on reaching the bottom and shows an endMessage to the user
      loader={<SearchLoader />}
      endMessage={<EndOfResultsMessage />}
      mobStyles={mobInfiniteScrollStyles}
      deskStyles={deskInfiniteScrollStyles}
    >
      {itemsToDisplay}
      {hasMore && <PseudoBox />}
    </InfiniteScroll>
  )
}

const PseudoBox = () => {
  return (
    <Box
      mobStyles={[
        css`
          height: 75vh;
          width: 10vw;
        `,
      ]}
      deskStyles={[
        css`
          height: 75vh;
          width: 25vw;
        `,
      ]}
    />
  )
}

const EndOfResultsMessage = () => {
  return <MessageBox header="END OF THE CATWALK" message="No more results." />
}

const NoResultsMessage = () => {
  return (
    <MessageBox
      header="EMPTY CATWALK"
      message="No results matched this search."
    />
  )
}

const SearchLoader = () => {
  const isDesktop = useMediaQuery(desktopQuery)

  return (
    <Box mobStyles={loaderWrapper}>
      <Loader
        type="ThreeDots"
        color="#7c848b"
        height={isDesktop ? 250 : 100}
        width={isDesktop ? 250 : 100}
      />
    </Box>
  )
}

const build = (searchType, data, index) => {
  switch (searchType) {
    case SearchType.gig:
      return createGigResult(data, index)
    case SearchType.creative:
      return createUserResult(data, index)
    case SearchType.images:
      return createLicenseImageResult(data, index)
  }
}

const createLicenseImageResult = (licenseImageJSON, index) => {
  return (
    <LicenseImageWithModal
      key={uuid()}
      index={index}
      licenseImage={new LicenseImage(undefined, licenseImageJSON)}
    />
  )
}

const createGigResult = (gigJSON, index) => {
  return (
    <GigBox
      gig={new Gig(undefined, gigJSON)}
      key={index}
      boxType={GigBoxType.miniLink}
    />
  )
}

const createUserResult = (userJSON, index) => {
  return (
    <Box
      mobStyles={[
        css`
          flex: 0 0 50%;
          display: flex;
        `,
      ]}
      deskStyles={[
        css`
          flex: 1;
          max-width: 23vw;
          display: flex;
        `,
      ]}
      key={uuid()}
    >
      <ProfileBox
        user={new User(undefined, userJSON)}
        index={index}
        boxType={ProfileBoxType.Mini}
      />
    </Box>
  )
}
