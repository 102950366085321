import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { getFontSize, variantsAndStyles } from '../../utils/variantsAndStyles'

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  border: none;
  color: ${p => p.theme.colors.primary};
  box-sizing: border-box;
  ${getFontSize}

  ${variantsAndStyles()}
`
