import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore'

import {
  Box,
  Image,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
  Text,
} from '../../shared/'
import { collections } from '../../firebaseApp'
import { Gig } from '../../classes'
import { RequestStatus, RequestType } from '../../constants/enums'
import { GigInformationCenterModule, GigManagementModule } from '../../modules'
import {
  DesktopPageWrapper,
  LoadingScreen,
  ProposalForm,
} from '../../components'
import { useHistory } from 'react-router-dom'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { deskImageVariants } from '../../shared/Image/deskImageVariants'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { createChatRoom } from '../../utils/createChatroom'
import { sendNewProposalReceivedNotif } from '../../utils/notificationHandlers'

const { column, mobWrapper } = mobBoxVariants
const {
  mobPageHeader,
  mobGigBoxLocation,
  mobGigDate,
  mobGigBoxDescription,
} = mobTextVariants
const { mobGigLocationImage } = mobImageVariants
const { deskGigLocationImage } = deskImageVariants
const { deskPageHeader } = deskTextVariants

export const ViewGigPage = ({ currentUserAuth, match, user }) => {
  const history = useHistory()
  const [gig, setGig] = useState(new Gig())

  // Grab from the url query, if one cannot be found redirect to home
  const gigId = match.params.gig_id
  if (!gigId) {
    history.push('/')
  }

  const [
    gigDocumentSnapshot,
    gigDocumentSnapshotLoading,
    gigDocumentSnapshotError,
  ] = useDocumentData(collections.gigs.doc(gigId), {
    idField: 'id',
  })

  const [
    currentUserRequests,
    currentUserRequestsLoading,
    currentUserRequestsError,
  ] = useCollectionData(
    collections.activeRequests
      .where('eventId', '==', gigId)
      .where('operative', '==', currentUserAuth.uid)
      .where('status', 'in', [RequestStatus.accepted, RequestStatus.pending]),
    {
      idField: 'id',
    }
  )

  useEffect(() => {
    setGig(new Gig(gigDocumentSnapshot))
  }, [gigDocumentSnapshot])

  const handleProposalSubmit = async evt => {
    // We don't want to let default form submission happen here, which would refresh the page.
    evt.preventDefault()
    const { activeRequests } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()

    // TraceProposalStep1: A request is made in activeRequests with all details below
    // This is called after an operative submits a proposal for an active gig. We
    // then take all available info and create a Proposal Request. We then write the
    // id of this request to several other places for quick retrieval.
    activeRequests
      .add({
        // These get added at the same time for offers and proposals
        // TODO: Use gig and event id until we can get rid of eventId entirely
        eventId: gig.id,
        gigId: gig.id,
        operative: currentUserAuth.uid,
        createdAt: serverTimestamp,
        status: RequestStatus.pending,
        duration: gig.duration,
        operativeRate: user.getUserHourlyRate(),
        fixedPriceGig: gig.fixedPriceGig,
        fixedPrice: gig.fixedPricePennies,
        eventCreator: gig.createdBy,
        requestCreator: currentUserAuth.uid,
        requestType: RequestType.proposal,
        eventDocument: gigDocumentSnapshot,
        proposal: 'This user is interested in your gig',
      })
      .then(async requestDocument => {
        try {
          await createChatRoom([currentUserAuth.uid, gig.createdBy])
          await sendNewProposalReceivedNotif(gig.createdBy)
          await collections.activeRequests
            .doc(requestDocument.id)
            .collection('history')
            .doc()
            .set({
              note: `${currentUserAuth.uid} created this proposal for ${currentUserAuth.uid}.`,
              timestamp: firebase.firestore.Timestamp.now(),
            })

          history.push(`/proposal/${requestDocument.id}`)
        } catch (error) {
          errorHandler({
            error,
            errorId: 'ERROR_WRITING_HISTORY_LOG_ENTRY',
            message: 'Error while writing log entry to request history.',
            type: errorTypes.gig,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'ViewGigPage.jsx',
          })
        }
      })
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_SUBMITTING_PROPOSAL',
          message: 'Error while user trying to submit proposal request.',
          type: errorTypes.gig,
          user: currentUserAuth && currentUserAuth.uid,
          file: 'ViewGigPage.jsx',
        })
      })
  }

  const dependenciesLoading =
    gigDocumentSnapshotLoading || currentUserRequestsLoading

  const dependenciesError = gigDocumentSnapshotError || currentUserRequestsError

  const dependenciesReady = gigDocumentSnapshot && currentUserRequests

  return (
    <DesktopPageWrapper>
      {dependenciesError && <strong>Error: {gigDocumentSnapshotError}</strong>}
      {dependenciesLoading && <LoadingScreen />}
      {dependenciesReady && (
        <Box mobStyles={[column, mobWrapper]}>
          <Text mobStyles={mobPageHeader} deskStyles={[deskPageHeader]}>
            {gig.title}
          </Text>
          <Text mobStyles={mobGigDate}>{gig.getDateString()}</Text>
          <GigInformationCenterModule
            currentUserRequests={currentUserRequests}
            gig={gig}
            currentUser={user}
          />

          <GigManagementModule
            currentUserAuth={currentUserAuth}
            gig={gig}
            currentUser={user}
          />
          <Text mobStyles={mobGigBoxDescription}>{gig.description}</Text>
          <Text mobStyles={mobGigBoxLocation}>{gig.address}</Text>

          <Image
            src={gig.locationMobileImageUrl}
            mobStyles={mobGigLocationImage}
            deskStyles={deskGigLocationImage}
          />
          <ProposalForm
            gig={gig}
            handleProposalSubmit={handleProposalSubmit}
            user={user}
          />
        </Box>
      )}
    </DesktopPageWrapper>
  )
}
