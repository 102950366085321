import styled from 'styled-components'
import { up } from 'styled-breakpoints'

export const StyledInput = styled.input`
  border: solid 1px ${({ theme }) => theme.colors.lightGray};
  box-shadow: ${({ boxShadow }) => boxShadow};
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.colors.secondary};
  opacity: 1;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 15px;
  line-height: 1.64;
  color: ${({ theme }) => theme.colors.gray};
  width: ${props => props.mobileWidth};
  height: ${props => props.mobileHeight};
  border-radius: ${props => props.borderRadius};
  padding-left: 20px;
  padding-right: 20px;

  :focus {
    outline: thin dotted;
  }

  ${up('tablet')} {
    width: ${props => props.tabletWidth};
    height: ${props => props.tabletHeight};
    padding-left: 30px;
    padding-right: 30px;
    font-size: 20px;
  }

  ${up('desktop')} {
    width: ${props => props.desktopWidth};
    height: ${props => props.desktopHeight};
    padding-left: 50px;
    padding-right: 50px;
    font-size: 22px;
  }
`
