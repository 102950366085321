import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, mobBoxVariants, mobTextVariants, Text } from '../../shared/index'
import { css, useTheme } from 'styled-components'
import { IconContext } from 'react-icons'
import './accordion.css'

const { mobPageSubHeader, mobHorizontalRule } = mobTextVariants
const { row, column, mobWrapper } = mobBoxVariants

/**
 *  This component is used to easily create items in an accordion menu
 *
 * @param props
 * @constructor
 */
// FIXME: When using the accordion flashes close when you update something. It should remain open until the closed by the user
export const Accordion = ({ title, icon, children }) => {
  const [setActive, setActiveState] = useState('')
  const [setRotate, setRotateState] = useState('accordion__icon')
  const content = useRef(null)
  const theme = useTheme()

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '')
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate'
    )
  }

  return (
    <>
      <Box
        mobStyles={[
          mobWrapper,
          row,
          css`
            margin-bottom: 15px;
          `,
        ]}
        className={`${setActive}`}
        onClick={toggleAccordion}
      >
        <Text
          mobStyles={[
            mobPageSubHeader,
            css`
              margin: 10px;
            `,
          ]}
        >
          {title}
        </Text>

        <IconContext.Provider
          value={{
            color: theme.colors.blueGray,
            size: '2.75em',
            className: `accordion__icon ${setRotate}`,
          }}
        >
          {icon}
        </IconContext.Provider>
      </Box>

      <div
        ref={content}
        style={{ height: `${setActive ? 'auto' : 0}` }}
        className="accordion__content"
      >
        <Box mobStyles={[mobWrapper, column]}>{children}</Box>
      </div>
      <Text mobStyles={mobHorizontalRule} />
    </>
  )
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  icon: PropTypes.object,
  title: PropTypes.string,
}
