import styled from 'styled-components'
import { up } from 'styled-breakpoints'

export const StyledMainImage = styled.div`
  background-image: url(${props => props.sourceImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: ${props => props.mobHeight};
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius || '0'};
  border-bottom-right-radius: ${props => props.borderBottomRightRadius || '0'};
  display: flex;
  position: relative;
  max-width: 1920px;
  padding: ${props => props.padding || ''};

  ${up('tablet')} {
    height: 600px;
  }

  ${up('desktop')} {
    height: 800px;
  }
`
