import React, { forwardRef } from 'react'

import { StyledBox, StyledMotionBox } from './styles'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Box = forwardRef(({ children, enableMotion, ...props }, ref) => {
  if (enableMotion) {
    return (
      <StyledMotionBox {...props} ref={ref}>
        {children}
      </StyledMotionBox>
    )
  }

  return (
    <StyledBox {...props} ref={ref}>
      {children}
    </StyledBox>
  )
})

Box.propTypes = {
  ...responsiveStyleAndVariantsProps,
}

Box.displayName = 'Box'
