import React, { Suspense, useEffect, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, collections } from '../firebaseApp'
import { ROUTES } from '../constants/routes'
import { LoadingScreen } from '../components/LoadingScreen'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { NavigationModule } from '../modules/NavigationModule'
import { User } from '../classes'
import { Notifications } from '../modules/NotificationsModule/Notifications'
import get from 'lodash/get'

// const devFeatures = process.env.REACT_APP_DEV_FEATURES === 'true'
const devFeatures = false

const NavigationSwitch = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME.link} component={NavigationModule}/>
      <Route exact path={ROUTES.SIGN_IN.link} component={NavigationModule}/>
      <Route exact path={ROUTES.SIGN_UP.link} component={NavigationModule}/>
      <Route exact path={ROUTES.LICENSING.link} component={NavigationModule}/>
      <Route exact path={'/w/account-type'} component={NavigationModule}/>
      <Route exact path={'/w/details'} component={NavigationModule}/>
      <Route exact path={'/w/signup'} component={NavigationModule}/>
      <Route exact path={'/w/login'} component={NavigationModule}/>
      <Route exact path={'/w/gigs'} component={NavigationModule}/>
      <Route exact path={'/w/payment'} component={NavigationModule}/>
      <Route path="*" component={NavigationModule}/>
    </Switch>
  )
}

const NotificationSwitch = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.NOTIFICATIONS.link}/>
      <Route path="*" component={Notifications}/>
    </Switch>
  )
}

const ApplicationRoutes = ({ currentUserAuth, user }) => {
  const userAccountType = user ? get(user, 'accountType') : 1
  
  return (
    
    <>
      <NavigationSwitch/>
      {currentUserAuth && <NotificationSwitch/>}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {Object.keys(ROUTES).map(routeKey => {
            const currRoute = ROUTES[routeKey]
            const { access, exact, link, component: Component } = currRoute
            const userCanAccess =
              Array.isArray(access) && access.includes(userAccountType)
            
            if (userCanAccess) {
              return (
                <Route
                  key={link}
                  exact={exact}
                  path={link}
                  render={props => (
                    <Component
                      {...props}
                      currentUserAuth={currentUserAuth}
                      user={user}
                    />
                  )}
                />
              )
            }
          })}
          <Route
            path={'*'}
            component={ROUTES.HOME.component}
            currentUserAuth={currentUserAuth}
          />
        </Switch>
      </Suspense>
    </>
  )
}

/**
 * These routes are always available to a user that is not logged in.
 *
 * @returns {*}
 * @constructor
 */
const UnauthedRoutes = () => {
  // If the route is not exact it MUST be before the last route will is a catchall
  return (
    <>
      <NavigationSwitch/>
      <Switch>
        <Route
          exact
          path={ROUTES.GUEST_SUPPORT.link}
          component={ROUTES.GUEST_SUPPORT.component}
        />
        <Route
          exact
          path={ROUTES.TERMS_AND_CONDITIONS.link}
          component={ROUTES.TERMS_AND_CONDITIONS.component}
        />
        <Route
          exact
          path={ROUTES.TERMS_OF_SERVICE.link}
          component={ROUTES.TERMS_OF_SERVICE.component}
        />
        <Route
          exact
          path={ROUTES.PRIVACY.link}
          component={ROUTES.PRIVACY.component}
        />
        <Route
          exact
          path={ROUTES.HOME.link}
          component={ROUTES.HOME.component}
        />
        <Route
          exact
          path={ROUTES.SIGN_IN.link}
          component={ROUTES.SIGN_IN.component}
        />
        <Route
          exact
          path={ROUTES.SIGN_UP.link}
          component={ROUTES.SIGN_UP.component}
        />
        <Route
          exact
          path={ROUTES.SEARCH.link}
          component={ROUTES.SEARCH.component}
        />
        <Route exact path={ROUTES.FAQ.link} component={ROUTES.FAQ.component}/>
        <Route
          path={ROUTES.USER_PROFILE.link}
          component={ROUTES.USER_PROFILE.component}
        />
        <Route
          exact
          path={ROUTES.BLOG.link}
          component={ROUTES.BLOG.component}
        />
        <Route exact path={ROUTES.CONTRIBUTORS.link} component={null}/>
        <Route exact path={ROUTES.LICENSING.link} component={null}/>
        <Route path={ROUTES.HOME.link} component={ROUTES.HOME.component}/>
      </Switch>
    </>
  )
}

export const Router = () => {
  const [currentUserAuth, currentUserAuthLoading] = useAuthState(auth)
  const [userData, userDataLoading, userDataError] = useDocumentData(
    currentUserAuth ? collections.userInformation.doc(currentUserAuth.uid) : null,
    {
      idField: 'id',
    },
  )
  
  useEffect(() => {
    if (userDataError) {
      console.error(userDataError)
    }
  }, [userDataError])
  
  const user = useMemo(() => {
    if (userData) {
      return new User(userData)
    }
  }, [userData])
  
  return (currentUserAuth && user) ? (
    <ApplicationRoutes currentUserAuth={currentUserAuth} user={user}/>
  ) : (
    <UnauthedRoutes/>
  )
}
