import { atom } from 'recoil'

/*An atom represents a piece of state. Atoms can be read from and written to from any component. Components that read
 the value of an atom are implicitly subscribed to that atom, so any atom updates will result in a re-render of all
 components subscribed to that atom */
export const generalState = atom({
  key: 'generalState', // unique ID (with respect to other atoms/selectors)
  default: {
    showGoToUpdateButton: false,
    showNotifications: true,
  }, // default value (aka initial value)
})
