import styled, { css } from 'styled-components'
import { variantsAndStyles } from '../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

const boxStyles = css`
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;

  ${variantsAndStyles()}
`

export const StyledMotionBox = styled(motion.div)`
  ${boxStyles}
`

export const StyledBox = styled.div`
  ${boxStyles}
`
