import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { css } from 'styled-components'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import 'firebase/storage'

import { collections, functions, licenseImageStorage } from '../../firebaseApp'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import {
  Box,
  Button,
  Input,
  inputVariants,
  mobBoxVariants,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../shared/index'
import { LoadingScreen } from '../../components/LoadingScreen'
import { uuid } from '../../utils/uuid'
import { LicenseImageStatus } from '../../constants/enums'
import { LicenseImageWithModal } from '../../components/LicenseImageWithModal'
import { PrivateUser } from '../../classes'
import { TagInput } from '../../components/Inputs/TagInput'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { LicenseImage } from '../../classes/Models/LicenseImage'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { MessageBox } from '../../components/MessageBox'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const mobFilenameStyles = css`
  width: 100%;
  text-align: right;
  padding: 0 15px 0 15px;
`

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const { column, mobWrapper, mobMultiImageWrapper, row, center } = mobBoxVariants
const { mobInput } = inputVariants
const {
  mobPageHeader,
  mobPageSubHeader,
  mobInstructionParagraph,
} = mobTextVariants
const { mobSubmitButton, mobLong305x46 } = mobButtonVariants
const { deskPageHeader } = deskTextVariants

const createImageLicensePrice = functions.httpsCallable(
  'createImageLicensePrice'
)

export const ManageLicensingPage = ({ user, currentUserAuth }) => {
  const [privateUser, setPrivateUser] = useState(new PrivateUser())

  const [fileName, setFileName] = useState('')
  const [exclusivePrice, setExclusivePrice] = useState(null)
  const [monthlyPrice, setMonthlyPrice] = useState(null)
  const [imageTags, setImageTags] = useState(['Example'])
  const [
    privateUserSnapshot,
    privateUserSnapshotLoading,
    privateUserSnapshotError,
  ] = useDocument(collections.privateUserInformation.doc(currentUserAuth.uid))
  // user_id
  const [
    licenseImagesSnapshot,
    licenseImagesSnapshotLoading,
    licenseImagesSnapshotError,
  ] = useCollection(
    collections.licenseImages.where('createdBy', '==', currentUserAuth.uid)
  )

  useEffect(() => {
    setPrivateUser(new PrivateUser(privateUserSnapshot))
  }, [privateUserSnapshot])

  // Method/Hooks to handle Licensable Image Submission
  const handleLicenseImageSubmit = event => {
    event.preventDefault()
    const portfolioStorageRef = licenseImageStorage.ref()
    const userPortfolioImageReference = portfolioStorageRef.child(
      currentUserAuth.uid
    )

    const imageInput = document.getElementById('licensing-image-input')

    const file = imageInput.files && imageInput.files[0]
    const uniqueId = uuid()

    if (file) {
      collections.licenseImages
        .add({
          uniqueId: uniqueId,
          uploadedAt: firebase.firestore.Timestamp.now(),
          exclusivePrice: Number(exclusivePrice) * 100,
          monthlyPrice: Number(monthlyPrice) * 100,
          exclusiveAvailable: !!exclusivePrice,
          monthlyAvailable: !!monthlyPrice,
          createdBy: currentUserAuth.uid,
          tags: imageTags,
          status: LicenseImageStatus.active,
          orderMap: {},
          visibleInSearch: true,
        })
        .then(licenseImageReference => {
          /**
           * This cloud functions creates the image license exclusive prices in Stripe
           * and saves the price id to the LicenseImage to be used on purchase.
           */
          createImageLicensePrice({
            uniqueId: uniqueId,
            userUid: user.userId,
            licenseImageId: licenseImageReference.id,
            exclusivePrice: Number(exclusivePrice) * 100,
            monthlyPrice: Number(monthlyPrice) * 100,
          })
            .then(() => {})
            .catch(error => {
              errorHandler({
                error,
                errorId: 'ERROR_CREATING_LICENSABLE_PHOTO_PRICE',
                message: 'Error while trying to create a photo license price.',
                type: errorTypes.billing,
                user: currentUserAuth && currentUserAuth.uid,
                file: 'ManageLicensePage.jsx',
              })
            })

          const uploadTask = userPortfolioImageReference
            .child('/user-license-image-' + uniqueId)
            .put(file, {
              customMetadata: {
                userUid: user.userId,
                licenseImageId: licenseImageReference.id,
              },
            })

          uploadTask.on(
            'state_changed',
            () => {},
            // snapshot => {
            //   // Observe state change events such as progress, pause, and resume
            //   // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            //   let progress =
            //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            //   switch (snapshot.state) {
            //     case firebaseStorageModule.TaskState.PAUSED: // or 'paused'
            //       break
            //     case firebaseStorageModule.TaskState.RUNNING: // or 'running'
            //       break
            //     default:
            //     // code block
            //   }
            // },
            () => {}
          )
          //
          //
        })
        .then(() => {
          // Reset the image form
          imageInput.value = ''
          setFileName('')
          setMonthlyPrice(null)
          setExclusivePrice(null)
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_SUBMITTING_LICENSABLE_PHOTO',
            message: 'Error while user trying to submit licensable photo.',
            type: errorTypes.profile,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'ManageLicensePage.jsx',
          })
        })
    }
  }

  const displayFileExplorer = () => {
    let inputElement = document.getElementById('licensing-image-input')
    inputElement.click()
  }

  const dependenciesLoading =
    licenseImagesSnapshotLoading || privateUserSnapshotLoading
  const dependenciesError =
    licenseImagesSnapshotError || privateUserSnapshotError
  const dependencies = privateUserSnapshot && licenseImagesSnapshot
  // TODO: Don't allow 'e' in the number inputs
  return (
    <DesktopPageWrapper>
      {dependenciesError && <strong>Error: {privateUserSnapshotError}</strong>}
      {dependenciesLoading && <LoadingScreen />}
      {dependencies && (
        <Box mobStyles={[column, mobWrapper]}>
          <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
            Manage License Photos
          </Text>
          <Text mobStyles={[mobPageSubHeader]}>
            Your Images Available For Licensing
          </Text>
          {licenseImagesSnapshot.docs.length <= 0 && (
            <MessageBox
              header="None"
              message="Your images that can be licensed will appear here when created."
            />
          )}

          {licenseImagesSnapshot.docs.length > 0 && (
            <>
              <Text mobStyles={mobInstructionParagraph}>
                Click your license image to edit and control availability
                settings
              </Text>
              <Box mobStyles={mobMultiImageWrapper}>
                {licenseImagesSnapshot.docs.map((imageDocument, index) => {
                  if (imageDocument.data().largeSizeUrl) {
                    return (
                      <LicenseImageWithModal
                        index={index}
                        licenseImage={new LicenseImage(imageDocument)}
                        userId={currentUserAuth.uid}
                      />
                    )
                  }
                })}
              </Box>
            </>
          )}

          <Text mobStyles={[mobPageSubHeader]}>
            Add a new photo for licensing
          </Text>
          <Box
            onClick={displayFileExplorer}
            mobStyles={[
              row,
              mobInput,
              css`
                line-height: 16px;
                overflow: hidden;
              `,
            ]}
          >
            <Text
              mobStyles={[
                css`
                  width: 100%;
                `,
              ]}
            >
              Choose File
            </Text>
            <Text mobStyles={mobFilenameStyles}>{fileName}</Text>
          </Box>

          <Input
            onChange={() => {
              const imageInput = document.getElementById(
                'licensing-image-input'
              )

              const file = imageInput.files && imageInput.files[0]
              setFileName(file.name.substr(0, 20))
            }}
            type="file"
            id="licensing-image-input"
            name="file"
            hidden
          />

          <InputWithHeader
            header="EXCLUSIVE PRICE"
            inputName="fullName"
            onChange={event => setExclusivePrice(event.target.value)}
            placeholderDefault="Example: $100"
            type="number"
          />
          <InputWithHeader
            header="MONTHLY PRICE"
            inputName="height"
            onChange={event => setMonthlyPrice(event.target.value)}
            placeholderDefault="Example: $10"
            type="number"
          />
          <TagInput imageTags={imageTags} setImageTags={setImageTags} />
          <Box
            mobStyles={[
              center,
              css`
                padding: 15px 0 15px 0;
              `,
            ]}
          >
            <Button
              disabled={!fileName || (!exclusivePrice && !monthlyPrice)}
              mobStyles={[mobSubmitButton, mobLong305x46]}
              onClick={handleLicenseImageSubmit}
            >
              ADD IMAGE
            </Button>
          </Box>
        </Box>
      )}
    </DesktopPageWrapper>
  )
}
