import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'
import './index.css'
import './fonts.css'

import { App } from './components'
import { theme } from './theme'

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </RecoilRoot>,
  document.getElementById('root')
)
