import { css } from 'styled-components'
import GoogleMapExample from '../../assets/photos/detroit-map.png'
import { up } from 'styled-breakpoints'

export const deskImageVariants = {
  darkGreyBackground: css`
    // just an example
    background-color: ${p => p.theme.colors.darkGray};
  `,
  mobileGridImage: css`
    height: 180px;
    width: 164px;
    border-radius: 5px;
    box-shadow: ${p => p.theme.shadow.regular};
  `,
  mobileProfilePicture: css`
    height: 117px;
    width: 117px;
    box-shadow: ${p => p.theme.shadow.regular};
    border-radius: 100%;
  `,
  mobUserProfileImageMini: css`
    margin: 0;
    height: 70%;
    width: 100%;
    color: ${p => p.theme.colors.blueGray};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: cover;
    transform: scaleX(-1);
  `,
  mobUserProfileImageRequest: css`
    margin: 0;
    height: 75%;
    width: 75%;
    color: ${p => p.theme.colors.blueGray};
    border-radius: 100%;
    background-size: cover;
    transform: scaleX(-1);
  `,
  deskGigLocationImage: css`
    border-radius: 10px;
    height: 40vw;
    width: 50vw;
    color: ${p => p.theme.colors.blueGray};
    background-image: url(${GoogleMapExample});
    background-size: cover;
    margin: 8px 0 20px;
  `,

  // Grey Bam Logo
  deskGreyBamLogo: css`
    width: 70px;
    margin: 20px auto 15px;
  `,

  deskImageSquare: css`
    border: 1px solid ${p => p.theme.colors.lightGray};
    margin-top: 1%;
    margin-bottom: 1%;
    display: inline-flex;
    border-radius: 10px;
    box-shadow: ${p => p.theme.shadow.regular};
    max-height: 32vw;
    width: 32vw;
    max-width: 100%;
  `,

  mobImageFullWidthSquare: css`
    border: 1px solid ${p => p.theme.colors.lightGray};
    margin-top: 3.5%;
    margin-bottom: 2.5%;
    display: inline-flex;
    max-height: 335px;
    min-height: 335px;
    border-radius: 10px;
    box-shadow: ${p => p.theme.shadow.regular};
  `,
  mobImageSquare320: css`
    border: 1px solid ${p => p.theme.colors.lightGray};
    margin-top: 3.5%;
    margin-bottom: 2.5%;
    display: inline-flex;
    width: 48%;
    max-height: 320px;
    min-height: 320px;
    max-width: 320px;
    min-width: 320px;
    border-radius: 5px;
    box-shadow: ${p => p.theme.shadow.regular};
    object-fit: cover;

    :after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  `,

  mobProfileImage: css`
    height: 25vh;
    width: 48%;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 5px;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin-top: 2%;
    margin-bottom: 3%;
    display: inline-flex;
    background-size: cover;
  `,

  deskProfilePhoto: css`
    height: 300px;
    width: 300px;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 100%;
    background-color: ${p => p.theme.colors.secondary};
    box-shadow: ${p => p.theme.shadow.regular};
    margin: 25px 0 0 25px;
    background-size: contain;
  `,
  deskLeftProfilePhoto: css`
    border-radius: 5px;
    height: 30vw;
    width: 30vw;
    border: 1px solid ${p => p.theme.colors.lightGray};
    background-color: ${p => p.theme.colors.secondary};
    margin: 0 auto 3%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `,

  mobHeaderLogo: css`
    display: none;
    position: absolute;
    top: 18px;
    left: 22px;
    width: 180px;
    ${up('tablet')} {
      top: 40px;
      left: 22px;
      width: 180px;
    }

    ${up('desktop')} {
      display: unset;
      width: 350px;
      top: 65px;
      left: 60px;
    }
  `,
}
