import React from 'react'
import { Box, Input } from '../../shared/index'
import {
  mobModelRequestSearchInput,
  mobSearchBarIcon,
  mobSearchBarWrapper,
  mobSearchIcon,
} from './styles'
import { IconContext } from 'react-icons'
import { FaSearch } from 'react-icons/fa'
import { useTheme } from 'styled-components'

/**
 * This is a user only search bar that will eventually be used to search for users to send offers to directly
 * after creating the Gig itself. Not sure when this feature will actually be added to the App. More of a
 * nicety than V1 feature
 *
 * @param props
 * @constructor
 */
// TODO V2 component
export const SimpleSearchBar = () => {
  const theme = useTheme()
  return (
    <Box mobStyles={mobSearchBarWrapper}>
      <Input
        mobStyles={mobModelRequestSearchInput}
        placeholder="What are you looking for?"
      />
      <Box mobStyles={mobSearchBarIcon}>
        <IconContext.Provider
          value={{
            color: theme.colors.white,
          }}
        >
          <Box mobStyles={mobSearchIcon}>
            <FaSearch />
          </Box>
        </IconContext.Provider>
      </Box>
    </Box>
  )
}
