import { css } from 'styled-components'

export const popoverStyle = css`
  position: absolute;
  z-index: 10;
  overflow-y: auto;
  width: 100%;
  //height: ${p => p.height}px;
  height: 1000px;
  //max-height: 215px;
  border: 1px solid rgb(223, 223, 223);
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  background-color: ${p => p.theme.colors.secondary};
  font-weight: 700;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  //bottom: -${p => p.height}px;
  bottom: -1000px;
  flex-direction: column;
  box-sizing: border-box;
`
