import React, { useRef, useState } from 'react'
import {
  Box,
  Checkbox,
  mobBoxVariants,
  mobTextVariants,
  Text,
} from '../../shared/'
import { css } from 'styled-components'
import 'rc-time-picker/assets/index.css'
import firebase from 'firebase/app'

import { Label } from '../../shared'
import { collections } from '../../firebaseApp'
import {
  AutoCompleteLocationInput,
  DesktopPageWrapper,
  InputWithHeader,
  LoadingButton,
  StickyFooter,
  StickyFooterWithButton,
  TextAreaWithHeader,
  TimeInputWithHeader,
} from '../../components'
import { CreateGigForm } from '../../classes/Forms/CreateGigForm'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'

const { column, mobWrapper, row } = mobBoxVariants
const { mobPageHeader, mobInstructionParagraph } = mobTextVariants
const { mobInputHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

const deskPageCenterWrapper = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const CreateGigPage = ({ history, currentUserAuth }) => {
  const [showLoading, setShowLoading] = useState(false)
  const [submitFormDisabled, setSubmitFormDisabled] = useState(false)
  const [fixedPriceInputEnabled, setFixedPriceInputEnabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [formDate, setFormDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
  )

  const updateDateValue = date => {
    setFormDate(date)
  }

  const clearErrors = () => {
    setErrorMessage(null)
    setSubmitFormDisabled(false)
  }

  // Use the same instance of CreateEventForm every render
  const createGigForm = useRef(
    new CreateGigForm(updateDateValue, fixedPriceInputEnabled, clearErrors)
  ).current

  const validateGigForm = () => {
    if (isEmpty(trim(createGigForm.title))) {
      setSubmitFormDisabled(true)
      setErrorMessage('Title cannot be empty.')
      return false
    }

    if (
      fixedPriceInputEnabled &&
      (!Number.isInteger(createGigForm.fixedPricePennies) ||
        createGigForm.fixedPricePennies <= 0)
    ) {
      setSubmitFormDisabled(true)
      setErrorMessage(
        'Fixed price is not a non-zero number. It must be 1 or more.'
      )
      return false
    }

    if (
      createGigForm.time.date === undefined ||
      createGigForm.time.startTime === undefined ||
      createGigForm.time.endTime === undefined
    ) {
      setSubmitFormDisabled(true)
      setErrorMessage('Gig times cannot be empty.')
      return false
    }

    if (
      isEmpty(trim(createGigForm.location.address)) ||
      isEmpty(trim(createGigForm.location.latLong.lat)) ||
      isEmpty(trim(createGigForm.location.latLong.lng)) ||
      isEmpty(trim(createGigForm.location.locationMobileImageUrl))
    ) {
      setSubmitFormDisabled(true)
      setErrorMessage('Address cannot be empty.')
      return false
    }

    if (
      !createGigForm.timeRangeIsLogical(
        createGigForm.time.startTime,
        createGigForm.time.endTime
      )
    ) {
      setSubmitFormDisabled(true)
      setErrorMessage('Gig start time cannot be after the end time.')
      return false
    }

    if (isEmpty(trim(createGigForm.description))) {
      setSubmitFormDisabled(true)
      setErrorMessage('Gig description cannot be empty.')
      return false
    }

    // Calculate Duration at the last moment
    createGigForm.addGigDuration(
      createGigForm.time.startTime,
      createGigForm.time.endTime
    )
    if (createGigForm.time.duration < 0) {
      setSubmitFormDisabled(true)
      setErrorMessage('Gig cannot start and end at the same time')
      return false
    }

    setSubmitFormDisabled(false)
    return true
  }

  // Handle Submit Gig
  const submitGigHandler = () => {
    setShowLoading(true)
    const { gigs } = collections
    let serverTimestamp = firebase.firestore.Timestamp.now()
    if (validateGigForm()) {
      gigs
        .add({
          title: createGigForm.title,
          time: {
            date: createGigForm.time.date,
            startTime: createGigForm.time.startTime,
            endTime: createGigForm.time.endTime,
            duration: createGigForm.time.duration,
          },
          location: {
            address: createGigForm.location.address,
            latLong: {
              lat: createGigForm.location.latLong.lat,
              lng: createGigForm.location.latLong.lng,
            },
            locationMobileImageUrl:
              createGigForm.location.locationMobileImageUrl,
          },
          description: createGigForm.description,
          acceptingProposals: createGigForm.acceptingProposals,
          createdAt: serverTimestamp,
          status: 1,
          createdBy: currentUserAuth.uid,
          applicants: 0,
          visibleInSearch: false,
          fixedPriceGig: fixedPriceInputEnabled,
          fixedPrice: fixedPriceInputEnabled
            ? Number(createGigForm.fixedPricePennies)
            : null,
        })
        .then(gigDocument => {
          // Redirect to the the created gig
          history.push(`/gig/${gigDocument.id}`)
        })
        .catch(error => {
          setShowLoading(false)
          errorHandler({
            error,
            errorId: 'ERROR_SUBMIT_GIG',
            message: 'Failed to submit gig.',
            type: errorTypes.creation,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'CreateGigPage.jsx',
          })
        })
    } else {
      setShowLoading(false)
    }
  }

  return (
    <DesktopPageWrapper>
      <Box mobStyles={[column, mobWrapper]} deskStyles={deskPageCenterWrapper}>
        <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
          Create New Gig
        </Text>
        <Text mobStyles={mobInstructionParagraph}>
          Here you can create a new gig. After specifying a few details the gig
          will be viewable by all creatives who can then make a proposal to be
          selected to become a part of your gig.
        </Text>
        <InputWithHeader
          header="GIG TITLE"
          inputName="eventTitle"
          onChange={createGigForm.updateTitle}
          placeholder="Short description of gig"
        />

        <Box
          mobStyles={[
            row,
            css`
              display: flex;
              align-items: center;
              margin: 30px 0 0 0;
            `,
          ]}
        >
          <Text
            mobStyles={[
              mobInputHeader,
              css`
                width: auto;
                flex: 1;
              `,
            ]}
          >
            FIXED PRICE?
          </Text>
          <Checkbox
            mobStyles={[
              css`
                /* Double-sized Checkboxes */
                -ms-transform: scale(3); /* IE */
                -moz-transform: scale(3); /* FF */
                -webkit-transform: scale(3); /* Safari and Chrome */
                -o-transform: scale(3); /* Opera */
                transform: scale(3);
              `,
            ]}
            onChange={() => {
              setFixedPriceInputEnabled(!fixedPriceInputEnabled)
              clearErrors()
            }}
            checked={fixedPriceInputEnabled}
          />
        </Box>

        <Text
          mobStyles={[
            mobInstructionParagraph,
            css`
              height: auto;
              width: auto;
              color: ${p => p.theme.colors.blueGray};
              font-family: ${p => p.theme.fonts.secondary};
              margin: 5px 0 7.5px;
              opacity: 0.71;
              font-size: 16px;
              line-height: 30px;
            `,
          ]}
        >
          Instead of paying models at their requested rate, gigs that use this
          option will only pay the amount specified with no negotiation.
        </Text>

        {fixedPriceInputEnabled && (
          <InputWithHeader
            header="FIXED PRICE AMOUNT"
            type="number"
            inputName="gigFixedPriceAmount"
            onChange={createGigForm.updateFixedPrice}
            placeholder="Amount you will pay PER MODEL (e.g. $100 per model)"
          />
        )}
        <Text mobStyles={mobInputHeader}>DATE</Text>
        <Calendar
          onChange={createGigForm.updateDate}
          value={formDate}
          next2Label={null}
          prev2Label={null}
          className="availability-calendar"
          tileDisabled={({ date }) => {
            return date < new Date()
          }}
        />
        <TimeInputWithHeader
          header="GIG TIMES"
          startTimeHandler={createGigForm.updateStartTime}
          endTimeHandler={createGigForm.updateEndTime}
        />

        <Label mobStyles={mobInputHeader} htmlFor="Gig Address">
          GIG ADDRESS
        </Label>
        <AutoCompleteLocationInput
          locationHandler={createGigForm.updateAddress}
          inputName="Gig Address"
        />
        <TextAreaWithHeader
          header="GIG DESCRIPTION"
          inputName="eventDescription"
          onChange={createGigForm.updateDescription}
          placeholderDefault="What are you looking for?"
        />
      </Box>
      {showLoading && (
        <StickyFooter>
          <LoadingButton />
        </StickyFooter>
      )}
      {!showLoading && (
        <StickyFooterWithButton
          mainButtonDisabled={submitFormDisabled}
          mainButtonHandler={submitGigHandler}
          mainButtonText="CREATE GIG"
          errorText={errorMessage}
        />
      )}
    </DesktopPageWrapper>
  )
}
//#fd5f60
