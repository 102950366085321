import styled, { css } from 'styled-components'

import { variantsAndStyles } from '../../utils/variantsAndStyles'
import { motion } from 'framer-motion'

const formStyles = css`
  border: none;
  box-sizing: border-box;
  ${variantsAndStyles()}
`

export const StyledForm = styled.form`
  ${formStyles}
`

export const StyledMotionForm = styled(motion.form)`
  ${formStyles}
`
