import { User } from './User'
import { Gig } from './Gig'
import { Request } from './Request'

/**
 * Booking is a object that consists of an Gig and a Gig Request
 *
 */
export class Booking {
  gig
  operative
  request

  // serviceRate = 0.15 // 15%
  /**
   *
   * @param gig
   * @param operative {User}
   * @param request
   */
  constructor(
    gig = new Gig(),
    operative = new User(),
    request = new Request()
  ) {
    this.gig = gig
    this.operative = operative
    this.request = request
  }

  // Stripe treats all money as pennies  $10.00 === 1000
  // Stripe treats all money as pennies  $10.00 === 1000
  // Stripe treats all money as pennies  $10.00 === 1000
  // Stripe treats all money as pennies  $10.00 === 1000
  // Stripe treats all money as pennies  $10.00 === 1000
  getBookingSubtotalAgencyDisplay = () => {
    if (this.gig.fixedPriceGig) {
        return this.gig.fixedPriceDisplay
      } else {
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(this.gig.duration * this.operative.getUserHourlyRate() * 0.15)
      }

    }

    getBookingFeesAgencyDisplay = () => {
      if (this.gig.fixedPriceGig) {
        // Fixed Price stored as pennies
        return this.gig.agencyFixedFeesDisplay
      } else {
        // agencyFixedFeesDisplay
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(this.gig.duration * this.operative.getUserHourlyRate() * 0.15)
      }
    }

    getBookingGrandTotalAgencyDisplay = () => {
      if (this.gig.fixedPriceGig) {
        // Fixed Price stored as pennies
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(
          (this.gig.fixedPricePennies + this.operative.getUserHourlyRate() * 0.15) / 100
        )
      } else {
        // agencyFixedFeesDisplay
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(
          this.gig.duration * this.operative.getUserHourlyRate() * 0.15) / 100
      }
    }

    getBookingPayOperativeDisplay = () => {
      if (this.gig.fixedPriceGig) {
        return this.gig.operativeFixedPayDisplay
      } else {
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(this.gig.duration * this.operative.getUserHourlyRate() * 0.85) / 100
      }
    }

    getBookingFeesOperativeDisplay = () => {
      if (this.gig.fixedPriceGig) {
        // Fixed Price stored as pennies
        return this.gig.operativeFixedFeesDisplay
      } else {
        // agencyFixedFeesDisplay
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(this.gig.duration * this.operative.getUserHourlyRate() * 0.15) / 100
      }
    }

    getBookingGrossPayOperativeDisplay = () => {
      if (this.gig.fixedPriceGig) {
        return this.gig.fixedPriceDisplay
      } else {
        // agencyFixedFeesDisplay
        return new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: 'USD',
        }).format(this.gig.duration * this.operative.getUserHourlyRate())
      }
    }

    getBookingSubtotal = () => {
      if (this.gig.fixedPriceGig) {
        // Fixed Price stored as pennies
        console.log(
          'Number(this.gig.fixedPrice)',
          Number(this.gig.fixedPricePennies)
        )
        return Number(this.gig.fixedPricePennies)
      } else {
        return (
          Number(this.operative.getStripeUserHourlyRate()) *
          Number(this.gig.duration)
        )
      }
    }

    getBookingServiceCharge = () => {
      console.log(
        'getBookingServiceCharge',
        Number(this.getBookingSubtotal()) * 0.15
      )
      return Number(this.getBookingSubtotal()) * 0.15
    }

    getBookingGrandTotal = () => {
      return (
        Number(this.getBookingSubtotal()) + Number(this.getBookingServiceCharge())
      )
    }

    getStripeGrandTotal = () => {
      //Grand total should be
      // Subtotal: Operative Payment
      // +
      // Service Fees: 15% of Subtotal
      //
      // Convert back to pennies
      return Math.round(
        Number(this.getBookingSubtotal()) + Number(this.getBookingServiceCharge())
      )
    }

    getOperativePay = () => {
      if (this.gig.fixedPriceGig) {
        return Number(this.gig.fixedPricePennies) * 0.85
      } else {
        return (
          Number(this.operative.getUserHourlyRate()) *
          Number(this.gig.duration) *
          0.85
        )
      }
    }

    getOperativePayPennies = () => {
      if (this.gig.fixedPriceGig) {
        return Number(this.gig.fixedPricePennies) * 0.85
      } else {
        return Number(this.getOperativePay()) * 100
      }
    }

    getOperativeFees = () => {
      if (this.gig.fixedPriceGig) {
        return Number(this.gig.fixedPricePennies) * 0.15
      } else {
        return (
          Number(this.operative.getUserHourlyRate()) *
          Number(this.gig.duration) *
          0.15
        )
      }
    }
  }
