import firebase from 'firebase/app'
import { RequestStatus, RequestType } from '../../constants/enums'
import get from 'lodash/get'
import without from 'lodash/without'

/**
 * Request is a object that consists of an Gig document and helper methods
 *
 */
export class Request {
  request
  requestData
  requestId
  createdAt

  duration
  eventId
  eventCreator
  requestCreator
  type
  proposal
  status
  operative
  operativeRate
  stripeToken

  accepted
  pending
  declined
  completed

  isRequestOperative
  stripeOperativePay
  stripeGrandTotal

  supportTicketId

  // Saved response from Stripe
  paymentResponse
  
  constructor(
    request,
    requestId = '',
    createdAt = firebase.firestore.Timestamp.now(),
    duration = 0,
    eventId = '',
    creator = '',
    type = RequestType.notAvailable,
    proposal = '',
    status = RequestStatus.notAvailable,
    operative = '',
    operativeRate = 0,
    stripeOperativePay = 0,
    stripeGrandTotal = 0
  ) {
    if (request) {
      this.request = request
      this.requestData = request && request.data ? request.data() : request
      this.requestId = request.id
    }

    this.createdAt = get(this.requestData, 'createdAt', createdAt)
    this.eventId = get(this.requestData, 'eventId', eventId)
    this.duration = get(this.requestData, 'duration', duration)
    this.eventCreator = get(this.requestData, 'eventCreator', creator)
    this.requestCreator = get(this.requestData, 'requestCreator', creator)
    this.type = get(this.requestData, 'requestType', type)
    this.proposal = get(this.requestData, 'proposal', proposal)
    this.status = get(this.requestData, 'status', status)
    this.operative = get(this.requestData, 'operative', operative)
    this.operativeRate = get(this.requestData, 'operativeRate', operativeRate)
    this.stripeGrandTotal = get(
      this.requestData,
      'stripeGrandTotal',
      stripeGrandTotal
    )
    this.stripeOperativePay = get(
      this.requestData,
      'stripeOperativePay',
      stripeOperativePay
    )

    this.ownerReviewForOperative = get(
      this.requestData,
      'ownerReviewForOperative',
      ''
    )
    this.ownerReviewedAt = get(this.requestData, 'ownerReviewedAt', createdAt)
    this.ownerRatingForOperative = get(
      this.requestData,
      'ownerRatingForOperative',
      ''
    )
    this.ownerRatingForOperativeComplete = get(
      this.requestData,
      'ownerRatingForOperativeComplete',
      false
    )
    this.operativeReviewForOwner = get(
      this.requestData,
      'operativeReviewForOwner',
      ''
    )
    this.operativeReviewedAt = get(
      this.requestData,
      'operativeReviewedAt',
      createdAt
    )
    this.operativeRatingForOwner = get(
      this.requestData,
      'operativeRatingForOwner',
      ''
    )
    this.operativeRatingForOwnerComplete = get(
      this.requestData,
      'operativeRatingForOwnerComplete',
      false
    )
    this.supportTicketId = get(this.requestData, 'supportTicketId', '')
    this.paymentResponse = get(this.requestData, 'paymentResponse', {})
    this.fixedPriceGig = get(this.requestData, 'fixedPriceGig', false)
    this.fixedPriceDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format(get(this.requestData, 'fixedPrice', 0) / 100)
    this.fixedPricePennies = get(this.requestData, 'fixedPrice', 0)
  
  
    this.operativeFixedPayDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format((get(this.requestData, 'fixedPrice', 0) * .85 )/ 100)
    this.operativeFixedFeesDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format((get(this.requestData, 'fixedPrice', 0) * .15 )/ 100)
    this.operativeFixedPayPennies = (get(this.requestData, 'fixedPrice', 0) * .85 )
    this.operativeFixedFeesPennies = (get(this.requestData, 'fixedPrice', 0) * .15 )

    this.accepted = this.status === RequestStatus.accepted
    this.pending = this.status === RequestStatus.pending
    this.declined = this.status === RequestStatus.declined
    this.completed = this.status === RequestStatus.completed

    this.inactive = this.status === RequestStatus.inactive
    this.canceled = this.status === RequestStatus.canceled
    this.disputed = this.status === RequestStatus.disputed
    this.deleted = this.status === RequestStatus.deleted
    this.awaitingBAM = this.status === RequestStatus.awaitingBAM
    this.notAvailable = this.status === RequestStatus.notAvailable
  }
  
  getAgencyGrandTotal = () => {
    if(this.fixedPriceGig) {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
      }).format(
        (this.fixedPricePennies + (this.fixedPricePennies * .15)) / 100
      )
    } else {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
      }).format(
        ((this.operativeRate * this.duration) + ((this.operativeRate * this.duration) * .15) )
        / 100
      )
    }
  }
  
  getOperativePayWithoutFees = () => {
    if(this.fixedPriceGig) {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
      }).format(
        (this.fixedPricePennies * .85) / 100
      )
    } else {
      return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
      }).format(
        ((this.operativeRate * this.duration) * .85 )
        / 100
      )
    }
  }
  
  isGigCreator = userId => {
    return this.eventCreator === userId
  }

  isOperative = userId => {
    return this.operative === userId
  }

  isOwner = userId => {
    return this.eventCreator === userId
  }

  isRequestCreator = userId => {
    return this.requestCreator === userId
  }

  isOffer = () => {
    return this.type === RequestType.offer
  }

  isProposal = () => {
    return this.type === RequestType.proposal
  }

  /**
   * Util to get the other user on the request regardless of role
   * @param currentUserId
   * @returns {*}
   */
  getUserB = currentUserId => {
    const bothUsers = [this.eventCreator, this.operative]

    return without(bothUsers, currentUserId)[0]
  }
}
