import React, { useState } from 'react'

import { auth } from '../../../firebaseApp'
import { ROUTES } from '../../../constants/routes'
import { InputWithHeader, LoadingButton } from '../../index'
import { css } from 'styled-components'
import {
  Box,
  Button,
  Link,
  mobBoxVariants,
  mobButtonVariants,
  mobTextVariants,
  Text,
} from '../../../shared'
import { deskFieldLabelText } from '../styles'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'
import { useHistory } from 'react-router-dom'
import { deskTextVariants } from '../../../shared/Text/deskTextVariants'
import { haveAnAccountTextMobStyles } from '../../../pages/SignUp'

const { mobLong305x46, mobSubmitButton } = mobButtonVariants
const { deskSubmitButton } = deskTextVariants
const { mobSignUpText } = mobTextVariants
const { column } = mobBoxVariants

const mobSubmitButtonStyles = css`
  margin-top: 30px;
`

const moblegalLinks = css`
  color: ${p => p.theme.colors.blueGray};
  font-size: 15px;
  text-decoration: underline;
`

const desklegalLinks = css`
  font-size: 18px;
`

export const EmailSignUpForm = ({ nextPageLink }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const history = useHistory()

  const handleSignUp = event => {
    event.preventDefault()
    setIsSubmitting(true)

    if (password !== confirmPassword) {
      setError('The passwords do not match.')
      setIsSubmitting(false)
      return
    }

    auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        history.push(nextPageLink)
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            setError('The email is already taken.')
            break
          case 'auth/invalid-email':
            setError('The email you entered is invalid.')
            break
          case 'auth/weak-password':
            setError(
              'The password you used is too weak. Please use a stronger password.'
            )
            break
          default:
            setError('Account creation failure, please try again.')
        }
        setIsSubmitting(false)
        errorHandler({
          error,
          errorId: 'ERROR_USER_SIGN_UP',
          message: 'There was an error when user was trying to sign up',
          type: errorTypes.auth,
          file: 'EmailSignUpForm.jsx',
        })
      })
  }

  return (
    <>
      <InputWithHeader
        headerStyles={{ deskStyles: deskFieldLabelText }}
        header="EMAIL ADDRESS"
        inputName="email"
        type="email"
        onChange={event => {
          setError(null)
          setEmail(event.target.value)
        }}
        placeholderDefault="Enter Email"
        boldHeader={true}
        value={email}
      />
      <InputWithHeader
        headerStyles={{ deskStyles: deskFieldLabelText }}
        header="PASSWORD"
        inputName="password"
        type="password"
        onChange={event => {
          setError(null)
          setPassword(event.target.value)
        }}
        placeholderDefault="Enter Password"
        boldHeader={true}
        value={password}
      />
      <InputWithHeader
        headerStyles={{ deskStyles: deskFieldLabelText }}
        header="CONFIRM PASSWORD"
        inputName="confirm-password"
        type="password"
        autoComplete={true}
        onChange={event => {
          setError(null)
          setConfirmPassword(event.target.value)
        }}
        placeholderDefault="Confirm Password"
        boldHeader={true}
        value={confirmPassword}
      />
      {error && (
        <Text
          mobStyles={css`
            color: red;
            margin: 0;
          `}
        >
          {error}
        </Text>
      )}
      {isSubmitting && (
        <Box mobStyles={mobBoxVariants.center}>
          <LoadingButton mobStyles={mobSubmitButtonStyles} />
        </Box>
      )}
      {!isSubmitting && (
        <Box mobStyles={[mobBoxVariants.center, column]}>
          <Button
            mobStyles={[mobSubmitButton, mobLong305x46, mobSubmitButtonStyles]}
            deskStyles={deskSubmitButton}
            onClick={handleSignUp}
          >
            SIGN UP
          </Button>
          <Text mobStyles={[mobSignUpText, haveAnAccountTextMobStyles]}>
            By creating an account you agree to our{' '}
            <Link
              mobStyles={[moblegalLinks]}
              deskStyles={[desklegalLinks]}
              to={ROUTES.PRIVACY.link}
            >
              privacy policy
            </Link>
            ,{' '}
            <Link
              mobStyles={[moblegalLinks]}
              deskStyles={[desklegalLinks]}
              to={ROUTES.TERMS_OF_SERVICE.link}
            >
              terms of service
            </Link>
            , and{' '}
            <Link
              mobStyles={[moblegalLinks]}
              deskStyles={[desklegalLinks]}
              to={ROUTES.TERMS_AND_CONDITIONS.link}
            >
              terms and conditions
            </Link>
            .
          </Text>
        </Box>
      )}
    </>
  )
}
