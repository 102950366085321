import React from 'react'
import { auth } from '../../../firebaseApp'
import { ROUTES } from '../../../constants/routes'
import { errorHandler, errorTypes } from '../../../utils/errorHandler'

/**
 * Component that logs the current user out and
 * redirects them to the sign in page
 *
 * @param history
 * @constructor
 */
export const SignOutButton = ({ history }) => {
  const logout = () => {
    auth
      .signOut()
      .then(() => {})
      .catch(error => {
        errorHandler({
          error,
          errorId: 'ERROR_USER_LOG_OUT',
          message: 'Error occurred while user trying to log out.',
          type: errorTypes.auth,
          userId: null,
          file: 'SignOutButton.jsx',
        })
        history.goBack()
      })
      .finally(() => {
        history.push(ROUTES.SIGN_IN.link)
        window.location.reload()
      })
  }

  return logout()
}
