import React from 'react'

import { StyledMotionTableBody, StyledTableBody } from './styles'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

export const TableBody = ({ children, ...props }) => {
  if (props.enableMotion) {
    return <StyledMotionTableBody {...props}>{children}</StyledMotionTableBody>
  }

  return <StyledTableBody {...props}>{children}</StyledTableBody>
}

TableBody.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
