import React from 'react'
import {
  Box,
  Link,
  mobBoxVariants,
  mobImageVariants,
  mobTextVariants,
} from '../../../shared'
import { Text } from '../../../shared/Text'
import { deskSecondPanelHeading, mobSecondPanelHeading } from './styles'
import { Frame, Scroll } from 'framer'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collections } from '../../../firebaseApp'
import { convertDateAndTimeForView } from '../../../utils/convertDateAndTimeForView'
import { deskImageVariants } from '../../../shared/Image/deskImageVariants'
import { ROUTES } from '../../../constants/routes'

const { column, mobWrapper, row, center } = mobBoxVariants
const {
  mobGigBoxHeader,
  mobGigBoxLocation,
  mobGigDate,
  mobHorizontalRule,
  mobGigBoxDescription,
} = mobTextVariants

export const GigSpotlightModule = ({
  currentUserAuth,
  relevantField,
  gigIcon,
  moduleHeader,
}) => {
  const [
    spotlightGigs,
    spotlightGigsLoading,
    spotlightGigsError,
  ] = useCollectionData(
    collections.gigs,
      // .orderBy('createdAt', 'asc'),
      // .where(relevantField, '==', true),
    {
    idField: 'id',
  })

  if (spotlightGigsLoading || spotlightGigsError) {
    return null
  }

  const gigIsCurrent = startTime => {
    const today = new Date()
    const gigStartDate = new Date(startTime.seconds * 1000)
    return today < gigStartDate
  }

  const filterGigs = spotlightGigs.filter(gig =>
    gigIsCurrent(gig.time.startTime)
  )

  if (filterGigs.length <= 0) {
    return null
  }

  return (
    <Box
      mobStyles={[
        center,
        column,
        mobWrapper,
        css`
          margin-bottom: 2.5vh;
        `,
      ]}
    >
      <Text
        mobStyles={[mobSecondPanelHeading]}
        deskStyles={[deskSecondPanelHeading]}
      >
        {moduleHeader}
      </Text>
      <Box
        mobStyles={[
          center,
          column,
          css`
            height: 400px;
          `,
        ]}
      >
        <Scroll direction={'horizontal'} height={450} width={'100%'}>
          <Frame height={450} background="FFF" width={'100%'}>
            <Box mobStyles={[center, row, mobWrapper]}>
              {filterGigs.map((gig, index) => {
                if (gig.deleted !== true && gig.hidden !== true) {
                  return createSpotlightGig(
                    gig,
                    index,
                    currentUserAuth,
                    gigIcon
                  )
                }
              })}
            </Box>
          </Frame>
        </Scroll>
      </Box>
    </Box>
  )
}

const gigContainer = css`
  height: 300px;
  width: 300px;
  text-align: left;
  border: 1px solid ${p => p.theme.colors.lightGray};
  border-radius: 5px;
  background-color: ${p => p.theme.colors.secondary};
  box-shadow: ${p => p.theme.shadow.regular};
  padding: 15px 15px 0px 15px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 15px;

  display: flex;
  ${up('desktop')} {
    height: 400px;
    width: 400px;
    margin: 35px;
  }
`

export const SignatureDate = styled.p`
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
  ${up('desktop')} {
    font-size: 20px;
  }
`

export const mobGreyBamLogo = css`
  width: 35px;
  margin-bottom: auto;
`

export const deskGreyBamLogo = css`
  width: 50px;
`

export const announcementDescription = css`
  height: 100px;
  width: auto;
  color: ${p => p.theme.colors.gray};
  font-family: ${p => p.theme.fonts.primary};
  font-size: 14px;
  line-height: 20px;
  margin: 3% 0 1%;
  overflow: scroll;
  flex: 1 1 auto;
  text-align: left;
`

const createSpotlightGig = (gig, index, currentUserAuth, gigIcon) => {
  const redirectLink = currentUserAuth ? `/gig/${gig.id}` : ROUTES.SIGN_UP.link

  return (
    <Link mobStyles={gigContainer} key={index} to={redirectLink}>
      <Box mobStyles={[row]}>
        <Text
          mobStyles={[
            mobGigBoxHeader,
            css`
              height: auto;
              font-size: 20px !important;
            `,
          ]}
          deskStyles={[
            mobGigBoxHeader,
            css`
              font-size: 25px !important;
            `,
          ]}
        >
          {gig.title}
        </Text>
        {gigIcon}
      </Box>
      <Text mobStyles={mobGigDate}>
        {convertDateAndTimeForView(
          gig.time.date,
          gig.time.startTime,
          gig.time.endTime
        )}
      </Text>
      <Text mobStyles={mobHorizontalRule} />
      <Text mobStyles={css`
    width: auto;
    max-height: 100px;
    color: ${p => p.theme.colors.gray};
    font-family: ${p => p.theme.fonts.primary};
    font-size: 15px;
    line-height: 20px;
    text-overflow: ellipsis;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
      `}>{gig.description}</Text>
      <Text
        mobStyles={[
          mobSecondPanelHeading,
          css`
            font-size: 20px;
            width: 100%;
            text-align: center;
          `,
        ]}
        deskStyles={[
          deskSecondPanelHeading,
          css`
            font-size: 25px;
          `,
        ]}
      >
        {currentUserAuth ? 'Click to apply!' : 'Click to sign up and apply!'}
      </Text>
      <Box
        mobStyles={[
          row,
          css`
            flex: 1;
            justify-content: flex-start;
            align-items: flex-end;
          `,
        ]}
      >
        {gig.location.address && (
          <Text mobStyles={mobGigBoxLocation}>{gig.location.address}</Text>
        )}
      </Box>
    </Link>
  )
}
