import React from 'react'
import PropTypes from 'prop-types'

import { StyledTextArea } from './styles'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const TextArea = ({
  fontSize = {
    mob: '16px',
    tab: '18px',
    desk: '18px',
    lgDesk: '18px',
  },
  children,
  role = 'textbox',
  ...props
}) => {
  return (
    <StyledTextArea fontSize={fontSize} {...props} role={role}>
      {children}
    </StyledTextArea>
  )
}

TextArea.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
}
