import React from 'react'
import { StyledInput, StyledMotionInput } from './styles'

import PropTypes from 'prop-types'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Input = ({ role = 'textbox', enableMotion, ...props }) => {
  if (enableMotion) {
    return <StyledMotionInput role={role} {...props} />
  }

  return <StyledInput role={role} {...props} />
}

Input.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
  icon: PropTypes.any,
  role: PropTypes.string,
  enableMotion: PropTypes.bool,
}
