import React, { useMemo } from 'react'
import { useSetRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { User } from '../../classes'
import { LoadingScreen } from '../../components'
import { ROUTES } from '../../constants/routes'
import { bookingCartState, searchState } from '../../store'
import { SearchType } from '../../constants/enums'
import { MobileProfileModule } from './MobileProfileModule'
import { useMediaQuery } from 'react-responsive'
import { desktopQuery } from '../../utils/responsiveVars'
import { DeskProfileModule } from './DeskProfileModule'
import { useUserAccountType } from '../../utils/useUserAccountType'

/**
 * In this file the user MAY or not be the current user.
 * @param match
 * @param history
 * @returns {*}
 * @constructor
 */
export const PublicProfile = ({ match, currentUserAuth }) => {
  const isDesktop = useMediaQuery(desktopQuery)
  const history = useHistory()
  const setSearch = useSetRecoilState(searchState)
  const setBookingCart = useSetRecoilState(bookingCartState)
  const userAccountType = useUserAccountType(currentUserAuth)

  const updateBookingCart = () => {
    setBookingCart(previousCart => {
      return {
        ...previousCart,
        operative: user.userId,
        operativeRate: user.getUserHourlyRate(),
      }
    })
    history.push(ROUTES.SELECT_GIG.link)
  }

  let profileIdToDisplay

  if (match.path === ROUTES.USER_PROFILE.link) {
    profileIdToDisplay = match.params.profile_id
  } else {
    profileIdToDisplay = currentUserAuth.uid
  }

  // Note: Not necessarily the current user's document
  const [
    selectedUserData,
    selectedUserDataLoading,
    selectedUserDataError,
  ] = useDocumentData(collections.userInformation.doc(profileIdToDisplay), {
    idField: 'id',
  })

  const [
    portfolioImagesData,
    portfolioImagesDataLoading,
    portfolioImagesDataError,
  ] = useCollectionData(
    collections.userInformation
      .doc(profileIdToDisplay)
      .collection('portfolioImages'),
    {
      idField: 'id',
    }
  )

  const redirectToUserLicenseImages = () => {
    setSearch(previousSearch => {
      return {
        ...previousSearch,
        type: SearchType.images,
        query: profileIdToDisplay,
      }
    })
    history.push(ROUTES.SEARCH.link)
  }

  const user = useMemo(() => {
    if (selectedUserData) {
      return new User(selectedUserData)
    }
  }, [selectedUserData])

  const dependencies = selectedUserData && portfolioImagesData
  const dependenciesLoading =
    selectedUserDataLoading || portfolioImagesDataLoading
  const dependenciesError = selectedUserDataError || portfolioImagesDataError

  if (dependenciesError) {
    return <strong>Error: {selectedUserDataError}</strong>
  }

  if (dependenciesLoading) {
    return <LoadingScreen />
  }

  const filteredPortfolio = portfolioImagesData.filter(portfolioImage => {
    return !portfolioImage.deleted || portfolioImage.deleted === null
  })

  let viewingOwnProfile
  if (currentUserAuth) {
    viewingOwnProfile = user.userId === currentUserAuth.uid
  }

  if (isDesktop) {
    return (
      <DeskProfileModule
        user={user}
        currentUserAuth={currentUserAuth}
        userAccountType={userAccountType}
        redirectToUserLicenseImages={redirectToUserLicenseImages}
        portfolioImagesData={filteredPortfolio}
        updateBookingCart={updateBookingCart}
        viewingOwnProfile={viewingOwnProfile}
      />
    )
  }

  return (
    <MobileProfileModule
      user={user}
      currentUserAuth={currentUserAuth}
      redirectToUserLicenseImages={redirectToUserLicenseImages}
      portfolioImagesData={filteredPortfolio}
      updateBookingCart={updateBookingCart}
      viewingOwnProfile={viewingOwnProfile}
    />
  )
}
