import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

/**
 * AccountSettingsForm is
 *
 */
export class AccountSettingsForm {
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT
  // TODO: ADD VALIDATION TO ALL USER INPUT

  /*
   * Identity  Fields and Functions
   */
  verifyFirstName
  verifyMiddleName
  verifyLastName
  verifyDateOfBirth
  verifyDriversLicense
  verifySSN
  verifyCity
  verifyZIP
  verifyState

  updateVerifyFirstName = event => {
    this.verifyFirstName = event.target.value
  }

  updateVerifyMiddleName = event => {
    this.verifyMiddleName = event.target.value
  }

  updateVerifyLastName = event => {
    this.verifyLastName = event.target.value
  }

  updateVerifyDateOfBirth = date => {
    this.verifyDateOfBirth = date
  }

  updateVerifyCity = event => {
    this.verifyCity = event.target.value
  }

  updateVerifyZIP = event => {
    this.verifyZIP = event.target.value
  }

  updateVerifyState = event => {
    this.verifyState = event.target.value
  }

  updateVerifyDriversLicense = event => {
    this.verifyDriversLicense = event.target.value
  }

  updateVerifySSN = event => {
    this.verifySSN = event.target.value
  }

  /*
   * Verification code Fields and Functions
   */
  verificationCode = ''

  updateVerificationCode = event => {
    this.verificationCode = event.target.value
  }

  /*
   * Phone Number Info Fields and Functions
   */
  newPhoneNumber
  phoneNumber

  updateNewPhoneNumber = event => {
    this.newPhoneNumber = event.target.value
  }

  /*
   * Password reset Info Fields and Functions
   */
  currentPassword
  newPassword
  confirmNewPassword

  updateCurrentPassword = event => {
    this.currentPassword = event.target.value
  }
  updateNewPassword = event => {
    this.newPassword = event.target.value
  }
  updateConfirmNewPassword = event => {
    this.confirmNewPassword = event.target.value
  }

  /*
   * Emails Info Fields and Functions
   */
  emailAddress
  newEmailAddress

  updateEmailAddress = event => {
    this.emailAddress = event.target.value
  }

  updateNewEmailAddress = event => {
    this.newEmailAddress = event.target.value
  }

  /*
   * Billing Info Fields and Functions
   */
  billingName
  billingEmail
  addressLine1
  addressLine2
  city
  state
  zip
  country
  token

  constructor() {
    this.country = 'US'
  }

  updateBillingName = event => {
    this.billingName = event.target.value
  }

  updateAddressLine1 = event => {
    this.addressLine1 = event.target.value
  }

  updateAddressLine2 = event => {
    this.addressLine2 = event.target.value
  }

  updateCity = event => {
    this.city = event.target.value
  }

  updateState = event => {
    this.state = event.target.value
  }

  updateBillingEmail = event => {
    this.billingEmail = event.target.value
  }

  updateZip = event => {
    this.zip = event.target.value
  }

  updateCountry = event => {
    this.country = event.target.value
  }

  updateToken = token => {
    this.token = token
  }

  billingFieldNames = new Set([
    'billingName',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip',
    // Country is automatically set to US until we explicitly open up to countries
    // Leaving for completeness
    // 'country',
  ])

  /**
   * This method prepares the data for submission to firestore by
   * removing all undefined values. If no data is found it returns undefined
   */
  validBillingData = () => {
    let formData = {}
    this.billingFieldNames.forEach(fieldName => {
      let fieldValue = get(this, fieldName, undefined)

      if (fieldValue) {
        formData[fieldName] = fieldValue
      } else {
        return false
      }
    })

    return !isEmpty(formData)
  }

  // Set of all required fields
  verifyIdentityFieldNames = new Set([
    'verifyFirstName',
    // 'verifyMiddleName',
    'verifyLastName',
    'verifyDateOfBirth',
    'verifyDriversLicense',
    'verifySSN',
    'verifyCity',
    'verifyZIP',
    'verifyState',
  ])

  /**
   * This method prepares the data for submission to firestore by
   * removing all undefined values. If no data is found it returns undefined
   */
  validVerifyIdentityData = () => {
    let allFieldsFilled = true
    this.verifyIdentityFieldNames.forEach(fieldName => {
      let fieldValue = get(this, fieldName, undefined)

      if (!fieldValue) {
        allFieldsFilled = false
      }
    })

    return allFieldsFilled
  }

  // updatesThatRequireAuth = () => {
  //   return (this.newPhoneNumber || this.newPassword || this.newEmailAddress)
  // }
}
