import firebase from 'firebase/app'
import { convertDateAndTimeForView } from '../../utils/convertDateAndTimeForView'
import get from 'lodash/get'

/**
 * Gig is a object that consists of an Gig document and helper methods
 *
 */
export class Gig {
  gigDocument
  gigData
  id

  applicants
  createdBy
  createdAt
  description
  title

  location
  address
  latLong
  lat
  lng
  locationMobileImageUrl

  status
  time
  date
  startTime
  endTime
  duration

  acceptingProposals

  constructor(gigDocumentData, gigJSON) {
    if (gigDocumentData) {
      this.gigDocument = gigDocumentData
      this.id = get(this.gigDocument, 'id', '')
      this.gigData =
        this.gigDocument && this.gigDocument.data
          ? this.gigDocument.data()
          : this.gigDocument
      this.time = get(this.gigData, 'time', '')
      this.date = get(this.time, 'date', firebase.firestore.Timestamp.now())
      this.startTime = get(
        this.time,
        'startTime',
        firebase.firestore.Timestamp.now()
      )
      this.endTime = get(
        this.time,
        'endTime',
        firebase.firestore.Timestamp.now()
      )
      this.createdAt = get(
        this.gigData,
        'createdAt',
        firebase.firestore.Timestamp.now()
      )
    }

    if (gigJSON) {
      this.gigData = gigJSON
      this.id = get(this.gigData, 'objectID', '')

      this.time = get(this.gigData, 'time', '')
      this.date = this.convertToFSTimestamp(
        get(this.time, 'date', { seconds: 0, nanoseconds: 0 })
      )

      this.startTime = this.convertToFSTimestamp(
        get(this.time, 'startTime', { seconds: 0, nanoseconds: 0 })
      )
      this.endTime = this.convertToFSTimestamp(
        get(this.time, 'endTime', { seconds: 0, nanoseconds: 0 })
      )
      this.createdAt = this.convertToFSTimestamp(
        get(this.gigData, 'createdAt', { seconds: 0, nanoseconds: 0 })
      )
    }

    // If Constructed without either param set defaults
    if (!gigJSON && !gigDocumentData) {
      this.time = {}
      this.date = firebase.firestore.Timestamp.now()
      this.startTime = firebase.firestore.Timestamp.now()
      this.endTime = firebase.firestore.Timestamp.now()
      this.createdAt = firebase.firestore.Timestamp.now()
    }

    this.acceptingProposals = get(this.gigData, 'acceptingProposals', true)
    this.createdBy = get(this.gigData, 'createdBy', '')
    this.applicants = get(this.gigData, 'eventData', '')
    this.description = get(this.gigData, 'description', '')
    this.title = get(this.gigData, 'title', '')
    this.location = get(this.gigData, 'location', '')
    this.address = get(this.location, 'address', '')
    this.latLong = get(this.location, 'latLong', '')
    this.lat = get(this.latLong, 'lat', 0)
    this.lng = get(this.latLong, 'lng', 0)
    this.locationMobileImageUrl = get(
      this.location,
      'locationMobileImageUrl',
      ''
    )

    this.duration = get(this.time, 'duration', 0)
  
    
    this.fixedPriceDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format(get(this.gigData, 'fixedPrice', 0) / 100)
    
    this.operativeFixedPayDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format((get(this.gigData, 'fixedPrice', 0) * .85 )/ 100)
    this.operativeFixedFeesDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format((get(this.gigData, 'fixedPrice', 0) * .15 )/ 100)
    this.agencyFixedFeesDisplay = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD',
    }).format((get(this.gigData, 'fixedPrice', 0) * .15 )/ 100)
    
    this.fixedPricePennies = get(this.gigData, 'fixedPrice', 0)
    this.operativeFixedPayPennies = (get(this.gigData, 'fixedPrice', 0) * .85 )
    this.operativeFixedFeesPennies = (get(this.gigData, 'fixedPrice', 0) * .15 )
    this.fixedPriceGig = get(this.gigData, 'fixedPriceGig', false)
    this.bronnerBrosIBS = get(this.gigData, 'bronnerBrosIBS', false)
  }

  getGig() {
    return this.gigDocument
  }

  getDateStringForGigBox() {
    convertDateAndTimeForView(this.date, this.startTime, this.endTime)
  }

  getDateStringSplit() {
    return convertDateAndTimeForView(
      this.date,
      this.startTime,
      this.endTime
    ).split('|')
  }

  getDateString() {
    return convertDateAndTimeForView(this.date, this.startTime, this.endTime)
  }

  isGigOwner(userId) {
    return userId === this.createdBy
  }

  renderProposalForm(userId) {
    return !this.isGigOwner(userId) && this.acceptingProposals
  }

  renderProposalModule(userId) {
    if (userId === this.createdBy) {
      return true
    }
  }

  gigStringy() {
    return `Gig: ${JSON.stringify(this.gigData)}`
  }

  createdInLastWeek = () => {
    if (!this.createdAt) {
      return false
    }
    const oneWeekInSeconds = 604800
    const nowSeconds = firebase.firestore.Timestamp.now().seconds
    const createdAt = this.createdAt.seconds
    return !(nowSeconds - createdAt < oneWeekInSeconds)
  }

  /**
   *  Used to transform Alogolia date objects back into Firestore Timestamps
   * @param object
   */
  convertToFSTimestamp(object) {
    return new firebase.firestore.Timestamp(
      object._seconds,
      object._nanoseconds
    )
  }
}
