import React from 'react'

import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6,
} from './styles'
import PropTypes from 'prop-types'
import { fontSizeProps } from '../sharedTypes'
import { responsiveStyleAndVariantsProps } from '../../utils/variantsAndStyles'

export const Heading = ({
  fontSize = {
    mob: '20px',
    tab: '24px',
    desk: '28px',
    lgDesk: '32px',
  },
  headingTag = 'h2',
  children,
  ...props
}) =>
  ({
    h1: (
      <StyledH1 fontSize={fontSize} {...props}>
        {children}
      </StyledH1>
    ),
    h2: (
      <StyledH2 fontSize={fontSize} {...props}>
        {children}
      </StyledH2>
    ),
    h3: (
      <StyledH3 fontSize={fontSize} {...props}>
        {children}
      </StyledH3>
    ),
    h4: (
      <StyledH4 fontSize={fontSize} {...props}>
        {children}
      </StyledH4>
    ),
    h5: (
      <StyledH5 fontSize={fontSize} {...props}>
        {children}
      </StyledH5>
    ),
    h6: (
      <StyledH6 fontSize={fontSize} {...props}>
        {children}
      </StyledH6>
    ),
  }[headingTag] || (
    <StyledH2 fontSize={fontSize} {...props}>
      {children}
    </StyledH2>
  ))

Heading.propTypes = {
  fontSize: PropTypes.shape({
    ...fontSizeProps,
  }),
  ...responsiveStyleAndVariantsProps,
  headingTag: PropTypes.string,
}
