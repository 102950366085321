import React from 'react'

import { StyledMotionTH, StyledTH } from './styles'
import { responsiveStyleAndVariantsProps } from '../../../utils/variantsAndStyles'

export const TH = ({ children, ...props }) => {
  if (props.enableMotion) {
    return <StyledMotionTH {...props}>{children}</StyledMotionTH>
  }

  return <StyledTH {...props}>{children}</StyledTH>
}

TH.propTypes = {
  ...responsiveStyleAndVariantsProps,
}
