import React, { useState } from 'react'
import 'firebase/storage'
import {
  Box,
  Button,
  mobBoxVariants,
  mobButtonVariants,
  mobTextVariants,
} from '../../shared'
import 'react-image-crop/dist/ReactCrop.css'
import { ImageWithModal } from '../../components/ImageWithModal'
import { Text } from '../../shared/Text'
import { css } from 'styled-components'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'
import { ProfileImageInput } from '../../components/Inputs/ProfileImageInput'

const { mobPageSubHeader, mobInstructionParagraph } = mobTextVariants
const { deskPageSubHeader, deskInstructionParagraph } = deskTextVariants
const { mobMultiImageWrapper, column, center } = mobBoxVariants
const {
  mobSubmitButton,
  mobLong305x46,
  mobSkipButton,
  mobButtonSpacer,
} = mobButtonVariants
const { deskSubmitButton } = deskTextVariants

export const ProfileImageUpdateModule = ({ user }) => {
  const [showProfileInput, setShowProfileInput] = useState(false)

  // If the user has a default image display the Image Input
  // If they have a custom image show them that image with instructions on how to delete it
  if (user.defaultImage || showProfileInput) {
    return (
      <ProfileImageInput
        user={user}
        setShowProfileInput={setShowProfileInput}
      />
    )
  } else {
    return (
      <Box mobStyles={[center, column]} id={'update-profile-image'}>
        <Text mobStyles={[mobPageSubHeader]} deskStyles={[deskPageSubHeader]}>
          Profile Image
        </Text>
        <Text
          mobStyles={[
            mobInstructionParagraph,
            css`
              margin: 0;
            `,
          ]}
          deskStyles={[deskInstructionParagraph]}
        >
          Click the photo below to access the delete button.
        </Text>
        <ImageWithModal
          imageSrc={user.getProfileImage()}
          userId={user.userId}
          profile
        />
        <Button
          mobStyles={[mobSubmitButton, mobLong305x46, mobButtonSpacer]}
          deskStyles={deskSubmitButton}
          onClick={() => {
            setShowProfileInput(true)
          }}
        >
          UPLOAD NEW IMAGE
        </Button>
      </Box>
    )
  }
}
