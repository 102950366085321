import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Box, deskBoxVariants, Image, mobBoxVariants, Text } from '../../shared'
import BAMLogo from '../../assets/logos/logo.svg'
import { getDeskMenuItems } from './utils'
import { AnimatePresence, motion } from 'framer-motion'
import { ROUTES } from '../../constants/routes'
import BlackBAMLogo from '../../assets/logos/black-logo.svg'
import { StickyHeader } from '../../components/StickyHeader'
import { AccountType } from '../../constants/enums'
import { useDocument } from 'react-firebase-hooks/firestore'
import { collections } from '../../firebaseApp'
import { User } from '../../classes'
import { IconContext } from 'react-icons'
import { IoMdMail } from 'react-icons/io'
import { theme } from '../../theme'
import { useUserUnreadMessages } from '../../utils/useUserUnreadMessages'

const { deskHomeWrapper, column } = deskBoxVariants

const DesktopNavigationWrapper = styled(Box)`
  position: ${p => (p.absolutePositioning ? 'absolute' : '')};
  margin-top: ${p => (p.absolutePositioning ? '35px' : '')};
  box-shadow: ${p =>
    p.absolutePositioning ? '' : '0 4px 12px 6px rgba(137,137,137,0.1)'};
  height: 100px;
  width: 100%;
`

const imageStyles = css`
  height: 35px;
  cursor: pointer;
`
const menuItemStyles = css`
  height: 18px;
  width: 150px;
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  color: ${p => (p.absolutePositioning ? '#ffffff' : '#000000')};
  cursor: pointer;
`
const menuLinkStyles = css`
  color: #000000;
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 10px;
  padding: 0;
  text-align: left;
  cursor: pointer;
`

const blackMenuLinkStyles = css`
  color: #000000;
  font-family: ${p => p.theme.fonts.secondary};
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 10px;
  padding: 0;
  text-align: left;
`

export const DesktopNavigationModule = ({
  match,
  userAccountType,
  currentUserAuth,
}) => {
  const history = useHistory()
  const absolutePositioning = [
    ROUTES.HOME.link,
    ROUTES.SIGN_IN.link,
    ROUTES.SIGN_UP.link,
    ROUTES.LICENSING.link,
    '/w/account-type',
    '/w/details',
    '/w/signup',
    '/w/login',
    '/w/gigs',
    '/w/payment',
  ].includes(match.path)

  const showBlackMenu = [ROUTES.SIGN_UP.link].includes(match.path)

  // Object with categories names as the key. And an array of routes as the value
  const deskCategories = getDeskMenuItems(userAccountType)
  const desktopNav = (
    <Box
      deskStyles={[
        deskHomeWrapper,
        !absolutePositioning
          ? css`
              padding: 0;
            `
          : '',
      ]}
    >
      <Box
        deskStyles={css`
          flex: 1;
          width: auto;
        `}
      >
        <Image
          src={absolutePositioning ? BAMLogo : BlackBAMLogo}
          deskStyles={imageStyles}
          onClick={() => {
            history.push(ROUTES.HOME.link)
          }}
        />
      </Box>
      {userAccountType === AccountType.guest && (
        <>
          <MenuCatergory
            text={'NAVIGATE'}
            category={'Navigate'}
            absolutePositioning={absolutePositioning}
            deskCategories={deskCategories}
            showBlackMenu={showBlackMenu}
          />
          <Text
            absolutePositioning={absolutePositioning}
            enableMotion
            initial={false}
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            deskStyles={[menuItemStyles, showBlackMenu ? 'color: black;' : '']}
            onClick={() => {
              history.push(ROUTES.SIGN_UP.link)
            }}
          >
            SIGN UP
          </Text>
          <Text
            absolutePositioning={absolutePositioning}
            enableMotion
            initial={false}
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            deskStyles={[menuItemStyles, showBlackMenu ? 'color: black;' : '']}
            onClick={() => {
              history.push(ROUTES.SIGN_IN.link)
            }}
          >
            LOG IN
          </Text>
        </>
      )}
      {userAccountType !== AccountType.guest && (
        <>
          <MenuCatergory
            text={'NAVIGATE'}
            category={'Navigate'}
            absolutePositioning={absolutePositioning}
            deskCategories={deskCategories}
          />
          <MenuCatergory
            text={'MANAGE'}
            category={'Manage'}
            absolutePositioning={absolutePositioning}
            deskCategories={deskCategories}
          />
          <MenuCatergory
            text={'ACCOUNT'}
            category={'Account'}
            absolutePositioning={absolutePositioning}
            deskCategories={deskCategories}
          />

          {currentUserAuth && (
            <>
              <MenuUnreadMessages
                currentUserAuth={currentUserAuth}
                history={history}
              />
              <MenuGreeting
                currentUserAuth={currentUserAuth}
                history={history}
              />
            </>
          )}
        </>
      )}
    </Box>
  )

  if (absolutePositioning) {
    return (
      <DesktopNavigationWrapper absolutePositioning={absolutePositioning}>
        {desktopNav}
      </DesktopNavigationWrapper>
    )
  } else {
    return <StickyHeader>{desktopNav}</StickyHeader>
  }
}

const { center, row } = mobBoxVariants

const MenuUnreadMessages = ({ currentUserAuth, history }) => {
  const [unreadCount] = useUserUnreadMessages(currentUserAuth)
  if (unreadCount <= 0) {
    return null
  }

  return (
    <Box
      enableMotion
      animate={{
        scale: [1, 1.15, 1],
      }}
      transition={{
        duration: 1,
        ease: 'easeInOut',
        loop: Infinity,
      }}
      onClick={() => {
        history.push(ROUTES.INBOX.link)
      }}
      mobStyles={[
        row,
        css`
          max-height: 50px;
          cursor: pointer;
          width: 75px;
          height: 100%;
          align-items: center;
          justify-content: center;
        `,
      ]}
    >
      <IconContext.Provider
        value={{
          color: theme.colors.blueGray,
          size: '2em',
        }}
      >
        <IoMdMail />
      </IconContext.Provider>
      <Text
        deskStyles={css`
          color: ${theme.colors.blueGray};
        `}
      >
        {unreadCount}
      </Text>
    </Box>
  )
}

const MenuGreeting = ({ currentUserAuth, history }) => {
  const [
    currentUserSnapshot,
    currentUserSnapshotLoading,
    currentUserSnapshotError,
  ] = useDocument(collections.userInformation.doc(currentUserAuth.uid))

  if (currentUserSnapshotLoading || currentUserSnapshotError) {
    return null
  }

  const user = new User(currentUserSnapshot)
  return (
    // WILL ONLY EVER BE ON MOBILE
    <Box
      mobStyles={[
        row,
        css`
          height: auto;
          justify-content: flex-end;
          align-items: flex-start;
          cursor: pointer;
        `,
      ]}
      onClick={() => {
        history.push(ROUTES.PROFILE.link + '/' + currentUserAuth.uid)
      }}
    >
      <Box
        mobStyles={[
          css`
            width: 50px;
            height: 50px;
          `,
          center,
        ]}
      >
        <Image
          src={user.getProfileImage()}
          mobStyles={[
            css`
              margin: 0;
              height: 100%;
              width: 100%;
              border-radius: 100%;
              background-size: cover;
            `,
          ]}
        />
      </Box>
    </Box>
  )
}

const MenuCatergory = ({
  text,
  category,
  absolutePositioning,
  deskCategories,
  showBlackMenu,
}) => {
  const history = useHistory()
  return (
    <TextAccordion
      catergory={text}
      absolutePositioning={absolutePositioning}
      showBlackMenu={showBlackMenu}
    >
      {deskCategories[category] &&
        Object.keys(deskCategories[category]).map((routeKey, index) => {
          const currRoute = deskCategories[category][index]
          return (
            <Box key={index}>
              <Text
                enableMotion
                whileHover={{ scale: 1.15 }}
                whileTap={{ scale: 0.9 }}
                deskStyles={[menuLinkStyles]}
                onClick={() => {
                  history.push(currRoute['link'])
                }}
              >
                {currRoute['text']}
              </Text>
            </Box>
          )
        })}
    </TextAccordion>
  )
}

const TextAccordion = ({
  catergory,
  children,
  absolutePositioning,
  showBlackMenu,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Box
      deskStyles={[
        column,
        css`
          position: relative;
        `,
      ]}
    >
      <Text
        absolutePositioning={absolutePositioning}
        enableMotion
        initial={false}
        onMouseEnter={() => {
          setOpen(true)
        }}
        onMouseLeave={() => {
          setOpen(false)
        }}
        onKeyPress={() => {
          setOpen(!open)
        }}
        onClick={() => {
          setOpen(!open)
        }}
        deskStyles={[menuItemStyles, showBlackMenu ? 'color: black;' : '']}
      >
        {catergory}
      </Text>
      <AnimatePresence initial={false}>
        {open && (
          <RouteContainer
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            onMouseEnter={() => {
              setOpen(true)
            }}
            onMouseLeave={() => {
              setOpen(false)
            }}
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </RouteContainer>
        )}
      </AnimatePresence>
    </Box>
  )
}

const RouteContainer = styled(motion.section)`
  opacity: 1;
  height: auto;
  background-color: white;
  border-radius: 10px;
  width: 150px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: black;
  margin-top: 0;
  box-shadow: 0 0 4px 2px rgba(202, 201, 201, 0.5);
  z-index: 10;
`
