import React, { useRef } from 'react'
import { collections } from '../../firebaseApp'
import 'firebase/storage'
import { InputWithHeader } from '../../components/Inputs/InputWithHeader'
import isEmpty from 'lodash/isEmpty'

import {
  Box,
  inputVariants,
  mobBoxVariants,
  mobTextVariants,
  Option,
  Select,
  Text,
} from '../../shared/index'
import { AccountType, Gender } from '../../constants/enums'
import { StickyFooterWithButton } from '../../components/StickyFooter'
import { UpdateProfileForm } from '../../classes/Forms/UpdateProfileForm'
import firebase from 'firebase/app'
import { DesktopPageWrapper } from '../../components/DesktopPageWrapper'
import { errorHandler, errorTypes } from '../../utils/errorHandler'
import { deskTextVariants } from '../../shared/Text/deskTextVariants'

const { column, mobWrapper } = mobBoxVariants
const { mobSelectInput } = inputVariants
const { mobPageHeader, mobInputHeader, mobPageSubHeader } = mobTextVariants
const { deskPageHeader } = deskTextVariants

export const CreateCreativePage = ({ history, currentUserAuth, user }) => {
  const profileForm = useRef(new UpdateProfileForm(undefined)).current
  const userInfoDocumentRef = collections.userInformation.doc(
    currentUserAuth.uid
  )

  const handleCreativeCreation = event => {
    event.preventDefault()
    const newCreativeInfo = profileForm.getProfileData()
    if (!isEmpty(newCreativeInfo)) {
      collections.userInformation
        .add({
          profileDetails: newCreativeInfo,
          managedBy: currentUserAuth.uid,
          managedByAgencyName: user.fullName,
          createdBy: currentUserAuth.uid,
          managedAccount: true,
          visibleInSearch: false,
          profileComplete: false,
          accountType: AccountType.model,
        })
        .then(newCreativeDocument => {
          userInfoDocumentRef
            .set(
              {
                usersManaged: firebase.firestore.FieldValue.arrayUnion(
                  newCreativeDocument.id
                ),
              },
              { merge: true }
            )
            .then(() => {
              history.push('/profile/' + newCreativeDocument.id)
            })
        })
        .catch(error => {
          errorHandler({
            error,
            errorId: 'ERROR_CREATE_NEW_CREATION',
            message: 'Failed to create a creative',
            type: errorTypes.creation,
            user: currentUserAuth && currentUserAuth.uid,
            file: 'CreateCreativePage.jsx',
          })
        })
    }
  }

  return (
    <DesktopPageWrapper>
      {user && (
        <Box mobStyles={[column, mobWrapper]}>
          <Text mobStyles={mobPageHeader} deskStyles={deskPageHeader}>
            Create a new Creative
          </Text>
          <Text mobStyles={[mobPageSubHeader]}>Profile Details</Text>
          <InputWithHeader
            header="NAME"
            inputName="fullName"
            onChange={profileForm.updateName}
            placeholderDefault="Enter Full Name"
          />
          <InputWithHeader
            header="HEIGHT (INCHES)"
            inputName="height"
            onChange={profileForm.updateHeight}
            placeholderDefault={`Example: 68`}
          />
          <InputWithHeader
            header="WEIGHT (LBS)"
            inputName="weight"
            type="number"
            onChange={profileForm.updateWeight}
            placeholderDefault="Example: 100"
          />
          <Text mobStyles={mobInputHeader} htmlFor="gender">
            GENDER
          </Text>
          <Select
            mobStyles={mobSelectInput}
            name="gender"
            onChange={profileForm.updateGender}
            data-testid="select-gender"
          >
            <Option
              value={profileForm.currentGender || 'pnts'}
              disabled
              defaultValue="true"
            >
              {user.getGender() || '-- Select One --'}
            </Option>
            <Option value={Gender.female}>Female</Option>
            <Option value={Gender.male}>Male</Option>
            <Option value={Gender.other}>Rather not say</Option>
          </Select>
          <Text mobStyles={mobInputHeader}>RACE</Text>
          <Select
            mobStyles={mobSelectInput}
            name="race"
            onChange={profileForm.updateRace}
            data-testid="select-race"
          >
            <Option value="pnts" disabled defaultValue="true">
              {profileForm.currentRace || '-- Select One --'}
            </Option>
            <Option value="black">Black</Option>
            <Option value="white">White</Option>
            <Option value="asian">Asian</Option>
            <Option value="american-indian">American Indian</Option>
            <Option value="native-hawaiian">Native Hawaiian</Option>
            <Option value="pnts">Prefer not to say</Option>
          </Select>
          <InputWithHeader
            header="ZIP CODE"
            inputName="zipCode"
            type="number"
            onChange={profileForm.updateZip}
            placeholderDefault="Example: 10001"
          />
          <InputWithHeader
            header="HOURLY RATE ($)"
            inputName="hourlyRate"
            type="number"
            onChange={profileForm.updateRate}
            placeholderDefault="100"
          />
        </Box>
      )}

      <StickyFooterWithButton
        mainButtonHandler={() => {}}
        mainButtonText={'COMING SOON'}
      />
    </DesktopPageWrapper>
  )
}
//        mainButtonHandler={handleCreativeCreation}
//         mainButtonText={'CREATE'}
